import React, { useState, useEffect, useRef } from 'react';
import { Form, Input, Button, Radio, Space, Typography, Select, Modal, message, Tag } from 'antd';
import { useHistory } from "react-router-dom";

import Api from "../../../api";
import SelfTable from "../../../components/SelfTable"
import {pagingParam} from "../../../utils/defaultValue";
import BackIcon from "../../../components/BackIcon/index";
import {ModalConfirm} from '../../../utils/tool'

import './index.scss'

const { Link } = Typography;
const { TextArea } = Input;

const Dict = () => {
    const history = useHistory();
    const [form] = Form.useForm();
    const [addForm] = Form.useForm();

    // 分页
    const [pagination, setPagination] = useState({
        "pageNum": 0,
        "pageSize": 0,
        "total":0
    });

    const [operationData, setOperationData] = useState({});

    // 列表数据
    const [listData, setListData] = useState([]);

    // 当前正在操作的数据KEY
    const [editingKey, setEditingKey] = useState('');

    const [tableLoading, setTableLoading] = useState(false);

    // 列表选择数据
    const [selectedRowKey, setSelectedRowKey] = useState([]);

    // 查询数据
    const [searchData, setSearchData] = useState({
        ...pagingParam,
    });

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);

    const columns = [
        {
            title: "字典编号",
            dataIndex: 'dictId',
            width:'100px',
          },
          {
            title: "字典名称",
            dataIndex: 'dictName',
          },
          {
            title: "字典类型",
            dataIndex: 'dictType',
            render: (dom, record) => {
              return (
                <Link onClick={() => searchDictData(dom)}>
                    {dom}
                </Link>
              );
            },
          },
          {
            title: "状态",
            dataIndex: 'status',
            width:'100px',
            render: (_, record) => {
              return (<Tag color={record.status == 0 ? 'blue' : 'red'}>{record.status == 0 ? '正常' : '停用'}</Tag>);
            },
          },
          {
            title: "备注",
            dataIndex: 'remark',
          },
          {
            title: "创建时间",
            dataIndex: 'createTime',
            width:'150px',
          },
        {
            title: '操作',
            width: '130px',
            render: (_, record) => {
                return <Space>
                <Link onClick={() => edit(record)}>
                    调整
                </Link>

                <Link  onClick={() => deleteDict(record)}>
                    删除
                </Link>
            </Space>
            },
        },
    ];


    useEffect(() => {
        getList();
    }, [searchData]);

    const getList = async() => {
        setTableLoading(true);
        const res = await Api.Dict.getDictList({data:searchData});
        setTableLoading(false);
        if(res&&res.code === 0){
            let _data = res.data;
            setListData(_data.records);
            setEditingKey('');
            setPagination({
                "pageNum": _data.current,
                "pageSize": _data.size,
                "total":_data.total
            });
        }else{
            setListData([]);
        }
    }

    // 修改
    const edit = (record) => {
        addForm.setFieldsValue({
            ...record,
            status:parseInt(record.status)
        });
        setEditingKey(record.dictId);
        setIsModalOpen(true);
    };

    const searchDictData = (dictType) => {
        history.push(`/page/manage/dictData?dictType=${dictType}`);
    }

    const onSearch = () => {
        let newSearchData = {
            ...searchData,
            ...operationData,
            pageNum:1
        }
        setSearchData(newSearchData);
    }

    const onResetting = () => {
        setOperationData({});
        setSearchData({
            ...pagingParam,
        })
    }

    // 修改-保存
    const save = async (key) => {
        try {
            const row = await addForm.validateFields();
            row.dictId = editingKey;
            setConfirmLoading(true);
            const res = await Api.Dict.updateDictType({data:row});
            if(res.code === 0){
                message.success("修改信息成功",2,()=>{
                    handleCancel();
                    getList();
                })
            }else{
                setConfirmLoading(false);
            }
        } catch (errInfo) {
            setConfirmLoading(false);
            console.log('验证失败:', errInfo);
        }
    };

    const deleteDict = (record) => {
        let ids = record.dictId ? record.dictId : selectedRowKey.join(',');
        if(ids){
            ModalConfirm({
                title: `您确认要删除字典编号为${ids}的数据项吗?`,
                onOk:async(resolve, reject)=> {
                    try{
                        const res = await Api.Dict.deleteDictType({data:ids});
                        resolve()
                        if(res&&res.code === 0){
                            getList();
                            setSelectedRowKey("");
                            message.success("删除成功")
                        }
                    }catch{}
                },
            })
        }else{
            message.info("请选择您需要删除的数据")
        }
    }

      // 新增
    const handleOk = async() => {
        if(editingKey){
            save() 
        }else{
            try {
                const row = await addForm.validateFields();
                setConfirmLoading(true);
                const res = await Api.Dict.addDictType({data:row});
                setConfirmLoading(false);
                if(res&&res.code === 0){
                    message.success("新增信息成功",2,()=>{
                        handleCancel();
                        getList();
                    })
                }
            } catch (errInfo) {
                setConfirmLoading(false);
                console.log('验证失败:', errInfo);
            }
        }
    }

    // 打开
    const addDict = () => {
        addForm.setFieldsValue({
            status:0
        });
        setIsModalOpen(true);
    }

    const handleCancel = () => {
        setConfirmLoading(false);
        setIsModalOpen(false);
        setEditingKey('');
    }


    const onChanges = (pagination, filters) => {
        let newSearchData = {...searchData}
        newSearchData.pageNum = pagination.current;
        newSearchData.pageSize = pagination.pageSize;
        setSearchData(newSearchData);
    };

    const searchChange = (value, type) => {
        let newOperationData = {...operationData};
        newOperationData[type] = value;
        setOperationData(newOperationData)
    }

    const refreshCache = async() => {
        try {
            const res = await Api.Dict.deleteRefreshCache({});
            if(res&&res.code === 0){
                message.success("刷新成功")
                getList();
            }
        } catch (errInfo) {
            console.log('验证失败:', errInfo);
        }
        
    }

    return (
        <div className="dictFrame">
             <div className="title">字典管理</div>
            <div className="operation">
                <div className="operationLeft">
                    <div className="operationItem">
                        <span className="inputTitle">字典名称：</span>
                        <Input 
                            placeholder="输入字典名称" 
                            value={operationData.dictName} 
                            onChange={(e)=>{
                                searchChange(e.target.value,"dictName")
                            }}
                            allowClear={true}
                        />
                    </div>
                    <div className="operationItem">
                        <span className="inputTitle">字典类型：</span>
                        <Input 
                            placeholder="输入字典类型" 
                            value={operationData.dictType} 
                            onChange={(e)=>{
                                searchChange(e.target.value,"dictType")
                            }}
                            allowClear={true}
                        />
                    </div>
                    <div className="operationItem">
                        <span className="inputTitle">状态：</span>
                        <Select
                            allowClear={true}
                            style={{
                                width: 120,
                            }}
                            value={operationData.status} 
                            onChange={(e)=>{
                                searchChange(e,"status")
                            }}
                            options={[
                                {
                                    value: 0,
                                    label: '正常',
                                },
                                {
                                    value: 1,
                                    label: '停用',
                                }
                            ]}
                        />
                    </div>
                    <Space size={"middle"}>
                        <Button type="primary" onClick={onSearch}>搜索</Button>
                        <Button onClick={onResetting}>重置</Button>
                    </Space>
                </div>

                <Space size={"large"}>
                    <Button type="primary" onClick={addDict}>添加字典</Button>
                    <Button type="primary" danger onClick={deleteDict}>删除字典</Button>
                    <Button danger onClick={refreshCache}>刷新缓存</Button>
                </Space>
            </div>

            <BackIcon />
            <div className="table">
                <Form form={form} component={false}>
                    <SelfTable
                        rowKey={'dictId'}
                        rowRadioSelection={{
                            type:'checkbox',
                            selectedRowKey:selectedRowKey,
                            setSelectedRowKey:setSelectedRowKey,
                        }}
                        pagination={{
                            "pageIndex": pagination.pageNum,
                            "pageSize": pagination.pageSize,
                            "total":pagination.total
                        }}
                        dataSource={listData}
                        columns={columns}
                        loading={tableLoading}
                        onChange={onChanges} 
                    />
                </Form>
            </div>

            <Modal 
                title="新增项目" 
                open={isModalOpen} 
                onOk={handleOk} 
                onCancel={handleCancel}
                maskClosable={false}
                destroyOnClose={true}
                confirmLoading={confirmLoading}
            >
                <Form
                    form={addForm}
                    component={false}
                    labelCol={{ span: 6 }}
                    wrapperCol={{ span: 14 }}
                    preserve={false}
                >
                    <Form.Item
                        key={"dictName"}
                        label={"字典名称"}
                        name={"dictName"}
                        rules={[{ required: true, message: `请输入字典名称!` }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        key={"dictType"}
                        label={"字典类型"}
                        name={"dictType"}
                        rules={[{ required: true, message: `请输入字典类型!` }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        key={"status"}
                        label={"状态"}
                        name={"status"}
                    >
                        <Radio.Group>
                            <Radio value={0}>正常</Radio>
                            <Radio value={1}>停用</Radio>
                        </Radio.Group>
                    </Form.Item>
                    <Form.Item
                        key={"remark"}
                        label={"备注"}
                        name={"remark"}
                    >
                        <TextArea
                            style={{
                                height: 120,
                            }}
                        />
                    </Form.Item>
                </Form>
            </Modal>


            <Modal 
                title="新增字典" 
                open={isModalOpen} 
                onOk={handleOk} 
                onCancel={handleCancel}
                maskClosable={false}
                destroyOnClose={true}
                confirmLoading={confirmLoading}
            >
                <Form
                    form={addForm}
                    component={false}
                    labelCol={{ span: 6 }}
                    wrapperCol={{ span: 14 }}
                    preserve={false}
                >
                    <Form.Item
                        key={"dictName"}
                        label={"字典名称"}
                        name={"dictName"}
                        rules={[{ required: true, message: `请输入字典名称!` }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        key={"dictType"}
                        label={"字典类型"}
                        name={"dictType"}
                        rules={[{ required: true, message: `请输入字典类型!` }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        key={"status"}
                        label={"状态"}
                        name={"status"}
                    >
                        <Radio.Group>
                            <Radio value={0}>正常</Radio>
                            <Radio value={1}>停用</Radio>
                        </Radio.Group>
                    </Form.Item>
                    <Form.Item
                        key={"remark"}
                        label={"备注"}
                        name={"remark"}
                    >
                        <TextArea
                            style={{
                                height: 120,
                            }}
                        />
                    </Form.Item>
                </Form>
            </Modal>

        </div>
    );
};
export default Dict;
