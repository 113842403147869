import react, {useEffect,useState} from 'react';
import { Select, Input, DatePicker, Tooltip, Tag } from 'antd';
import { CaretDownOutlined, CaretUpOutlined, SearchOutlined } from '@ant-design/icons';
import moment from 'moment'
import { useDispatch } from "react-redux";


import { refreshAIcarNumF } from "../../../../features/AIIntelligent/AIReducer";
import Api from "../../../../api";
import KnowledgeList from '../../../../components/KnowledgeList'
import Iconfont from "../../../../components/Iconfont/Iconfont";
import {getSearchParams, addAICart} from '../../../../utils/tool'

import './index.scss';

const { RangePicker } = DatePicker;

const ContentList = ({
    defaultSortKey = '',
    getDepartmentTree,
    autoHight,
    sortChange,
    paginationChange,
    pagination,
    searchData,
    listDate,
    projectChange,
    projectId,
    getList,
    knowledgeData,
    onSearch,
    searchChange,
    operationData,
    dateChange,
    overseasCaseSelect
}) => {
    const dispatch = useDispatch();
    const [projectList, setProjectList] = useState([])

    // 排序
    const [timeSort, setTimeSort] = useState('');
    const [sortKey, setSortKey] = useState('');

    const [sortList, setSortList] = useState([
        {key:'1',value:'按阅读量排序'},
        {key:'4',value:''}
    ]);

    const [showSearch, setShowSearch] = useState(false); // 控制搜索输入框是否显示
    const [showDate, setShowDate] = useState(false); // 控制日历选择框是否显示

    useEffect(()=>{
        if(searchData.knowledgeTypeId && searchData.knowledgeTypeId == 2){
            getProject();
        }
        setShowSearch(false);
        setShowDate(false);
    },[searchData.knowledgeTypeId])

    useEffect(()=>{
        if(defaultSortKey && (defaultSortKey == '2' || defaultSortKey == '3')){
            setTimeSort(defaultSortKey);
            setSortKey('4');
        }else{
            setSortKey(defaultSortKey)
        }
        
    },[defaultSortKey])
    
    const getProject = async() => {
        let res = await Api.Knowledge.getProjectIdAndName({data:{}});
        if(res&&res.code === 0){
            let _data = [{id:'',project_name:'全部项目'},...res.data]
            setProjectList(_data)
        }
    }

    const sortValueChange = (value) => {
        let _sort = '';
        // 按时间排序
        // 0 相关度 1 阅读量 4表示按时间排序 3 降序 2 升序
        if(value == '4'){
            _sort = timeSort === '2' ? '3' : timeSort === '3' ? '' :'2';
        }else{
            _sort = value
        }
        setSortKey(value);
        setTimeSort(_sort);
        sortChange(_sort,value)
    }

    const disabledDate = (current) => {
        return current && current > moment().endOf('day');
    };

    const addKnowledgeToAICart = async() => {
        console.log(searchData)
        
        const res = await addAICart([],{
            selectKnowledgeParam:searchData,
            addCartType:1
        });
        if(res){
            dispatch(refreshAIcarNumF(Date.now().toString()))
            // 刷新列表
            getList();
        }
    }


    return (
        <div className="contentListFrame">
            {overseasCaseSelect?.updateStutas && searchData.knowledgeTypeId == '9' &&<div className="overseasCaseBox">
                <span className="titleText">{overseasCaseSelect.octName}</span>
                <div className="extractBox">{overseasCaseSelect.digest}</div>
                <div className="keyWards">
                    <span className="keyWardsTitle">监控关键词：</span>
                    <div className="keyWardsList">
                        {overseasCaseSelect.keywords.map(item => (
                            <Tag key={item} color="default" title={item}>{item}</Tag>))}
                    </div>
                </div>
            </div>}

            {getSearchParams().inlet != "manage"?(<div className="sortBox">
                {/* {listDate && listDate.length >0?( */}
                    <div className="sort">
                        {sortList.map(item=>{
                            return(
                                <div className={item.key === sortKey ?'activeKeyClass':''} key={item.key} onClick={()=>{sortValueChange(item.key)}}>{item.value?item.value:(
                                    <div className="timeSort">按时间排序
                                        <CaretUpOutlined style={timeSort === '3' ?{color:'rgba(0, 0, 0, 0.85)'}:{}} />
                                        <CaretDownOutlined style={timeSort === '2' ?{color:'rgba(0, 0, 0, 0.85)'}:{}} />
                                    </div>
                                )}</div>
                            )
                        })}
                    </div>
                {/* ):""} */}

                {/* 经典案例 - 增加项目选项 */}
                {searchData.knowledgeTypeId && searchData.knowledgeTypeId == 2 ?(<Select 
                    defaultValue={projectId?projectId:"全部项目"}
                    options={projectList} 
                    onSelect={projectChange}
                    showSearch
                    filterOption={(input, option) =>
                        option.project_name.toLowerCase().includes(input.toLowerCase()) ||
                        option.id
                            .toString()
                            .toLowerCase()
                            .includes(input.toLowerCase())
                    } // 同时匹配 label 和 value
                    fieldNames={{
                        label:'project_name',
                        value:'id'
                    }}
                    size={'small'}
                    style={{
                        position: 'absolute',
                        top: '3px',
                        left: '208px',
                        width: "180px"
                    }}
                />
                ):""}

                {searchData.knowledgeTypeId && searchData.knowledgeTypeId == 9 && <div>
                    <div className="conditionItem" onClick={(e) => e.stopPropagation()} style={{marginRight: '100px'}}>
                        <Input
                            className="searchInputBox"
                            value={operationData.knowledgeName}
                            placeholder={"输入标题"}
                            allowClear={true}
                            onPressEnter={()=> {
                                onSearch();
                            }}
                            onChange={(e)=>{
                                searchChange(e.target.value,"knowledgeName");
                            }}
                        />
                         <span
                            className="iconBtn"
                            onClick={(e) => {
                                e.stopPropagation();
                                if (!operationData.knowledgeName) {
                                    setShowSearch(!showSearch)
                                }
                            }}
                        ><Iconfont type={"icon-search"} /></span>

                    </div>
                </div>}

                {searchData.knowledgeTypeId && searchData.knowledgeTypeId == 7 && <div>
                    <div className="conditionItem" onClick={(e) => e.stopPropagation()}>
                        {
                            (showSearch || operationData.text) ? (
                                <Input
                                    className="searchInputBox"
                                    value={operationData.text}
                                    placeholder={"输入标题或内容关键字"}
                                    allowClear={true}
                                    onPressEnter={()=> {
                                        onSearch();
                                    }}
                                    onChange={(e)=>{
                                        searchChange(e.target.value,"text");
                                    }}
                                />
                            ) : undefined
                        }

                        <span
                            className="iconBtn"
                            onClick={(e) => {
                                e.stopPropagation();
                                if (!operationData.text) {
                                    setShowSearch(!showSearch)
                                }
                            }}
                        ><Iconfont type={"icon-search"} /></span>

                    </div>
                    <div className="conditionItem" onClick={(e) => e.stopPropagation()}>
                        {
                            (showDate || operationData.startTime) ? (
                                <RangePicker
                                    className="choiceDateBox"
                                    style={{width: "10rem"}}
                                    suffixIcon={undefined}
                                    disabledDate={disabledDate}
                                    value={operationData.startTime?[moment(operationData.startTime), moment(operationData.endTime)]:[]}
                                    onChange={(date, dateString)=>{
                                        dateChange(dateString)
                                    }}
                                />
                            ) : undefined
                        }
                        <span
                            className="iconBtn"
                            onClick={(e) => {
                                e.stopPropagation();
                                if (!operationData.startTime) {
                                    setShowDate(!showDate)
                                }
                            }}
                        ><Iconfont type={"icon-riqi2"} /></span>
                    </div>
                </div>}

                {
                    searchData.knowledgeTypeId != 7 && <Tooltip 
                    title="全部加入购物车"
                >
                    <div className='addCartAll' onClick={(e)=>{
                        e.stopPropagation();
                        addKnowledgeToAICart();
                    }}>
                        <span className={`AiBtn`}>
                            <Iconfont type="icon-waixingren" />
                            <Iconfont type="icon-jiahao" />
                        </span>
                        全部添加
                    </div>
                </Tooltip>
                }
                
            </div>):""}
            
            <KnowledgeList
                type={"contentList"}
                listDate={listDate}
                pagination={pagination}
                paginationChange={paginationChange}
                // autoHight={autoHight}
                getList={getList}
                knowledgeData={knowledgeData}
                showProject={searchData.knowledgeTypeId==2?true:false}
                getDepartmentTree={getDepartmentTree}
            />
        </div>
    )
}

export default ContentList;