import React,{ useState, useEffect } from 'react';
import {Input, Modal, Button, message } from 'antd';
import {
    InfoCircleOutlined,
    FolderOpenOutlined,
    RightCircleOutlined,
    DownCircleOutlined
} from "@ant-design/icons";

import Api from "../../api/index";
import SelfTable from "../SelfTable/index"
import { getSizeText, jumpToPage } from '../../utils/tool'
import './index.scss';

const { Search } = Input;
const Personalknowledge = ({open, cancelModal, addKnowledgeF}) => {
    const leftMenu = [
        {key: '1', label: '我收藏的'},
        {key: '2', label: '我上传的'}, // 草稿箱-未分享
        {key: '3', label: '我分享的'}, // 已分享
        {key: '4', label: '我创作的'}, // 作者中有我的
        {key: '5', label: '我读过的'},
    ]

    const columns = [
        {
          title: '资料名称',
          dataIndex: 'knowledgeName',
          key: 'knowledgeName',
          ellipsis: true,
          render: (text, record) => {
            return (
                <a className="overflowEllipsis" title={text} onClick={() => jumpToPage(record)}>{text}</a>
            )
        },
        },
        {
          title: '资料类型',
          dataIndex: 'fileType',
          key: 'fileType',
          width: '80px',
        },
        {
          title: '发布者',
          dataIndex: 'userName',
          key: 'userName',
          width: '100px',
        },
        {
          title: '修改时间',
          dataIndex: 'updateTime',
          key: 'updateTime',
          width: '160px',
        },
        {
            title: '文件大小',
            dataIndex: 'size',
            width: '80px',
            key: 'x',
            render: (text) => <span>{getSizeText(parseInt(text))}</span>,
        },
    ];


    const [multipleLength, setMultipleLength] = useState(0);
    const [allSelectedRowKey, setAllSelectedRowKey] = useState({
        '1':{},
        '2':{},
        '3':{},
        '4':{},
        '5':{},
    });
    const [listData, setListData] = useState([]);
    const [searchData, setSearchData] = useState({
        pageIndex: 1,
        pageSize:10
    });
    // 分页
    const [pagination, setPagination] = useState({
        "pageIndex": 0,
        "pageSize": 0,
        "total":0
    });
    const [selectedRowKey, setSelectedRowKey] = useState('');
    const [tableLoading, setTableLoading] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [currentId, setCurrentId] = useState('1');
    const [inputValue, setInputValue] = useState('');
    
    useEffect(() => {
        setIsModalOpen(open)
        if(open){
            getList();
        }
    }, [open])

    useEffect(()=>{
        getList();
    },[currentId])

    useEffect(()=>{
        getList();
    },[JSON.stringify(searchData)])

    useEffect(()=>{
        let _multipleLength = 0;
        let allRowList = {...allSelectedRowKey}; 
        
        for(let key in allRowList){
            let rowList = allRowList[key];
            for(let keys in rowList){
                _multipleLength += rowList[keys].length;
            }
        }
        console.log(_multipleLength)

        _multipleLength += selectedRowKey.length;
        
        setMultipleLength(_multipleLength)
    },[JSON.stringify(selectedRowKey)])

    const getList = async() => {
        setTableLoading(true);
        let listApi = getApi(searchData);
        try{
            const res = await listApi;
            const {code, data} = res;
            setTableLoading(false);
            if(code === 0){
                setListData(data.records);
                setPagination({
                    "pageIndex": data.current,
                    "pageSize": data.size,
                    "total":data.total
                });
            }
        } catch(res){
            setTableLoading(false);
        }
    }

    const getApi = () => {
        //  1 我收藏的 
        //  2 我上传的  // 草稿箱-未分享
        //  3 我分享的  // 已分享
        //  4 我创作的  // 作者中有我的
        //  5 我读过的 
        let _searchData = {...searchData}
        if(currentId === '1'){
            return Api.PersonalCenter.getKnowledgeCollection({data:_searchData});
        }else if(currentId === '2'){
            // _searchData.type = 2;
            return Api.PersonalCenter.getKnowledgeNotPublished({data:_searchData});
        }else if(currentId === '3'){
            return Api.PersonalCenter.getKnowledgePublished({data:_searchData});
        }else if(currentId === '4'){
            _searchData.name = _searchData.knowledgeName;
            delete _searchData.knowledgeName;
            return Api.Chat.getPageMyCreate({data:_searchData});
        }else if(currentId === '5'){
            return Api.PersonalCenter.getUserKnowledgeRead({data:_searchData});
        }
    }

    const changeList = (key) =>{
        setRowKeys(pagination.pageIndex);
        
        setSelectedRowKey('');
        setInputValue('');
        setSearchData({
            pageIndex: 1,
            pageSize:10
        })
        setPagination({
            "pageIndex": 0,
            "pageSize": 0,
            "total":0
        });
        setCurrentId(key);
    }

    const setRowKeys = (pageIndex) => {
        let allRowList = {...allSelectedRowKey}; 
        let rowList = allRowList[currentId];
        if(rowList){
            rowList[pageIndex] = selectedRowKey;
        }
        if(allRowList){
            allRowList[currentId] = rowList
        }
        setAllSelectedRowKey(allRowList);
    }

    const handleCancel = () => {
        setIsModalOpen(false);
        cancelModal();
    };

    const onSearch = () => {
        let newSearchData = {...searchData}
        newSearchData.knowledgeName = inputValue;
        setSearchData(newSearchData)
    }

    const tableChanges = (pagination, filters) => {
        let newSearchData = {...searchData}
        newSearchData.pageIndex = pagination.current;
        newSearchData.pageSize = pagination.pageSize;
        setSearchData(newSearchData);
        
        setSelectedRowKey('');
        setRowKeys(pagination.current)
        // allSelectedRowKey[currentId][pagination.current] = selectedRowKey;
        // console.log(allSelectedRowKey,"allSelectedRowKey")
        // allSelectedRowKey[pagination.current] = selectedRowKey;
        // setAllSelectedRowKey(allSelectedRowKey);
    }

    const addKnowledge = () => {

        let allRowList = {...allSelectedRowKey}; 
        let ids = [];

        for(let key in allRowList){
            let rowList = allRowList[key];
            for(let keys in rowList){
                ids = [...ids,...rowList[keys]]
            }
        }

        if(selectedRowKey){
            ids = [...ids,...selectedRowKey]
        }

        console.log(ids,selectedRowKey,"ids")
        if(ids.length === 0){
            message.info('请选择需要添加的知识')
            return;
        }{
            addKnowledgeF(ids);
            handleCancel();
        }
    }

    const onInputChange = (e) => {
        setInputValue(e.target.value)
    }

    let showSelectedRowKey =  selectedRowKey ? selectedRowKey: [];
    if(JSON.stringify(allSelectedRowKey) !== '{}' && allSelectedRowKey[currentId] && allSelectedRowKey[currentId][pagination.pageIndex]){
        showSelectedRowKey = allSelectedRowKey[currentId][pagination.pageIndex]
    }
    
    return (
        <div>
            <Modal 
                centered={true} 
                open={isModalOpen} 
                footer={null}
                width={1100}
                wrapClassName={"personalknowledgeModal"}
                onCancel={handleCancel}
            >
                <div className='headerTitle'>
                    <span className='titleIcon'><InfoCircleOutlined />添加知识</span>
                </div>
                <div className='content'>
                    <div className='left'>
                        {leftMenu.map(item=>{
                            return <div className={`${currentId === item.key ? "selectedItem" : ""} item`} onClick={()=>changeList(item.key)}><FolderOpenOutlined />{item.label}</div>
                        })}
                    </div>
                    <div className='right'>
                        <div className='searchBox'>
                            <div className='searchInput'>
                                <span className='inputTitle'>已添加知识：</span>
                                <Search placeholder="请输入文件名称" value={inputValue} onChange={onInputChange} allowClear onSearch={onSearch}/>
                            </div>

                            已选{multipleLength}项<a style={{marginLeft:5}} onClick={()=>{
                                setMultipleLength(0);
                                setAllSelectedRowKey({});
                                setSelectedRowKey('');
                            }}>取消选择</a>

                            <Button 
                                type='primary'
                                className='startConversation' 
                                onClick={addKnowledge}
                            >确定添加</Button>
                        </div>
                        <SelfTable
                            selfClassName={"aiTableClass"}
                            rowKey={"knowledgeId"}
                            rowRadioSelection={{
                                type: 'checkbox',
                                selectedRowKey:showSelectedRowKey,
                                setSelectedRowKey:setSelectedRowKey,
                                getCheckboxProps: (record) => ({
                                                //0可加 1不可加                   //0未加 1已加
                                    disabled: record.cartStatus != 0 && record.addCartStatus != 0,
                                }),
                            }}
                            dataSource={listData}
                            columns={columns}
                            pagination={pagination}
                            loading={tableLoading}
                            onChange={tableChanges} 
                            expandable={{
                                expandedRowRender: (record) => (
                                    <p
                                        style={{
                                            margin: 0,
                                        }}
                                    >
                                        {record.knowledgeDigest}
                                    </p>
                                ),
                                expandIcon: ({expanded, onExpand, record}) =>
                                    expanded ? (
                                        <DownCircleOutlined style={{color: "#1C76FD"}} onClick={e => onExpand(record, e)}/>
                                    ) : (
                                        <RightCircleOutlined style={{color: "#1C76FD"}} onClick={e => onExpand(record, e)}/>
                                    )
                            }}
                            
                            // String(cartStatus) === "0"
                            size={"small"}
                            bordered={false}
                            tableBodyHight={400}
                        />
                    </div>
                </div>
            </Modal>
        </div>
    )
};

export default Personalknowledge;