import React, { useEffect, useState } from "react";
import { Button, DatePicker } from "antd";

import AllNumView from "../../components/allNumView";
import SelfTable from "../../../../components/SelfTable";
import Api from "../../../../api";
import "./index.scss";

const { RangePicker } = DatePicker;

const PointsInquiry = () => {
  const [data, setData] = useState([]);
  const [isSpinning, setIsSpinning] = useState(true);
  // 查询数据
  const [siftData, setSiftData] = useState({
    endTime: null,
    pageIndex: 1,
    pageSize: 10,
    startTime: null,
  });
  const [pagination, setPagination] = useState({});
  const [defaultDate, setDefaultDate] = useState();
  useEffect(() => {
    getHistoryList(siftData);
  }, [siftData]);
  // 获取积分记录
  const getHistoryList = async (data) => {
    setIsSpinning(true);
    try {
      let res = await Api.PersonalCenter.getUserPointsHistory({ data });
      if (res && res.code === 0) {
        let _data = res.data;
        setData(_data.records);
        setIsSpinning(false);
        setPagination({
          pageIndex: _data.current,
          pageSize: _data.size,
          total: _data.total,
        });
      }
    } catch (error) {}
  };
  const columns = [
    {
      title: "时间",
      dataIndex: "createTime",
      key: "createTime",
      width: '160px',
    },
    {
      title: "变更维度",
      dataIndex: "scoreChangeDeimensionName",
      key: "scoreChangeDeimensionName",
      width: '120px',
    },
    {
      title: "变更分值",
      dataIndex: "scoreChangeValue",
      key: "scoreChangeValue",
      width: '100px',
      render: (_, records) => (
        <div className="scoreChangeValue">
          {" "}
          {records.scoreChangeType == "1" ? (
            <span className="add">+</span>
          ) : (
            <span className="reduce">-</span>
          )}{" "}
          {records.scoreChangeValue}
        </div>
      ),
    },
    {
      title: "当前余额",
      dataIndex: "currentScore",
      key: "currentScore",
      width: '130px',
    },
    {
      title: "变更原因",
      dataIndex: "scoreChangeId", 
      key: "scoreChangeId",
      filters: [
        {value:"1", label:"发布知识", text:"发布知识"},
        {value:"2", label:"收获点赞", text:"收获点赞"},
        {value:"3", label:"知识被收藏", text:"知识被收藏"},
        {value:"4", label:"知识被下载", text:"知识被下载"},
        {value:"5", label:"知识被阅读", text:"知识被阅读"},
        {value:"6", label:"知识被评论", text:"知识被评论"},
        {value:"7", label:"阅读知识", text:"阅读知识"},
        {value:"8", label:"下载知识", text:"下载知识"},
        {value:"9", label:"收藏知识", text:"收藏知识"},
        {value:"10", label:"阅读时长奖励", text:"阅读时长奖励"},
        {value:"11", label:"记笔记", text:"记笔记"},
        {value:"12", label:"提问题", text:"提问题"},
        {value:"13", label:"兑换权益", text:"兑换权益"},
      ],
      filterMultiple:false,
      filteredValue:siftData.scoreChangeId ? [siftData.scoreChangeId] : [],
      render:(_, record)=>{
          return record.scoreChangeName
      },
      changeType:"select",
      width: '120px',
    },
    {
      title: "详情",
      dataIndex: "scoreChangeDetailMessage",
      key: "scoreChangeDetailMessage",
      render: (_,records) => <span>{records.scoreChangeDetailMessage}</span>,
    },
  ];
  const onChange = (date, dateString) => {
    let newSearchData = { ...siftData };
    newSearchData.startTime = dateString[0];
    newSearchData.endTime = dateString[1];
    newSearchData.pageIndex = 1;
    newSearchData.pageSize = 10;
    setSiftData(newSearchData);
  };
  const tableChange = (pagination, filters) => {
    let newSearchData = { ...siftData };
    newSearchData.pageSize = pagination.pageSize;
    newSearchData.pageIndex = pagination.current;
    for (let key in filters) {
      newSearchData[key] = filters[key] ? filters[key][0] : ''
    }
    setSiftData(newSearchData);
  };
  const resetPickDate = () => {
    setDefaultDate(new Date());
    getHistoryList({
      endTime: null,
      pageIndex: 1,
      pageSize: 10,
      startTime: null,
    });
  };

  return (
    <div className="pointsInquiryFrame">
      <div className="numall">
        <AllNumView></AllNumView>
      </div>
      <div className="pointsHistory">
        <div className="pointsHistoryTitle">
          系统积分变动记录
          <div className="pickDate">
            <RangePicker
              onChange={onChange}
              allowClear={true}
              key={defaultDate}
            />
            <Button
              type="primary"
              style={{ marginLeft: "0.3rem" }}
              onClick={resetPickDate}
            >
              重置
            </Button>
          </div>
        </div>
        <div className="pointsHistoryContent">
          <SelfTable
            onChange={tableChange}
            columns={columns}
            dataSource={data}
            loading={isSpinning}
            pagination={pagination}
          />
        </div>
      </div>
    </div>
  );
};

export default PointsInquiry;
