import React, {useState, useEffect, useRef} from 'react';
import {Button, Tag, Space, Typography, Modal, Form, InputNumber, Input, Avatar, Tooltip, message, Select, Divider, Spin, List, Transfer, Tree, Popconfirm, Checkbox} from 'antd';
import {PlusOutlined, LoadingOutlined, SwapOutlined} from '@ant-design/icons'
import { useHistory } from "react-router-dom";

import storage from "../../../utils/storage";
import Iconfont from "../../../components/Iconfont/Iconfont";
import NoneData from "../../../components/NoneData/index";
import PolicyTree from "../../../components/PolicyTree";
import PolicyList from "../../../components/PolicyList";
import UploadBtn from "../../../components/PolicyComponents/UploadBtn";
import UploadDrawer from "../../../components/PolicyComponents/UploadDrawer";
import PolicyListModal from "../../../components/PolicyComponents/PolicyListModal";
import PolicyIndicator from "../../../components/PolicyComponents/PolicyIndicator";
import {getSearchParams, ModalConfirm} from "../../../utils/tool";
import Api from "../../../api/index";
import {pagingParam} from '../../../utils/defaultValue'
import BackIcon from "../../../components/BackIcon/index";
import DefalutHeadImg from "../../../assets/img/defalutHeadImg.png";

import "./index.scss";

const { Text, Link } = Typography;

let policyThemeIDandName = {}

const PolicyOperationManage = () => {
    const history = useHistory();
    let expandedKeys = [];

    const isManage = getSearchParams().inlet === "manage";

    const [addPolicyThemesForm] = Form.useForm();
    const KeyWordsInputRef = useRef(null);
    const PolicyIndicatorRef = useRef(null);
    const items = [
        {label: '政策主题跟踪', key: '1'},
        {label: '政策指标查询', key: '2'},
    ];
    const [tabsActiveKey, setTabsActiveKey] = useState('1');

    // 抽屉开关
    const [open, setOpen] = useState(false);
    // 上传的文件id
    const [uploadFileId, setUploadFileId] = useState(null);
    const [userInfo, setUserInfo] = useState(storage.getItem("userInfo") ? storage.getItem("userInfo") : {})

    const [listDate, setListDate] = useState([]);
    const [pagination, setPagination] = useState("");

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [ConfirmLoading, setConfirmLoading] = useState(false);

    // 关键字
    const [KeyWordsList, setKeyWordsList] = useState([]);
    const [KeyWordsInputVisible, setKeyWordsInputVisible] = useState(false);
    const [KeyWordsInputValue, setKeyWordsInputValue] = useState('');

    // 分类管理
    const [draggable, setDraggable] = useState(false); // 是否显示操作按钮
    const [policyThemeType, setPolicyThemeType] = useState(""); // 0主题类型，1主题名称

    const [selectedNode, setSelectedNode] = useState(null); // 选中的操作节点

    const [selectedTheme, setSelectedTheme] = useState(""); //当前选中的主题
    const [modalTitle, setModalTitle] = useState(""); // 弹窗title

    const [expandedKeyList, setExpandedKeyList] = useState([]);

    // 分类树
    const [treeDate, setTreeDate] = useState("");
    const [treeName, setTreeName] = useState("");

    // 搜索框
    const [searchOpen, setSearchOpen] = useState(false);
    const [selecthValue, setSelecthValue] = useState('1');
    const [searchValue, setSearchValue] = useState('');

    // 站内搜索文件弹窗
    const [searchModalOpen, setSearchModalOpen] = useState(false);

    const [searchData, setSearchData] = useState({
        departmentId: isManage ? -1 : storage.getItem("userInfo").departmentId,
        ...pagingParam,
        policyRegionId: "",
    });

    // 判断新增类型是上传还是站内搜索
    const [policyFromType, setPolicyFromType] = useState(null);
    // 上传的文件名
    const [uploadFileName, setUploadFileName] = useState(null);
    const [uploadFileExtension, setUploadFileExtension] = useState(null);

    const [policyType, setPolicyType] = useState({});

    const [loadingType, setLoadingType] = useState(false);
    const [loadingTreeType, setLoadingTreeType] = useState(false);

    const [toEditFormData, setToEditFormData] = useState(null)

    const [teamOpen, setTeamOpen] = useState(false);
    const [managementTeamBoxType, setManagementTeamBoxType] = useState('list');
    const [managementTeamBoxOption, setManagementTeamBoxOption] = useState({});
    const [initLoading, setInitLoading] = useState(false);
    const [annotateMembers, setAnnotateMembers] = useState([]);
    const [allMembers, setAllMembers] = useState([]);
    const [targetKeys, setTargetKeys] = useState([]);
    const [checkboxValue, setCheckboxValue] = useState([]);
    

    const [list, setList] = useState([{
        picture:{},
        name:"123213"
    },{
        picture:{},
        name:"222222"
    }]);

    useEffect(() => {
        if (uploadFileId) {
            setUploadFileId(null);
            history.push(`/page/policySignDetail?fileId=${uploadFileId}&policyThemeId=${searchData.policyThemeId}`);
        }
    }, [uploadFileId])

    useEffect(() => {
        getpolicyTree();

        if (sessionStorage.searchData) {
            let _searchData = JSON.parse(sessionStorage.searchData);
            setSearchData(_searchData)
        }

        if (sessionStorage.selectedTheme) {
            let _selectedTheme = JSON.parse(sessionStorage.selectedTheme);
            setSelectedTheme(_selectedTheme);
        }

        if (sessionStorage.themeTreeName) {
            setTreeName(sessionStorage.themeTreeName)
        }

        // 打开新增/修改弹窗时储存的初始化数据，
        // 在刷新页面的时候判断有数据直接打开弹窗回显数据
        // if (sessionStorage.addPolicyData) {
        //     let addPolicyData = JSON.parse(sessionStorage.addPolicyData);
        //     setUploadFileId(addPolicyData.uploadFileId);
        //     setSelectedTheme(addPolicyData.selectedTheme);
        //     setPolicyFromType(addPolicyData.policyFromType);
        //     setUploadFileName(addPolicyData.uploadFileName);
        //     setUploadFileExtension(addPolicyData.uploadFileExtension);
        //     setToEditFormData(addPolicyData.formData);
        //     setOpen(addPolicyData.open);
        //     setTreeName(addPolicyData.treeName)
        // }

        getAllAnnotateMembersList();
    }, [])

    useEffect(() => {
        if (searchData.policyThemeId) {
            sessionStorage.searchData = JSON.stringify(searchData)
            getPolicyList();
        }
    }, [JSON.stringify(searchData)])

    useEffect(() => {
        if (selectedTheme) {
            sessionStorage.selectedTheme = JSON.stringify(selectedTheme)
            let _searchData = {
                ...searchData,
                ...pagingParam,
                policyRegionId: "",
                "search": "",
                "startTime": "",
                "endTime": "",
                "code": "",
                "regionId": ""
            }
            _searchData.policyThemeId = selectedTheme.id;
            _searchData.policyKeyWords = selectedTheme.keywords;
            setSearchData(_searchData);
            setPagination("");
            setPolicyType({});
        }
    }, [JSON.stringify(selectedTheme)])

    const getPolicyList = async () => {
        setLoadingType(true);
        setListDate([])

        let _searchData = {...searchData};

        if (_searchData.regionId === 999) {
            delete _searchData.regionId
        }

        try {
            let res = await Api.Knowledge.getPolicyPageList({
                data: {
                    ..._searchData,
                    policyKeyWords: [], // 后端不要policyKeyWords 他要自己查
                    // labelType: 0
                }
            });

            setLoadingType(false);
            if (res && res.code === 0 && res.data.records) {
                setListDate([...res.data.records])
                setPagination({
                    "pageIndex": res.data.current,
                    "pageSize": res.data.size,
                    "total": res.data.total
                })
            } else {
                setPagination("")
            }
        } catch (errInfo) {
            setLoadingType(false);
            console.log('验证失败:', errInfo);
        }
    }

    const getpolicyTree = async () => {
        setLoadingTreeType(true);
        try {
            let res = await Api.Knowledge.getPolicyeThemeList({
                data: {
                    departmentId: isManage ? -1 : storage.getItem("userInfo").departmentId
                }
            });
            setLoadingTreeType(false);
            if (res && res.code === 0 && res.data) {
                setTreeDate(res.data)

                getPolicyeThemeName(res.data);

                getFirstPolicyeTheme(res.data);

                if(expandedKeys.length > 0){
                    setExpandedKeyList(expandedKeys)
                }

            }else{setTreeDate([])}
        } catch (errInfo) {
            setTreeDate([]);
            setLoadingTreeType(false);
            console.log('验证失败:', errInfo);
        }
    }

    // 获取所有的分类ID
    const getFirstPolicyeTheme = (data) => {
        data.map(item=>{
            if(item.children){

                expandedKeys.push(item.id);

                getFirstPolicyeTheme(item.children)
            }
        })
    }

    const getPolicyeThemeName = (data,name) => {
        data.map(item=>{
            let _name = name ? name+'-'+item.policyName : item.policyName
            if(item.children&&item.children.length>0){
                getPolicyeThemeName(item.children,_name)
            }else{
                policyThemeIDandName[item.id] = _name
            }
        })
    }


    const onTabsChange = (activeKey) => {
        setTabsActiveKey(activeKey)
    }

    // 关键字
    const handleKeyWordsClose = (removedTag) => {
        const newTags = KeyWordsList.filter((tag) => tag !== removedTag);
        setKeyWordsList(newTags)
    };
    const showKeyWordsInput = () => {
        setKeyWordsInputVisible(true);
        setTimeout(() => {
            KeyWordsInputRef.current?.focus();
        })
    };
    const handleKeyWordsInputChange = (e) => {
        setKeyWordsInputValue(e.target.value);
    };
    const handleKeyWordsInputConfirm = () => {
        if (KeyWordsInputValue && KeyWordsList.indexOf(KeyWordsInputValue) === -1) {
            setKeyWordsList([...KeyWordsList, KeyWordsInputValue]);
        }
        setKeyWordsInputVisible(false);
        setKeyWordsInputValue('');
    };

    const handleCancel = () => {
        setIsModalOpen(false);
        setIsEdit(false);
        setKeyWordsList([]);
        addPolicyThemesForm.setFieldsValue({
            "policyName": "",
            "sort": "",
        });
    };
    const handleOk = async (state) => {
        const {
            isTheme, isEdit, selectedNode, addPolicyThemesForm,
            policyThemeType, KeyWordsList,
        } = state;
        setConfirmLoading(true);
        try {
            const row = await addPolicyThemesForm.validateFields();
            let tipText = "新增";
            let param = {
                ...row,
                policyThemeType,
                departmentId: isManage ? -1 : storage.getItem("userInfo").departmentId,
                parentId: selectedNode?.id || 0,
            }
            let func = Api.Knowledge.addPolicyTheme;

            if (isTheme) {
                param.keywords = [...KeyWordsList];
            }

            if (isEdit) {
                tipText = "修改";
                param.id = selectedNode.id;
                param.parentId = selectedNode.parentId;
                func = Api.Knowledge.updatePolicyTheme;
            }

            let res = await func({
                data: param
            });
            setConfirmLoading(false);
            if (res && res.code === 0) {

                if (isEdit && isTheme) {
                    // 修改成功后更新右侧的标题和关键词数据
                    setSelectedTheme({
                        ...selectedTheme,
                        keywords: KeyWordsList,
                        "policyName": row.policyName,
                        "sort": row.sort,
                    })
                }

                getpolicyTree();
                handleCancel();

                message.success(tipText + "成功");
            } else {
                message.error(tipText + "失败");
            }
        } catch (errInfo) {
            setConfirmLoading(false);
            console.log('验证失败:', errInfo);
        }
    }

    const deleteModal = async (nodeData) => {
        if (!nodeData) {
            message.success("请选择需要删除的政策类型");
            return false;
        }
        ModalConfirm({
            title: '你确定要删除该分类吗？',
            content: <Text type="danger">删除此分类后分类下所有数据都会被删除</Text>,
            onOk:async(resolve, reject)=> {
                try {
                    let res = await Api.Knowledge.deletePolicyTheme({
                        data: {
                            "id": nodeData.id
                        }
                    });
                    if (res && res.code === 0) {
                        resolve();
                        getpolicyTree();
                        message.success("删除成功");
                    }else{
                        reject();
                    }
                } catch (errInfo) {
                    reject();
                    console.log('删除失败:', errInfo);
                }
            },
        })
    }
    // 新增主题
    const addThemeFunc = (type, param) => {
        setPolicyThemeType(1);
        setIsModalOpen(true);

        if (type === "edit") {
            setKeyWordsList(param.keywords ? param.keywords : []);
            addPolicyThemesForm.setFieldsValue({
                "policyName": param.policyName,
                "sort": param.sort,
            });
            setIsEdit(true);
        } else {
            setIsEdit(false);
        }
    }

    // 选中主题
    const changePolicyTree = (param) => {
        setSelectedTheme(param);
        console.log(param,policyThemeIDandName,"11111")
        if (!param) {
            // 取消选中的主题后重置查询数据
            let _searchData = {
                departmentId: isManage ? -1 : storage.getItem("userInfo").departmentId,
                ...pagingParam,
                policyRegionId: "",
                "search": "",
                "startTime": "",
                "endTime": "",
                "code": "",
                "regionId": ""
            }
            setSearchData(_searchData)
            setPolicyType({});
            sessionStorage.removeItem("selectedTheme")
            sessionStorage.removeItem("themeTreeName")
        }else{
            sessionStorage.themeTreeName = policyThemeIDandName[param.id]
            setTreeName(policyThemeIDandName[param.id])
        }
    }

    const policyTypeChange = (param) => {
        setPolicyType({
            ...param,
        });
        delete param.name;

        let _searchData = {
            ...searchData,
            ...param
        }
        setSearchData(_searchData);

    }

    const paginationChange = (pageIndex, pageSize) => {
        let _searchData = {...searchData}
        _searchData.pageIndex = pageIndex;
        _searchData.pageSize = pageSize;
        setSearchData(_searchData);
    }

    // 搜索弹窗
    const onSearchValueChange = (e) => {
        setSearchValue(e.target.value)
    }
    const onSelect = (value) => {
        setSelecthValue(value);
    }
    const onClose = () => {
        setSearchOpen(false);
        setSearchValue("");
    }
    const openSearch = () => {
        setSearchOpen(true);
    }
    const onSearch = () => {
        let searchValueTrim = searchValue.trim()
        if (searchValueTrim) {
            setSearchOpen(false);
            setSearchModalOpen(true);
        } else {
            message.info('请输入需要搜索的内容')
        }
    }

    // 站内搜索列表
    const onCloseSearchModal = () => {
        setSearchModalOpen(false);
        setSearchValue("")
    }

    // 政策类型下 - 时间和标题筛选
    const operationChange = (value) => {
        let _searchData = {
            ...searchData,
            ...value
        }
        setSearchData(_searchData)
    };

    const menuClickFunc = (type, nodeData) => {
        setSelectedNode(nodeData); // 记录操作的节点

        switch (type) {
            case "addRootSort":
                setModalTitle("新增根分类");
                setPolicyThemeType(0);
                setIsEdit(false);
                setIsModalOpen(true);
                break;
            case "addSort":
                setModalTitle("新增子分类");
                setPolicyThemeType(0);
                setIsEdit(false);
                setIsModalOpen(true);
                break;
            case "addTheme":
                setModalTitle("新增主题");
                setPolicyThemeType(1);
                setIsEdit(false);
                setIsModalOpen(true);
                break;
            case "edit":
                const themeType = nodeData?.policyThemeType;
                const isTheme = String(themeType) === "1";
                setPolicyThemeType(themeType);
                setModalTitle(`编辑${ isTheme ? "主题" : "分类"}`);
                setIsEdit(true);

                addPolicyThemesForm.setFieldsValue({
                    "policyName": nodeData.policyName,
                    "sort": nodeData.sort,
                });

                if (isTheme) {
                    setKeyWordsList(nodeData.keywords ? nodeData.keywords : []);
                }
                setIsModalOpen(true);
                break;
            case "delete":
                deleteModal(nodeData);
                break;
        }
    }

    const managementTeam = () => {
        if(annotateMembers.length > 0){
            setManagementTeamBoxType('list');
            getManagementTeamOperation('list');
        }else{
            setManagementTeamBoxType('selct');
            getManagementTeamOperation('selct');
            getNotBecomeAnnotateMembers();
        }
        setTeamOpen(true);
    }

    const checkboxChange = (checkedValue) => {
        setCheckboxValue(checkedValue)
    }

    const getManagementTeamOperation = (type) => {
        let option = null;
        switch (type) {
            case 'list':
                setCheckboxValue([]);
                option = {
                    wrapClassName:"memberManagementModal",
                    title: <div><span>成员管理</span><Link onClick={()=>{
                        setManagementTeamBoxType('selct');
                        getManagementTeamOperation('selct');
                        getNotBecomeAnnotateMembers();
                    }}>添加成员</Link></div>,
                    width: 600,
                    onOk: (state) => {
                        setTeamOpen(false);
                    },
                    onCancel: (state) => {
                        setTeamOpen(false);
                    },
                };
            break;
            case 'selct':
                option = {
                    wrapClassName:"selectMemberManagementModal",
                    title: "选择标注人员",
                    width: 600,
                    onOk: (state) => {
                        addAnnotateMembers(state.targetKeys)
                    },
                    onCancel: (state) => {
                        if(state.annotateMembers.length <= 0){
                            setTeamOpen(false);
                        }else{
                            setManagementTeamBoxType('list');
                            getManagementTeamOperation('list');
                            getAllAnnotateMembersList();
                        }
                    },
                };
            break;
        }
        
        setManagementTeamBoxOption(option);
    }

    const getManagementTeamContent = (type) => {
        let content = null;
        switch (type) {
            case 'list':
                
                content = (<div>
                    <Checkbox.Group onChange={checkboxChange}>
                        <List
                            className="demo-loadmore-list"
                            loading={initLoading}
                            itemLayout="horizontal"
                            dataSource={annotateMembers}
                            renderItem={(item) => (
                                <List.Item key={item.id}>
                                    <Checkbox key={item.id} value={item.id}></Checkbox>
                                    <List.Item.Meta
                                        avatar={<Avatar src={item.fileUrl || DefalutHeadImg} />}
                                        title={item.userName}
                                    />
                                    <Space>
                                        <Text>成员</Text>
                                        <Popconfirm placement="topLeft" title={`确定移出标注人员${item.userName}吗？`} onConfirm={()=>{onMoveUser([item.id])}} okText="确定" cancelText="取消">
                                            <Text type="danger">移除</Text>
                                        </Popconfirm>
                                    </Space>
                                </List.Item>
                            )}
                        />
                    </Checkbox.Group>

                    {checkboxValue.length>0&&<Button type="danger" size="small" onClick={batchDeletion}>删除</Button>}
                </div>)
            break;
            case 'selct':
                const isChecked = (selectedKeys, eventKey) => selectedKeys.includes(eventKey);

                const generateTree = (treeNodes = [], checkedKeys = []) =>
                treeNodes.map(({ children, ...props }) => ({
                    ...props,
                    disabled: checkedKeys.includes(props.key),
                    children: generateTree(children, checkedKeys),
                }));

                const transferDataSource = [];
                function flatten(list = []) {
                  list.forEach((item) => {
                    transferDataSource.push(item);
                    flatten(item.children);
                  });
                }
                flatten(allMembers);

                content = (<div>
                    <Transfer
                        dataSource={transferDataSource} 
                        onChange={onTransferChange}
                        targetKeys={targetKeys}
                        className="tree-transfer"
                        render={(item) => item.title}
                        showSelectAll={false}
                        showSearch={true}
                        onSearch={onTransferSearch}
                    >
                        {({ direction, onItemSelect, selectedKeys }) => {
                            if (direction === 'left') {
                                const checkedKeys = [...selectedKeys, ...targetKeys];
                                return (
                                    <Tree
                                        blockNode
                                        checkable
                                        checkStrictly
                                        defaultExpandAll
                                        checkedKeys={checkedKeys}
                                        treeData={generateTree(allMembers, targetKeys)}
                                        onCheck={(_, { node: { key } }) => {
                                            onItemSelect(key, !isChecked(checkedKeys, key));
                                        }}
                                        onSelect={(_, { node: { key } }) => {
                                            onItemSelect(key, !isChecked(checkedKeys, key));
                                        }}
                                    />
                                );
                            }
                        }}
                    </Transfer>
                </div>)
            break;
        }

        return content
    }

    // 获取所有标注用户
    const getAllAnnotateMembersList = async() => {
        setInitLoading(true);
        try {
            let res = await Api.Knowledge.getAllPolicyUser({});
            if (res && res.code === 0) {
                setAnnotateMembers(res.data)
            }
            setInitLoading(false);
        } catch (errInfo) {
            setInitLoading(false);
            console.log('失败:', errInfo);
        }
    }

    // 移除标注团队成员
    const onMoveUser = async(userIds) => {
        try {
            let res = await Api.Knowledge.removePolicyUser({data:{userIds}});
            if (res && res.code === 0) {
                message.success("删除成功");
                getAllAnnotateMembersList();
                setCheckboxValue([])
            }
        } catch (errInfo) {
            console.log('失败:', errInfo);
        }
    }
    // 批量删除
    const batchDeletion = () => {
        ModalConfirm({
            title: `您确认要删除${checkboxValue.length}位标注人员吗?`,
            onOk:async(resolve, reject)=> {
                onMoveUser(checkboxValue)
                resolve();
            },
        })
    }

    // 获取所有未成为标注成员的人员
    const getNotBecomeAnnotateMembers = async(name) => {
        try {
            let res = await Api.Knowledge.getNotBecomeAnnotateMembers({data:{name}});
            if (res && res.code === 0) {
                const generateTree = (treeNodes = []) =>{
                    return treeNodes.map((item) => ({
                        key: item.userId || item.id,
                        title: item.userName || item.name,
                        children: item.users && generateTree(item.users),
                    }));
                }
                let data = generateTree(res.data)

                setAllMembers(data)
            }
        } catch (errInfo) {
            console.log('失败:', errInfo);
        }
    }

    const onTransferChange = (keys) => {
        setTargetKeys(keys);
    }

    const onTransferSearch = (direction,value) => {
        getNotBecomeAnnotateMembers(value);
    }

    // 添加标注团队成员
    const addAnnotateMembers = async(userIds) => {
        if(userIds && userIds.length > 0){
            try {
                let res = await Api.Knowledge.addAnnotateMembers({data:{userIds}});
                if (res && res.code === 0) {
                    message.success("添加成功");
                    setManagementTeamBoxType('list');
                    getManagementTeamOperation('list');
                    getAllAnnotateMembersList();
                }
            } catch (errInfo) {
                console.log('失败:', errInfo);
            }
        }else{
            message.info("请选择要添加的成员！")
        }
    }

    const isHaveKeywords = selectedTheme?.keywords?.length > 0;
    const isTheme = String(policyThemeType) === "1";

    const state = {
        isTheme, isEdit, selectedNode, addPolicyThemesForm,
        policyThemeType, KeyWordsList, targetKeys, annotateMembers
    };

    return (
        <div className="policyOperationFrame">

            <div className="topTitle">
                {
                    isManage ? "公共政策":userInfo?.departmentName
                }
                <BackIcon />

                <div className="teamManagement">
                    <div className='teamManagementList'>
                        <Space>
                            {annotateMembers.map(item=>{
                                return <Avatar src={item.fileUrl || DefalutHeadImg} />
                            })}
                        </Space>
                    </div>
                    <Button 
                        type="primary" 
                        onClick={managementTeam}
                        style={{marginLeft:10,marginTop: 9}}
                    >标注团队管理</Button>
                </div>
            </div>

            <div className="content">
                <div className="leftBox">
                    <div className="leftHeadBox">
                        <span className="headTitleText">主题列表</span>
                        <div className="classificationList" style={{display: userInfo.departmentId ? "" : "none"}}>
                            <Tooltip title={"新增根分类"}>
                                <span
                                    className="operateBtn"
                                    onClick={() => menuClickFunc("addRootSort")}
                                ><Iconfont type={"icon-roundadd"} /></span>
                            </Tooltip>
                        </div>
                    </div>

                    <div className="policyTreeBox">
                        <Spin tip="数据加载中" spinning={loadingTreeType}>
                            <PolicyTree
                                treeDate={treeDate}
                                changePolicyTree={changePolicyTree}
                                selectedNode={selectedTheme}
                                menuClickFunc={menuClickFunc}
                                expandedKeyList={expandedKeyList}
                            />
                        </Spin>
                    </div>
                </div>

                <div className="rightBox">
                    {
                        selectedTheme ?
                        (
                            <div
                                className="rightContentBox"
                                style={{
                                    height: "100%",
                                    display: 'flex',
                                    flexDirection: 'column'
                                }}
                            >
                                <div className="rightTopBox" style={{paddingBottom: isHaveKeywords ? "" : "1rem"}}>
                                    <div className="topLeftBox">
                                        <span className="titleText">{selectedTheme.policyName}</span>
                                        <div className="keyWards">
                                            <span className="keyWardsTitle">监控关键词：</span>
                                            <div className="keyWardsList">
                                                {isHaveKeywords ? selectedTheme.keywords.map(item => (
                                                    <Tag key={item} color="default" title={item}>{item}</Tag>)) : ""}
                                                    <Tag
                                                        className="site-tag-plus"
                                                        onClick={() => menuClickFunc("edit",selectedTheme)}
                                                    >
                                                        <PlusOutlined />
                                                    </Tag>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="topRightBox">
                                        {/* <div className="tabListBox">
                                            {
                                                items.map(item => {
                                                    const {label, key} = item;
                                                    const isActive = tabsActiveKey === key;
                                                    return (
                                                        <span
                                                            className={`tabItem ${isActive ? "activeStatus" : ""}`}
                                                            key={key}
                                                            onClick={() => onTabsChange(key)}
                                                        >{label}</span>
                                                    )
                                                })
                                            }
                                        </div> */}
                                        {/* {
                                            tabsActiveKey === '1' ? (
                                                <div className="addButton">
                                                    <UploadBtn
                                                        setOpen={setOpen}
                                                        setUploadFileId={setUploadFileId}
                                                        setPolicyFromType={setPolicyFromType}
                                                        setUploadFileName={setUploadFileName}
                                                        setUploadFileExtension={setUploadFileExtension}
                                                    />
                                                    <Button type="primary" onClick={openSearch}>关联政策文件</Button>
                                                </div>
                                            ) : undefined
                                        } */}
                                        <div className="addButton">
                                            <UploadBtn
                                                setOpen={setOpen}
                                                setUploadFileId={setUploadFileId}
                                                setPolicyFromType={setPolicyFromType}
                                                setUploadFileName={setUploadFileName}
                                                setUploadFileExtension={setUploadFileExtension}
                                            />
                                            {/* <Button type="primary" onClick={openSearch}>关联政策文件</Button> */}
                                        </div>
                                    </div>
                                </div>

                                <div className="rightBottomBox">
                                    {tabsActiveKey === '1' ? (
                                        <div className="policyListBox">
                                            <Spin tip="数据加载中" spinning={loadingType}>
                                                <PolicyList
                                                    policyTypeChange={policyTypeChange}
                                                    listDate={listDate}
                                                    paginationChange={paginationChange}
                                                    pagination={pagination}
                                                    // policyThemeName={selectedTheme.policyName}
                                                    policyType={policyType}
                                                    searchData={searchData}
                                                    getPolicyList={getPolicyList}
                                                    setToEditFormData={setToEditFormData}
                                                    setOpen={setOpen}
                                                    operationChange={operationChange}
                                                    setPolicyFromType={setPolicyFromType}
                                                />
                                            </Spin>
                                        </div>
                                    ) : ""}
                                    {
                                        tabsActiveKey === "2" ?
                                            <PolicyIndicator
                                                ref={PolicyIndicatorRef}
                                                selectedTheme={selectedTheme}
                                                tabsActiveKey={tabsActiveKey}
                                            ></PolicyIndicator> : null
                                    }
                                </div>
                            </div>
                        ) : <NoneData text={"请选择政策主题后查询数据"} icon={"icon-zanwushuju"}/>
                    }
                </div>
            </div>

            {/* 站内搜索弹窗 */}
            {searchValue && searchModalOpen ? (
                <PolicyListModal
                    searchModalOpen={searchModalOpen}
                    defaultSearchValue={searchValue}
                    defaultSelecthValue={selecthValue}
                    onCloseSearchModal={onCloseSearchModal}
                    setToEditFormData={setToEditFormData}
                    selectedTheme={selectedTheme}
                    setOpen={setOpen}
                    setPolicyFromType={setPolicyFromType}
                />
            ) : ""}

            <Modal
                title={modalTitle}
                open={isModalOpen}
                onOk={() => handleOk(state)}
                onCancel={handleCancel}
                centered={true}
                confirmLoading={ConfirmLoading}
                forceRender={true}
                wrapClassName={"selfDesignModal"}
            >
                <Form
                    form={addPolicyThemesForm}
                    component={false}
                    labelCol={{span: 6}}
                    wrapperCol={{span: 18}}
                    preserve={false}
                >
                    <Form.Item
                        key={'policyName'}
                        name={'policyName'}
                        label={isTheme ? '政策主题名称' : '政策类型名称'}
                        rules={[{
                            required: true,
                            message: isTheme ? '请输入政策主题名称!' : '请输入政策类型名称!'
                        }]}
                    >
                        <Input/>
                    </Form.Item>
                    <Form.Item
                        key={'sort'}
                        name={'sort'}
                        label={'排序'}
                        rules={[{required: true, message: `请输入排序!`}]}
                    >
                        <InputNumber min={0} style={{width: "100%"}}/>
                    </Form.Item>

                    {isTheme ? (<Form.Item
                        key={'keywords'}
                        name={'keywords'}
                        label={'关键词'}
                        rules={[{required: false}]}
                    >
                        <div>
                            {KeyWordsList && KeyWordsList.map((item, index) => {
                                return <Tag closable={true}
                                            style={{marginBottom: 5}}
                                            onClose={(e) => {
                                                e.preventDefault();
                                                handleKeyWordsClose(item);
                                            }} key={item}>{item}</Tag>
                            })}
                            <Input
                                ref={KeyWordsInputRef}
                                type="text"
                                size="small"
                                style={{
                                    display: KeyWordsInputVisible ? "" : "none",
                                    width: 78,
                                }}
                                value={KeyWordsInputValue}
                                onChange={handleKeyWordsInputChange}
                                onBlur={handleKeyWordsInputConfirm}
                                onPressEnter={handleKeyWordsInputConfirm}
                            />
                            {!KeyWordsInputVisible && (
                                <Tag onClick={showKeyWordsInput} className="site-tag-plus"
                                ><PlusOutlined/></Tag>
                            )}
                        </div>
                    </Form.Item>) : ""}
                </Form>
            </Modal>

        {/* <UploadDrawer
            open={open}
            setOpen={setOpen}
            uploadFileId={uploadFileId}
            policyFromType={policyFromType}
            setPolicyFromType={setPolicyFromType}
            uploadFileName={uploadFileName}
            uploadFileExtension={uploadFileExtension}
            selectedTheme={selectedTheme}
            formData={toEditFormData}
            setToEditFormData={setToEditFormData}
            getPolicyList={getPolicyList}
            onCloseSearchModal={onCloseSearchModal}
            treeName={treeName}
        ></UploadDrawer> */}

        <Modal
            onCancel={onClose}
            open={searchOpen}
            title={"搜索"}
            footer={null}
            wrapClassName={"searchModal"}
            maskClosable={false}
            zIndex={800}
        >
            <div className="searchBar">
                <Select
                    value={selecthValue || '全文'}
                    size={"large"}
                    options={[{
                        value: '1',
                        label: '全文'
                    },
                    {
                        value: '0',
                        label: '标题'
                    }]}
                    onChange={onSelect}
                    bordered={false}
                    style={{width:100}}>
                </Select>
                <Divider className='verticalDivider' type="vertical" />
                <Input
                    value={searchValue}
                    placeholder="输入关键字"
                    size={"large"}
                    bordered={false}
                    onChange={onSearchValueChange}
                    onPressEnter={onSearch}
                />
            </div>
            <div className="footer">
                <Space>
                    <Button type="primary" onClick={onSearch}>搜索</Button>
                    <Button onClick={onClose} >取消</Button>
                </Space>
            </div>
        </Modal>


                    
        <Modal
            // onCancel={onClose}
            open={teamOpen}
            // title={"成员管理"}
            maskClosable={false}
            zIndex={800}
            {...managementTeamBoxOption}
            onOk={() => managementTeamBoxOption.onOk(state)} 
            onCancel={() => managementTeamBoxOption.onCancel(state)} 
        >
            {teamOpen && getManagementTeamContent(managementTeamBoxType)}
        </Modal>

    </div>)
}

export default PolicyOperationManage;
