import React, { useState, useEffect, useRef } from 'react';
import { InputNumber, Form, Input, Button, Radio, Space, Typography, Select, Modal, message, Checkbox, Switch } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import SelfTable from "../../../components/SelfTable/index";
import BackIcon from "../../../components/BackIcon";
import {pagingParam} from "../../../utils/defaultValue";
import {ModalConfirm} from '../../../utils/tool';
import Api from "../../../api";

import './index.scss'

const { confirm } = Modal;
const { Link } = Typography;

const DepartmentList = ({tableHight}) => {
    const [form] = Form.useForm();
    const [addForm] = Form.useForm();

    // 列表数据
    const [listData, setListData] = useState([]);
    const [defaultDate, setDefaultDate] = useState([]);

    // 当前正在操作的数据KEY
    const [editingKey, setEditingKey] = useState('');

    // 用户数据
    const [userList, setUserList] = useState([]);

    // 知识类型数据
    const [knowledgeList, setKnowledgeList] = useState([]);

    // 查询数据
    const [searchData, setSearchData] = useState({
        ...pagingParam,
    });
    // 分页
    const [pagination, setPagination] = useState({
        "pageIndex": 0,
        "pageSize": 0,
        "total":0
    });

    const [isModalOpen, setIsModalOpen] = useState(false);

    const [addDate, setAddDate] = useState([
        {
            title:"序号",
            dataIndex:"sort",
            type:""
        },
        {
            title:"部门",
            dataIndex:"name",
            type:""
        },
        {
            title:"部长",
            dataIndex:"ministerId",
            type:"select",
            required:'false'
        },
        {
            title:"知识管理员",
            dataIndex:"adminId",
            type:"select",
            required:'false'
        },
        // {
        //     title: '是否关联知识',
        //     dataIndex: 'knowledgeRel',
        //     type:"switch",
        //     required:'false',
        //     defaultDate:true
        // },
        // {
        //     title: '是否在发布范围中可见',
        //     dataIndex: 'publishRel',
        //     type:"switch",
        //     required:'false',
        //     defaultDate:false
        // },
        // {
        //     title:"默认关联知识类型",
        //     dataIndex:"knowledgeTypeIds",
        //     type:"checkbox",
        //     required:'false'
        // },
    ]);

    const [confirmLoading, setConfirmLoading] = useState(false);

    // 列表选择数据
    const [selectedRowKey, setSelectedRowKey] = useState('');

    const [tableLoading, setTableLoading] = useState(false);

    const [knowledgeType, setKnowledgeType] = useState(true);
    const [knowledgeDisabled, setKnowledgeDisabled] = useState(true);
    const [publishDisabled, setPublishDisabled] = useState(true);

    useEffect(() => {
        setInitDate();
    }, []);

    useEffect(() => {
        getList();
    }, [searchData]);

    const setInitDate = async() => {

        // 获取所有用户
        const res = await Api.User.getAllUsersList({});
        if(res&&res.code === 0){
            setUserList(res.data);
        }else{
            setUserList([])
        }

        // 获取知识类型
        const resKnowledge = await Api.Knowledge.getAllKnowledgeList({});
        if(resKnowledge&&resKnowledge.code === 0){
            let newData = resKnowledge.data.map(item=>{
                return{
                    value: item.id,
                    label: item.knowledge_type_name,
                }
            });
            setKnowledgeList(newData);
        }else{
            setKnowledgeList([])
        }
    }


    // 获取列表数据
    const getList = async() => {
        setTableLoading(true);
        const res = await Api.Department.getDepartmentPage({data:searchData});
        setTableLoading(false);
        if(res&&res.code === 0){
            let _data = res.data;
            setListData(_data.records);
            setDefaultDate(_data.records);
            setPagination({
                "pageIndex": _data.current,
                "pageSize": _data.size,
                "total":_data.total
            });
            setEditingKey('');
        }else{
            setListData([]);
            setDefaultDate([]);
        }
    }

    const isEditing = (record) => record.id === editingKey;

    const EditableCell = ({
        editing,
        dataIndex,
        title,
        record,
        index,
        children,
        changeType,
        ...restProps
    }) => {

        const inputNode = dataIndex === 'sort'?<InputNumber style={{width:50}} min={1}/>:<Input/>;

        return (
            <td {...restProps}>
                {editing ? (
                    <Form.Item
                        name={dataIndex}
                        style={{
                            margin: 0,
                        }}
                        rules={[
                            {
                                required:true,
                                message: `请输入${title}!`,
                            },
                        ]}
                    >
                        {inputNode}
                    </Form.Item>
                ) : (
                    children
                )}
            </td>
        );
    };

    // 修改
    const edit = (record) => {
        // 回填数据
        addForm.setFieldsValue({
            ...record,
        });
        setKnowledgeType(record.knowledgeRel);
        setEditingKey(record.id);
        setIsModalOpen(true);
    };

    // 取消
    const cancel = () => {
        setListData([...defaultDate])
        setEditingKey('');
    };

    // 修改-保存
    const save = async () => {
        try {
            const row = await addForm.validateFields();
            setConfirmLoading(true);
            row.id = editingKey;
            const res = await Api.Department.updateDepartment({data:row});
            if(res&&res.code === 0){
                message.success("修改成功",2,()=>{
                    setConfirmLoading(false);
                    handleCancel();
                    getList();
                })
            }else{
                setConfirmLoading(false);
            }
        } catch (errInfo) {
            setConfirmLoading(false);
            console.log('验证失败:', errInfo);
        }
    };

    // 新增
    const handleOk = async() => {
        try {
            const row = await addForm.validateFields();
            if(row.ministerId && row.adminId){
                addDepartment(row);
            }else{
                confirm({
                    icon: <ExclamationCircleOutlined />,
                    content: <div style={{paddingLeft: '38px'}}>未选择部长或知识专员，可能导致审批流程出错，是否确定不设置部长或知识专员</div>,
                    onOk() {
                        addDepartment(row);
                    },
                    onCancel() {},
                });
            }

        } catch (errInfo) {
            console.log('验证失败:', errInfo);
        }
    }

    const addDepartment = async(row) => {
        if(editingKey){
            save();
        }else{
            try {
                setConfirmLoading(true);
                // 0关联 1不关联
                const res = await Api.Department.addDepartment({data:row});
                if(res&&res.code === 0){
                    message.success("新增成功",2,()=>{
                        setConfirmLoading(false);
                        handleCancel();
                        getList();
                    })
                }else{setConfirmLoading(false);}
            } catch (errInfo) {
                console.log('验证失败:', errInfo);
            }
        }

    }

    const changeKnowledgeRel = (e) => {
        setKnowledgeType(e.target.value);
        if(e.target.value === 1){
            addForm.setFieldValue("knowledgeTypeIds",[])
        }
    }

    const adjustment = (type) => {
        if(type === 'knowledgeRel'){
            setKnowledgeDisabled(!knowledgeDisabled)
        }else{
            setPublishDisabled(!publishDisabled)
        }
    }

    // 打开新增窗口
    const addModal = () => {
        setConfirmLoading(false);
        setIsModalOpen(true);
        setEditingKey("");

        // 是否在发布范围中可见 和 是否关联知识 默认是
        addForm.setFieldsValue({
            knowledgeRel:0,
            publishRel:0
        });
        setKnowledgeType(0);
    }

    //删除
    const deleteModal = () => {
        if(selectedRowKey){
            ModalConfirm({
                title: `您确认要删除这条数据吗?`,
                onOk:async(resolve, reject)=> {
                    try{
                        const res = await Api.Department.deleteDepartment({data:{id:selectedRowKey}});
                        resolve();
                        if(res&&res.code === 0){
                            let newSearchData = {...searchData}
                            newSearchData.pageIndex = 1;
                            setSearchData(newSearchData);
                            setSelectedRowKey("");
                            message.success("删除成功")
                        }
                    }catch{}
                },
            })
        }else{
            message.info("请选择您需要删除的数据")
        }
    }

    // 关闭
    const handleCancel = () => {
        setIsModalOpen(false);
        setKnowledgeType(true);
        setKnowledgeDisabled(true);
        setPublishDisabled(true);
        setEditingKey('');
    }

    // 分页变化
    const paginationChange = (page, pageSize) => {
        let newSearchData = {...searchData}
        newSearchData.pageIndex = page;
        newSearchData.pageSize = pageSize;
        setSearchData(newSearchData);
    }

    const onSortChange = async(value,records) => {
        try {
            let param = {
                // ...records,
                id:records.id,
                sort: parseInt(value),
                name: records.name,
            }
            const res = await Api.Department.updateDepartment({data:param});
            if(res.code === 0){
                getList();
            }
        } catch (errInfo) {
            console.log('验证失败:', errInfo);
        }
    }

    const onChangeRel = async(type,checked,records) => {
        try {
            let param = {
                id:records.id,
                [type]: checked ? 0 : 1, // 0关联 1不关联
                name: records.name,
            }
            const res = await Api.Department.updateDepartment({data:param});
            if(res.code === 0){
                getList();
            }
        } catch (errInfo) {
            console.log('验证失败:', errInfo);
        }
    }

    const columns = [
        {
            title: '部门',
            dataIndex: 'name',
            width: '200px',
            align:"center",
        },
        {
            title: '排序',
            dataIndex: 'sort',
            width: '120px',
            render: (status,records) => {
                return <InputNumber min={1} defaultValue={status} onPressEnter={(e)=>onSortChange(e.target.value,records)} />
            }
        },
        {
            title: '部长',
            dataIndex: 'ministerId',
            width: '120px',
            align:"center",
            render:(text, record)=>{
                return record.ministerName
            },
            changeType:"select"
        },
        {
            title: '知识管理员',
            dataIndex: 'adminId',
            width: '120px',
            align:"center",
            render:(text, record)=>{
                return record.adminName
            },
            changeType:"select"
        },
        {
            title: '是否关联知识',
            dataIndex: 'knowledgeRel',
            width: '130px',
            align:"center",
            render:(text, record)=>{
                // 0关联 1不关联
                return text === 0 ? '是': '否'
            },
        },
        {
            title: '是否在发布范围中可见',
            dataIndex: 'publishRel',
            width: '130px',
            align:"center",
            render:(text, record)=>{
                // 0关联 1不关联
                // 0关联 1不关联
                return text === 0 ? '是': '否'
            },
        },
        {
            title: '默认关联知识类型',
            dataIndex: 'knowledgeTypeIds',
            width: '',
            changeType:"checkbox",
            align:"left",
            className:"table-align-center",
            render:(text)=>{
                return <Checkbox.Group
                    options={knowledgeList}
                    disabled={true}
                    defaultValue={text}
                />
            }
        },
        {
            title: '操作',
            width: '100px',
            align:"center",
            dataIndex: 'operation',
            render: (_, record) => {
                return <Link onClick={() => edit(record)}>
                调整
                </Link>
            },
        },
    ];

    return (
        <div className="departmentListFrame">
            <div className="titleBox">
                <span className="title">部门管理</span>
                <BackIcon />
            </div>
            <div className="operation">
                <Space size={"large"}>
                    <Button type="primary" onClick={addModal}>新增部门</Button>
                    <Button type="primary" danger onClick={deleteModal}>删除部门</Button>
                </Space>
            </div>
            <div className="table">
                <Form form={form} component={false}>
                    <SelfTable
                        components={{
                            body: {
                                cell: EditableCell,
                            },
                        }}
                        rowRadioSelection={{
                            selectedRowKey:selectedRowKey,
                            setSelectedRowKey:setSelectedRowKey,
                        }}
                        isEditing={isEditing}
                        dataSource={listData}
                        columns={columns}
                        pagination={{
                            ...pagination,
                            onChange: paginationChange,
                        }}
                       
                        loading={tableLoading}
                        // isShowSort={false}
                    />
                </Form>
            </div>

            <Modal
                title={`${editingKey ? "调整" : "新增"}部门`}
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
                maskClosable={false}
                destroyOnClose={true}
                confirmLoading={confirmLoading}
                className="departmentModal"
            >
                <Form
                    form={addForm}
                    component={false}
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 13 }}
                    preserve={false}
                >
                    {addDate.map(item=>{
                        return(
                            <Form.Item
                                key={item.dataIndex}
                                label={item.title}
                                name={item.dataIndex}
                                rules={[{
                                    required: item.required === 'false' ? false : true,
                                    message: (item.type === 'select') ? `请选择${item.title}!`:`请输入${item.title}!` }]}
                            >
                                {item.type === 'select' ? <Select
                                    showSearch
                                    fieldNames={
                                        {label:'user_name', value: 'id',}
                                    }
                                    options={[...userList]}
                                    filterOption={(input, option) =>
                                        (option?.user_name ?? '').toLowerCase().includes(input.toLowerCase())
                                    }
                                />

                                :item.dataIndex === 'sort'?<InputNumber min={1}/>

                                :<Input/>}

                            </Form.Item>
                        )
                    })}

                    <Form.Item label={'是否关联知识'}>
                        <Space>
                            <Form.Item
                                key={'knowledgeRel'}
                                name={'knowledgeRel'}
                                style={{marginBottom: 0}}
                            >
                                <Radio.Group
                                    onChange={changeKnowledgeRel}
                                    disabled={knowledgeDisabled}
                                >
                                    <Radio value={0}>是</Radio>
                                    <Radio value={1}>否</Radio>
                                </Radio.Group>
                            </Form.Item>
                            <Link
                                target="_blank"
                                onClick={()=>adjustment('knowledgeRel')}
                            >调整</Link>
                        </Space>
                    </Form.Item>

                    <Form.Item label={'是否在发布范围中可见'}>
                        <Space>
                            <Form.Item
                                key={'publishRel'}
                                name={'publishRel'}
                                style={{marginBottom: 0}}
                            >
                                <Radio.Group
                                    disabled={publishDisabled}
                                >
                                    <Radio value={0}>是</Radio>
                                    <Radio value={1}>否</Radio>
                                </Radio.Group>

                            </Form.Item>
                            <Link
                                target="_blank"
                                onClick={()=>adjustment('publishRel')}
                            >调整</Link>
                        </Space>
                    </Form.Item>

                    <Form.Item
                        key={'knowledgeTypeIds'}
                        label={'默认关联知识类型'}
                        name={'knowledgeTypeIds'}
                        rules={[{
                            required: knowledgeType === 0,
                            message: `请选择默认关联知识类型!`
                        }]}
                    >
                        <Checkbox.Group
                            options={[...knowledgeList]}
                            disabled={knowledgeType === 1}
                        />
                    </Form.Item>

                </Form>
            </Modal>

        </div>
    );
}
export default DepartmentList
