import React, {useEffect, useState, useRef, useMemo} from 'react';
import {Layout, Typography, Space, message, Tabs, Form, Result, Tooltip, Row, Col, Checkbox, Spin, Modal} from 'antd';
import { LikeOutlined, CommentOutlined, StarFilled, SearchOutlined, CloseCircleOutlined} from '@ant-design/icons'
import { useDispatch, useSelector } from "react-redux";
import {useLocation, useHistory} from 'react-router-dom';
import Cropper from 'react-cropper';
import copy from "copy-to-clipboard";
import moment from "moment";

import '../../../assets/js/noPrint';
import DetailsData from './detailsData'
import DocumentOperations from './documentOperations/index'
import QAListArticles from '../components/QAListArticles/index'
import LableList from './label/index'
import NoteList from './note/index'
import ExcerptList from './excerpt/index'
import DiscussBox from './discuss/index'
import UEditor from "../../../components/Ueditor/Ueditor";
import Iconfont from "../../../components/Iconfont/Iconfont";
import BackIcon from "../../../components/BackIcon/index";
import KnowledgeFileIcon from "../../../components/KnowledgeFileIcon";
import AIModal from "../../../components/AIModal";
import ViewImg from "../../../components/ViewImg";
import storage from "../../../utils/storage";
import { setKnowledgeDetailData, setDownloadModalStatus, setKnowledgeState } from "../../../features/editKnowledge/editKnowledgeReducer";
import { setAIModalState } from "../../../features/AIIntelligent/AIReducer";
import Api from "../../../api";
import {KnowledgeApply, downloadFileBlob, getSearchParams, jumpToPage} from '../../../utils/tool'

import './index.scss'
import NoneData from "../../../components/NoneData";

const {Title, Text} = Typography;
const {Header, Content, Sider, Footer} = Layout;

let time = null;
let showOprationTime = null, IntervalTime = 100;
let WPSSDK = "";
let WPSAPP = "";
var onBeforeUnloadFired = false;
let modalInfo = null;
//是否点击笔记更改选区 - 不弹出操作框
// var excleOnClickNote = false;

const KnowledgeDetails = ({pageType}) => {
    const dispatch = useDispatch();
    const fileListType = ["dwg", "zip"];
    const viewImgType = ["png", "jpg", "jpeg", "gif", "bmp", "titf", "psd", "raw", "eps", "svg"];
    const items = [
        {label: '我的笔记', key: 0}, // 务必填写 key
        {label: '共享笔记', key: 1},
    ];
    const menuItem = [
        {label: 'AI帮我读', key: 0}, // 务必填写 key
        {label: '阅读笔记', key: 1},
        // {label: '相关文章', key: 2},
    ]
    
    const {knowledgeFileType} = getSearchParams() || {};
    
    const parser = new DOMParser();
    const ueRef = useRef(null);
    const location = useLocation();
    const timeRef = useRef(null);
    const history = useHistory(); 

    const [usEditor, setUsEditor] = useState(null);
    const [EditorScrollHeight, setEditorScrollHeight] = useState(0);
    const [EditorScrollTop, setEditorScrollTop] = useState(0);

    const [hour, setHour] = useState(0)
    const [minute, setMinute] = useState(0)
    const [second, setSecond] = useState(0)

    const [knowledgeDetail, setKnowledgeDetail] = useState({});

    const [tableHight, setTableHight] = useState(0);

    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);

    const [siderType, setSiderType] = useState(0);
    const [rightSiderType, setRightSiderType] = useState(2);
    const [discussTotal, setDiscussTotal] = useState(0);

    // 0 我的笔记 1 共享笔记
    const [noteType, setNoteType] = useState(0);

    // Excerpt 摘录
    const [excerptList, setExcerptList] = useState([]);
    const [excerptLoading, setExcerptLoading] = useState(false);

    // 分页
    const [excerptPagination, setExcerptPagination] = useState({
        "pageIndex": 0,
        "pageSize": 0,
        "total": 0
    });

    const [showOperateBox, setShowOperateBox] = useState(false);
    const [selectedText, setSelectedText] = useState('');
    const [textRange, setTextRange] = useState([]);

    const [ExcerptDetails, setExcerptDetails] = useState(false);

    // Node
    const [NodeDetails, setNodeDetails] = useState(false);
    const [spinningType, setSpinningType] = useState(false);
    const [NodeListData, setNodeList] = useState([]);
    const [refreshList, setRefreshList] = useState("");
    const [nodePaginationChange, setNodePaginationChange] = useState(1);
    const [locationNotes, setLocationNotes] = useState("");

    // 分页
    const [NodePagination, setNodePagination] = useState({
        "pageIndex": 0,
        "pageSize": 0,
        "current": 1,
        "total": 0
    });

    const [initData, setInitData] = useState("");
    const [initText, setInitText] = useState("");

    //编辑器的宽度
    const [onLIneConfig, setOnLIneConfig] = useState({
        readonly: true,
        initialFrameWidth: "100%",
    });

    // 翻译图标
    const [textUrl, setTextUrl] = useState("");
    const [OCRShowUrl, setOCRShowUrl] = useState("");

    const [DiscussType, setDiscussType] = useState(false);

    const [isCollection, setIsCollection] = useState(false);
    const [isLike, setIsLike] = useState(false);
    const [knowledgeLikeNum, setKnowledgeLikeNum] = useState(0);
    const [likeState, setLikeState] = useState(false);
    const [collectionState, setCollectionState] = useState(false);
    const [downLoading, setDownLoading] = useState(false);
    const [knowledgeId, setKnowledgeId] = useState(
        getSearchParams().knowledgeId ? getSearchParams().knowledgeId : 
        (location.state && location.state.knowledgeId) ? location.state.knowledgeId : ""
    );

    const [countDownEnable, setCountDownEnable] = useState('');
    const [downloadFileEnable, setDownloadFileEnable] = useState('');

    // 可选分享所有部门
    const [shareDepartmentObject, setShareDepartmentObject] = useState({});

    // 下载范围选择框
    const [openDownloadRange, setOpenDownloadRange] = useState(false);
    const [specifiedRangeModal, setSpecifiedRangeModal] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);

    const [rangeTypeValue, setRangeTypeValue] = useState(1);

    const [knowledgeDetailsDate, setKnowledgeDetailsDate] = useState({});

    // wps
    const [wordFindIndex, setWordFindIndex] = useState(0);

    // 验证当前用户是否有权限
    const [permission, setPermission] = useState(false);
    const [pageLoading, setPageLoading] = useState(true);

    const intervalInstance = useRef();

    const memoizedTimerFun = useMemo(() => () => {
        if (!time) {
            let _hour = hour;
            let _minute = minute;
            let _second = second;
            time = setInterval(() => {
                if (_second < 60) {
                    _second = _second + 1;
                    setSecond(_second);
                } else if (minute < 60) {
                    _second = 0;
                    _minute = _minute + 1;
                    setSecond(0);
                    setMinute(_minute)
                } else if (hour < 24) {
                    _second = 0
                    _minute = 0
                    _hour = _hour + 1;
                    setSecond(0);
                    setMinute(0);
                    setHour(_hour)
                }
            }, 1000)
        }

    }, []);

    // 图片预览列表
    const [viewImgList, setViewImgList] = useState([]);
    const [isShowViewImg, setIsShowViewImg] = useState(false);
    const [viewImgIdx, setViewImgIdx] = useState(0);

    const [rightMenuType, setRightMenuType] = useState(1);
    const [oprationStatus, setOprationStatus] = useState(2);
    
    const [aiPage, setAipage] = useState(1);

    // 多文档问答
    const [showCloseBtn,setShowCloseBtn] = useState(false);
    const multiKnowledgeIds = useSelector(state => state.AIModal.multiKnowledgeIds);
    const multiKnowledgeList = useSelector(state => state.AIModal.multiKnowledgeList);
    const multiPage = useSelector(state => state.AIModal.multiPage);
    const [checkedMultiKey,setCheckedMultiKey] = useState([]);
    
    
    const multiChatId = useSelector(state => state.AIModal.multiChatId);

    const [cartStatus, setCartStatus] = useState('1');
    
    
    useEffect(() => {
        
        if(pageType === "multiAi"){
            setAipage(multiPage);
            // setRightMenuType(0);
            setShowCloseBtn(true);
            setKnowledgeId(multiKnowledgeIds[0])
            setCheckedMultiKey(multiKnowledgeIds)
            setPermission(true);
            setPageLoading(false);
            dispatch(setAIModalState({
                knowledgeIds:[multiKnowledgeIds[0]]
            }))
        }else if(getSearchParams().type === 'AI'){
            // if(getSearchParams().chatId){
            //     dispatch(setAIModalState({
            //         historyTopicId:getSearchParams().chatId
            //     }))
            // }
            if(getSearchParams().largeModelQaLogDeatilId){
                // 从AI推荐的知识进入
                getSource();
            }else{
                // AI帮我读进入
                dispatch(setAIModalState({
                    questionLocation:0,
                    knowledgeIds:[knowledgeId],
                    historyTopicId: null,
                    multiChatId: null,
                    queryText:"推荐问题",
                    questionModule:"summary",
                    relQuestions:2,
                    refLoc: true,
                    sendAPI: Date.now().toString()
                }))

                setAipage(getSearchParams().page || 1)
                // setRightMenuType(0);
            }
        }
    }, [])
    
    useEffect(() => {
        if(knowledgeId){
            verifyPermissions();
        }
    }, [knowledgeId])

    // AI推荐知识进入，定位到推荐内容来源页
    useEffect(()=>{
        // (getSearchParams().type === 'AI' || pageType === "multiAi") && 
        if(aiPage && WPSAPP){
            // if(knowledgeDetail.fileType === 'zip'){

            //     let url =  `/page/knowledgeDetails?permission=true&type=AI&knowledgeId=${knowledgeDetail.knowledgeId}&page=${aiPage}&fileType=${knowledgeDetail.fileType}`
            
            // }else{
                wpsPagePositioning(knowledgeDetail.fileType, aiPage)
            // }
        }
    },[aiPage, WPSAPP])

    useEffect(()=>{
        if(totalPages){
            dispatch(setKnowledgeState({
                fileTotalPages: totalPages, //文件总页数
                fileCurrentPage:currentPage
            }))
        }
    },[totalPages, currentPage])

    const verifyPermissions = () => {
        // 通过知识库知识列表点击跳转的会带有permission=true 表示已经通过了阅读审核可以查看
        // AI 多文档问答的时候也可以直接访问
        if(getSearchParams().permission || pageType === "multiAi"){
            setPermission(true);
            setPageLoading(false);
        }else{
            // 通过知识清单导出知识访问链接需要验证是否有查看权限
            authorization();
        }
    }

    const getSource = async() => {
        try{
            let res = await Api.Chat.getContentByDetailIdAndSource({data:{
                'largeModelQaLogDeatilId': getSearchParams().largeModelQaLogDeatilId,
                'sourceNum': parseInt(getSearchParams().sourceNum)
            }})

            const {code, data} = res;
            if(code === 0){
                const {knowledgeId, fileType, pages} = data;
                setKnowledgeId(knowledgeId);
                // setRightMenuType(0);
                dispatch(setAIModalState({
                    questionLocation:0,
                    knowledgeIds:[knowledgeId],
                    historyTopicId: null,
                    queryText:"推荐问题",
                    questionModule:"summary",
                    relQuestions:2,
                    refLoc: true,
                    sendAPI: Date.now().toString()
                }))
                setSiderType(0);
                setAipage(pages[0]);
            }
            
        }catch(err){}
    }
    const wpsPagePositioning = async(fileType, page) => {
        
        let _page = page ? parseInt(page) : parseInt(aiPage);

        console.log("wpsPagePositioning",fileType, _page);
        console.log(WPSAPP, "WPSAPP.Enum")

        if (fileType === 'doc' || fileType === 'docx' || fileType === 'docm') {
            await WPSAPP.ActiveDocument.ActiveWindow.Selection.GoTo(
                WPSAPP.Enum.WdGoToItem.wdGoToPage,
                WPSAPP.Enum.WdGoToDirection.wdGoToAbsolute,
                _page,
            );
        } else if (fileType === 'pdf') {
            await WPSAPP.ActivePDF.JumpToPage(_page);
        } else if (fileType === 'ppt' || fileType === 'pptx') {
            await WPSAPP.ActivePresentation.SlideShowWindow.View.GotoSlide(_page);
        }
    }

    const authorization = async() => {
        try{
            let res = await Api.Knowledge.ifTeamMembers({});
                // 0 班子成员，1 表示不是班子成员，班子成员不需要审批都可以查看
            if(res && res.code === 0 && res.data === 0){
                // 是班子成员
                setPermission(true);
                setPageLoading(false);
            }else{
                // 检查是否需要走审批
                checkPermissions()
            }
        }catch{setPageLoading(false);}
    }

    const checkPermissions = async() => {
        try{
            let res = await KnowledgeApply(knowledgeId,4);
            setPageLoading(false);
            if(res.bool){
                setPermission(true);
            }
        }catch{setPageLoading(false);}
    }

    useEffect(() => {

        if(permission && knowledgeId){
            
            // ZIP文件查讯左侧详情数据时用ZIP的ID查讯
            if(getSearchParams().zipId){
                getKnowledgeDetailDate(getSearchParams().zipId)
            }

            getAllDepartment();

            if (!isMobile()) {

                getAllTranslation();

                getTotalCommentNum();

                getLikeAndCollection();

                getSetting();

                memoizedTimerFun();
            }

            getContentHeight();

            getKnowledgeDetail();
        }

    }, [permission, knowledgeId])

    useEffect(() => {

        window.addEventListener('resize', getContentHeight);

        // 监听页面重新加载
        window.addEventListener("beforeunload", (ev) => {

            if (!onBeforeUnloadFired) {
                onBeforeUnloadFired = true;
                ev.returnValue = "你确定离开此页面吗?";
                setReadRecord();
            }

            window.setTimeout(ResetOnBeforeUnloadFired, 10);
        });

        // window.addEventListener("onunload", (ev) => {
        //     setReadRecord();
        // });

        // 阻止编辑器内复制
        if (!intervalInstance.current) {
            intervalInstance.current = setInterval(() => {
                const dom = document.getElementById("ueditor_0");
                let documentContent = dom?.contentDocument || dom?.contentWindow.document;
                if (documentContent) {
                    clearInterval(intervalInstance.current);
                    documentContent.addEventListener("keydown", stopCopyKeyDownFunc);
                }
            }, 1000);
        }

        return () => {
            clearInterval(time);
            time = null;

            window.removeEventListener("resize", getContentHeight);

            if (!onBeforeUnloadFired) {
                setUsEditor(null);
                if(window.UE.delEditor){
                    window.UE.delEditor("editor");
                }
                setReadRecord();
            }
        }
    }, [])

    useEffect(() => {

        if (knowledgeDetail && WPSAPP) {
            
            let _fileType = knowledgeDetail.fileType;
            console.log("_fileType", _fileType);
            let percentage = 80;
            if (_fileType === 'ppt' || _fileType === 'pptx') {
                percentage = 40;
            }
            if (siderType === 0) {
                percentage = percentage + 20
            }
            if (rightSiderType === 0) {
                percentage = percentage + 20
            }
            console.log("SiderType", siderType, rightSiderType);
            console.log("percentage", percentage);
            if (_fileType === 'doc' || _fileType === 'docx' || _fileType === 'docm') {
                WPSAPP.ActiveDocument.ActiveWindow.View.Zoom.Percentage = percentage;
                document.querySelector('#office-iframe').style.cssText = "width:100%;height:100%";
            } else if (_fileType === 'pdf') {
                WPSAPP.ActivePDF.Zoom = percentage;
                document.querySelector('#office-iframe').style.cssText = "width:100%;height:100%";
            } else if (_fileType === 'ppt' || _fileType === 'pptx') {
                WPSAPP.ActiveWindow.View.Zoom = percentage;
                document.querySelector('#office-iframe').style.cssText = "width:100%;height:100%";
            } else if(_fileType === 'xls' || _fileType === 'xlsx' || _fileType === 'xlsm'){
                document.querySelector('#office-iframe').style.cssText = "width:100%;height:100%";
            }
        }
    }, [siderType, rightSiderType, WPSAPP])

    const isMobile = () => {
        var userAgentInfo = navigator.userAgent;
        return /Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(userAgentInfo);
    }

    const getAllDepartment = async () => {
        try {
            let res = await Api.Department.getDepartmentListAll();
            if (res && res.code === 0 && res.data) {
                let _data = res.data;
                let shareDepartment = {};
                _data.forEach((item) => {
                    shareDepartment[item.id] = item.name;
                })
                setShareDepartmentObject(shareDepartment)
            } else {
            }
        } catch {
        }
    }

    const getSetting = async() => {
        try{
            let res = await Api.Settings.getModuleFunction({data:{}});
            if(res && res.code === 0 && res.data){
                let _data = res.data;

                _data.map(item => {
                    if (item.moduleType == 2) {
                        setCountDownEnable(item.isEnable)
                    } else if (item.moduleType == 1) {
                        setDownloadFileEnable(item.isEnable)
                    }
                })
            } else {
            }
        } catch {
        }
    }

    const getContentHeight = () => {
        
        let divHeight = document.querySelector('.knowledgeDetailsFrame');
        setTableHight(divHeight.offsetHeight);
        setEditorScrollTop(divHeight.offsetHeight - 23);
        setOnLIneConfig({
            ...onLIneConfig,
            initialFrameHeight: divHeight.offsetHeight - 23,
        })
    }

    // 收藏和点赞的状态
    const getLikeAndCollection = async () => {
        try {
            let res = null;
            if(getSearchParams().type === 'policy' || getSearchParams().knowledgeTypeId === '4'){
                // 政策
                res = await Api.Knowledge.getPolicyCheckOperation({
                    data: {
                        id: knowledgeId
                    }
                });
            }else{
                res = await Api.Knowledge.getLikeAndCollection({
                    data: {
                        knowledgeId: knowledgeId
                    }
                });
            }

            if (res && res.code === 0 && res.data) {
                setIsCollection(res.data.isCollection);
                setIsLike(res.data.isLike);
                setKnowledgeLikeNum(res.data.likeNum);
            } else {
            }
        } catch {
        }
    }

    const getAllTranslation = async () => {
        try {
            let res = await Api.Knowledge.getAllTranslation({params: {}});
            if (res && res.code === 0 && res.data) {
                res.data.map(item => {
                    if (item.translationType === 0) {
                        setTextUrl(item.fileUrl)
                    } else {
                        setOCRShowUrl(item.fileUrl)
                    }
                })
            } else {
            }
        } catch {
        }
    }

    const stopCopyKeyDownFunc = (e) => {
        e?.preventDefault();
        if (e.ctrlKey && e.code === "KeyC") {
            copy("", {
                message: "",
                format: "text/plain",
                debug: false,
                onCopy: () => {
                },
            });
        }
    }

    function ResetOnBeforeUnloadFired() {
        onBeforeUnloadFired = false;
    }

    useEffect(() => {
        timeRef.current = {
            hour: hour,
            minute: minute,
            seconds: second,
            knowledgeId: knowledgeDetail.id,
            knowledgeTypeId: knowledgeDetail.knowledgeTypeId,
            currentPage: currentPage,
            totalPage: totalPages,
            fileType: knowledgeDetail.fileType,
            EditorScrollHeight: EditorScrollHeight,
            EditorScrollTop: EditorScrollTop
        }
    }, [hour, minute, second, currentPage, totalPages, EditorScrollHeight, EditorScrollTop])

    // const beforeunload =(e) => {
    //     let confirmationMessage = '你确定离开此页面吗?';
    //     (e || window.event).returnValue = confirmationMessage;
    //     return confirmationMessage;
    // }

    /*
    *  摘录
    */
    useEffect(() => {
        if (noteType === 0 && !isMobile()) {
            getExcerptList();
        }
    }, [noteType]);

    const loadingF =(loadingText) => {
        modalInfo = Modal.info({
            title: null,
            icon: null,
            content: <div className="loadingBox"><Spin size={"large"} tip={loadingText || "资源加载中，请耐心等待..."}/></div>,
            centered: true,
            className: 'loadingModal',
            maskStyle: {
                backgroundColor: 'rgba(0,0,0,0.7)'
            }
        })
    }

    const closeLoading = () => {
        if (modalInfo) {
            console.log(modalInfo);
            modalInfo.destroy();
            modalInfo = null;
        }
    }

    const getTotalCommentNum = async () => {
        try {
            let res = await Api.Knowledge.getTotalCommentNum({
                data: {
                    "knowledgeId": knowledgeId
                }
            });
            if (res && res.code === 0 && res.data) {
                setDiscussTotal(res.data);
            }
        } catch {
        }
    }

    // 获取摘录列表
    const getExcerptList = async (page) => {
        setExcerptLoading(true);
        try {
            let res = await Api.Knowledge.getExcerptList({
                params: {
                    "pageIndex": page,
                    "knowledgeId": knowledgeId,
                    "type" : (getSearchParams().type === 'policy' || getSearchParams().knowledgeTypeId === '4') ? 1 : 0 //0知识 1政策
                }
            });
            setExcerptLoading(false);
            if (res && res.code === 0 && res.data) {
                setExcerptList(res.data.records);
                setExcerptPagination({
                    "pageIndex": res.data.current,
                    "pageSize": res.data.size,
                    "total": res.data.total,
                })
            } else {
                setExcerptList([])
            }
        } catch {
            setExcerptLoading(false);
        }
    }

    const updataExcerpt = (item) => {
        setExcerptDetails(item);
        setSelectedText(item.knowledgeExcerptMessage)
    }

    /*
    *  笔记
    */
    const onClickNote = async (item, fileType) => {
        let _fileType = knowledgeDetail.fileType || fileType;

        if (_fileType === 'online') {

            let _initData = initText.replace(/\n/g, '');
            if (!_initData.indexOf(item.noteBelongMessage)) {
                message.info("当前笔记位置未找到");
                return false;
            }
            let iframeDom = document.querySelector("#ueditor_0");
            // _initData = _initData.replace(item.noteBelongMessage, '<span style="background:red">'+item.noteBelongMessage+'</span>')
            // usEditor.setContent(_initData); //设置默认值/表单回显

            var iframeWindow =
                iframeDom.contentWindow || iframeDom.contentDocument.defaultView;
            iframeWindow.scrollTo({
                top: item.endIndex,
                left: item.startIndex,
                behavior: "smooth",
            });

        } else {

            if (!WPSAPP) {
                message.info("WPD未加载成功");
                return false;
            }

            if (_fileType === 'doc' || _fileType === 'docx' || _fileType === 'docm') {
                wordNotePositioning(item)
            } else if (_fileType === 'pdf') {
                pdfNotePositioning(item)
            } else if (_fileType === 'ppt' || _fileType === 'pptx') {
                pptNotePositioning(item)
            }
            // else if(_fileType === 'xls' || _fileType === 'xlsx'){
            //     excleNotePositioning(item)
            // }
        }
    }

    const pptNotePositioning = async (item) => {
        // 跳转到指定页
        await WPSAPP.ActivePresentation.SlideShowWindow.View.GotoSlide(item.endIndex);
    }

    const excleNotePositioning = async (item) => {
        // excleOnClickNote = true;
        // 切换到指定工作表
        const sheetIndex = parseInt(item.endIndex); // Sheets 序号，从 1 开始
        WPSAPP.ActiveWorkbook.Sheets.Item(sheetIndex).Activate();

        const newRange = await WPSAPP.Range(item.startIndex);
        newRange.Select();
    }

    const pdfNotePositioning = async (item) => {
        // ActivePDF
        const pdf = await WPSAPP.ActivePDF;

        // 设置滚动条位置
        await pdf.ScrollTo(item.startIndex, item.endIndex)
    }

    const wordNotePositioning = async (item) => {
        // 获取选中区域
        const range = await WPSAPP.ActiveDocument.Range(item.startIndex, item.endIndex);
        // 获取文本
        const text = await range.Text;

        // 添加书签
        // const bookmarks =  WPSAPP.ActiveDocument.Bookmarks;
        // await bookmarks.Add({
        //     Name: 'Note',
        //     Range: {
        //         Start: item.startIndex,
        //         End: item.endIndex,
        //     },
        // });

        // 笔记是noteBelongMessage，摘录是knowledgeExcerptMessage
        let theOriginal = item.noteBelongMessage ? item.noteBelongMessage : item.knowledgeExcerptMessage;

        if (
            (theOriginal === text) && theOriginal && item.endIndex
        ) {

            // 滚动文档窗口, 显示指定的区域
            await WPSAPP.ActiveDocument.ActiveWindow.ScrollIntoView(range)

            // 选中书签内容
            // await WPSAPP.ActiveDocument.Bookmarks.Item('Note').Select();

            // 搜索并高亮文本
            await WPSAPP.ActiveDocument.Find.Execute(theOriginal, true);
        } else {
            message.info("当前笔记位置未找到");
        }
    }


    // 笔记
    const modifyNodeLable = (item) => {
        setNodeDetails(item);
    }

    useEffect(() => {
        if (locationNotes) {
            onClickNote(locationNotes);
            setLocationNotes("");//清空数据
        }
    }, [locationNotes]);


    useEffect(() => {
        if (refreshList && knowledgeId) {
            getNodeList();
        }
    }, [refreshList]);

    useEffect(() => {
        if (nodePaginationChange === 1 && !isMobile() && knowledgeId) {
            getNodeList();
        } else if (!isMobile()) {
            setNodePaginationChange(1)
        }
    }, [noteType]);

    useEffect(() => {
        if (!isMobile() && knowledgeId) {
            getNodeList();
        }
    }, [nodePaginationChange]);

    const getNodeList = async () => {

        setSpinningType(true);
        try {
            let res = await Api.Knowledge.getKnowledgeNoteList({
                data: {
                    "pageIndex": nodePaginationChange,
                    "knowledgeId": knowledgeId,
                    "isOwner": noteType
                }
            });
            setSpinningType(false);
            if (res && res.code === 0 && res.data) {
                setNodeList(res.data.records);
                setNodePagination({
                    "pageIndex": res.data.current,
                    "pageSize": res.data.size,
                    "total": res.data.total,
                    "current": res.data.current,
                })
            } else {
                setNodeList([])
            }
        } catch {
            setSpinningType(false);
            setNodeList([]);
        }
    }

    /*
    *  WPS
    */
    const getWPSFileUrl = async (data) => {

        document.getElementById("office").innerHTML = "";

        let _fileType = data.fileType;
        try {
            let res = ""

            // 有researchReportId 代表是参考研报
            if (data.researchReportId) {
                res = await Api.Knowledge.getWPSFileResearchReport({data: {researchReportId: data.researchReportId}});
            } else if(getSearchParams().type === 'policy' || getSearchParams().knowledgeTypeId === '4'){
                // 政策
                // res = await Api.Knowledge.getPreviewUrlByPolicyId({data: {id: data.id}});
                res = await Api.Knowledge.getPolicyPreviewUrl({data:{policyId:data.id}});
            }else {
                res = await Api.Knowledge.getWPSFilePreview({fileId: data.relationFileId});
            }

            defalutOperation(res.data);

            if (res && (res.code === 200 || res.code === 0) && res.data) {
                const link = res.data.link || res.data.data.link;

                WPSSDK = OpenSDK.config({
                    url: link,
                    mount: document.querySelector('#office'),
                })

                // 手机端打开不执行后续操作
                if (isMobile()) {
                    // window.open(res.data.link);
                    return false;
                }
                
                // 等待加载完毕
                await WPSSDK.ready();

                WPSAPP = WPSSDK.Application;

                // WPSAPP.ActiveDocument.ActiveWindow.View.Zoom.PageFit = 2;

                // 从个人中心-笔记管理或者笔记查询进入需要定位笔记位置
                if (getSearchParams().endIndex && getSearchParams().startIndex) {
                    onClickNote({
                        endIndex: parseInt(getSearchParams().endIndex),
                        startIndex: parseInt(getSearchParams().startIndex),
                        noteBelongMessage: sessionStorage.noteBelongMessage
                    }, getSearchParams().fileType)
                }

                // ES搜索带入分片后的关键字
                // 是否有关键字
                let keyword = getSearchParams().keyword;
                if(keyword){
                    highlightedText(_fileType,keyword)
                }

                // AI推荐知识进入，定位到推荐内容来源页
                console.log('AIModal:',getSearchParams())
                // if(getSearchParams().type === 'AI' && (getSearchParams().largeModelQaLogDeatilId || aiPage > 1)){
                //     wpsPagePositioning(_fileType);
                // }
                // 多文档进入
                // if(pageType === "multiAi" && multiPage){
                //     wpsPagePositioning(_fileType, multiPage);
                // }
                console.log(_fileType,"_fileType_fileType")
                if (_fileType === 'doc' || _fileType === 'docx' || _fileType === 'docm') {
                    wordMethod()
                } else if (_fileType === 'pdf') {
                    pdfMethod();
                } else if (_fileType === 'ppt' || _fileType === 'pptx' || _fileType === 'pptm') {
                    setShowOperateBox(true);
                    pptMethod();
                }
                // else if(_fileType === 'xls' || _fileType === 'xlsx'){
                // excleMethod(WPSSDK,WPSAPP)
                // }

                // document.querySelector('#office-iframe').style.cssText="width:100%;height:100%";
            }
        } catch {
        }
    }

    const highlightedText = async(_fileType,keyword) => {
        if(_fileType === 'doc' || _fileType === 'docx' || _fileType === 'docm'){

            let esSearchData = storage.getItem('searchData');
            let eskeyword = "", keyWordData = [];

            // 先匹配搜素中心输入框中输入的文字
            if(esSearchData && JSON.parse(esSearchData) && JSON.parse(esSearchData).text){
                eskeyword = JSON.parse(esSearchData).text;
                keyWordData = await WPSAPP.ActiveDocument.Find.Execute(eskeyword, true);
            }

            console.log(keyWordData,"keyWordData1")

            // 输入框中搜索的文字匹配不到，再匹配分词后的文字
            if(keyWordData.length <= 0){
                keyWordData = await WPSAPP.ActiveDocument.Find.Execute(keyword, true);
            }

            console.log(keyWordData,"keyWordData2")

            let Start = keyWordData[0].pos, End = keyWordData[0].pos+keyWordData[0].len;
            // 滚动到第一次出现的位置
            const range = await WPSAPP.ActiveDocument.Range(Start,End);
            await WPSAPP.ActiveDocument.ActiveWindow.ScrollIntoView(range);


            // console.log({ Start, End },WPSAPP.ActiveDocument,WPSAPP.ActiveDocument.Find,WPSAPP.Act/iveDocument.JumpToSpecifyLocation,"11111111111111111")

            // let activeDocument = await WPSAPP.ActiveDocument

            // console.log(activeDocument,"activeDocument")

            // activeDocument.JumpToSpecifyLocation({ Start, End });

        }else if(_fileType === 'pdf'){
            await WPSAPP.ActivePDF.Find({ Value: keyword });

        }
        // else if(_fileType === 'ppt' || _fileType === 'pptx'  || _fileType === 'pptm'){
        //     setShowOperateBox(true);
        //     pptMethod()
        // }
    }

    // 获取wps地址
    async function getFileViewUrl(fileId, type, viewImgList, fileItem) {
        if (viewImgType.includes(String(type).toLowerCase())) {
            if (!isMobile()) {
                const idx = viewImgList.findIndex(item => item.id === fileId);
                setViewImgIdx(idx);
                setIsShowViewImg(true);
            }
            return
        }
        if (!isMobile() && fileItem.knowledgeId) {
            jumpToPage({
                ...fileItem,
                "selecthValue":null,
                "id":"",
                "fileType":type
            },undefined,undefined,undefined,fileItem.cartStatus);
        }else if(isMobile() && fileItem.knowledgeId){
            const res = await Api.Knowledge.getWPSFilePreview({fileId: fileId});
            if (res?.code === 200) {
                window.location.href = res.data.link
            }
        }else{
            message.info("当前文件类型不支持")
        }
        
    }

    const pptMethod = async () => {
        // PPT
        // 监听当前页改变事件
        WPSAPP.Sub.SlideSelectionChanged = async (curryPage) => {
            setCurrentPage(curryPage, "CurrentPageChange");
        };


        // 演示文稿对象
        const presentation = await WPSAPP.ActivePresentation;

        // 幻灯片对象
        const slides = await presentation.Slides;

        // 获取总页数
        const count = await slides.Count;

        setTotalPages(count);
        console.log(count, "ppt-totalPages")

        // 获取当前页码
        const curryPage = await WPSAPP.ActivePresentation.SlideShowWindow.View.Slide.SlideIndex;
        console.log(curryPage,"首次加载的页码");
        setCurrentPage(curryPage);

        // 监听 PPT 的移动
        // WPSAPP.Sub.SlideMove = (d) => {
        //     console.log('监听 PPT 的移动：', d);
        // };
        // WPSSDK.ApiEvent.AddApiEventListener("SlideMove", (data) => {
        //     console.log("SlideMove: ", data);
        // });

        // // 文档窗口对象
        // const activeWindow = await WPSAPP.ActiveWindow;

        // // 选区对象
        // const selection = await activeWindow.Selection;

        // console.log(selection,"selection")

    }

    const excleMethod = async (WPSSDK, WPSAPP) => {
        // EXCLE的选区监听和获取文字有问题 - 暂不开放
        // EXCLE
        // 监听工作表的切换
        WPSSDK.ApiEvent.AddApiEventListener("Worksheet_Activate", async (data) => {
            console.log('ActiveSheet：', await WPSAPP.ActiveSheet.Name);
        });

        // 监听选区改变事件
        WPSSDK.ApiEvent.AddApiEventListener("Worksheet_SelectionChange", async (data) => {
            console.log("监听选区改变事件: ", data);
            // 选区对象
            const range = await WPSAPP.Selection;

            const address = await range.Address(false, false);

            // 活动工作簿中的活动工作表
            const activeSheet = await WPSAPP.ActiveWorkbook.ActiveSheet;

            // 获取当前工作表名称
            const name = await activeSheet.Name;

            const index = await WPSAPP.ActiveWorkbook.Sheets.Item(name).Index;

            let ranges = WPSAPP.Range(address);
            const text = await ranges.Text;

            // if(text!="" && !excleOnClickNote){
            if (text != "") {
                // address 选区坐标
                // index 第几个工作区
                showOpration(text, address, index);
            } else {

                setShowOperateBox(false);
            }
        });

        // B1:D2
        // 区域对象
        // const range = await app.Range('A1');

        // 选择区域 A1 并且设置闪烁动画
        // range.Select(null, true);

    }

    const wordMethod = async () => {
        console.log("wordMethod", WPSSDK, WPSSDK.ApiEvent)
        // Word
        // 控制目录显示与否
        WPSAPP.ActiveDocument.ActiveWindow.DocumentMap = true;
        const pages = await WPSAPP.ActiveDocument.ActiveWindow.ActivePane.Pages.Count;
        setTotalPages(pages);
        console.log(pages, "word-totalPages")

        // 监听当前页码改变事件
        WPSSDK.ApiEvent.AddApiEventListener("CurrentPageChange", (data) => {
            console.log(data, "CurrentPageChange")
            setCurrentPage(data);
        });
        // 监听选区变化事件
        WPSSDK.ApiEvent.AddApiEventListener("WindowSelectionChange", async (data) => {
            console.log(data,"WindowSelectionChange")

            // 页面定制对象：查找
            // const moreMenus = await WPSAPP.CommandBars('Find');
            // const visible = await moreMenus.Visible;
            // console.log(visible,"visible");

            if (data.begin != data.end) {
                // 获取选中区域
                const range = await WPSAPP.ActiveDocument.Range(data.begin, data.end);
                // 获取文本
                const text = await range.Text;

                showOpration(text, data.begin, data.end);
            } else {
                setShowOperateBox(false);
            }
        });
    }

    const pdfMethod = async () => {
        console.log("pdfMethod")
        // 控制目录的显示隐藏
        WPSAPP.ActivePDF.DocumentMap = true;

        // 获取总页数
        const totalPages = await WPSAPP.ActivePDF.PagesCount;
        setTotalPages(totalPages);
        console.log(totalPages, "pdf-totalPages")

        // 获取当前页码
        const curryPage = await WPSAPP.ActivePDF.CurrentPage;
        console.log(curryPage,"获取首次加载当前页码");
        setCurrentPage(curryPage);

        // PDF
        // 监听当前页码改变事件
        WPSSDK.ApiEvent.AddApiEventListener("CurrentPageChange", (data) => {
            //pdf的页码从0开始
            console.log(data + 1, "CurrentPageChange")
            setCurrentPage(data + 1);
        });
        // 监听选区变化事件
        WPSSDK.ApiEvent.AddApiEventListener("TextSelectChange", async (data) => {
            console.log(data, "datadatadata")
            if ((data.x != data.y) && data.hasSelection) {
                // 获取滚动位置
                const result = await WPSAPP.ActivePDF.Scroll;
                console.log(result, '滚动位置');

                //获取选区文本
                const selectionText = await WPSAPP.ActivePDF.GetTextSelection();

                showOpration(selectionText, result.x, result.y);
            } else {
                setShowOperateBox(false);
            }
        });
    }

    const showOpration = (text, begin, end) => {
        if (showOprationTime) clearTimeout(showOprationTime)
        showOprationTime = setTimeout(async () => {
            showOprationTime = null;
            setShowOperateBox(true);
            setTextRange([begin, end]);
            setSelectedText(text);
        }, IntervalTime)
    }

    const setReadRecord = async () => {
        if (
            (timeRef.current.hour != 0 ||
                timeRef.current.minute != 0 ||
                timeRef.current.seconds > 3)
            && timeRef.current.knowledgeId &&
            timeRef.current.totalPage > 0 &&
            !isMobile() && timeRef.current.knowledgeTypeId != '4'
        ) {
            let param = {
                hour: timeRef.current.hour,
                minute: timeRef.current.minute,
                seconds: timeRef.current.seconds,
                knowledgeId: timeRef.current.knowledgeId,
            };
            if (timeRef.current.fileType === 'online') {
                param = {
                    ...param,
                    current: timeRef.current.EditorScrollTop,
                    total: timeRef.current.EditorScrollHeight
                }
            } else {
                param = {
                    ...param,
                    current: timeRef.current.currentPage,
                    total: timeRef.current.totalPage,
                }
            }
            try {
                let res = await Api.Knowledge.saveReadRecord({
                    data: {
                        "knowledgeReadSchedule": 0,
                        ...param,
                    }
                });
            } catch {
            }
        }
    }

    const getKnowledgeDetailDate = async (id) => {
        try {

            let res = await Api.Knowledge.getKnowledgeDetailById({data: {knowledgeId: id}});
            if (res && res.code === 0 && res.data) {
                let _data = res.data;
                setKnowledgeDetailsDate(_data);
                dispatch(setKnowledgeDetailData(_data))
                console.log("11111")
            } else {
                setKnowledgeDetailsDate({});
            }
        } catch {
        }
    }

    const getKnowledgeDetail = async (id = knowledgeId) => {
        if (!modalInfo) {
            loadingF();
        }
        try {
            let res = null;

            if(getSearchParams().type === 'policy' || getSearchParams().knowledgeTypeId === '4'){
                // 政策详情
                res = await Api.Knowledge.getPolicyDetailById({data: {id}});
            }else{
                res = await Api.Knowledge.getKnowledgeDetailById({data: {knowledgeId: id}});
            }
            
            if (res && res.code === 0 && res.data) {
                let _data = {...res.data};

                if(getSearchParams().type === 'policy' || getSearchParams().knowledgeTypeId === '4'){
                    // 政策详情
                    _data.knowledgeTitle = _data.title
                    _data.createDate = _data.insertTime
                }

                dispatch(setKnowledgeDetailData(_data));
                
                // 不是ZIP的时候左侧详情数据才使用这个接口的数据
                if(!getSearchParams().zipId){
                    setKnowledgeDetailsDate(_data);
                }
                
                // ZIP 带入 getSearchParams().cartStatus
                let _cartStatus = null;
                if(getSearchParams().cartStatus || String(getSearchParams().cartStatus) === '0'){
                    _cartStatus = String(getSearchParams().cartStatus)
                }else{
                    _cartStatus = String(_data.cartStatus)
                }
                setCartStatus(_cartStatus);

                // 文件类型不是在线文档，并且文件解析完成，就可以显示AI帮我读
                if(_data.fileType != "online" && _cartStatus === '0'){
                    setRightMenuType(0);
                }

                // 是否可以AI帮我读
                if(_cartStatus === '0' && getSearchParams().type != 'AI' && pageType != "multiAi"){
                    dispatch(setAIModalState({
                        questionLocation:0,
                        knowledgeIds:[knowledgeId],
                        historyTopicId: null,
                        multiChatId: null,
                        queryText:"推荐问题",
                        questionModule:"summary",
                        relQuestions:2,
                        refLoc: true,
                        sendAPI: Date.now().toString()
                    }))
                }

                setKnowledgeDetail(_data);
                if (_data.fileType === 'online') {
                    setInitData(_data.knowledgeMainBodyHtml);
                    setInitText(_data.knowledgeMainBody);
                    setTotalPages(1);
                } else if (!fileListType.includes(_data.fileType)) { 
                    getWPSFileUrl(_data);
                } else {
                    const imgList = (_data.unzipFile || []).filter(item => viewImgType.includes(String(item.type).toLowerCase()));
                    setViewImgList(imgList);
                }

            } else {
                setKnowledgeDetail({});
                // message.info(res.msg);
            }
            closeLoading();
        } catch {
            closeLoading();
        }
    }

    // 进入详情是否需要打开讨论或下载弹窗
    const defalutOperation = (_data) => {
        // 从通知中心点击立即下载进入
        if (getSearchParams().type === "downloadFile") {
            let fileType = [
                'docx',
                'docm',
                'doc',
                'xlsx',
                'xls',
                'xlsm',
                'online'
            ]
            if (!fileType.includes(_data.fileType)) {
                dispatch(setDownloadModalStatus(true));
            }
        }

        // 从评论的消息通知进入，默认打开讨论
        if (getSearchParams().type === "comment") {
            openDiscuss();
        }
    }

    const timerFun = () => {
        time = setTimeout(() => {
            if (second < 60) {
                setSecond(preSecond => preSecond + 1);
            } else if (minute < 60) {
                setSecond(0);
                setMinute(preMinute => preMinute + 1)
            } else if (hour < 24) {
                setSecond(0);
                setMinute(0);
                setHour(preHour => preHour + 1)
            }
        }, 1000)
    }

    // 对政策进行点赞或收藏 id 政策id type 操作类型（0点赞 1收藏）
    const onPolicyCollectionOrLike = async(type) => {
        if(type == 0){
            setLikeState(true);
        }else{
            setCollectionState(true);
        }
        try {
            let res = await Api.Knowledge.setPolicyLikeState({
                data: {
                    id: knowledgeDetail.id,
                    type, //0点赞 1收藏
                }
            });
            if(type == 0){
                setLikeState(false);
            }else{
                setCollectionState(false);
            }
            if (res && res.code === 0) {
                if(type === 0){
                    message.success(isLike ? "取消点赞成功" : "点赞成功");
                }else{
                    message.success(isCollection ? "取消收藏成功" : "收藏成功");
                }
                
                getLikeAndCollection();
            }
        } catch {
            if(type == 0){
                setLikeState(false);
            }else{
                setCollectionState(false);
            }
        }
    }

    // 收藏
    const onCollection = async() => {
        // message.loading({content:
        //     isCollection?'取消收藏中..':'收藏中..',
        //     key:"collection"
        // });
        setCollectionState(true);
        try {
            let res = null;
            if(getSearchParams().knowledgeTypeId === '4'){
                res = await Api.Knowledge.operationPolicyFileState({
                    data: {
                        id: knowledgeDetail.id,
                        type: 1
                    }
                });
            }else{
                res = await Api.Knowledge.setKnowledgeCollection({
                    data: {
                        knowledgeId: knowledgeDetail.id,
                        knowledgeTypeId: knowledgeDetail.knowledgeTypeId
                    }
                });
            }
            setCollectionState(false);
            // message.destroy("collection");
            if (res && res.code === 0) {
                message.success(isCollection ? "取消收藏成功" : "收藏成功");
                getLikeAndCollection();
            }
        } catch {
            setCollectionState(false);
        }
    }

    // 点赞
    const onLike = async () => {
        setLikeState(true)
        try {
            let likeType = isLike ? 0 : 1;
            let res = null;
            if(getSearchParams().knowledgeTypeId === '4'){
                res = await Api.Knowledge.operationPolicyFileState({
                    data: {
                        id: knowledgeDetail.id,
                        type: 0,
                        // likeType
                    }
                });
            }else{
                res = await Api.Knowledge.setKnowledgeLike({
                    data: {
                        likeType,
                        knowledgeId: knowledgeDetail.id
                    }
                });
            }


            setLikeState(false)
            if (res && res.code === 0) {
                message.success(isLike ? "取消点赞成功" : "点赞成功");
                getLikeAndCollection();
            }
        } catch {
            setLikeState(false)
        }
    }

    const onSelectDownloadRange = (key) => {
        if (key === "SpecifyPage") {
            // 指定页码
            setSpecifiedRangeModal(true);
            setOpenDownloadRange(false)
        }
    }

    


    // 切换笔记类型
    const TabsOnchange = (activeKey) => {
        setNodeList([]);
        setNoteType(activeKey)
    }

    const documentOperationsCallback = (type) => {
        // 摘录保存成功
        if (type === 'ExtractOk') {
            getExcerptList();
            setSelectedText("");
            setExcerptDetails(false);
        } else if (type === 'NodeOk') {
            if (noteType === 0) {
                getNodeList();
            } else {
                setNoteType(0)
            }
            setNodeDetails(false);
        } else if (type === 'NodeCancel') {
            setNodeDetails(false);
        } else if (type === 'ExtractCancel') {
            setExcerptDetails(false);
            setSelectedText("");
        } else if (type === 'translate') {
            setSelectedText("");
        }
        if (knowledgeDetail.fileType != "ppt" && knowledgeDetail.fileType != "pptx") {
            setShowOperateBox(false);
        }
    }

    // 富文本
    useEffect(() => {
        if (usEditor && (initData != "")) {
            usEditor.setContent(initData); //设置默认值/表单回显

            // 从个人中心-笔记管理或者笔记查询进入需要定位笔记位置
            if (getSearchParams().endIndex && getSearchParams().startIndex) {
                onClickNote({
                    endIndex: parseInt(getSearchParams().endIndex),
                    startIndex: parseInt(getSearchParams().startIndex),
                    noteBelongMessage: sessionStorage.noteBelongMessage
                }, getSearchParams().fileType)
            }

            const iframe = document.querySelector("#ueditor_0").firstElementChild || window.frames['ueditor_0'];
            const _EditorView = iframe.contentWindow;
            setEditorScrollHeight(_EditorView.document.firstElementChild.scrollHeight)
            _EditorView.onscroll = function () {
                //获取滚动条的位置
                var scrollTop = EditorScrollTop + _EditorView.document.firstElementChild.scrollTop;
                setEditorScrollTop(Math.ceil(scrollTop))
            }
        }
    }, [usEditor, initData])

    const editorReady = (editor) => {
        setUsEditor(editor);
        defalutOperation();
    }

    const setNoPrint = () => {
        // const script = document.createElement('script');
        // script.src = '/noPrint.js';
        // document.body.appendChild(script);
    }

    const getScrollTopAndText = (text, coordinate) => {
        // text = text.replace(/\\/g, '')
        console.log(text.toString(), "text")
        if (text) {
            console.log(coordinate, "coordinate")
            setTextRange([coordinate.x, coordinate.y]);
            setSelectedText(text);
            setShowOperateBox(true);
        } else {
            setTextRange([]);
            setSelectedText("");
            setShowOperateBox(false);
        }
    }

    const switchState = () => {
        setSiderType(siderType === 0 ? 1 : 0);
    }

    const switchRightState = () => {
        setRightSiderType(rightSiderType === 0 ? 1 : 0);
    }

    // 展开/折叠 评论区域
    const openDiscuss = () => {
        setDiscussType(!DiscussType);
        if (!DiscussType) {
            const timer = setTimeout(() => {
                clearTimeout(timer);
                const dom = document.getElementById("centerContentBox");
                if (dom) {
                    const noPrintDom = document.getElementById("noPrintBox");
                    dom.scrollTop = noPrintDom.clientHeight / 3 * 2;
                }
            })
        }
    }

    const setDiscussTotals = () => {
        getTotalCommentNum();
    }

    const getCountdown = () => {
        let content = "";

        let _hour = hour.toString().length == 1 ? '0' + hour : hour;
        // console.log(_hour, _hour.slice(0, 1), _hour.slice(1, 2))
        let hour1 = _hour.slice(0, 1);
        let hour2 = _hour.slice(1, 2);

        let _minute = minute.toString().length == 1 ? '0' + minute : minute;

        let minute1 = _minute.slice(0, 1);
        let minute2 = _minute.slice(1, 2);

        let _second = second.toString().length == 1 ? '0' + second : second;
        let second1 = _second.slice(0, 1);
        let second2 = _second.slice(1, 2);

        content = (<div>
            <span>{hour1}</span>
            <span>{hour2}</span>:
            <span>{minute1}</span>
            <span>{minute2}</span>:
            <span>{second1}</span>
            <span>{second2}</span>
        </div>)


        return content;
    }

    const downloadContent = (
        <div className="downloadContent">
            {[
                {label: '下载全文', key: 'fullText'},
                {label: '下载当前页', key: 'currentPage'},
                {label: '下载指定页码', key: 'SpecifyPage'},
            ].map(item => {
                return <p onClick={() => {
                    onSelectDownloadRange(item.key)
                }}>{item.label}</p>
            })}
        </div>
    );

    
    

    // useEffect(()=>{
    //     // await WPSAPP.CommandBars('Find').Execute();
    //     if(wordFindIndex > 0){
    //         findText();
    //     }
    // },[wordFindIndex])

    // const findText =  async () => {
    //      // 高亮文字并获取位置
    //      const data = await WPSAPP.ActiveDocument.Find.Execute('计算机', true);
    //      console.log(data,"datadatadata")
    //      // 滚动到第一次出现的位置
    //     //  const range = await WPSAPP.ActiveDocument.Range(data[wordFindIndex].pos,data[wordFindIndex].pos+data[wordFindIndex].len);
    //     //  await WPSAPP.ActiveDocument.ActiveWindow.ScrollIntoView(range);
    //      await WPSAPP.ActiveDocument.JumpToSpecifyLocation({ Start:data[wordFindIndex].pos, End:data[wordFindIndex].pos+data[wordFindIndex].len });
    // }

    const openSearch = async () => {
        await WPSAPP.CommandBars('Find').Execute();
    }

    // 关闭详情
    const closeDetails = () => {
        let ids = []
        multiKnowledgeList.map(item=>{
            let id = item.id || item.knowledgeId || item.policyId || item.elasticId;
            ids.push(id);
        })
        dispatch(setAIModalState({
            multiOpen:false,
            modalOpen: true,
            queryText:null,
            sendAPI:null,
            knowledgeIds:ids
        }))
    }

    const onMultiChange = (checkedValue) => {
        setCheckedMultiKey(checkedValue);
        dispatch(setAIModalState({
            knowledgeIds:checkedValue
        }))
        console.log(checkedValue,"checkedValue")
    }

    // 页面定位
    const callbackPositioning = async(page, largeModelQaLogDeatilId) => {
        try{
            let res = await Api.Chat.getContentByDetailIdAndSource({data:{
                'largeModelQaLogDeatilId': largeModelQaLogDeatilId,
                'sourceNum': parseInt(page)
            }})
            const {code, data} = res;
            if(code === 0){
                const {pages} = data;
                // if(knowledgeId != data.knowledgeId){
                    // WPSAPP = null;
                    // setKnowledgeId(data.knowledgeId);
                // }
                setAipage(pages[0]);
                console.log("pages", pages, pages[0]);
            }
            
        }catch(err){}
    }

    // 多文档切换的时候，清除上一个文档的监听事件
    const destroyWPSSDK = async(id) => {
        if(WPSSDK){
            let _fileType = knowledgeDetail.fileType;
            // 清除页码改变的监听
            // WPSSDK.ApiEvent.RemoveApiEventListener('CurrentPageChange', ()=>{
            //     console.log("页码改变监听事件注销成功")
            // })
            if (_fileType === 'doc' || _fileType === 'docx' || _fileType === 'docm') {
                WPSSDK.ApiEvent.RemoveApiEventListener('WindowSelectionChange', ()=>{
                    console.log("word选区监听事件注销成功");
                    
                })
            } else if (_fileType === 'pdf') {
                WPSSDK.ApiEvent.RemoveApiEventListener('TextSelectChange', async ()=>{
                    console.log("pdf选区监听事件注销成功");
                })
            } else if (_fileType === 'ppt' || _fileType === 'pptx' || _fileType === 'pptm') {
                //    PPT没有选区监听，按页做笔记
            }
            setTimeout(()=>{
                setKnowledgeId(id);
                WPSSDK.destroy();
                WPSSDK = "";
                WPSAPP = "";
            }, 1000)
        }
    }

    const unzipFileList = knowledgeDetail.unzipFile || [];

    const discussExcludeType = ["xls", "xlsx", "xlsm", "zip", "dwg"];

    const classNameObject = {
        "0": {
            "0": "layoutRightSiderCloseAi",
            "1": "layoutRightSiderOpenAi",
        },
        "1": {
            "0": "layoutRightSiderClose",
            "1": "layoutRightSiderOpen",
        },
    };
    const aiClassNameObject = {
        "0": "layoutRightSiderdefaultAi",
        "1": "layoutRightSiderdefault",
    };
    
    
    const knowledgeDetailFileType = knowledgeFileType || knowledgeDetail.fileType;
    

    return (<>
        {!permission&&!pageLoading&&<Result
            className="resultBox"
            status="info"
            title="暂无阅读权限"
            extra={
                <div className="prompt">
                    <span>您还未获得当前知识的阅读权限</span><br/>
                    <a onClick={checkPermissions} key="list-loadmore-edit">立即申请</a>
                </div>
            }
        />}

        <div className="knowledgeDetailsFrame" style={permission&&!pageLoading?{}:{display:'none'}}>
            {
                isMobile() ?
                    (
                        knowledgeDetailFileType === "zip" ?
                            (
                                <div className="zipChildFileList">
                                    <div className="scrollBox">
                                        {
                                            unzipFileList.length > 0 ? unzipFileList.map(item => {
                                                const {id, name, createTime, type} = item;
                                                return (
                                                    <div
                                                        className="fileItem"
                                                        key={id}
                                                        onClick={() => getFileViewUrl(id, type, viewImgList, item)}
                                                    >
                                                        <div className="infoBox">
                                                            <KnowledgeFileIcon fileType={type}/>
                                                            <span className="fileName overflowEllipsis">{name}</span>
                                                        </div>
                                                        <span
                                                            className="timeText">{moment(createTime).format("YYYY-MM-DD HH:mm:ss")}</span>
                                                    </div>
                                                )
                                            }) : (
                                                NoneData({text: "无文件"})
                                            )
                                        }
                                    </div>
                                </div>
                            )
                            :
                            (
                                <React.Fragment>
                                    <div id="noPrintBox">{/* 模糊div */}
                                        {
                                            initData != "" ? (
                                                // <UEditor
                                                //     ref={ueRef}
                                                //     config={onLIneConfig}
                                                //     editorReady={editorReady}
                                                //     getScrollTopAndText={getScrollTopAndText}
                                                // ></UEditor>
                                                <div dangerouslySetInnerHTML={{__html: knowledgeDetail.knowledgeMainBodyHtml}}></div>
                                            ) : (
                                                <div
                                                    className="custom-mount"
                                                    id={"office"}
                                                    style={{
                                                        height: tableHight,
                                                    }}
                                                ></div>
                                            )
                                        }

                                        {/* 模糊后的遮罩层，提供点击事件使用 */}
                                        <div id="noPrintMask" className="noPrintMask"></div>
                                    </div>
                                </React.Fragment>
                            )
                    )
                    :
                    <Layout hasSider>
                        <Layout
                            className="site-layout"
                        >
                            <Content
                                style={{
                                    overflow: 'initial',
                                }}
                            >
                                <Layout className="site-layout">

                                    {/* 多文档问答 */}
                                    {pageType === "multiAi"?<>
                                        <Sider
                                            width={240}
                                            className="layoutSiderMulti"
                                        >
                                            <Checkbox.Group onChange={onMultiChange} value={checkedMultiKey}>
                                                {multiKnowledgeList.map(item=>{
                                                    let id = item.id || item.knowledgeId || item.policyId || item.elasticId;
                                                    return <div className='multiFileItem'>
                                                        <Checkbox value={id}></Checkbox>
                                                        <span onClick={()=>{
                                                            if (modalInfo) {
                                                                return false;
                                                            }
                                                            loadingF();
                                                            // destroyWPSSDK(id);
                                                                // 显示当前选中的文档
                                                                setKnowledgeId(id);
                                                                setShowOperateBox(false);
                                                                if(WPSSDK){
                                                                    WPSSDK.destroy();
                                                                    WPSSDK = "";
                                                                    WPSAPP = "";
                                                                }
                                                                // setAipage(1);
                                                            }} className={`multiFileTitle ${knowledgeId === id ? "checkedMultiFile" : ""}`}>{item.knowledgeName}</span>
                                                    </div>
                                                })}
                                            </Checkbox.Group>
                                        </Sider>
                                    </>:
                                    <div
                                        className={siderType === 1 ? "layoutLeftSiderOpen" : siderType === 0 ? "layoutLeftSiderClose" : "layoutLeftSiderdefault"}>
                                        <Sider
                                            width={280}
                                            className="layoutSider"
                                            style={{
                                                height: tableHight-20,
                                            }}
                                        >
                                            {/* <QAListArticles/> */}
                                            <div 
                                                className="basicInformation"
                                            >
                                                <DetailsData knowledgeDetails={knowledgeDetailsDate} shareDepartment={shareDepartmentObject}/>
                                            </div>
                                        </Sider>

                                        
                                        <div className="switchButton">
                                            <Iconfont
                                                type={'icon-right'}
                                                className={`${siderType === 0 ? "rotateClose" : "rotateOpen"} DoubleRightOutlined`}
                                                onClick={switchState}
                                            />
                                        </div>
                                    </div>}

                                    <Content
                                        style={{
                                            overflow: 'auto',
                                            scrollBehavior: "smooth",
                                        }}
                                        id="centerContentBox"
                                        className="centerContentBox"
                                    >
                                        {
                                            knowledgeDetailFileType === "zip" ? (
                                                <div className="zipChildFileList">
                                                    <div className="scrollBox">
                                                        {
                                                            unzipFileList.length > 0 ? unzipFileList.map(item => {
                                                                const {id, name, createTime, type} = item;
                                                                return (
                                                                    <div
                                                                        className="fileItem"
                                                                        key={id}
                                                                        onClick={() => getFileViewUrl(id, type, viewImgList, item)}
                                                                    >
                                                                        <div className="infoBox">
                                                                            <KnowledgeFileIcon fileType={type}/>
                                                                            <span
                                                                                className="fileName overflowEllipsis">{name}</span>
                                                                        </div>
                                                                        <span
                                                                            className="timeText">{moment(createTime).format("YYYY-MM-DD HH:mm:ss")}</span>
                                                                    </div>
                                                                )
                                                            }) : (
                                                                NoneData({text: "无文件"})
                                                            )
                                                        }
                                                    </div>
                                                </div>
                                            ) :  knowledgeDetailFileType === "dwg" ? (
                                                <div className="zipChildFileList">
                                                    <div className="scrollBox">
                                                        <div
                                                            className="fileItem"
                                                        >
                                                            <div className="infoBox">
                                                                <KnowledgeFileIcon fileType={knowledgeDetailsDate.fileType}/>
                                                                <span
                                                                    className="fileName overflowEllipsis">{knowledgeDetailsDate.knowledgeTitle}</span>
                                                            </div>
                                                            <span
                                                                className="timeText">{moment(knowledgeDetailsDate.createTime).format("YYYY-MM-DD HH:mm:ss")}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            ) : (
                                                <React.Fragment>
                                                    <div id="noPrintBox">{/* 模糊div */}
                                                        {
                                                            initData !== "" ? (
                                                                <UEditor
                                                                    ref={ueRef}
                                                                    config={onLIneConfig}
                                                                    editorReady={editorReady}
                                                                    getScrollTopAndText={getScrollTopAndText}
                                                                ></UEditor>
                                                            ) : (
                                                                <div
                                                                    className="custom-mount"
                                                                    id={"office"}
                                                                    style={{
                                                                        height: tableHight,
                                                                    }}
                                                                ></div>
                                                            )
                                                        }

                                                        {/* 模糊后的遮罩层，提供点击事件使用 */}
                                                        <div id="noPrintMask" className="noPrintMask"></div>

                                                        <div className='oprationCloseAndSearch'>

                                                            {/* AI问答多文档问答 */}
                                                            {showCloseBtn&&<Tooltip placement="left" title={"关闭"}>
                                                                <div 
                                                                    className="closeCircleOutlined"
                                                                    onClick={closeDetails}
                                                                >
                                                                    <CloseCircleOutlined />
                                                                </div>
                                                            </Tooltip>}

                                                            {WPSAPP&&
                                                            !discussExcludeType.includes(knowledgeDetailFileType)
                                                            &&
                                                            <Tooltip placement="left" title={"查找"}>
                                                                <div 
                                                                    className="searchOutlined"
                                                                    onClick={openSearch}
                                                                >
                                                                    <SearchOutlined />
                                                                </div>
                                                            </Tooltip>}
                                                        </div>
                                                        
                                                        <DocumentOperations
                                                            showOperateBox={showOperateBox}
                                                            selectedText={selectedText}
                                                            textRange={textRange}
                                                            callback={documentOperationsCallback}
                                                            NodeDetails={NodeDetails}
                                                            ExcerptDetails={ExcerptDetails}
                                                            noteType={noteType}
                                                            textUrl={textUrl}
                                                            knowledgeId={knowledgeId}
                                                            knowledgeDetails={knowledgeDetail}
                                                            currentPage={currentPage}
                                                            pageType={pageType}
                                                            showCloseBtn={showCloseBtn}
                                                        />
                                                    </div>

                                                    <div className='oprationFooter'>
                                                        <div className='oprationSwitch'>
                                                            <Iconfont
                                                                type={'icon-right'}
                                                                iStyle={{fontSize: '16px',float: 'left'}}
                                                                className={`${oprationStatus === 0 ? "rotateClose" : "rotateOpen"}`}
                                                                onClick={()=>setOprationStatus((oprationStatus === 1 || oprationStatus === 2) ? 0 : 1)}
                                                            />
                                                        </div>
                                                        
                                                        <div 
                                                            className={oprationStatus === 1 ? "oprationFooterOpen" : oprationStatus === 0 ? "oprationFooterClose" : "oprationFooterDefalut"}
                                                            style={{float: 'left'}}
                                                        >
                                                            <Space size={"small"} style={{float: 'left',height:"16px"}}>
                                                                <Text type="secondary"
                                                                    onClick={() => {
                                                                        if (!likeState) {
                                                                            // if((getSearchParams().type === 'policy')){
                                                                            // onPolicyCollectionOrLike(0)
                                                                            // }else{
                                                                            onLike();
                                                                            // }
                                                                        }
                                                                    }}
                                                                >
                                                                    <LikeOutlined
                                                                        style={isLike ? {color: "rgb(240, 209, 85)"} : {}}/>赞（{knowledgeLikeNum}）
                                                                </Text>
                                                                <Text type="secondary"
                                                                        onClick={openDiscuss}><CommentOutlined/>讨论（{discussTotal}）</Text>
                                                                <Text type="secondary"
                                                                    onClick={() => {
                                                                        if (!collectionState) {
                                                                            // if((getSearchParams().type === 'policy')){
                                                                            // onPolicyCollectionOrLike(1)
                                                                            // }else{
                                                                            onCollection();
                                                                            // }
                                                                        }
                                                                    }}
                                                                >
                                                                    <StarFilled
                                                                        style={isCollection ? {color: "rgb(240, 209, 85)"} : {}}/>收藏
                                                                </Text>
                                                            </Space>
                                                        </div>
                                                        
                                                    </div>


                                                    {/* 讨论 */}
                                                    {DiscussType ? (
                                                        <DiscussBox
                                                            siderType={siderType}
                                                            knowledgeDetails={knowledgeDetail}
                                                            discussTotal={discussTotal}
                                                            setDiscussTotals={setDiscussTotals}
                                                        />
                                                    ) : ""}
                                                </React.Fragment>
                                            )
                                        }
                                    </Content>
                                </Layout>
                            </Content>
                        </Layout>

                        {(discussExcludeType.includes(knowledgeDetailFileType) && cartStatus != '0')?
                            undefined :
                                (
                                    <div className={classNameObject[rightMenuType][rightSiderType] || aiClassNameObject[rightMenuType]}>
                                        <Sider
                                            width={"100%"}
                                            className="knowledgeNote"
                                            style={{
                                                overflow: 'auto',
                                                height: tableHight
                                            }}
                                        >
                                            <div className="rightbaseInfor">
                                                <div className='rightMenu'>
                                                    {menuItem.map(item=>{
                                                        if(cartStatus != '0' && item.key === 0){
                                                            return null
                                                        }
                                                        if(discussExcludeType.includes(knowledgeDetailFileType) && item.key === 1){
                                                            // 阅读笔记
                                                            return null
                                                        }
                                                        return <div onClick={()=>{
                                                            if(item.key === 0){
                                                                dispatch(setAIModalState({
                                                                    // questionLocation:0,
                                                                    // knowledgeIds:[knowledgeId],
                                                                    // historyTopicId: null,
                                                                    // queryText:"推荐问题",
                                                                    // questionModule:"summary",
                                                                    // relQuestions:2,
                                                                    // refLoc: true,
                                                                    sendAPI: null
                                                                }))
                                                            }
                                                            setRightSiderType(2)
                                                            setRightMenuType(item.key);
                                                        }} 
                                                        key={item.key} 
                                                        className={`rightMenuItem ${rightMenuType === item.key?'rightMenuItemSelected':''}`}
                                                        >{item.label}</div>
                                                    })}
                                                </div>
                                                
                                                <div style={{flex:1,height:400,display: rightMenuType === 0 ? 'block':'none'}}>
                                                    <AIModal 
                                                        aiType="detailsSum"
                                                        knowledgeDetails={knowledgeDetail}
                                                        callbackPositioning={(page, largeModelQaLogDeatilId)=>{
                                                            callbackPositioning(page, largeModelQaLogDeatilId)
                                                        }}
                                                    />
                                                </div>
                                                {rightMenuType === 1 && <>
                                                    <div className='readNoteTitle'>
                                                        <Row>
                                                            <Col span={13}>
                                                                <Text >阅读笔记</Text>
                                                            </Col>
                                                            <Col span={11}>
                                                                <Tabs size={"small"} onChange={TabsOnchange} items={items} activeKey={noteType}/>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                    {/* 笔记 */}
                                                    {discussExcludeType.includes(knowledgeDetailFileType) ?
                                                        undefined
                                                        :
                                                        (
                                                            <NoteList
                                                                noteType={noteType}
                                                                modifyNodeLable={setNodeDetails}
                                                                getNodeList={setRefreshList}
                                                                nodeList={NodeListData}
                                                                excerptList={excerptList}
                                                                setLocationNotes={setLocationNotes}
                                                                NodePagination={NodePagination}
                                                                spinningType={spinningType}
                                                                knowledgeId={knowledgeId}
                                                                setNodePaginationChange={setNodePaginationChange}
                                                            />
                                                        )
                                                    }
                                                    {/* 摘录 */}
                                                    {noteType === 0 && !discussExcludeType.includes(knowledgeDetailFileType) ?
                                                        (
                                                            <ExcerptList
                                                                excerptList={excerptList}
                                                                getExcerptList={getExcerptList}
                                                                excerptPagination={excerptPagination}
                                                                updataExcerpt={updataExcerpt}
                                                                excerptLoading={excerptLoading}
                                                                setLocationNotes={setLocationNotes}
                                                            />
                                                        ) : undefined
                                                    }
                                                </>}
                                            </div>
                                        </Sider>
                                        <div className="switchButtonRight">
                                            <Iconfont
                                                type={'icon-right'}
                                                className={`${rightSiderType === 0 ? "rotateOpen" : "rotateClose"} DoubleRightOutlined`}
                                                onClick={switchRightState}
                                            />
                                        </div>
                                    </div>
                                )
                        }
                    </Layout>
            }

            

            <ViewImg
                viewImgList={viewImgList}
                isShowViewImg={isShowViewImg}
                viewImgIdx={viewImgIdx}
                closeViewImg={() => setIsShowViewImg(false)}
            />
        </div>
    </>)
}

export default KnowledgeDetails;
