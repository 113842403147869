import React from "react";
import Api from "../../api";

export default async function getWPSFileUrl(fileId, keywords, apiType, callBack) {
    let flag = false;
    try {
        document.getElementById("office").innerHTML = "";

        let res = null;
        if(apiType === 'upload'){
            res = await Api.Knowledge.getWPSFilePreview({fileId:fileId});
        }else{
            res = await Api.Common.getPreviewUrl({data:{policyId:fileId}});
        }
        
        if (res && (res.code === 200 || res.code === 0) && res.data) {
            flag = true;
            const link = res.data.link || res.data.data.link;

            callBack(link);

            this.WPSSDK = OpenSDK.config({
                url: link,
                zoom: 0.6,
                mount: document.querySelector('#office'),
            })

            // 等待加载完毕
            await this.WPSSDK.ready();

            this.WPSAPP = this.WPSSDK.Application;
            console.log(this.WPSSDK);

            // ES搜索带入分片后的关键字
            // 是否有关键字
            if (keywords) {
                // 高亮文字并获取位置
                const data = await this.WPSAPP.ActiveDocument.Find.Execute(keyword, true);
                // 滚动到第一次出现的位置
                const range = await this.WPSAPP.ActiveDocument.Range(data[0].pos, data[0].pos + data[0].len);
                await this.WPSAPP.ActiveDocument.ActiveWindow.ScrollIntoView(range);
            }
            return link;
        } else {
            callBack("none");
        }
    } catch(e) {
        if (!flag) {
            callBack("none");
        }
        console.log(flag, "=======================");
        console.log(e);
    }
}
