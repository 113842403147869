import React , {useEffect, useState} from 'react';
import { Modal, Button, Dropdown, Input, message, Spin, Empty, Typography, Tooltip} from 'antd'
import { DeleteOutlined, FileAddOutlined, RightCircleOutlined, DownCircleOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from "react-redux";

// import HistoryTopic from '../../../components/AIModal/components/HistoryTopic/index'
// import BackIcon from '../../../components/BackIcon';
// import Api from '../../../api';
import { setAIModalState, refreshPublicKnowledge } from "../../../../features/AIIntelligent/AIReducer";
import PersonalKnowledge from '../../../../components/PersonalKnowledge';
import PublicKnowledge from '../../../../components/PublicKnowledge';
import SelfTable from "../../../../components/SelfTable/index"
import Api from '../../../../api';
import { getSizeText, ModalConfirm, jumpToPage, getSyncStatus } from "../../../../utils/tool";
import SelfDesignModal from "../../../../components/SelfDesignModal"; 
import UploadFile from "../../../../pages/personalCenter/components/UploadFile/index";


import './index.scss';

const { Search } = Input;
const { Text, Link } = Typography;
const FileList = () => {
    const dispatch = useDispatch();

    const themeList = [
        {
            key: 'public',
            label: "从公共知识库添加",
        },
        {
            key: 'personal',
            label: "从个人知识库添加",
        },
        {
            key: 'upLoad',
            label: "上传本地文档",
        }
    ]

    const columns = [
        {
          title: '资料名称',
          dataIndex: 'knowledgeName',
          key: 'knowledgeName', 
          width: '260px',
          ellipsis:true,
          render: (text, record) => {
            return (
                <a className="overflowEllipsis" title={text} onClick={() => jumpToPage(record)}>{text}</a>
            )
        },
        },
        {
            title: '同步状态',
            dataIndex: 'syncStatus',
            width: '120px',
            render: (text,record) => {
                return getSyncStatus(text,record)
            }
        },
        {
          title: '资料类型',
          dataIndex: 'fileType',
          key: 'fileType',
          width: '80px',
        },
        {
          title: '发布者',
          dataIndex: 'userName',
          key: 'userName',
          width: '100px',
        },
        {
          title: '修改时间',
          dataIndex: 'updateTime',
          key: 'updateTime',
          width: '160px',
        },
        {
            title: '文件大小',
            dataIndex: 'size',
            key: 'size',
            width: '80px',
            render: (text) => <span>{getSizeText(parseInt(text))}</span>,
        },
        {
            title: '操作',
            dataIndex: '',
            key: 'x',
            width: '60px',
            render: (text,record) => <a onClick={()=>removeKnowledge([record.id])}>移除</a>,
        },
      ];

    const [personalknowledgeOpen, setPersonalknowledgeOpen] = useState(false);
    const [publicknowledgeOpen, setPublicknowledgeOpen] = useState(false);
    const [selectedRowKey, setSelectedRowKey] = useState([]);
    const [loading, setLoading] = useState(true);
    // const [tableLoading, setTableLoading] = useState(false);
    const [listData, setListData] = useState([]);
    const [showcheckbox, setShowcheckbox] = useState(false);
    const [searchData, setSearchData] = useState({});

    const historyTopicId = useSelector(state => state.AIModal.historyTopicId);
    const historyTitle = useSelector(state => state.AIModal.historyTitle);
    const refreshHistoryTopicList = useSelector(state => state.AIModal.refreshHistoryTopicList);
    const refreshTopicList = useSelector(state => state.AIModal.refreshTopicList);
    const [fileModalIsShow, setFileModalIsShow] = useState(false);
    
    // 分页
    const [pagination, setPagination] = useState({
        "pageIndex": 0,
        "pageSize": 0,
        "total":0
    });

    useEffect(() => {
        if(historyTopicId){
            let newSearchData = {...searchData}
            newSearchData.largeModelQaLogId = historyTopicId;
            setSearchData(newSearchData);
            getDetails(newSearchData,(ids)=>{
                if(refreshTopicList){
                    return false;
                }

                // 第一次默认开启对话
                dispatch(setAIModalState({
                    modalOpen: true,
                    questionLocation: 0,
                    knowledgeIds: ids,
                    multiChatId:historyTopicId,
                    sendAPI:false
                }))
            })
            setSelectedRowKey([]);
            setShowcheckbox(false);
        }
    }, [historyTopicId])

    useEffect(() => {
        if(refreshHistoryTopicList){
            getDetails(searchData);
        }
  }, [refreshHistoryTopicList])

    

    // useEffect(() => {
    //     console.log(searchData,"2")
    //     getDetails()
    // }, [searchData])

    useEffect(() => {
        if(listData.length > 0 && historyTopicId){
            // startConversation();
        }
    }, [listData])

    const getDetails = async(param,callback) => {
        setListData([]);
        setLoading(true);
        try{
            const res = await Api.Chat.getKnowledgeList({data:{
                ...param,
                largeModelQaLogId:historyTopicId
            }}) 
            setLoading(false)
            if(res.code === 0){
                setListData(res.data || []);
                
                dispatch(setAIModalState({
                    multiKnowledgeList: res.data, //多文档数据
                }))

                if(callback){
                    let ids = []
                    res.data.map(item=>{
                        ids.push(item.id)
                    })
                    callback(ids)
                }
            }
        }catch(err){
            console.log(err)
            setLoading(false);
        }
    }

    
    const themeChange = ({ key }) => {
        if(key === 'personal'){
            setPersonalknowledgeOpen(true)
        }else if(key === 'public'){
            setPublicknowledgeOpen(true)
        }else if(key === 'upLoad'){
            // setFileModalIsShow(true);
            dispatch(setAIModalState({
                uploadModalType:'upDateCv',
                uploadModalOpen:true
            }))
        }
    }

    const onSearch = (value) => {
        let newSearchData = {...searchData}
        newSearchData.knowledgeName = value;
        setSearchData(newSearchData)
        getDetails(newSearchData)
    }

    // 删除
    const removeKnowledge = async(ids) => {
        ModalConfirm({
            title: `确认从当前对话列表中移除这${ids.length}条知识吗？`,
            onOk: async (resolve, reject) => {
              try {
                let res = await Api.Chat.deleteQaKnowledge({data:{
                    knowledgeIds:ids,
                    largeModelQaLogId:historyTopicId
                }});
                if (res.code === 0) {
                    message.success("操作成功")
                    getDetails();
                    setSelectedRowKey([]);
                    setShowcheckbox(false);
                    resolve();
                }else{
                    reject();
                }
              } catch (error) {reject();}
            },
        });
    }
    // 批量删除
    const batchRemoveKnowledge = () => {
        if(!showcheckbox){
            setShowcheckbox(true);
            return false
        }
        if(selectedRowKey.length <= 0){
            message.warning("请选择要删除的知识")
            return false;
        }

        removeKnowledge(selectedRowKey);
    }


    const addKnowledgeF = async(ids) =>{
        try{
            const res = await Api.Chat.addQaKnowledge({data:{
                knowledgeIds: ids,
                largeModelQaLogId:historyTopicId
            }})
            if(res.code === 0){
                message.success("添加成功");
                getDetails();
            }
        }catch(err){}
    }

    const startConversation = () => {
        if(listData.length <= 0){
            message.warning("请添加要对话的知识")
            return false;
        }
        
        let ids = []
        listData.map(item=>{
            ids.push(item.id)
        })
        
        dispatch(setAIModalState({
            modalOpen: true,
            questionLocation: 0,
            knowledgeIds: ids,
            multiChatId:historyTopicId,
            sendAPI:false
        }))
    }

    return (<div className='fileListFrame'>
        <Spin spinning={loading}>
            <div className='fileHeader'>
                <div className='title'>
                    <span className='titleIcon'>{historyTitle || "新建标题"}</span><br/>
                    <span className='titleDes'>添加文档内容进行问题解答</span>
                </div>
                <Button type="primary" onClick={startConversation} disabled={listData.length === 0}>开始对话</Button>
            </div>
            <div className='fileSearch'>
                <div className='searchInput'>
                    <span className='inputTitle'>已添加知识：</span>
                    <Search placeholder="请输入文件名称" allowClear onSearch={onSearch}/>
                </div>

                {listData.length > 1 ? (<span>
                
                    {showcheckbox&&<span>
                        已选{selectedRowKey.length}项<a style={{marginLeft:5}} onClick={()=>{
                        setSelectedRowKey([]);
                        setShowcheckbox(false);
                    }}>取消选择</a>
                    </span>}

                    <Button 
                        className='detaleFile' 
                        type="text" 
                        icon={<DeleteOutlined />} 
                        onClick={()=>{
                            batchRemoveKnowledge();
                        }}
                    >批量删除知识</Button>

                </span>):null}
                    
                <Dropdown
                    arrow
                    menu={{
                        items: themeList,
                        selectable: true,
                        onClick: themeChange,
                    }}
                    trigger={['click']}
                    getPopupContainer={()=>document.getElementById("addKnowledgeFile")}
                    placement="bottomRight"
                >
                    <Button 
                        id='addKnowledgeFile' 
                        className='addKnowledgeFile' 
                        type="text" 
                        icon={<FileAddOutlined />} 
                    >添加知识</Button>
                </Dropdown>
            </div>

            <SelfTable
                rowRadioSelection={{
                    type: 'checkbox',
                    selectedRowKey:selectedRowKey,
                    setSelectedRowKey:setSelectedRowKey,
                    getCheckboxProps: (record) => ({
                        disabled: !showcheckbox
                    }),
                }}
                dataSource={listData}
                columns={columns}
                // pagination={pagination}
                // loading={tableLoading}
                // onChange={tableChanges} 
                expandable={{
                    expandedRowRender: (record) => (
                        <p
                        style={{
                            margin: 0,
                        }}
                        >
                        {record.knowledgeDigest}
                        </p>
                    ),
                    expandIcon: ({expanded, onExpand, record}) =>
                        expanded ? (
                            <DownCircleOutlined style={{color: "#1C76FD"}} onClick={e => onExpand(record, e)}/>
                        ) : (
                            <RightCircleOutlined style={{color: "#1C76FD"}} onClick={e => onExpand(record, e)}/>
                        )
                }}
                isShowSort={false}
                footerHeight={0}
                locale={{
                    emptyText: (
                        <Empty
                            image={Empty.PRESENTED_IMAGE_SIMPLE}
                            description={
                                <span>
                                    暂无数据，
                                    <Dropdown
                                        arrow
                                        menu={{
                                            items: themeList,
                                            selectable: true,
                                            onClick: themeChange,
                                        }}
                                        trigger={['click']}
                                        getPopupContainer={()=>document.getElementById("addKnowledgeFileText")}
                                        placement="topRight"
                                    >
                                        <a href="#API" id='addKnowledgeFileText'>点击添加</a>
                                    </Dropdown>
                                </span>
                            }
                        >
                        </Empty>
                    ),
                }}
            />

        </Spin>
        {personalknowledgeOpen&&<PersonalKnowledge 
            open={personalknowledgeOpen} 
            cancelModal={()=>setPersonalknowledgeOpen(false)}
            addKnowledgeF={addKnowledgeF}
        />}

        {publicknowledgeOpen&&<PublicKnowledge
            open={publicknowledgeOpen} 
            cancelModal={()=>setPublicknowledgeOpen(false)}
            addKnowledgeF={addKnowledgeF}
        />} 

        {/* 文件上传 */}
        <SelfDesignModal
            open={fileModalIsShow}
            centered={true}
            closeIcon={false}
            closable={false}
            maskClosable={false}
            wrapClassName={"uploadFileModalBox"}
            destroyOnClose={true}
            content={(
                <UploadFile
                    isChoiceFile={true}
                    isSelected={false}
                    onCancel={() => {
                        setFileModalIsShow(false);
                    }}
                    uploadType={"parsingFiles"}
                />
            )}
        />
    </div>)
}

export default FileList