import React, {useState, useEffect} from "react";
import {useHistory} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import {message, Modal, Button, Popconfirm, Table, Progress} from "antd";
import { SyncOutlined, ExclamationCircleOutlined, CloseCircleOutlined, CloudUploadOutlined } from "@ant-design/icons";
import { fetchEventSource } from '@microsoft/fetch-event-source';

import Iconfont from "../Iconfont/Iconfont";
import {zuStandStore} from "../../zustand";
import {getSizeText} from "../../utils/tool";
import {fileLimitType} from "../../utils/defaultValue";
import SplitUpload from "../SplitUpload/index-websocket";
import {setKnowledgeState} from "../../features/editKnowledge/editKnowledgeReducer";
import { setAIModalState, refreshAIcarNumF } from "../../features/AIIntelligent/AIReducer";
import {addAICart} from "../../utils/tool";
import storage from "../../utils/storage";
import Api from "../../api";

import "./index.scss";
import { use } from "react";

const { confirm } = Modal;
let parsingEventSource = null;
const confirmContent = '当前文件解析未完成，关闭当前窗口将在后台继续解析，请确认是否关闭?'
const UploadFileModal = (props) => {

    const dispatch = useDispatch();
    
    const {
        onOk,
    } = props;

    const history = useHistory();
    
    const [isChoiceFile, setIsChoiceFile]  = useState(true);
    const [isCompleteUpload, setIsCompleteUpload] = useState(false);

    const [okText, setOkText] = useState("");
    const [okBtnDisabled, setOkBtnDisabled] = useState(false);
    const [cancelBtnDisabled, setCancelBtnDisabled] = useState(false);

    const [uploading, setUploading] = useState(false); // 是否正在上传中
    const [parsing, setParsing] = useState(false); // 是否正在解析中

    const [tableId, setTableId] = useState(`tableContentBox-${Math.random()}`);

    const columns = [
        {
            dataIndex: "name",
            key: "name",
            title: "文件名",
            align: "left",
        },
        {
            dataIndex: "size",
            key: "size",
            title: "大小",
            width: "100px",
            render: (val, record, idx) => {
                return getSizeText(val || 0, 'b')
            },
        },
        {
            dataIndex: "status",
            key: "status",
            title: "状态",
            width: "120px", 
            align: "left",
            render: (val, record, idx) => {
                let icon = "icon-time_fill";
                let text = "待上传";
                // 上传状态
                if (val === "success") {
                    icon = "icon-dui";
                    text = "上传成功";
                } else if (val === "processing") {
                    let process = `${Number(Math.round(record.process * 10000) / 100)}%`;
                    icon = (<SyncOutlined spin />);
                    text = `上传${process}`;
                } else if (val === "fail") {
                    icon = "icon-locationiconfail";
                    text = record.msg || "上传失败";
                }

                // 解析状态
                else if(val === "parsingProcessing"){
                    icon = (<SyncOutlined spin />);
                    text = `解析中`;
                }
                else if(val === "parsingFail"){
                    icon = "icon-locationiconfail";
                    text = record.msg || "解析失败";
                }

                else if(val === "parsingSuccess"){
                    icon = "icon-dui";
                    text = record.msg || "解析成功";
                }

                return (
                    <span className="statusBox">{
                        (val === "processing" || val === "parsingProcessing") ? icon : 
                        (<Iconfont type={icon} />)}<span style={{ display: 'inline-block',
                            width: '75px',
                            marginLeft: '5px'}}>{text}</span></span>
                )
            },
        },
        {
            dataIndex: "operate",
            key: "operate",
            title: "操作",
            width: "100px",
            render: (val, record, idx) => {
                const {status} = record;
                return (
                    <div className="uploadOperateBox">
                        {
                            (!status || status === 'fail') ? (
                                <Popconfirm
                                    placement={"left"}
                                    title={"确定删除吗？"}
                                    okText={"确定"}
                                    cancelText={"取消"}
                                    onConfirm={() => deleteSelectFileFunc(record)}
                                    overlayClassName={"deletePupConfirmBox"}
                                >
                                    <span className="operateBtn deleteBtn">删除</span>
                                </Popconfirm>
                            ) : ("-")
                        }
                    </div>
                )
            },
        },
    ];
    const [fileList, setFileList] = useState([]);
    const [tableHeight, setTableHeight] = useState(0);
    const [newFileList, setNewFileList] = useState([]);

    const uploadModalOpen = useSelector(state => state.AIModal.uploadModalOpen);
    const uploadModalType = useSelector(state => state.AIModal.uploadModalType);
    const historyTopicId = useSelector(state => state.AIModal.historyTopicId);

    useEffect(() => {
        let dom = document.getElementById(tableId);
        if (dom) {
            setTableHeight(dom.clientHeight - 48);
        }
    })

    useEffect(() => {
        console.log(isChoiceFile,isCompleteUpload,okBtnDisabled,cancelBtnDisabled,"状态")
    },[isChoiceFile,isCompleteUpload,okBtnDisabled,cancelBtnDisabled])

    useEffect(() => {
        if (newFileList.length > 0) {
            uploadFileCallBack(newFileList);
        }
    }, [newFileList])
// 已选择文件
    async function selectFilesChangeFunc(fileLists, state, e) {

        let list = [...state.fileList];

        for (const item of fileLists) {
            if (fileLimitType.includes(item.type) || item.name.split('.').pop() === "dwg") {
                let encodeName = window.encodeURI(item.name);
                let idStr = `${item.lastModified}-${encodeName}-${item.size}-${item.type}-${Date.now()}`;
                item.fileId = window.btoa(idStr);
                list.push(item);
            }
        }
        console.log(list,"hahahha")
        setFileList(list);
        setOkBtnDisabled(false);
        if(e){
            e.target.value = "";
        }
    }

    // 删除文件
    function deleteSelectFileFunc (file) {
        // 删除文件

        // if (isChoiceFile) {
        let idx = fileList.findIndex(item => item.fileId === file.fileId);
        if (idx > -1) {
            let newFileList = [...fileList];
            newFileList.splice(idx, 1);
            if(newFileList && newFileList.length <= 0){
                console.log(newFileList,'文件全部删完了')
                // 文件全部删完了
                clearUploadFile(true);
            }
            setFileList(newFileList);
        }
        // } else {
        //     if (deleteFileFunc) {
        //         deleteFileFunc(file);
        //     }
        // }

    }

    // 确定上传文件
    function uploadFile (state) {
        const {fileList} = state;
        if (fileList.length < 1) {
            message.warning("请选择需要上传的文件！");
            return;
        }

        let newFileList = [];
        for (const item of fileList) {
            item.uploadType = "parsingFiles";
            newFileList.push(item);
        }

        console.log("newFileList", newFileList);
        setFileList(newFileList);
        setNewFileList(newFileList);
        
        setUploading(true);
        setCancelBtnDisabled(true);
        setIsChoiceFile(false);
    }

    // 上传文件执行函数
    const uploadFileCallBack = async (newFileList) => {
        await SplitUpload(newFileList, (params, socket) => {
            const {status, process, fileId, otherData, linkStatus, msg} = params || {};
            if(params.status === "fail"){
                console.log("上传失败：", msg)
            }

            if(linkStatus != 'stop'){
                let commonFileList = [];
                let flag = true; //是否全部上传成功
                
                console.log(fileList,status,newFileList,"11111111111111111");
                if (status) {
                    fileList.forEach(item => {
                        const fileItem = item;
                        if(item.fileId === fileId){
                            fileItem.status = status;
                            fileItem.process = process;
                            fileItem.otherData = otherData
                        }
                        console.log(fileItem,"fileItemfileItem")
                        commonFileList.push(fileItem);
                        if (item.status !== "success" && item.status !== "fail") {
                            flag = false;
                        }
                    })
                    setFileList(commonFileList);
                }else{
                    flag = false;
                }
                
                console.log(process,params,commonFileList,"paramsparams")
                // 判断一下有没有失败的
                // 有失败的
                // 可以继续选择文件上传，显示开始解析按钮
                // if(failFlag){
                //     socket?.close();
                //     // setUploading(false);
                //     // setParsing(false);

                //     setIsChoiceFile(true);
                //     setOkBtnDisabled(false);
                //     setCancelBtnDisabled(false);
                //     setOkText("开始解析");
                    

                // }else 
                console.log(flag,"flag")
                if (flag) {
                    console.log("关闭连接")
                    socket?.close();
                    setUploading(false);
                    setParsing(true);
                    setCancelBtnDisabled(false);
                    parsingFiles(commonFileList);
                }
            }

        });
    }

    // 上传按钮
    const confirmFunc = (state) => {
        if (onOk) {
            onOk(state);
        } else  if(isCompleteUpload){
            clearUploadFile();
        }else if(isChoiceFile) {
            uploadFile(state);
        }
    }

    // 清除当前解析记录
    const clearUploadFile = async(ModalType) => {
        closeConnection();
        setIsChoiceFile(true);
        setIsCompleteUpload(false);
        setOkBtnDisabled(true);
        setCancelBtnDisabled(false);
        setOkText("");
        setUploading(false);
        setParsing(false);
        setFileList([]);
        setNewFileList([]);

        dispatch(setAIModalState({
            analysisStatus: false,
            uploadModalOpen: ModalType ? ModalType : false,
            refreshTopicList: null,
        }))
    }

    // 开始对话
    async function startConversation(ids){
        const res = await Api.Chat.createQa({
            data:{
                knowledgeIds: ids,
                questionLocation:0 // 0文档中的问答， 1知识中心 ， 2ES搜索界面， 为空查讯全部
            }
        })
        if(res.code === 0){
            sessionStorage.setItem("dialogueParam", JSON.stringify({
                questionLocation: 0,
                knowledgeIds: ids,
                historyTopicId:res.data,
            }));

            dispatch(setAIModalState({
                refreshTopicList: Date.now().toString()
            }));
        }
    }

    // 加入购物车
    async function addAIcarList(ids){
        const res = await addAICart(ids,{check:true});
        if(res){
            dispatch(refreshAIcarNumF(Date.now().toString()))
        }
    }

    // 更新对话文件
    async function upDateCvList(ids){
        try{
            const res = await Api.Chat.addQaKnowledge({data:{
                knowledgeIds: ids,
                largeModelQaLogId:historyTopicId
            }})
            if(res.code === 0){
                message.success("添加成功");
                dispatch(setAIModalState({refreshHistoryTopicList:Date.now().toString()}))
            }
        }catch(err){}
    }
    

    // 开始解析
    function parsingFiles (List){
        setParsing(true);
      
        let newList = [] , _fileList = List ? [...List] : [...fileList], data = [], knowledgeIds = [];

        _fileList.forEach(item => {
            let fileId = item.otherData ? (item.otherData.fId || item.otherData.fileId) : null
            let fileItem = {
                ...item,
                status: item.status === "success" ? "parsingProcessing": item.status,
                size: item.size,
                name: item.name,
                fileId: fileId
            };
            if(item.status != "fail"){
                let id = fileId;
                data.push(id);
                knowledgeIds.push(item.otherData.knowledgeId || item.otherData.knowledgeManagementId)
            }
            newList.push(fileItem);
        })
        _fileList = newList;
        setFileList(newList);

        console.log(newList,"newList22222")

        if(data.length <= 0){
            // 没有数据说明全都失败了
            // 可以继续上传文件
            setIsChoiceFile(true);
            setOkText("");
            return false;
        }

        // 处理数据，加入购物车，还是创建新话题，还是更新话题数据列表
        if(uploadModalType === 'AICart'){
            // 购物车
            addAIcarList(knowledgeIds)
        }else if(uploadModalType === 'createCv'){
            // 解析成功的文档 - 开始对话
            startConversation(knowledgeIds)
        }else if(uploadModalType === 'upDateCv'){
            upDateCvList(knowledgeIds)
        }

        // 开始解析
        parsingEventSource = fetchEventSource("/api/largeModelQa/getSseByFileIds", {
            openWhenHidden:true,
            method: 'POST',
            headers: {
                "Content-Type": 'application/json',
                "Accept": 'text/event-stream',
                "satoken": storage.getItem("token")
            },
            body: JSON.stringify(data),
            onmessage(event) {
                // 在这里操作流式数据
                console.info("接受到回复：",event);
                if(event.data){
                    try {
                        const data = JSON.parse(event.data);
                        
                        const status = String(data.status);
                        // 文件状态 0:处理成功;1:正在处理;2:处理失败;3:未处理;4:文档开始嵌入
                        // status=1, description="文件解析成功，正在准备嵌入"
                        // status=4, description="文档开始嵌入"
                        // status=0, description="处理完成"
                        if(status === '0' || status === '2'){
                            let flag = true;

                            let newListw = [];
                            _fileList.forEach(item => {
                                let fileItem = {
                                    ...item,
                                    status:item.status,
                                    name:item.name,
                                    size:item.size,
                                };

                                console.log("item:",fileItem, item)

                                if(item.fileId === data.fileId){
                                    console.log("status:",status)
                                    
                                    fileItem.status = status === '0' ? "parsingSuccess" : status === '2' ? "parsingFail" : "parsingProcessing";
                                }
                                newListw.push(fileItem);

                                console.log("fileItemStatus:",fileItem.status, typeof fileItem.status);
                                if((String(fileItem.status) != 'parsingSuccess' && String(fileItem.status) != 'parsingFail')){
                                    flag = false;
                                }
                            })
                            _fileList = newListw;
                            setFileList(newListw);

                            console.log("SSE:",flag,newListw)

                            if(flag){
                                analysisSuccessful(newListw);
                            }

                        }else{
                            console.log("数据未开始解析");
                        }
                    }catch(e){
                        console.log("数据格式错误:");
                        console.info(e);
                    }
                }
            },
            onclose(ee) {
                // 关闭流
                console.log("关闭")
            },
            onerror(error) {
                console.info(error);
                closeConnection();
                // throw error;
                return 5000;
                // 如果抛出时间，则间隔多少时间重连
                //返回流报错
            }
        })
    }

    // 解析成功
    const analysisSuccessful = (knowledgeIdsList) => {
        let knowledgeIds = [], fileIds = [];
        knowledgeIdsList.map(item=>{
            if(item.status === "parsingSuccess"){
                knowledgeIds.push(item.otherData.knowledgeId);
                fileIds.push(item.fileId);
            }
        })
        setIsCompleteUpload(true);
        setOkBtnDisabled(false);
        setOkText("完成");


        // 刷新一下数据列表
        // 处理数据，加入购物车，还是创建新话题，还是更新话题数据列表
        if(uploadModalType === 'AICart'){
            // 购物车
            dispatch(refreshAIcarNumF(Date.now().toString()))
        }else if(uploadModalType === 'createCv'){
            // 解析成功的文档 - 开始对话
            dispatch(setAIModalState({
                refreshTopicList: Date.now().toString()
            }));
        }else if(uploadModalType === 'upDateCv'){
            dispatch(setAIModalState({refreshHistoryTopicList:Date.now().toString()}))
        }
    }
    
    // 关闭会话
    const closeConnection = () => {
        console.log(parsingEventSource)
        if(parsingEventSource && parsingEventSource.close){
            parsingEventSource.close();
        }
    }
    

    const state = {
        fileList,
    };

    return (
        <div className="uploadFileModal">
            <div className="uploadFileModalFrame">
                {/* head */}
                <div className="headBox">
                    <div className="headTitle">智能解读</div>
                    {/* 未开始上传 ： 显示关闭按钮可以直接关闭 */}
                    {/* 上传中：不显示关闭按钮 */}
                    {/* 解析中：显示关闭按钮，提示后关闭 */}
                    {uploading ? null : 
                    parsing ? <Popconfirm
                        title={confirmContent}
                        onConfirm={()=>clearUploadFile()}
                        okText="关闭"
                        cancelText="取消"
                    >
                        <span className="closeBtn"><Iconfont type={"icon-jujue"}/></span>
                    </Popconfirm>:<span onClick={()=>{
                       clearUploadFile();
                    }} className="closeBtn"><Iconfont type={"icon-jujue"}/></span>}
                </div>

                <div className="uploadBtn">
                    <div className={`choiceFileBtn ${isChoiceFile ? "" : "disabledChoiceFile"}`}>
                        <span className="btnText">选择文件</span>
                        {/* 文件选择器 */}
                        {
                            isChoiceFile ? (
                                <input
                                    id="fileInput"
                                    type="file"
                                    multiple={true}
                                    accept={fileLimitType.join(",")}
                                    onChange={(e) => selectFilesChangeFunc(e.target.files, state, e)}
                                />
                            ) : undefined
                        }

                    </div>
                    <span className="tipText">支持批量上传文件，压缩包仅支持ZIP格式</span>
                </div>

                <div className="tableContentBox" id={tableId}>
                    <Table
                        rowKey="fileId"
                        columns={columns}
                        bordered={false}
                        pagination={false}
                        dataSource={[...fileList]}
                        scroll={{y: tableHeight}}
                        locale={"点击上方”选择文件”或将文件拖拽到此区域"}
                    />
                    {
                        (fileList?.length < 1 && isChoiceFile) ? (
                            <div className="dragFileBox">
                                <span className="tipText">点击上方”选择文件"或将文件拖拽到此区域</span>
                                <input
                                    onDrop={(e, a) => {
                                        e?.preventDefault();
                                        const files = e.dataTransfer.files;
                                        selectFilesChangeFunc(files, state, e)
                                    }}
                                    id="fileInput2"
                                    type="file"
                                    multiple={true}
                                    accept={fileLimitType.join(",")}
                                    onChange={(e) => selectFilesChangeFunc(e.target.files, state, e)}
                                />
                            </div>
                        ) : undefined
                    }

                </div>
                {/* footBox */}
                <div className="footBox">
                    <span
                        className={`operateBtn cancelBtn ${cancelBtnDisabled ? "disabled" : ""}`}
                        onClick={() => {
                            if (cancelBtnDisabled) {
                                return
                            }
                            if(parsing){
                                confirm({
                                    title: '提示',
                                    icon: <ExclamationCircleOutlined />,
                                    content: confirmContent,
                                    okText: '确认',
                                    cancelText: '取消',
                                    zIndex: 1002,
                                    onOk:() => {
                                        clearUploadFile();
                                    }
                                });
                            }else{
                                clearUploadFile();
                            }
                        }}
                    ><CloseCircleOutlined />关闭</span>
                    
                    <span
                        className={`operateBtn confirmBtn ${okBtnDisabled ? "disabled" : ""}`}
                        onClick={() => {
                            if (okBtnDisabled) {
                                return
                            }
                            if (isChoiceFile) {
                                if (fileList.length < 1) {
                                    return message.warning("请选择文件上传");
                                }else{
                                    
                                    setOkBtnDisabled(true);
                                }
                            } else if (!isCompleteUpload) {
                                return;
                            }
                            confirmFunc(state);
                        }}
                    >{okText || <span><CloudUploadOutlined />开始</span>}</span>
                </div>

            </div>
        </div>
    );
};

UploadFileModal.propTypes = {
    onOk: PropTypes.func,
}

export default UploadFileModal;
