import React, {useState, useEffect, useRef} from 'react';
import {Button, Tag, Space, Typography, Modal, Form, InputNumber, Input, Tooltip, message, Select, Divider, Spin} from 'antd';
import {PlusOutlined, LoadingOutlined, SwapOutlined} from '@ant-design/icons'

import storage from "../../../utils/storage";
import Iconfont from "../../../components/Iconfont/Iconfont";
import NoneData from "../../../components/NoneData/index";
import OverseasCaseTree  from "./OverseasCaseTree/index"
import PolicyList from "../../knowledgeCentre/components/policyList"
import KnowledgeList from '../../../components/KnowledgeList'
// import PolicyListModal from "./policyListModal/index"
// import PolicyIndicator from "./policyIndicator"
// import UploadDrawer from "./uploadDrawer";
import AddProject from "./addProject";
import {getSearchParams, ModalConfirm} from "../../../utils/tool";
import Api from "../../../api/index";
import {pagingParam} from '../../../utils/defaultValue'
import BackIcon from "../../../components/BackIcon/index";

import "./index.scss";

const { Text } = Typography;
const { TextArea } = Input;
let policyThemeIDandName = {}

const OverseasCaseLibrary = () => {

    let expandedKeys = [];

    const [userInfo, setUserInfo] = useState(storage.getItem("userInfo") ? storage.getItem("userInfo") : {})

    const [listDate, setListDate] = useState([]);
    const [pagination, setPagination] = useState("");

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isEdit, setIsEdit] = useState(false);

    // 分类管理
    const [selectedTheme, setSelectedTheme] = useState(""); //当前选中的主题
    const [expandedKeyList, setExpandedKeyList] = useState([]);

    // 分类树
    const [treeDate, setTreeDate] = useState("");

    const [searchData, setSearchData] = useState({
        departmentId: storage.getItem("userInfo").departmentId,
        knowledgeTypeId:"9",
        ...pagingParam,
    });


    const [loadingType, setLoadingType] = useState(false);
    const [loadingTreeType, setLoadingTreeType] = useState(false);

    const [overseasCaseTreeType, setOverseasCaseTreeType] = useState('2');


    useEffect(() => {
        getpolicyTree();

    }, [overseasCaseTreeType])

    useEffect(() => {
        if (searchData.overseasCaseTypeId) {
            getKnowledgeList();
        }
    }, [JSON.stringify(searchData)])

    useEffect(() => {
        if (selectedTheme) {
            let _searchData = {
                ...searchData,
                ...pagingParam,
            }
            _searchData.overseasCaseTypeId = selectedTheme.id;
            setSearchData(_searchData);
            setPagination("");
        }
    }, [JSON.stringify(selectedTheme)])

    // 知识中心获取知识列表
    const getKnowledgeList = async() => {
        console.log(searchData,"searchData")
        setLoadingType(true);
        let _searchData = {...searchData};
        try{
            let res = await Api.Knowledge.getKnowledgePageList({data:_searchData});
            setLoadingType(false);
            if(res && res.code === 0 && res.data && res.data.records && res.data.records.length > 0){
                setListDate(res.data.records);
                setPagination({
                    "pageIndex": res.data.current,
                    "pageSize": res.data.size,
                    "total":res.data.total,
                })
            }else{
                setListDate([]);
            }
        }catch(errInfo){
            setLoadingType(false);
        }
    }

    const getpolicyTree = async () => {
        setLoadingTreeType(true);
        try {
            let res = await Api.Knowledge.selectOct({
                data: {
                    departmentId: storage.getItem("userInfo").departmentId,
                    tagClassId:overseasCaseTreeType
                }
            });
            setLoadingTreeType(false);
            
            if (res && res.code === 0 && res.data) {
                let _data = res.data;
                let newData = [];

                _data.map(item=>{
                    let _datas = item.children.map(childrenItem=>{
                        return {
                            ...childrenItem,
                            parentId: item.tagValue,
                            updateStutas: true,
                        }
                    })
                    newData.push({
                        octName:item.tagValue,
                        id:item.tagValue,
                        tagIdOctIdStr:item.tagValue,
                        children:_datas,
                        type:0,
                        parentId:0,
                        selectable:false,
                    })
                })
                
                setTreeDate(newData);
                
                // getPolicyeThemeName(newData);
                getFirstOverseasCaseF(newData[0]);

                // getFirstOverseasCase(newData, newData);
                console.log(expandedKeys,"expandedKeys")

                if(expandedKeys.length > 0){
                    setExpandedKeyList(expandedKeys)
                }

            }else{setTreeDate([])}
        } catch (errInfo) {
            setTreeDate([]);
            setLoadingTreeType(false);
            console.log('验证失败:', errInfo);
        }
    }

    const getFirstOverseasCaseF = (data) => {
        expandedKeys.push(data.tagIdOctIdStr);
        if(data.children?.length > 0){
            getFirstOverseasCaseF(data.children[0]);
        }
    }

    let defalutObj = {};
    const getFirstOverseasCase = (data) => {
        if(expandedKeys.length > 0){
            return false;
        }
        try {
            data.map((item)=>{
                
                if(expandedKeys.length > 0){
                    throw new Error('Stop Map Loop');
                }else{
                    if(item.parentId == 0){
                        defalutObj[item.tagIdOctIdStr] = [item.tagIdOctIdStr];
                    }else{
                        let ids = defalutObj[item.parentId] ? [...defalutObj[item.parentId]] : [];
                        ids.push(item.tagIdOctIdStr);
                        defalutObj[item.tagIdOctIdStr] = ids;
                        delete defalutObj[item.parentId];
                    }
                    if(item.type == 1){
                        expandedKeys = defalutObj[item.tagIdOctIdStr];
                        defalutObj = {};
                        throw new Error('Stop Map Loop');
                    }else if(item.children){
                        getFirstOverseasCase(item.children);
                    }
                }
            })
        } catch (error) {
            if (error.message !== 'Stop Map Loop') throw error; // 如果抛出的不是预期的异常，则重新抛出
        }
    }

    // 获取所有的分类ID
    // const getFirstPolicyeTheme = (data) => {
    //     data.map(item=>{
    //         if(item.children){

    //             expandedKeys.push(item.id);

    //             getFirstPolicyeTheme(item.children)
    //         }
    //     })
    // }

    const getPolicyeThemeName = (data,name) => {
        data.map(item=>{
            let _name = name ? name+'-'+item.octName : item.octName
            if(item.children&&item.children.length>0){
                getPolicyeThemeName(item.children,_name)
            }else{
                policyThemeIDandName[item.id] = _name
            }
        })
    }

    const handleCancel = (type) => {
        setIsModalOpen(false);
        setIsEdit(false);
        if(type){
            getpolicyTree();
        }
    };
    

    const deleteModal = async (nodeData) => {
        if (!nodeData) {
            message.success("请选择需要删除的政策类型");
            return false;
        }
        ModalConfirm({
            title: '你确定要删除该分类吗？',
            content: <Text type="danger">存在文件的项目不能删除</Text>,
            onOk:async(resolve, reject)=> {
                try {
                    let res = await Api.Knowledge.deleteOverseasCaseType({
                        data: {
                            "id": nodeData.id
                        }
                    });
                    if (res && res.code === 0) {
                        resolve();
                        getpolicyTree();
                        message.success("删除成功");
                    }else{
                        reject();
                    }
                } catch (errInfo) {
                    reject();
                    console.log('删除失败:', errInfo);
                }
            },
        })
    }

    // 选中主题
    const changePolicyTree = (param) => {
        setSelectedTheme(param);
        if (!param) {
            // 取消选中的主题后重置查询数据
            let _searchData = {
                departmentId: storage.getItem("userInfo").departmentId,
                knowledgeTypeId:"9",
                ...pagingParam,
            }
            setSearchData(_searchData)
        }else{
            // sessionStorage.themeTreeName = policyThemeIDandName[param.id]
            // setTreeName(policyThemeIDandName[param.id])
        }

    }


    const paginationChange = (pageIndex, pageSize) => {
        let _searchData = {...searchData}
        _searchData.pageIndex = pageIndex;
        _searchData.pageSize = pageSize;
        setSearchData(_searchData);
    }


    const menuClickFunc = (type, nodeData) => {
        console.log(type, nodeData,"记录操作的节点")
        switch (type) {
            case "edit":
                setIsEdit(nodeData);
                setIsModalOpen(true);
                break;
            case "delete":
                deleteModal(nodeData);
                break;
        }
    }

    const onOverseasCaseTreeChange = (key) => {
        setOverseasCaseTreeType(key);
    }

    const isHaveKeywords = selectedTheme?.keywords?.length > 0;

    return (
        <div className="overseasCaseLibraryFrame">

            <div className="topTitle">
                {userInfo?.departmentName}
                <BackIcon />
            </div>

            <div className="content">
                <div className="leftBox">
                    <div className="leftHeadBox">
                        <span className="headTitleText">分类列表</span>
                        <div className="classificationList" style={{display: userInfo.departmentId ? "" : "none"}}>
                            <Tooltip title={"新建项目"}>
                                <span
                                    className="operateBtn"
                                    onClick={() => setIsModalOpen(true)}
                                ><Iconfont type={"icon-roundadd"} /></span>
                            </Tooltip>
                        </div>
                    </div>

                    <div className="policyTreeBox">
                        <Spin tip="数据加载中" spinning={loadingTreeType}>
                            <OverseasCaseTree
                                treeDate={treeDate}
                                changePolicyTree={changePolicyTree}
                                selectedNode={selectedTheme}
                                menuClickFunc={menuClickFunc}
                                expandedKeyList={expandedKeyList}
                                onOverseasCaseTreeChange={onOverseasCaseTreeChange}
                            />
                        </Spin>
                    </div>
                </div>

                <div className="rightBox">
                    {
                        selectedTheme ?
                        (
                            <div className="rightContentBox">
                                {selectedTheme.updateStutas &&<div className="rightTopBox" style={{paddingBottom: isHaveKeywords ? "" : "1rem"}}>
                                    <div className="topLeftBox">
                                        <span className="titleText">{selectedTheme.octName}</span>
                                        <div className="extractBox">{selectedTheme.digest}</div>
                                        <div className="keyWards">
                                            <span className="keyWardsTitle">监控关键词：</span>
                                            <div className="keyWardsList">
                                                {isHaveKeywords ? selectedTheme.keywords.map(item => (
                                                    <Tag key={item} color="default" title={item}>{item}</Tag>)) : ""}
                                                    {/* <Tag 
                                                        className="site-tag-plus" 
                                                        onClick={() => menuClickFunc("edit",selectedTheme)}
                                                    >
                                                        <PlusOutlined /> 
                                                    </Tag> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>}

                                <div className="rightBottomBox">
                                    <Spin tip="数据加载中" spinning={loadingType}>
                                        <KnowledgeList
                                            type={"contentList"}
                                            listDate={listDate}
                                            pagination={pagination}
                                            paginationChange={paginationChange}
                                            getList={getKnowledgeList}
                                        />
                                    </Spin>
                                </div>
                            </div>
                        ) : <NoneData text={"请选择分类后查询数据"} icon={"icon-zanwushuju"}/>
                    }
                </div>
            </div>

            <AddProject 
                modalOpen={isModalOpen} 
                handleCancel={handleCancel}
                isEdit={isEdit}
            />

        
    </div>)
}

export default OverseasCaseLibrary;
