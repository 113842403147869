import React, { useState, useEffect, useRef } from 'react';
import { Form, Input, Button, Radio, Space, Typography, InputNumber, Modal, message, DatePicker, Switch, Image, Popconfirm } from 'antd';
import { PlusOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import moment from 'moment'

import Api from "../../../../api";
import SelfTable from "../../../../components/SelfTable"
import {pagingParam} from "../../../../utils/defaultValue";
import BackIcon from "../../../../components/BackIcon/index";
import {ModalConfirm} from '../../../../utils/tool'

import './index.scss'

const { confirm } = Modal;
const { RangePicker } = DatePicker;
const { Text, Link } = Typography;
const SensitiveWords = () => {
    
    const [form] = Form.useForm();
    const [addForm] = Form.useForm();

    const [searchValue, setSearchValue] = useState('');

    // 分页
    const [pagination, setPagination] = useState({
        "pageIndex": 0,
        "pageSize": 0,
        "total":0
    });

    // 列表数据
    const [listData, setListData] = useState([]);

    // 当前正在操作的数据KEY
    const [editingKey, setEditingKey] = useState('');

    const [tableLoading, setTableLoading] = useState(false);

    // 列表选择数据
    const [selectedRowKey, setSelectedRowKey] = useState([]);

    // 查询数据
    const [searchData, setSearchData] = useState({
        ...pagingParam,
    });

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);

    const columns = [
        {
            title: '敏感词',
            dataIndex: 'word',
            width: '',
        },
        {
            title: '新增时间',
            dataIndex: 'createTime',
            width: '180px',
        },
        {
            title: '同步状态',
            dataIndex: 'syncStatus',
            width: '120px',
            render: (text) => {
                // 0:同步成功;1:同步失败 2未同步 3待处理
                if(text == 0){
                    return <Text type="success">
                        成功
                    </Text>
                }else if(text == 3){
                    return <Link>
                        同步中
                    </Link>
                }else if(text == 1){
                    return <Text type="danger">
                        同步失败
                    </Text>
                }else if(text == 2){
                    return <Text type="secondary">
                        未同步
                    </Text>
                }
            }
        },
        {
            title: '操作',
            width: '130px',
            dataIndex: 'operation',
            render: (_, record) => {
                return <Space>
                    {record.syncStatus == 1 || record.syncStatus == 2&&
                    <Link onClick={()=>syncToAI(record.id)}>同步
                    </Link>}

                    <Link onClick={() => edit(record)}>
                        调整
                    </Link>
                    
                    <Link onClick={()=>deleteProjects(record.id)}>
                        删除
                    </Link>
                </Space>
                
            },
        },
    ];

    useEffect(() => {
        getList();
    }, [searchData]);

    useEffect(() => {
        if(!searchValue){
            onSearch()
        }
    }, [searchValue]);

    const getList = async() => {
        setTableLoading(true);
        setListData([]);
        try {
            const res = await Api.AIModel.getPageSensitiveList({data:searchData});
            setTableLoading(false);
            if(res&&res.code === 0){
                let _data = res.data;
                setListData(_data.records);
                setEditingKey('');
                setPagination({
                    "pageIndex": _data.current,
                    "pageSize": _data.size,
                    "total":_data.total
                });
            }else{
                setListData([]);
            }
        } catch (errInfo) {
            setListData([]);
            console.log('验证失败:', errInfo);
        }
    }

    const syncToAI = async(id) => {

        let _knowledgeIds = [], text = '确定同步所有数据至大模型吗？';
        if(id){
            _knowledgeIds = [id];
            text = '确定同步这条数据至大模型吗？'
        }else if(selectedRowKey.length>0){
            _knowledgeIds = [...selectedRowKey];
            text = `确定同步选中的${selectedRowKey.length}条数据至大模型吗？`
        }

        confirm({
            title: text,
            icon: <ExclamationCircleOutlined />,
            content: '',
            onOk: ()=>{
                return new Promise(async (resolve, reject) => {
                    try{
                        const res = await Api.AIModel.syncSenWord({data:_knowledgeIds});
                        resolve();
                        if(res&&res.code === 0){
                            getList();
                            setSelectedRowKey([]);
                            message.success("操作成功，请耐心等待！")
                        }
                    }catch(erro){
                        reject();
                    }
                }).catch(() => {console.log('Oops errors!');});
            },
            onCancel() {},
        });
        
    }

    // 修改
    const edit = (record) => {
        addForm.setFieldsValue({
            ...record,
        });
        setEditingKey(record.id);
        setIsModalOpen(true);
    };

    // 搜索框
    const changeValue = (e) => {
        setSearchValue(e.target.value)
    }

    const onSearch = () => {
        let newSearchData = {...searchData}
        newSearchData.pageIndex = 1;
        newSearchData.word = searchValue;
        setSearchData(newSearchData);
    }

    const onResetting = () => {
        setSearchValue("");
        setSearchData({
            ...pagingParam,
            word:"",
        })
    }

    
    // 新增
    const handleOk = async() => {
        setConfirmLoading(true);
        if(editingKey){
            update()
        }else{
            add()
        }
    }

    // 修改-保存
    const update = async (fileId) => {
        try {
            const row = await addForm.validateFields();
            row.id = editingKey;
            row.fileId = fileId;
            const res = await Api.AIModel.updateSenWord({data:row});
            setConfirmLoading(false);
            if(res.code === 0){
                message.success("修改信息成功",2,()=>{
                    handleCancel();
                    getList();
                })
            }
        } catch (errInfo) {
            setConfirmLoading(false);
            console.log('验证失败:', errInfo);
        }
    };

    const add = async (fileId) => {
        try {
            const row = await addForm.validateFields();
            row.fileId = fileId;
            const res = await Api.AIModel.addSensitiveWord({data:row});
            setConfirmLoading(false);
            if(res&&res.code === 0){
                message.success("新增信息成功",2,()=>{
                    handleCancel();
                    getList();
                })
            }
        } catch (errInfo) {
            setConfirmLoading(false);
            console.log('验证失败:', errInfo);
        }
    }

    const deleteProjects = async (id) => {

        let _knowledgeIds = [], text = '';
        if(id){
            _knowledgeIds = [id];
            text = '确定要删除这条数据吗？'
        }else if(selectedRowKey.length>0){
            _knowledgeIds = [...selectedRowKey];
            text = `确定要删除这${selectedRowKey.length}条数据吗？`
        }else{
            message.info("请选择要删除的数据");
            return false;
        }

        confirm({
            title: text,
            icon: <ExclamationCircleOutlined />,
            content: '',
            onOk: ()=>{
                return new Promise(async (resolve, reject) => {
                    try{
                        const res = await Api.AIModel.deleteSenWord({data:_knowledgeIds});
                        resolve();
                        if(res&&res.code === 0){
                            getList();
                            setSelectedRowKey([]);
                            message.success("删除成功");
                        }
                    }catch(erro){
                        reject();
                    }
                }).catch(() => {console.log('Oops errors!');});
            },
            onCancel() {},
        });

    }


    // 打开
    const addProject = () => {
        addForm.setFieldsValue({
            word:""
        });
        setIsModalOpen(true);
    }

    const handleCancel = () => {
        setEditingKey('');
        setConfirmLoading(false);
        setIsModalOpen(false);
    }

    const onChanges = (pagination, filters, sorter) => {
        
        let newSearchData = {...searchData};
        
        newSearchData.pageIndex = pagination.current;
        newSearchData.pageSize = pagination.pageSize;
        setSearchData(newSearchData);
    };


    return (
        <div className="sensitiveWordsFrame">
            <div className="title">敏感词</div>
            <div className="operation">
                <Space size={"large"}>
                    <Button type="primary" onClick={addProject}>新增敏感词</Button>
                    <Button type="primary" disabled={selectedRowKey.length === 0} danger onClick={()=>deleteProjects()}>删除敏感词</Button>
                    <Link onClick={()=>syncToAI()}>{selectedRowKey.length === 0 ?'同步全部敏感词':'同步敏感词'}</Link>
                </Space>
                
                <div className="operationRight">

                    <Space size={"large"}>
                        <div className="operationItem">
                            <Input
                                placeholder="输入敏感词搜索"
                                onChange={changeValue}
                                onPressEnter={onSearch}
                                style={{width:350,float:"right"}}
                                value={searchValue}
                                allowClear={true}
                            />
                        </div>
                        <Button type="primary" onClick={onSearch}>搜索</Button>
                        <Button onClick={onResetting}>重置</Button>
                    </Space>
                </div>
            </div>
            {/* <BackIcon /> */}
            <div className="table">
                <Form form={form} component={false}>
                    <SelfTable
                        rowRadioSelection={{
                            selectedRowKey:selectedRowKey,
                            setSelectedRowKey:setSelectedRowKey,
                            type:"checkbox",
                            preserveSelectedRowKeys:true,
                            // getCheckboxProps: (record) => ({
                            //     disabled: (record.syncStatus != 1 && record.syncStatus != 2),
                            //     name: record.word,
                            // }),
                        }}
                        pagination={pagination}
                        dataSource={listData}
                        columns={columns}
                        loading={tableLoading}
                        onChange={onChanges} 
                    />
                </Form>
            </div>

            <Modal 
                title="新增热词" 
                open={isModalOpen} 
                onOk={handleOk} 
                onCancel={handleCancel}
                maskClosable={false}
                destroyOnClose={true}
                confirmLoading={confirmLoading}
                // wrapClassName={"selfDesignModal"}
            >
                <Form
                    form={addForm}
                    component={false}
                    labelCol={{ span: 4 }}
                    wrapperCol={{ span: 18 }}
                    preserve={false}
                >
                    <Form.Item
                        key={"word"}
                        label={"敏感词"}
                        name={"word"}
                        rules={[{ required: true, message: `请输入敏感词!` }]}
                    >
                        <Input />
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    )
}

export default SensitiveWords