import myAxios from '../utils/request.js';

export default {
    // 获取已发布项目列表(待修改)
    async getPublishProjectList (params={}) {
        let res = await myAxios.postJson('/business/tree/published', params);
        return res.data;
    },

    // 获取未发布项目列表
    async getUnPublishProjectList (params={}) {
        let res = await myAxios.postJson('/business/tree/unpublished', params);
        return res.data;
    },

    // 获取可选项目列表
    async getEnableProjectList (params={}) {
        let res = await myAxios.getJson('/business/project/list', params);
        return res.data;
    },

    // 获取单个项目详细数据(编辑时)
    async getProjectData (params={}) {
        let res = await myAxios.getJson('/business/tree/edit', params);
        return res.data;
    },

    // 获取单个项目详细数据（预览时）
    async getProjectViewData (params={}) {
        let res = await myAxios.getJson('/business/tree/read', params);
        return res.data;
    },

    // 添加项目
    async addProject (params={}) {
        let res = await myAxios.postJson('/business/tree/create', params);
        return res.data;
    },

    // 编辑项目
    async editProject (params={}) {
        let res = await myAxios.postJson('/business/tree/update', params);
        return res.data;
    },
    async updateOct (params={}) {
        let res = await myAxios.postJson('/business/tree/updateOct', params);
        return res.data;
    },

    // 删除项目
    async deleteProject (params={}) {
        let res = await myAxios.getJson('/business/tree/del', params);
        return res.data;
    },

    // 保存项目
    async saveProject (params={}) {
        let res = await myAxios.postJson('/addBallClub.json', params);
        return res.data;
    },

    // 发布项目
    async publishProject (params={}) {
        let res = await myAxios.getJson('/business/tree/publish', params);
        return res.data;
    },

    // 添加描述到某个节点
    async addFileDesc (params={}) {
        let res = await myAxios.getJson('/file/desc/create', params);
        return res.data;
    },

    // 添加文件到某个节点
    async addFileToNode (params={}) {
        let res = await myAxios.postJson('/file/upload', params);
        return res.data;
    },

    // 添加多个文件到某个节点
    async addMultFileToNode (params={}) {
        let res = await myAxios.postJson('/file/multUpload', params);
        return res.data;
    },

    // 私有/公开文件的某个节点
    async updateNodeAttribute (params={}) {
        let res = await myAxios.getJson('/business/tree/lock', params);
        return res.data;
    },

    // 删除某个节点的文件
    async deleteFile (params={}) {
        let res = await myAxios.postJson('/file/file/del', params);
        return res.data;
    },
};
