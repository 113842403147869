import React from "react";
import Api from "../../api/index";
import "./login.scss";
import { Form, Input, message, Modal, Spin } from "antd";
import { ExclamationCircleOutlined} from '@ant-design/icons';
import $ from 'jquery'
import Fingerprint2 from 'fingerprintjs2'

import SliderVerify from "../../components/SliderVerify";
import Image from "../../components/Image/Image";
import Iconfont from "../../components/Iconfont/Iconfont";
import LogoLog from "../../assets/img/login-log.png";
import FileTitle from "../../assets/img/file-title.png";
import storage from "../../utils/storage";
import {getSearchParams, getLoginUrl} from '../../utils/tool'


const { Item: FormItem } = Form;

class Login extends React.Component {
  // 表单ref
  formRef = React.createRef();

  verifyRef = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      jobNum: "",
      password: "",
      verifyModalVisible: false,
      captchaData: {},
      fingerprint:localStorage.fingerprint,
      showHtml:false,
      isMobile:false,
    };
  }

  componentWillMount(){

    console.log("20250124.v3.0 版本更新成功");

    if(getSearchParams().isLogin == 0){
      // 门户未登录的情况-停留在子系统登录页
      this.setState({
        showHtml:true
      })
       // 是否有指纹，有指纹就检查是的否登录，没有指纹就获取指纹
      // if(localStorage.fingerprint){
      //   this.checkLoginStatus(localStorage.fingerprint)
      // }else{
      //   this.getFingerprint();
      // }
    }else if(getSearchParams().isLogin == 1){

      this.checkLoginStatus(window.atob(getSearchParams().satoken))
    }else{
      if(getLoginUrl()){
        let url = `${getLoginUrl()}?redirectUrl=${window.location.href}`;
        window.location.href = url
      }else{
        window.location.href = `${window.location.origin}/login?isLogin=0`
      }
    }

    // if(localStorage.loginType != "logOut"){
      // if(localStorage.fingerprint){
      //   this.checkLoginStatus(localStorage.fingerprint)

      // }else{
      //   this.getFingerprint();
      // }
    // }else{
    //   this.setState({
    //     showHtml:true
    //   },()=>{
    //     localStorage.removeItem("loginType")
    //   })
    // }

    if (this.isMobile()) {
      console.log("这是在手机上打开");
      this.setState({
        isMobile:true
      })
    } else {
        console.log("这不是在手机上打开");
    }
  }

  isMobile() {
    var userAgentInfo = navigator.userAgent;
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(userAgentInfo);
  }


  async getFingerprint(){
    // 获取电脑IP地址
    let that = this;
    try{
      // let res = await Api.Login.getIP({ data: {}});

      // const { data, code } = res || {};
      // if(code === 0){
      //   let ip = data;
      console.log("开始获取指纹")
        // 获取指纹
        let fingerprint = Fingerprint2.get(function(components) {
          const values = components.map(function(component,index) {
            if(component.key != "plugins"){
              return component.value
            }
          })
          // 生成最终id murmur
          const murmur = Fingerprint2.x64hash128(values.join(''), 31);

          // 最终的浏览器指纹
          let _fingerprint = window.btoa(murmur+window.navigator.userAgent);

          localStorage.fingerprint = _fingerprint

          that.checkLoginStatus(_fingerprint)
          that.setState({
            fingerprint:_fingerprint
          },()=>{
            console.log(that.state.fingerprint)
          })
        });
      // }else{
      //   this.setState({
      //     showHtml:true
      //   })
      // }
    }catch{
      this.setState({
        showHtml:true
      })
    }
  }

  async checkLoginStatus(token) {
    // 传入fingerprint判断是否登录
    try {
      let res = await Api.Login.getLoginInfo({ data: {
        satoken:token
      },noVerify: true });

      this.setLoginInfo(res)
    } catch (e) {
      this.setState({
        showHtml:true
      })
    }
  }

  // 登录
  async loginFunc(sliderLeft,nonceStr) {
    const that = this;

    try {
      const { jobNum, password } = that.state;
      let val = {
        jobNum,
        password,
        nonceStr,
        value:sliderLeft,
      };
      let res = await Api.Login.loginFunc({ data: val, loading: true, noVerify: true });

      this.setLoginInfo(res)

    } catch (e) {
      console.log("error:", e);
    } finally {
      // that.closeVerify();
    }
  }

  async setLoginInfo(res) {
    let { history } = this.props;

    const { data, code, msg } = res || {};
    console.log(code, msg)
    if(code === 402){
      Modal.error({
        content: msg,
        centered: true,
        footer: null,
        keyboard: false,
        maskClosable: false,
        okText: "确定",
      });
    }

    if (code === 0) {
      const { userName, phone, roleId, email, id, departmentName, departmentId ,fileUrl, jobNum} = data || {};
      const userInfo = {
        userName,
        phone,
        roleId,
        email,
        id,
        departmentName,
        departmentId,
        fileUrl,
        jobNum,
      };
      storage.setItem("token", data.saTokenInfo.tokenValue);
      localStorage.fingerprint = data.saTokenInfo.tokenValue;
      storage.setItem("WebSocketSubProtocol", data.fileToken);
      storage.setItem("userInfo", userInfo);
      const routeRes = await Api.Login.getlistMenuTree({
        data: { satoken: data.saTokenInfo.tokenValue },
      });
      storage.setItem("routeRes", routeRes);
      // let redirect = storage.getItem('redirect');
      // storage.removeItem('redirect');
      if(sessionStorage.prePathname){
        history.replace(sessionStorage.prePathname);
        sessionStorage.removeItem("prePathname")
      }else{
        history.replace("/page/homePage");
      }
      // history.replace(redirect ? redirect : (data[0] || ''));
    }else{
      const myVerify = this.verifyRef.current;
        myVerify?.sliderVerifyState(res.msg);

      this.setState({
        showHtml:true
      })
    }

  }

  // 登录验证
  async loginVerify() {
    try {
      const myForm = this.formRef.current;
      const val = await myForm.validateFields();

      this.setState(
        {
          verifyModalVisible: true,
          jobNum: val.jobNum,
          password: window.btoa(val.password),
        },
        () => {
          const myVerify = this.verifyRef.current;
          myVerify?.getBigImage();
        }
      );
    } catch (errInfo) {
      console.log("验证失败:", errInfo);
    }

    // const res = await Api.Login.addUser({})
    // if (res.success) {
    //     this.setState({
    //         captchaData: res?.result || {},
    //         verifyModalVisible: true,
    //     })
    // } else {
    //     message.error('验证码获取出错！')
    // }
  }

  // 关闭验证弹窗
  closeVerify() {
    this.setState(
      {
        verifyModalVisible: false,
        captchaData: {},
      },
      () => {
        const myVerify = this.verifyRef.current;
        myVerify?.reloadImage();
      }
    );
  }

  render() {
    const that = this;
    const { verifyModalVisible, showHtml, isMobile  } = that.state;
    return (<>
      {showHtml?(<div className="loginFrame" style={isMobile?{}:{minWidth: '1200px'}}>
        <div className="logoBox">
          <Image imgSrc={LogoLog} iClassName="LogoLog" />
        </div>
        <div className="loginBox" style={isMobile?{background:'transparent'}:{}}>
          <Image imgSrc={FileTitle} iClassName="loginTitle" />
          {/*<span className="loginTitle">文件管理系统</span>*/}
          <Form
            ref={that.formRef}
            layout="horizontal"
            name="myForm"
            size="middle"
            style={{ width: "100%" }}
          >

            <div className="userName">
              <span className="textPrompt">工号</span>
              <FormItem
                name="jobNum"
                rules={[
                  { required: true, message: "请输入工号" },
                  () => ({
                    validator(_, value) {
                      if (/^[\da-z]+$/i.test(value)) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error("工号只能包含字母、数字")
                      );
                    },
                  }),
                ]}
              >
                <Input placeholder="输入工号" style={isMobile?{background:'#fff'}:{}}/>
              </FormItem>
              <Iconfont type={"icon-user"} />
            </div>
            <div className="userPwd">
              <span className="textPrompt">密码</span>
              <FormItem
                name="password"
                rules={[{ required: true, message: "请输入密码" }]}
              >
                <Input.Password
                  className={isMobile?"mobileInput":""}
                  placeholder="输入密码"
                  onPressEnter={() => {
                    that.loginVerify();
                  }}
                  onChange={() => {
                    // 没有输入工号的时候再验证
                    if (!that.formRef.current.getFieldValue("jobNum")) {
                      that.loginVerify();
                    }
                  }}
                />
              </FormItem>
            </div>
            <FormItem>
              <span
                className="loginBtn"
                onClick={() => {
                  that.loginVerify();
                }}
              >
                登 录
              </span>
            </FormItem>
          </Form>
          {/* <div className="tips"><ExclamationCircleOutlined />此系统支持的浏览器：谷歌、火狐、Edge</div> */}
          {!isMobile&&<div className="tips"><ExclamationCircleOutlined />此系统支持的浏览器：谷歌、火狐、Edge</div>}
        </div>
        <div
          className={isMobile?"sliderVerifyMobileBox":"sliderVerifyBox"}
          style={{ display: verifyModalVisible ? "block" : "none" }}
        >
          <SliderVerify
            ref={that.verifyRef}
            closeFunc={() => this.closeVerify()}
            successFunc={(sliderLeft,nonceStr) => this.loginFunc(sliderLeft,nonceStr)}
          />
        </div>
        {/* 版权信息 */}
        <div className="bottomContent">© 2024 知识库 中冶赛迪重庆环境咨询有限公司版权所有</div>

      </div>):(<div className="loginSpinBox"><Spin/><div className="spinText">加载中...</div></div>)}
    </>);
  }
}
Login.propTypes = {};

export default Login;
