import React, {useState, useEffect} from "react";
import {Button, Upload, message} from "antd";
import SplitUpload from "../../SplitUpload";
import {zuStandStore} from "../../../zustand";

const allowedFileTypes = [
    "doc",
    "docx",
    "docm",
    "pdf",
    "ppt",
    "pptx",
    "pptm",
    "xls",
    "xlsx",
    "xlsm",
    "application/pdf",
    "application/msword",
    "application/vnd.ms-powerpoint",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "application/vnd.openxmlformats-officedocument.presentationml.presentation",
]; // 允许上传的文件类型
const UploadBtn = (props) => {
    const {
        setOpen,
        setUploadFileId,
        setPolicyFromType,
        setUploadFileName,
        setUploadFileExtension,
        callBack,
        isSingle,
    } = props;

    const [fileList, setFileList] = useState([]);
    const [uploadingFileId, setUploadingFileId] = useState(null);
    const [isMessageShown, setMessageShown] = useState(false);
    const uploadFileState = zuStandStore(state => state);
    const changeUploadStateFunc = uploadFileState.changeUploadStateFunc;

    useEffect(() => {
        const {fileOtherData} = uploadFileState;
        const fileData = fileOtherData[uploadingFileId];
        if (uploadingFileId && fileData) {
            setUploadingFileId(null);
            setMessageShown(false);
            setUploadFileId(fileData.fId || fileData.fileId);
            setPolicyFromType("upload");
            setOpen(true);
            // 关闭上传弹窗
            changeUploadStateFunc({
                uploadFileList: [],
                fileProcessObject: {},
                fileUploadStatus: {},
                fileOtherData: {},
                uploadOpen: false,
                modalStatus: "small",
                isAnswering: false,
                linkStatus: null,
            });
        }
    }, [uploadFileState.fileOtherData]);

    const handleChange = async (file, fileList) => {
        // console.log(file, "文件");
        // console.log(fileList, "文件列表");
        let fileName = file.name.split(".");
        const fileExtension = fileName[1].toLowerCase();
        const fileType = file.type;
        setUploadFileName(fileName[0]);
        setUploadFileExtension(fileExtension);
        if (
            !allowedFileTypes.includes(fileExtension) &&
            !allowedFileTypes.includes(fileType)
        ) {
            message.error(`${file.name} 格式不符合上传类型`);
        }
        if (fileList.length > 1) {
            if (!isMessageShown) {
                // 只有在没有显示过提示时才会进行提示，否则忽略该提示
                message.info("单次只能上传一个文件，请选择一个文件进行上传");
                setMessageShown(true);
            }
            fileList = [];
        } else if (fileList.length === 1) {
            // let res = await SplitUpload(fileList, "policy");
            // if (res[0]?.fileId) {
            //     setMessageShown(false);
            //     setUploadFileId(res[0].fileId);
            //     setPolicyFromType("upload");
            //     setOpen(true);
            // }


            const {fileProcessObject, fileUploadStatus, uploadFileList} = uploadFileState;
            let processObject = {};
            let fileStatus = {};
            let list = [...uploadFileList];
            let newFileList = [];

            for (const item of fileList) {
                let encodeName = window.encodeURI(item.name);
                let idStr = `${item.lastModified}-${encodeName}-${item.size}-${item.type}-${Date.now()}`;
                const fileId = window.btoa(idStr);
                processObject[fileId] = 0;
                fileStatus[fileId] = "processing";
                item.fileId = fileId;
                item.uploadType = "common";
                newFileList.push(item);
            }
            list.push({
                uploadType: "common",
                fileList: newFileList,
            })
            const newState = {
                uploadFileList: list,
                fileProcessObject: {
                    ...fileProcessObject,
                    ...processObject,
                },
                fileUploadStatus: {
                    ...fileUploadStatus,
                    ...fileStatus,
                },
                uploadOpen: true,
                modalStatus: "big",
            };
            changeUploadStateFunc(newState);
            setUploadingFileId(fileList[0].fileId);
        }
        setFileList(fileList);
        return false;
    };

    const upLoadProps = {
        beforeUpload: handleChange,
        multiple: !isSingle,
        showUploadList: false,
    };

    const isDisabled = Boolean(uploadingFileId);

    return (
        <div className="uploadBtnFrame">
            <Upload
                disabled={isDisabled}
                {...upLoadProps}
                fileList={fileList}
                accept=".xls,.xlsx,.xlsm,.doc,.docx,.docm,.pdf,.ppt,.pptx,.pptm,application/pdf,application/msword,application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.openxmlformats-officedocument.presentationml.presentation"
            >
                <Button type="primary" style={isDisabled ? {opacity: "0.6", cursor: "not-allowed"} : {}}>上传政策文件</Button>
            </Upload>
        </div>
    );
};

export default UploadBtn;
