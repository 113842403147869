import React from 'react';
import {message, Switch, Tooltip, Modal} from 'antd';
import Iconfont from "../../../../../components/Iconfont/Iconfont";
import MindMap from "../../../../../components/MindMap";
import {getFileType} from '../../../../../utils/tool'
import request from "../../../../../utils/request";
import Api from '../../../../../api/index';
import localStorage from "../../../../../utils/storage";
import {responseDataFilter} from "../../../../../utils/tool";
import './index.scss';
class ViewModel extends React.Component {
    constructor(props) {
        super(props);
        const that = this;
        const {fileType, enableViewFileType} = getFileType()
        that.state = {
            lineColorList: ['#169bd5', '#939516', '#9816d5', '#4b7900'],
            bgColorList: ['#dbeff6', '#f3f4e1', '#fff1ff', '#e6f7eb'],
            expandIds: {},
            modelData:{
                "name": "模板A", // 模板名称
                "desc": "模板AAAAAAA", // 模板描述
                "sortName": "aaca", // 分类名称
                "id": "tree", // 模板id
                "sortId": "0", // 分类id
                "dataList": [
                    {
                        "name": "开拓阶段", // 阶段名称
                        "id": "1671780746823", // 阶段id  时间戳
                        "children": [ // 子阶段数据
                            {
                                "name": "客户招标询价资料", // 子阶段名称
                                "id": "1671781056664", // 子阶段id  时间戳
                                "children": [
                                    {
                                        "name": "招标书/询价函", // 子阶段名称
                                        "id": "1671781724594", // 子阶段id  时间戳
                                        "children": [],
                                        "isRequired": true, // 是否必填
                                        "isPrivate": true,  // 是否保密
                                        "data": [
                                            {
                                                "fileName": '标书招标书招标书招标书招标书', // 文件名称
                                                "type": 'word', // 文件类型
                                                "url": 'aada', // 文件路径
                                                "userName": '刘德福', // 文件上传人名称
                                                "date": '2022-11-22', // 文件上传时间
                                                "id": 'file-0', // 文件Id
                                            }
                                        ], // 文件列表
                                    },
                                    {
                                        "name": "模板说明书",
                                        "id": "1671781775872",
                                        "children": [],
                                        "isRequired": true,
                                        "data": []
                                    },
                                    {
                                        "name": "参考资料",
                                        "id": "1671781776932",
                                        "children": [],
                                        "data": []
                                    }
                                ]
                            },
                            {
                                "name": "技术报价",
                                "id": "1671781057192",
                                "children": [
                                    {
                                        "name": "技术报价书",
                                        "id": "1671781807788",
                                        "children": [],
                                        "isRequired": true,
                                        "data": []
                                    },
                                    {
                                        "name": "参考资料",
                                        "id": "1671781826968",
                                        "children": [],
                                        "data": []
                                    }
                                ]
                            },
                            {
                                "name": "商务报价",
                                "id": "1671781059981",
                                "children": [
                                    {
                                        "name": "成本测算表",
                                        "id": "1671781840732",
                                        "children": [],
                                        "isRequired": true,
                                        "data": []
                                    },
                                    {
                                        "name": "商务报价书",
                                        "id": "1671781860598",
                                        "children": [],
                                        "isRequired": true,
                                        "data": []
                                    }
                                ]
                            },
                            {
                                "name": "合同文件",
                                "id": "1671781152645",
                                "children": [
                                    {
                                        "name": "签入合同正文及附件",
                                        "id": "1671781888248",
                                        "children": [],
                                        "isRequired": true,
                                        "data": []
                                    },
                                    {
                                        "name": "合同交底文件",
                                        "id": "1671781920958",
                                        "children": [],
                                        "data": []
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "name": "策划启动",
                        "id": "1671780747578",
                        "children": [
                            {
                                "name": "开工报告",
                                "id": "1671781940013",
                                "children": [
                                    {
                                        "name": "开工报告",
                                        "id": "1671781967901",
                                        "children": [],
                                        "isRequired": true,
                                        "data": []
                                    },
                                    {
                                        "name": "附件",
                                        "id": "1671781982874",
                                        "children": [],
                                        "data": []
                                    }
                                ]
                            },
                            {
                                "name": "分包采购",
                                "id": "1671781947536",
                                "children": [
                                    {
                                        "name": "分包询价书",
                                        "id": "1671782019232",
                                        "children": [],
                                        "data": []
                                    },
                                    {
                                        "name": "分包合同及附件",
                                        "id": "1671782037063",
                                        "children": [],
                                        "data": []
                                    }
                                ]
                            },
                            {
                                "name": "开球会文件",
                                "id": "1671781948035",
                                "children": [
                                    {
                                        "name": "开球会汇报PPT",
                                        "id": "1671782069355",
                                        "children": [],
                                        "isRequired": true,
                                        "data": []
                                    },
                                    {
                                        "name": "会议纪要",
                                        "id": "1671782083939",
                                        "children": [],
                                        "isRequired": true,
                                        "data": []
                                    }
                                ]
                            },
                            {
                                "name": "模板协调程序文件",
                                "id": "1671781948419",
                                "children": [
                                    {
                                        "name": "约定双方沟通、联络、交付方式等信息的文件",
                                        "id": "1671782109650",
                                        "children": [],
                                        "isRequired": true,
                                        "data": []
                                    },
                                    {
                                        "name": " 文档模版",
                                        "id": "1671782139590",
                                        "children": [],
                                        "isRequired": true,
                                        "data": []
                                    }
                                ]
                            },
                            {
                                "name": "外部资料",
                                "id": "1671781948799",
                                "children": [
                                    {
                                        "name": " 文档模版",
                                        "id": "1671782158067",
                                        "children": [],
                                        "isRequired": true,
                                        "data": []
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "name": "执行过程",
                        "id": "1671780748031",
                        "children": [
                            {
                                "name": "管理过程文件",
                                "id": "1671782169850",
                                "children": [
                                    {
                                        "name": "模板进度计划",
                                        "id": "1671782247142",
                                        "children": [],
                                        "isRequired": true,
                                        "data": [
                                            {
                                                "fileName": '标书招标书招标书招标书招标书', // 文件名称
                                                "type": 'word', // 文件类型
                                                "url": 'aada', // 文件路径
                                                "userName": '刘德福', // 文件上传人名称
                                                "date": '2022-11-22', // 文件上传时间
                                                "id": 'file-3', // 文件Id
                                            }
                                        ]
                                    },
                                    {
                                        "name": " 统一技术规定",
                                        "id": "1671782248311",
                                        "children": [],
                                        "data": []
                                    },
                                    {
                                        "name": " 重要会议纪要",
                                        "id": "1671782249244",
                                        "children": [],
                                        "isRequired": true,
                                        "data": []
                                    }
                                ]
                            },
                            {
                                "name": "模板交付物",
                                "id": "1671782178371",
                                "children": [
                                    {
                                        "name": " 交付物清单",
                                        "id": "1671782286137",
                                        "children": [],
                                        "isRequired": true,
                                        "data": []
                                    },
                                    {
                                        "name": "咨询主报告文本",
                                        "id": "1671782288241",
                                        "children": [],
                                        "isRequired": true,
                                        "data": []
                                    }
                                ]
                            },
                            {
                                "name": "汇报评审文件",
                                "id": "1671782178885",
                                "children": [
                                    {
                                        "name": "对业主的汇报材料（含PPT）",
                                        "id": "1671782312531",
                                        "children": [],
                                        "isRequired": true,
                                        "data": []
                                    },
                                    {
                                        "name": "咨询报告的评审意见",
                                        "id": "1671782313335",
                                        "children": [],
                                        "isRequired": true,
                                        "data": []
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "name": "收尾阶段",
                        "id": "1671780748519",
                        "children": [
                            {
                                "name": "总结文件",
                                "id": "1671781020254",
                                "children": [
                                    {
                                        "name": "总结文本或PPT",
                                        "id": "1671782376239",
                                        "children": [],
                                        "isRequired": true,
                                        "data": []
                                    },
                                    {
                                        "name": "实际消耗人工时表",
                                        "id": "1671782376780",
                                        "children": [],
                                        "isRequired": true,
                                        "data": []
                                    },
                                    {
                                        "name": "相关附件",
                                        "id": "1671782377545",
                                        "children": [],
                                        "data": []
                                    }
                                ]
                            },
                            {
                                "name": "其他参考资料",
                                "id": "1671781022778",
                                "children": [
                                    {
                                        "name": " 其他",
                                        "id": "1671782367477",
                                        "children": [],
                                        "data": []
                                    }
                                ]
                            }
                        ]
                    }
                ]
            },
            id: '',
            targetId: '',
            fileTypeIcon: fileType,
            selectedFileIds: {},
            isProject: false,
            enableViewFileType,
            activeNav: '全部',
            labelList: [],
        };
    }

    async componentWillMount () {
        const {history, location} = this.props
        const search = location.search
        if (search) {
            const searchList = search.slice(1).split('&')
            let searchParams = {}
            searchList.forEach(item => {
                const keyValue = item.split('=')
                searchParams[keyValue[0]] = keyValue[1]
            })
            const res = await Api.Documentation.getProjectViewData({data: {id: searchParams.id}, loading: true})
            const data = res.data || {}
            this.setState({
                modelData: data,
                id: searchParams.id,
                targetId: searchParams.targetId,
                isProject: true,
                labelList: (data.tags || []).map(item => ({className: item})),
            }, () => {
                document.title = this.state.modelData.projectName
                this.expandAllTree(true)
                if (this.state.targetId) {
                    const timer = setTimeout(() => {
                        clearTimeout(timer)
                        this.toPosition(this.state.targetId)
                    }, 300)
                }
            })
        } else {
            const modelData = localStorage.getItem('modelData') || {}
            const labelList = localStorage.getItem('labelList') || {}
            this.setState({
                modelData: modelData || {},
                labelList: labelList || []
            }, () => {
                this.expandAllTree(true)
            })
        }
    }

    componentDidMount () {}

    // 选中需要删除的文件
    selectedFileId (parentId, fileId, isAll) {
        const {selectedFileIds} = this.state
        let child = [...(selectedFileIds[parentId] || [])];
        if (isAll) {
            child = fileId
        } else {
            if (child.includes(fileId)) {
                child = child.filter(item => item !== fileId)
            } else {
                child.push(fileId)
            }
        }
        this.setState({
            selectedFileIds: {
                ...selectedFileIds,
                [parentId]: child
            }
        })
    }

    // 生成文件节点树
    fileDataDom (mapData, positionList=[]) {
        const {fileTypeIcon, selectedFileIds, isProject, targetId, enableViewFileType} = this.state
        const {data: fileData=[], desc, isPrivate} = mapData
        const selectedIds = selectedFileIds[mapData.id] || []
        let isAll = false
        let fileDataIds = []
        let dom = (
            <span className="noneFileText">
               {desc || '( 空 )'}
            </span>
        )
        if (isProject && fileData && fileData.length > 0) {
            isAll = selectedIds.length >= fileData.length

            dom = fileData.map((item, idx) => {
                fileDataIds.push(item.id)
                const icon = fileTypeIcon[String(item.fileType).toLocaleLowerCase()] || fileTypeIcon.default
                const fileId = 'file-'+item.id
                return (
                    <div className={"fileItem" + (targetId === String(fileId) ? ' activeNode' : '')} key={idx} id={fileId}>
                        <span className={"checkbox" + (selectedIds.includes(item.id) ? ' checked' : '')}
                              onClick={(val) => this.selectedFileId(mapData.id, item.id) } />
                        <div className="leftBox fileNode">
                            <Iconfont type={icon}/>
                            <Tooltip placement={'top'} title={item.title}>
                                <span className="fileName" style={{maxWidth: '26rem'}}>{item.title}</span>
                            </Tooltip>
                            <div className="fileOperateBtnBox">
                                {
                                    (!isPrivate && enableViewFileType.includes(item.fileType)) ? (
                                        <Tooltip placement={'top'} title={'预览'}>
                                            <span onClick={() => this.viewFile(item)}><Iconfont type={"icon-view"} /></span>
                                        </Tooltip>
                                    ) : ''
                                }
                                <Tooltip placement={'top'} title={'下载'}>
                                    <span onClick={() => this.downloadFile([item.id])}><Iconfont type={"icon-xiazai1"} /></span>
                                </Tooltip>
                            </div>
                        </div>
                        <div className="rightBox">
                            <span className="userName">{item.uploader}</span>
                            <span className="dateText">{item.uploadDate}</span>
                        </div>
                    </div>
                )
            })
        }

        return (
            <div className={"fileDataBox" + (targetId === String(mapData.id) ? ' activeNode' : '')} id={mapData.id}>
                <div className="mapDataBox">
                    {
                        fileData?.length > 0 ? (
                            <span
                                className={"checkbox" + (selectedIds.length > 0 ? isAll ? ' allChecked' : ' checked' : '')}
                                onClick={(val) => this.selectedFileId(mapData.id, isAll ? [] : fileDataIds, true) }
                            />
                        ) : ''
                    }

                    <Tooltip placement={'top'} title={mapData.name} zIndex={30}>
                        <span className="mapNameText" style={{maxWidth: "30rem"}}>{mapData.name}</span>
                    </Tooltip>
                    {
                        isProject ? '' : (<span className="mapDataSign" style={{color: isPrivate ? 'red' : ''}}>（{mapData.isRequired ? '必填' : '如有'}）</span>)
                    }
                    {/*{ mapData.isPrivate ? (<Iconfont type={"icon-lock"} iStyle={{marginLeft: isProject ? '0.45rem' : 0}} />) : ''}*/}
                    <Iconfont type={mapData.isPrivate ? "icon-suo" : 'icon-jiesuo2'} iStyle={{marginLeft: isProject ? '0.45rem' : 0}} />
                    {
                        (isProject) ? (
                            <div className="operateBtnBpx">
                                {
                                    <Tooltip placement={'top'} title={'下载'}>
                                            <span className={"fileDataDelete" + (selectedIds.length > 0 ? '' : ' disabled')}
                                                  onClick={() => this.downloadFile(selectedIds)}
                                            >
                                                <Iconfont type={"icon-xiazai1"} />
                                            </span>
                                    </Tooltip>
                                }
                            </div>
                        ) : ''
                    }
                </div>
                <div className="fileDataListBox" >
                    {dom}
                </div>
            </div>
        )
    }

    // 生成树
    productTree (data=[], idx) {
        const {lineColorList, targetId, isProject} = this.state
        return (
            data?.map((it, index) => {
                if (isProject && it.fileNumber < 1) {
                    return ''
                }
                return (
                    <div className={"secondLevelNode" + (targetId === String(it.id) ? ' activeNode' : '')} key={`${idx}-${index}`} id={it.id}>
                        <div id={'name-' + it.id} className="secondLevelItem" style={{backgroundColor: lineColorList[idx % 4]}}>
                            <span>{it.name}{`${isProject ? ("（"+it.fileNumber+"）") : ''}`}</span>
                        </div>
                        <div className="thirdLevelNode">
                            {
                                it.children?.map((obj, objIndex) => {
                                    if (isProject && (!obj.data || obj.data.length < 1)) {
                                        return ''
                                    }
                                    return (
                                        MindMap({
                                            parentDomId: 'name-' + it.id,
                                            domMindMapId: obj.id,
                                            dom: this.fileDataDom(obj, [idx, index, objIndex]),
                                            lineColor: lineColorList[idx % 4]
                                        })
                                    )
                                })
                            }
                        </div>
                    </div>
                )
            })
        )
    }

    // 展开树
    expandTree (id) {
        if (id) {
            return
        }
        const newExpandIds = {...this.state.expandIds}
        if (newExpandIds[id]) {
            delete newExpandIds[id]
        } else {
            newExpandIds[id] = 1
        }

        this.setState({
            expandIds: newExpandIds
        })
    }

    // 一件展开树
    expandAllTree (expandFlag) { // true：展开  false：折叠
        if (expandFlag) {
            const modelData = this.state.modelData
            const dataList = modelData?.dataList || []
            const expandIds = {}
            dataList.forEach((item) => {
                expandIds[item.id] = 1
            })
            this.setState({
                expandIds
            })
        } else {
            this.setState({
                expandIds: {}
            })
        }
    }

    // 定位m
    toPosition (positionId, offsetTop = 0) {
        let positionDom = document.getElementById(positionId)
        if (positionDom) {
            if (positionDom.offsetParent.id === 'addModelContent') {
                const scrollDom = document.getElementById("addModelContent")
                if (scrollDom && positionDom) {
                    scrollDom.scrollTop = positionDom.offsetTop + (offsetTop ? (offsetTop - 100) : 0)
                }
            } else {
                this.toPosition(positionDom.offsetParent.id, positionDom.offsetTop)
            }
        }
    }

    // 下载
    async downloadFile (ids = []) {
        if (!ids || ids.length < 1) {
            return message.warning("请选择需要下载的文件")
        }
        Modal.confirm({
            title: '下载文件需走审批，确定下载吗？',
            // content: (<div style={{borderTop: '1px solid #ccc', padding: '0.5rem 0 0'}}>
            //     <p>确定下载所选文件吗？</p>
            //     {/*<p>你的下载申请将以邮件方式发送至 xx ，审批通过后AICON平台知识管理专员会将你申请下载的文件发送给你，请注意查收！</p>*/}
            //     {/*<p>如确认下载，请点击确定发送邮件。</p>*/}
            // </div>),
            centered: true,
            okText: '确定',
            cancelText: '取消',
            // width: 580,
            onOk: async () => {
                let func = Api.Common.downLoadFile
                let params = {data: {fileId: ids[0]}}
                if (ids.length > 1) {
                    func = Api.Common.downLoadFiles
                    params = {data: {fileIds: ids.join()}}
                }
                const res = await func(params);
                const data = responseDataFilter(res, "下载失败");
                if (data) {
                    message.success('下载成功');
                    const aDom = document.createElement('a');
                    aDom.href = data;
                    aDom.download = String(Date.now());
                    aDom.click();
                }
            }
        })
    }

    // 预览文件
    async viewFile (fileData) {
        const res = await request.getJson(fileData.url);
        console.log(res.data);
        if (res?.data) {
            window.open(res.data.data)
        }
    }

    render() {
        const that = this;
        const {
            lineColorList, bgColorList, targetId,
            expandIds={}, modelData, isProject,
            activeNav, labelList,
        } = that.state
        let dataList = modelData?.dataList || [];
        const isHaveExpand = Object.keys(expandIds).length > 0
        let countTotal = 0;
        const navList = dataList.map(item => {
            item.name.replace(/\s/g, '')
            countTotal += item.fileNumber || 0
            return item
        })
        navList.unshift({
            name: '全部',
            fileNumber: countTotal,
        })
        let activeNavIdx = navList.findIndex((item) => item.name === activeNav) - 1
        if (activeNav !== '全部') {
            dataList = dataList.filter((item) => item.name === activeNav)
        }
        const labelDom = (<div className={"labelValueListBox" + (isProject ? '' : ' modelLabelView')}>
            {labelList.map((item, idx) => {
                return (<span className="labelValueItem" key={idx}>{item.className}</span>)
            })}
        </div>)
        return (
            <div className="viewModelFrame">
                {/* 模板信息 */}
                <div className="baseInfoBox">
                    <div className="leftBox">
                        {
                            isProject ? (
                                <React.Fragment>
                                    <div className={"infoItem"}>
                                        <span className={"infoItemText"} style={{fontSize: '0.8rem',}}>{modelData.projectName}</span>
                                    </div>
                                    <div className={"infoItem"}>
                                        <span className={"infoItemText"} style={{fontSize: '0.65rem', opacity: '0.6'}}>{modelData.describe}</span>
                                    </div>
                                    {labelDom}
                                </React.Fragment>
                            ) : (
                                <React.Fragment>
                                    <div className={"infoItem"}>
                                        <span className={"infoItemLabel"}>模板名称：</span>
                                        <span className={"infoItemText"}>{modelData.name}</span>
                                    </div>
                                    <div className={"infoItem"}>
                                        <span className={"infoItemLabel"}>模板简介：</span>
                                        <span className="infoItemText infoItemDesc">{modelData.desc}</span>
                                    </div>
                                    <div className={"infoItem"}>
                                        <span className={"infoItemLabel"}>所属分类：</span>
                                        <span className={"infoItemText"}>{modelData.sortName}</span>
                                    </div>
                                    {
                                        labelList.length > 0 ? (
                                            <div className={"infoItem"}>
                                                <span className={"infoItemLabel"} style={{lineHeight: '1.4rem'}}>默认标签：</span>
                                                {labelDom}
                                            </div>
                                        ) : undefined
                                    }
                                </React.Fragment>
                            )
                        }
                    </div>

                    {/*<div className="operateBox">*/}
                    {/*    /!*<Tooltip placement={'top'} title={'一键折叠'}>*!/*/}
                    {/*    /!*    <span onClick={() => this.setState({expandIds: {}})}><Iconfont type={"icon-zhedie"} /></span>*!/*/}
                    {/*    /!*</Tooltip>*!/*/}
                    {/*    <Tooltip placement={'top'} title={isHaveExpand ? '一键折叠' : '一键展开'}>*/}
                    {/*        <span onClick={() => this.expandAllTree(!isHaveExpand)}><Iconfont type={isHaveExpand ? "icon-zhedie" : "icon-combination"} /></span>*/}
                    {/*    </Tooltip>*/}
                    {/*</div>*/}
                </div>
                {/* 模板图 */}
                <div className="addModelContentBox">
                    <div className="navListBox">
                        {
                            navList.map((item, idx) => {
                                const {name, fileNumber=0} = item
                                return (
                                    <div
                                        className={`navItem ${activeNav === name ? 'activeNav' : ''}`}
                                        key={idx}
                                        onClick={() => {
                                            this.setState({activeNav: name}, () => {
                                                let scrollDom = document.getElementById('addModelContent')
                                                if (scrollDom) {
                                                    scrollDom.scrollTop = 0
                                                }
                                            })
                                        }}
                                    >
                                        <span className="text" title={name}>{name}</span>
                                        {
                                            isProject ? (<span className="count">（{fileNumber}）</span>) : ''
                                        }
                                    </div>
                                )
                            })
                        }
                    </div>
                    <div id={"addModelContent"} className={"addModelContent"} style={{background: dataList.length < 2 ? bgColorList[activeNavIdx % 4] : ''}}>
                        {
                            dataList?.map((item, idx) => {
                                const index = activeNavIdx > -1 ? activeNavIdx : idx
                                return (
                                    <div
                                        className={"addModelItem" + (targetId === String(item.id) ? ' activeNode' : '')}
                                        key={'mindMap-'+idx}
                                        id={item.id}
                                        style={{background: bgColorList[index % 4]}}
                                    >
                                        <div className="stageNameBox">
                                            <span className={"stageNameLabel"}><Iconfont iStyle={{color: lineColorList[index % 4]}} type={'icon-wodexiangmu'}/></span>
                                            <span className={"stageNameText"} onClick={() => this.expandTree(item.id)}>{item.name || '无'}{isProject ? (`（${item.fileNumber}）`) : ''}</span>
                                            {/*<Tooltip placement={'top'} title={expandIds[item.id] ? '折叠' : '展开'}>*/}
                                            {/*    <span className={"zhankaiIcon" + (expandIds[item.id] ? ' rotateIcon' : '')} onClick={() => this.expandTree(item.id)}><Iconfont type={'icon-you'} /></span>*/}
                                            {/*</Tooltip>*/}
                                        </div>
                                        {
                                            (item.children && item.children.length > 0) ? (
                                                <div
                                                    className="secondLevelBox"
                                                    id={'secondLevel-'+item.id}
                                                    style={
                                                        expandIds[item.id] ?
                                                        {height: '',} :
                                                        {height: 0, marginTop: 0, overflow: 'hidden' }
                                                    }
                                                >
                                                    {
                                                        that.productTree(item.children, index)
                                                    }
                                                </div>
                                            ) : ''
                                        }
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        );
    }
}

ViewModel.propTypes = {};

export default ViewModel;
