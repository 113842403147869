import React, { useEffect, useState, useRef } from "react";
import { Typography, message, Space, Button, Input, Modal } from "antd";


import SelfTable from "../../../../components/SelfTable/index"

const { Title, Text } = Typography;
const { Search } = Input;

const ProcessConfirmation = ({
    knowledgeList,
    confirmRealese,
    cancelRealese,
    showConfirmModal,
    editType,
    spinning
}) => {

    const columns = [{
        title: '报告名称',
        dataIndex: 'knowledgeName',
        render: (text) => <a>{text}</a>,
    }];

    const [selectedRowKey, setSelectedRowKey] = useState([]);
    const [open, setOpen] = useState(false);
    const [tipsText, setTipsText] = useState("发布");

    useEffect(() => {
        if(editType === "updateFile"){
            setTipsText("更新")
        }
    }, [editType])

    useEffect(() => {
        setOpen(showConfirmModal)
        // 默认全选
        let knowledgeIds = [];
        knowledgeList.map(item=>{
            knowledgeIds.push(item.knowledgeId)
        })
        setSelectedRowKey(knowledgeIds)
            
    }, [showConfirmModal])

    return <Modal title="流程发起确认" 
        open={open} 
        onCancel={cancelRealese}
        destroyOnClose={true}
        maskClosable={false}
        footer={[
            <Button onClick={()=>cancelRealese()}>取消{tipsText}</Button>,
            <Button loading={spinning} disabled={selectedRowKey.length <= 0} onClick={()=>{
                if(selectedRowKey.length <= 0){
                    message.error("请至少选择一条数据发布！");
                }else{
                    confirmRealese(selectedRowKey)
                }
            }} type="primary">确认{tipsText}</Button>
        ]}
        width={600}
    >
        <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
            <Text>本次操作拟{selectedRowKey.length > 1 ? "批量" : ""}{tipsText} {selectedRowKey.length} 篇文档，请确认无误后{tipsText}！</Text>
            <SelfTable
                rowKey="knowledgeId"
                rowRadioSelection={{
                    type: 'checkbox',
                    selectedRowKey:selectedRowKey,
                    setSelectedRowKey:setSelectedRowKey,
                }}
                dataSource={knowledgeList}
                columns={columns}
                tableBodyHight={200}
            />
            <div>
                <Text type="secondary">*{tipsText}知识可能需要审批，点击确认{tipsText}自动发起审批流程。</Text><br/>
                <Text type="secondary">*根据文档保密要求，批量{tipsText}的文件可能需要不同的审批流程，系统自动按文档数量将流程拆分后发起。</Text>
            </div>
        </Space>
    </Modal>;
    }
export default ProcessConfirmation;