import react, {useEffect,useState} from 'react';
import { Input, Typography, Radio, Empty, Modal, Space, message, Spin, Select } from 'antd';
import { CaretUpOutlined, CaretDownOutlined, FileWordOutlined, FilePdfOutlined } from '@ant-design/icons';

import './index.scss';
import storage from "../../../../utils/storage";
import SelfPagination from "../../../../components/SelfPagination/index";
import KnowledgeFileIcon from "../../../../components/KnowledgeFileIcon/index";
import Api from "../../../../api/index";
import {serachType,pagingParam} from '../../../../utils/defaultValue'

import {getSearchParams} from "../../../../utils/tool";
import "./index.scss";

const { Text, Link, } = Typography;
const { Search } = Input;

const PolicyListModal = ({searchModalOpen, onCloseSearchModal, defaultSearchValue, defaultSelecthValue,setToEditFormData, setOpen, selectedTheme, setPolicyFromType}) => {
    const isManage = getSearchParams().inlet === "manage";
    
    const [userInfo, setUserInfo] = useState(storage.getItem("userInfo")?storage.getItem("userInfo"):{})
    const [timeSort, setTimeSort] = useState('0');
    const [sortKey, setSortKey] = useState('0');
    const [searchValue, setSearchValue] = useState(defaultSearchValue);
    const [sortList, setSortList] = useState([
        {key:'0',value:'按相关度排序'},
        {key:'1',value:'按阅读量排序'},
        {key:'4',value:''}
    ]);
    // 查询数据
    const [searchData, setSearchData] = useState({
        departmentId:isManage ? -1 : storage.getItem("userInfo").departmentId,
        policyThemeId: selectedTheme.id,
        ...pagingParam,
        "sort": timeSort,
        "siteSearch":defaultSearchValue,
        "searchType":defaultSelecthValue
    });

    // 分页
    const [pagination, setPagination] = useState({
        "pageIndex": 0,
        "pageSize": 0,
        "total":0
    });

    const [listDate, setListDate] = useState([]);

    const [radioValue, setRadioValue] = useState('');

    const [spinningType, setSpinningType] = useState(false);

    const [selecthValue, setSelecthValue] = useState('1');

    // useEffect(()=>{
    //     if(searchModalOpen){
    //         getPolicyList();
    //     }
    // },[searchModalOpen])

    useEffect(()=>{
        if(searchModalOpen){
            getPolicyList();
        }
    },[JSON.stringify(searchData)])

    useEffect(()=>{
        setSelecthValue(defaultSelecthValue);
    },[defaultSelecthValue])
    
    const getPolicyList = async() => {
        setListDate([]);
        setSpinningType(true);
        try {
            let res = await Api.Knowledge.getPolicyPageList({data:{
                ...searchData,
            }});
            setSpinningType(false);
            if(res && res.code === 0 && res.data.records){
                setListDate(res.data.records)
                setPagination({
                    "pageIndex": res.data.current,
                    "pageSize": res.data.size,
                    "total": res.data.total
                })
            }else{}
        } catch (errInfo) {
            setSpinningType(false);
            console.log('验证失败:', errInfo);
        }
    }

    const handleOk = () => {
        if(radioValue){
            let items = {}
            listDate.map(item=>{
                if((radioValue === item.elasticId) || (radioValue === item.policyId)){
                    items = item
                }
            })
            getByPolicyDetailBYPolicyId(items)
            
        }else{
            message.info('请输入需要添加的政策')
        }
    }
    const getByPolicyDetailBYPolicyId = async(items) => {
        try {
            let res = await Api.Knowledge.getByPolicyDetailBYPolicyId({data:{
                policyId:items.elasticId || items.policyId,
                policySourceType:items.policySourceType,
                titleHref:items.titleHref,
            }});

            if(res && res.code === 0){
                setPolicyFromType("2")
                setOpen(true);
                setToEditFormData({
                    ...res.data,
                    fileType:items.fileType
                });
            }
        } catch (errInfo) {
            console.log('验证失败:', errInfo);
        }
    }
    
    const onChange = (e) => {
        setRadioValue(e.target.value);
    };

    const sortValueChange = (value) => {
        let _sort = '';
        // 按时间排序时默认降序
        // 0 相关度 1 阅读量 4表示按时间排序 3 降序 2 升序
        if(value === '4'){
            _sort = timeSort === '3' ? '2' : '3'
        }else{
            _sort = value
        }
        setSortKey(value);
        setTimeSort(_sort);
        searchData.sort = _sort;
        setSearchData(searchData)
    }

    // 搜索弹窗
    const onSearchValueChange = (e) => {
        setSearchValue(e.target.value)
    }
   
    const onSearch = () => {
        let searchValueTrim = searchValue.trim()
        if(searchValueTrim){
            let _searchData = {...searchData}
            _searchData.siteSearch = searchValueTrim
            setSearchData(_searchData)
        }else{
            message.info('请输入需要搜索的内容')
        }
    }

    const onSelect = (value) => {
        searchData.searchType = value
        setSearchData(searchData)
        setSelecthValue(value);
    }

    // 分页变化
    const paginationChange = (pageIndex,pageSize) => {
        let _searchData = {...searchData}
        _searchData.pageIndex = pageIndex;
        _searchData.pageSize = pageSize;
        setSearchData(_searchData);
    }

    const getDitals = (item) => {
        if (item.titleHref) {
            window.open(item.titleHref);
        }else if(item.relationFileId){
            getWPSFileUrl(item.relationFileId)
        }
    }

    const getWPSFileUrl = async(relationFileId) => {
        try{
            let res = await Api.Knowledge.getWPSFilePreview({fileId:relationFileId});
            if(res&&res.code===200&&res.data){
                window.open(res.data.link);
            }
        }catch{}
    }

    return (
        <div className="policyListModalFrame">
            <Modal
                onCancel={onCloseSearchModal}
                open={searchModalOpen}
                onOk={handleOk}
                title={"选择要添加的政策"}
                wrapClassName={"policyListModal"}
                maskClosable={false}
                width={900}
                zIndex={900}
            >
                <div className="sortBox">
                    <div className="sort">
                        {sortList.map(item=>{
                            return(
                                <div
                                    className={item.key === sortKey ?'activeKeyClass':''}
                                    key={item.key} onClick={()=>{sortValueChange(item.key)}}
                                >
                                    {
                                        item.value ? item.value : (
                                            <div className="timeSort">
                                                按时间排序
                                                <CaretUpOutlined style={timeSort === '3' ?{color:'rgba(0, 0, 0, 0.85)'}:{}} />
                                                <CaretDownOutlined style={timeSort === '2' ?{color:'rgba(0, 0, 0, 0.85)'}:{}} />
                                            </div>
                                        )
                                    }
                                </div>
                            )
                        })}
                    </div>

                    <div className="searchBox">
                        <Input.Group compact>
                            <Select 
                                value={selecthValue || '全文'}
                                options={[{
                                    value: '1',
                                    label: '全文'
                                },
                                {
                                    value: '0',
                                    label: '标题'
                                }]}  
                                onChange={onSelect}
                                bordered={false}
                                style={{width:100}}>
                            </Select>
                            <Search
                                placeholder="请输入关键字"
                                onSearch={onSearch}
                                value={searchValue}
                                onChange={onSearchValueChange}
                                style={{
                                    width: 200,
                                }}
                            />
                        </Input.Group>
                    </div>
                </div>
                <Spin size="small" spinning={spinningType}>
                    {listDate&&listDate.length>0?(
                    <div className="listBox" style={{height:400}}>
                        <Radio.Group onChange={onChange} value={radioValue} style={{width:"100%"}}>
                            {listDate.map(item => {
                                return(
                                <div key={item.elasticId || item.policyId} className="listItem">
                                    <Radio value={item.elasticId || item.policyId} style={{float: 'left'}}></Radio>
                                    <div onClick={()=>getDitals(item)}>
                                        <div>
                                            <div className="listItemHeader">
                                            
                                                {/* 文件图标 */}
                                                <KnowledgeFileIcon
                                                    fileType={item.fileType}
                                                />
                                                
                                                {/* 标题 */}
                                                <div className="listTitle" dangerouslySetInnerHTML={{__html: item.title}} />
                                            </div>
                                            
                                            {/* 摘要 */}
                                            
                                            {item.isManual === 1?(
                                                <div className="listItemContent" dangerouslySetInnerHTML={{__html: item.content || "--"}} />
                                            ):(
                                                <div className="listItemContent" dangerouslySetInnerHTML={{__html: item.content || "--"}} />
                                            )}
                                        </div>

                                        <div className="listItemFooter">
                                            <div className="listItemFooterItem">
                                                <Space size={"large"}>
                                                    <span>
                                                    发文机构：<span className="jumpPointer">{item.source || "--"}</span>
                                                    </span>

                                                    <span>
                                                    发文字号：<span className="jumpPointer">{item.docNum || "--"}</span>
                                                    </span>
                                                    
                                                    <span>发文时间：{item.time || "--"}</span>
                                                    
                                                </Space>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                )    
                            })}
                        </Radio.Group>
                    </div>
                ):<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
                </Spin>
                <SelfPagination
                    current={pagination.pageIndex} 
                    total={pagination.total} 
                    onSizeChange={paginationChange}
                />
            </Modal>
        </div>
    )
}

export default PolicyListModal;