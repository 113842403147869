import React from 'react';
import {Modal, Tooltip, message, Switch} from 'antd';
import Iconfont from "../../../../components/Iconfont/Iconfont";
import SortTree from "../../../../components/SortTree";
import AddModel from "./addModel/index"
import NoneData from "../../../../components/NoneData";
import API from '../../../../api/index'
import moment from 'moment';
import {responseDataFilter} from "../../../../utils/tool";
import BackIcon from "../../../../components/BackIcon/index";
import './index.scss';

moment.locale('zh-cn');

const targetObject = {
    model: 'model',
    sort: 'sort',
}

class ModelManage extends React.Component {
    sortTreeRef = null
    addModelRef = null

    constructor(props) {
        super(props);
        const that = this;
        that.state = {
            dataList: [],
            expandIds: {},
            targetData: {},
            operateList: [
                {
                    type: 'add',
                    text: '新建',
                    icon: 'icon-roundadd',
                    targetType: targetObject.sort,
                    status: true,
                },
                {
                    type: 'switch',
                    text: '编辑',
                    icon: 'switch',
                    component: (data, operate, flag) => (<Switch
                        disabled={!flag}
                        checkedChildren={'启用'}
                        unCheckedChildren={'停用'}
                        checked={data.enabled}
                        onChange={(val) => {
                            this.operateFunc(operate)
                        }}
                    />),
                    targetType: targetObject.model,
                    status: true,
                },
                {
                    type: 'edit',
                    text: '编辑',
                    icon: 'icon-bianji2',
                    targetType: targetObject.model,
                    status: false,
                },
                {
                    type: 'delete',
                    text: '删除',
                    icon: 'icon-delete',
                    targetType: targetObject.model,
                    status: false,
                },
                {
                    type: 'move',
                    text: '移动',
                    icon: 'icon-zouhang',
                    targetType: targetObject.model,
                    status: true,
                },
                {
                    type: 'copy',
                    text: '复制',
                    icon: 'icon-copy',
                    targetType: targetObject.model,
                    status: true,
                },
            ],
            isLock: false,
            previousOperate: {},
            openCopyAndMove: false,
            selectedIds: [],
        };
    }

    async componentWillMount() {
        this.getModelList()
    }

    componentWillUnmount() {};

    async getModelList () {
        const res = await API.OverseasCaseManager.getModelList({loading: true})
        this.setState({
            dataList: res.data || []
        })
    }

    async apiFunc (func, params, successMessage, errorMessage, successFunc) {
        try {
            const res = await func(params)
            const data = responseDataFilter(res, errorMessage);
            if (data) {
                message.success(successMessage)
                this.getModelList()
                if (successFunc) {
                    successFunc()
                }
            }
        } catch (e) {
            message.error(errorMessage)
            console.log(e);
        }
    }

    // 生成树状列表
    createTreeData (treeData, parentId, keyStr) {
        const key = String(keyStr)
        if (treeData) {
            const {expandIds, targetData} = this.state
            const {children, modelList, id} = treeData
            let dataDom = ''
            if (modelList && modelList.length > 0) {
                dataDom = (
                    <ul className={"treeDataBox"}>
                        {
                            modelList.map((item, idx) => {
                                return (
                                    <li className={"treeDataItem"} key={`${parentId}-${id}-${item.id}`} onClick={() => this.choiceNode({...item, type: targetObject.model, parentId: id})}>
                                        <div className={"treeDataItemInfo"}>
                                            <span>{item.name}</span>
                                        </div>
                                        {
                                            targetData.id === item.id ? (<Iconfont type={'icon-dui'} />) : ''
                                        }
                                    </li>
                                )
                            })
                        }
                    </ul>
                )
            }

            const isHaveChild = (children && children.length > 0) || (modelList && modelList.length > 0)

            return (
                <div id={'tree-'+id} className={"treeRoot"} key={key}>
                    <div className={'treeSortBox'} onClick={() => this.choiceNode({...treeData, type: targetObject.sort})}>
                        <div className={'treeSortLeft' + ((expandIds[id] && isHaveChild) ? ' expand' : '')}>
                            {
                                isHaveChild ? (<span className={"expandIcon"}
                                                     onClick={(e) => {
                                                         e?.stopPropagation()
                                                         this.expandTree(id, isHaveChild)}
                                                     }
                                ><Iconfont type={'icon-you'}/></span>) :
                                    (
                                        <span className={"expandIcon"} style={{cursor: 'default'}}><Iconfont type={'icon-jian'}/></span>
                                    )
                            }
                            <span className={"sortName"}>{treeData.name}</span>
                        </div>
                        {
                            targetData.id === id ? (<Iconfont type={'icon-dui'} />) : ''
                        }
                    </div>
                    <div className={"treeContent"} style={!expandIds[id] ? { height: 0, overflow: 'hidden'} : {}}>
                        {dataDom}
                        {
                            isHaveChild ?
                                children?.map((item, idx) => {
                                    return this.createTreeData(item, id, `${key}-${idx}`)
                                })
                                : ''
                        }
                    </div>
                </div>
            )
        }
    }

    // 展开树
    expandTree(id, isHaveChild, key='expandIds') {
        if (!isHaveChild) {
            return
        }
        const expandIds = this.state[key]
        let ids = {...expandIds}
        if (ids[id]) {
            delete ids[id]
        } else {
            ids[id] = 1
        }
        this.setState({
            [key]: ids
        })
    }

    // 选择节点
    choiceNode (treeData) {
        const { targetData, isLock } = this.state
        const isSame = targetData.id === treeData.id
        if (isLock) {
            Modal.confirm({
                title: isSame ? '当前选择的文档正在操作中，确定取消当前操作吗？' : '当前选择的文档正在操作中，确定切换文档吗？',
                okText: '确定',
                cancelText: '取消',
                centered: true,
                onOk: () => {
                    this.setState({
                        targetData: isSame ? {} : treeData,
                        isLock: false,
                        previousOperate: {},
                    }, () => {
                        this.addModelRef?.initData()
                    })
                }
            })
        } else {
            this.setState({
                targetData: isSame ? {} : treeData
            })
        }
    }

    // 返回上一页
    returnPage () {
        const {changeType} = this.props
        if (Object.prototype.toString.call(changeType) === '[object Function]') {
            const {isLock, previousOperate} = this.state
            if (isLock) {
                Modal.confirm({
                    title: `模板正在${previousOperate.text}中，确定返回上一级吗？`,
                    okText: '确定',
                    cancelText: '取消',
                    centered: true,
                    onOk: () => {
                        changeType('')
                    }
                })
            } else {
                changeType('')
            }

        }
    }

    // 操作类型切换
    operateFunc (operate) {
        const {isLock, previousOperate} = this.state
        if (isLock) {
            if (!(operate.type === previousOperate.type)) {
                let text = operate.type === 'switch' ?
                    (this.state.targetData.enabled ? '停用' : '启用')
                    : operate.text;
                Modal.confirm({
                    title: `模板正在${previousOperate.text}中，确定${text}模板吗？`,
                    okText: '确定',
                    cancelText: '取消',
                    centered: true,
                    onOk: () => {
                        this.confirmOperate(operate)
                    }
                })
            }
        } else {
            this.confirmOperate(operate)
        }
    }

    // 操作类型功能的实现
    confirmOperate (operate) {
        const {isLock} = this.state
        if (operate.type === 'delete' || operate.type === 'switch') {
            let beforeStr = '删除'
            let func = () => this.deleteModelFunc()
            if (operate.type === 'switch') {
                beforeStr = this.state.targetData.enabled ? '停用' : '启用'
                func = () => this.switchModelFunc()
            }
            if (!isLock) {
                Modal.confirm({
                    title: `确定${beforeStr}该模板吗？`,
                    centered: true,
                    onOk: () => {
                        func()
                    },
                })
            } else {
                func()
            }
        } else {
            let isOpen = operate.type === 'move' || operate.type === 'copy'
            this.setState({
                previousOperate: operate,
                isLock: true,
                openCopyAndMove: isOpen,
            })
        }
    }

    // 取消操作
    cancelOperate (state={}) {
        this.setState({
            isLock: false,
            previousOperate: {},
            openCopyAndMove: false,
            selectedIds: [],
            ...state
        }, () => {
            this.sortTreeRef?.initData()
        })
    }

    // 移动或者复制模板函数
    moveOrCopyModelFunc () {
        const {previousOperate, selectedIds, targetData, expandIds} = this.state
        if (!selectedIds || selectedIds.length < 0) {
            return message.warning("请选择分类")
        }
        const func = previousOperate.type === 'copy' ? API.OverseasCaseManager.copyModel : API.OverseasCaseManager.moveModel
        this.apiFunc(
            func,
            {
                data: {
                    modelId: targetData.id,
                    modelGroupId: selectedIds[0]
                }
            },
            `模板${previousOperate.text}成功`,
            `模板${previousOperate.text}失败`,
            () => {
                this.cancelOperate({
                    targetData: {},
                    expandIds: {...expandIds, [selectedIds[0]]: 1}
                })
            }
        )

    }

    // 删除模板
    deleteModelFunc () {
        this.apiFunc(
            API.OverseasCaseManager.deleteModel,
            {data: {modelId: this.state.targetData?.id}},
            '模板删除成功',
            '模板删除失败',
            () => {
                this.setState({
                    isLock: false,
                    previousOperate: {},
                    targetData: {}
                })
            }
        )
    }

    // switch模板
    switchModelFunc () {
        const {targetData={}} = this.state
        const beforeStr = targetData.enabled ? '停用' : '启用'
        this.apiFunc(
            API.OverseasCaseManager.switchModel,
            {data: {modelId: targetData.id}},
            `模板${beforeStr}成功`,
            `模板${beforeStr}失败`,
            () => {
                this.setState({
                    isLock: false,
                    previousOperate: {},
                    targetData: {}
                })
            }
        )
    }

    // 选择节点id
    selectIds (ids = []) {
        this.setState({
            selectedIds: ids
        })
    }

    // 保存数据
    saveFunc () {
        const {
            targetData, expandIds
        } = this.state
        let newExpandIds = {...expandIds}
        if (targetData.type === targetObject.sort) {
            newExpandIds[targetData.id] = 1
        }

        this.cancelOperate({
            targetData: {}
        })
        this.getModelList()
        this.setState({
            expandIds: newExpandIds
        })
    }

    // 舍弃操作
    lossFunc () {
        this.cancelOperate()
    }

    render() {
        const that = this;
        const {
            dataList,
            operateList,
            targetData,
            openCopyAndMove,
            previousOperate,
        } = that.state;
        const isMove = previousOperate.type === 'move'
        const isSort = targetData.type === targetObject.sort
        return (
            <div className="modelManageFrame">
                {/* 返回 & 新增分类按钮 */}
                <div className={"topBox"}>
                    {/* 返回 */}
                    <div className={"returnBox"}>
                        {/* <Tooltip placement="right" title={'返回'} zIndex={10}>
                            <span style={{lineHeight: 1}} onClick={() => this.returnPage()}><Iconfont type={'icon-fanhui'} /></span>
                        </Tooltip> */}
                        {/*<span className={'returnText'}>返回</span>*/}
                        <BackIcon position="left"/>
                    </div>
                    <div className={"titleBox"}>
                        <Iconfont type={'icon-leftfont-18'} />
                        <span>模板管理</span>
                    </div>
                    <i></i>
                </div>
                {/* 内容 */}
                <div className={"contentBox"}>
                    {/* 模板树 */}
                    <div className={"contentLeftBox"}>
                        <div className={"modelOperateBox"}>
                            {
                                operateList.map((item,idx) => {
                                    const enableFlag = targetData.type === item.targetType && (item.status || !targetData.enabled)
                                    return (
                                        <React.Fragment>
                                            {
                                                (item.component) ? item.component(targetData, item, targetData.type === item.targetType) :
                                                    (
                                                        <Tooltip placement={'top'} title={item.text} key={`operate-${idx}`}>
                                                        <span
                                                            className={"modelOperateItem" + (enableFlag ? ' activeOperateItem' : '')}
                                                            onClick={() => {
                                                                if (enableFlag) {
                                                                    this.operateFunc(item)
                                                                }
                                                            }}
                                                        >
                                                           <Iconfont type={item.icon} />
                                                        </span>
                                                        </Tooltip>
                                                    )
                                            }
                                        </React.Fragment>
                                    )
                                })
                            }
                        </div>
                        <div className={"sortTreeContent"}>
                            <div className="sortTreeBox">
                                {
                                    (dataList && dataList.length > 0) ? dataList.map((item, idx) => {
                                        return this.createTreeData(item, item.id, idx)
                                    }) : (
                                        NoneData('暂无数据')
                                    )
                                }
                            </div>
                        </div>
                    </div>
                    {/* 模板详情 */}
                    <div className={"contentRightBox"}>
                        {
                            previousOperate.type === 'add' || previousOperate.type === 'edit' ? (
                                <AddModel
                                    {...that.props}
                                    modelId={isSort ? null : targetData.id}
                                    sortId={isSort ? targetData.id : targetData.parentId}
                                    type={previousOperate.type}
                                    sortName={isSort ? targetData.name : ''}
                                    saveFunc={() => this.saveFunc()}
                                    lossFunc={() => this.lossFunc()}
                                    onRef={(ref) => this.addModelRef = ref}
                                />
                            ) : (
                                <div className="noSelectTip">
                                    {NoneData(targetData.id ? '请选择操作！' : '请选择模板或者分类！', "icon-confirm-file")}
                                </div>
                            )
                        }
                    </div>
                </div>

                {/* 移动 & 复制模板 */}
                <Modal
                    open={openCopyAndMove}
                    centered={true}
                    title={previousOperate.text + "模板"}
                    okText={'确认'}
                    cancelText={'取消'}
                    onCancel={() => this.cancelOperate()}
                    onOk={() => this.moveOrCopyModelFunc()}
                    className={'sortModal'}
                >
                    <div className={"tipTextBox"}>
                        <span className={"tipLabel"}>操作模板：</span>
                        <span className={"tipContent"}>{targetData.name}</span>
                    </div>
                    <SortTree
                        onRef={(ref) => this.sortTreeRef = ref}
                        treeDataList={dataList}
                        disabledSelectIds={isMove ? [targetData.parentId] : []}
                        multiple={false}
                        onChange={(selectedIds) => this.selectIds(selectedIds)}
                    />
                </Modal>
            </div>
        );
    }
}

ModelManage.propTypes = {};

export default ModelManage;
