import React, {useEffect, useState} from 'react'
import { Table, Button } from 'antd';

import './index.scss'

const SelfTable = (props) => {
    let {
        rowKey = "id",
        components = null,
        scroll,
        dataSource,
        columns,
        pagination,
        loading,
        onChange,
        isEditing,
        rowRadioSelection,
        selfRowSelection,
        footerHeight = 64,
        isShowSort=true,
        expandable,
        bordered = true,
        tableContentBox,
        size="default",
        tableBodyHight,
        locale,
        selfClassName="selfTable",
        childrenColumnName
    } = props;

    const [tableHight, setTableHight] = useState(0);

    const [tableScrollHight, setTableScrollHight] = useState(0);

    useEffect(()=>{
        if(tableBodyHight){
            setTableHight(tableBodyHight)
        }else{
            if(!scroll || (scroll && !scroll.y)){
                // tab切换时不会销毁组件，会存在多个selfTable，查找到能生效的selfTable就可以了
                let selfTable = document.getElementsByClassName(selfClassName);
                // table title的高度
                let tableBodyTop = 66;
    
                // table title的高度可能是两行
                // console.log(document.querySelector('.ant-table-thead'),"ant-table-thead")
                // if(document.querySelector('.ant-table-thead')){
                //     tableBodyTop = document.querySelector('.ant-table-thead').offsetHeight
                // }
    
                for(let i=0; i<selfTable.length; i++){
                    if(selfTable[i].getBoundingClientRect().top){
                        console.log(selfTable[i].getBoundingClientRect().top,"top")
                        tableBodyTop = tableBodyTop + selfTable[i].getBoundingClientRect().top
                    }
                }
    
                let rootHeight = document.querySelector(tableContentBox || "#root").offsetHeight;
                console.log(tableBodyTop,rootHeight,"123")

                setTableScrollHight(rootHeight-tableBodyTop + 25);
                // let tableBodyTop = document.querySelector(".selfTable").getBoundingClientRect().top+55;
                //root高度 - .ant-table-tbody距离顶部的高度tableBodyTop -
                // 底部分页的高度-footerHeight - 64
                // 底部版权信息-32
                // padding- 20
                
                let tableContentHeight = rootHeight-tableBodyTop-32-20;
                if (tableContentBox) {
                    tableContentHeight = rootHeight - 40;
                }
    
                tableContentHeight = (pagination&& (dataSource && dataSource.length>0)) ? tableContentHeight - footerHeight : tableContentHeight
    
                setTableHight(tableContentHeight);
                console.log(rootHeight,tableBodyTop,tableContentHeight);
            }
        }
        
    },[JSON.stringify(dataSource)])

    if(isShowSort){
        columns = [{
            title: '序号',
            dataIndex: 'index',
            width: '80px',
            editable: false,
            render:(text, record, index)=>{
                return (pagination ? pagination.pageSize * (pagination.pageIndex - 1) : 0) + index + 1;
            }
        },...columns]
    }

    const mergedColumns = columns.map((col) => {
        if(!col.align){
            col.align="center";
        }
        if (!col.editable || !isEditing) {
            return col;
        }
        return {
            ...col,
            onCell: (record) => ({
                record,
                changeType: col.changeType,
                dataIndex: col.dataIndex,
                title: col.title,
                editing: isEditing(record),
            }),
        };
    });

    const rowSelection = rowRadioSelection ? {
        type: rowRadioSelection.type ? rowRadioSelection.type : 'radio',
        columnWidth:"80px",
        preserveSelectedRowKeys:rowRadioSelection.preserveSelectedRowKeys,
        selectedRowKeys: Array.isArray(rowRadioSelection.selectedRowKey) ? rowRadioSelection.selectedRowKey : [rowRadioSelection.selectedRowKey],
        onChange: (selectedRowKeys, selectedRows, info) => {
            if(rowRadioSelection.type === "checkbox"){
                rowRadioSelection.setSelectedRowKey(selectedRowKeys)
            }else{
                rowRadioSelection.setSelectedRowKey(selectedRowKeys[0])
            }
        },
        getCheckboxProps: (record)=>{
            if(rowRadioSelection.getCheckboxProps){
                return rowRadioSelection.getCheckboxProps(record)
            }
            // else if(setCheckboxStatus){
            //     return {
            //         disabled: record.cartStatus != 0,
            //         name: record.professionName,
            //     }
            // }
        },
        columnTitle: (
            rowRadioSelection.type === "checkbox" &&
            rowRadioSelection.selectedRowKey.length == 0 &&
            !rowRadioSelection.hideAllButton
        )?
        <Button onClick={()=>{
            let _selectedRowKeys = [];
            dataSource.map(item=>{
                if(rowRadioSelection.getCheckboxProps){
                    // 排除掉禁选项
                    if(! rowRadioSelection.getCheckboxProps(item).disabled){
                        _selectedRowKeys.push(item[rowKey])
                    }
                }else{
                    _selectedRowKeys.push(item[rowKey])
                }
            })
            rowRadioSelection.setSelectedRowKey(_selectedRowKeys);
        }} type="link">全选</Button>:
        <Button onClick={()=>rowRadioSelection.setSelectedRowKey('')} type="link">取消</Button>
    }:null;

    return(
        <div 
            className={`selfTable ${selfClassName === 'selfTable' ?'' : selfClassName}`}
            style={tableScrollHight?{height:tableScrollHight,overflowY:'auto'}:{}}
        >
            <Table
                rowKey={rowKey}
                components={components || null}
                scroll={{
                    scrollToFirstRowOnChange:true,
                    y:tableScrollHight ? null : tableHight,
                    ...scroll
                }}
                rowSelection={selfRowSelection ? selfRowSelection : (rowRadioSelection ? rowSelection : null)}
                bordered={bordered}
                dataSource={dataSource}
                columns={mergedColumns}
                rowClassName="selfTable-row"
                pagination={pagination && (dataSource && dataSource.length>0)?{
                    total:pagination.total,
                    current:pagination.pageIndex,
                    pageSize:pagination.pageSize,
                    showSizeChanger:true,
                    showQuickJumper:true,
                    onChange:pagination.onChange,
                    showTotal:()=> `总计${pagination.total}条数据`,
                    ...pagination,
                }:false}
                loading={loading}
                onChange={onChange}
                expandable={expandable}
                size={size}
                locale={locale}
                childrenColumnName={childrenColumnName}
            />
        </div>
    )
};
export default SelfTable;
