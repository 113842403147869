import React from 'react';
import {message, Switch, Tooltip, Input, Modal, Popconfirm} from 'antd';
import moment from 'moment';
import Iconfont from "../../../../../components/Iconfont/Iconfont";
import ModelNav from "../../../../../components/ModelNav";

import MindMap from "../../../../../components/MindMap";
import NoneData from "../../../../../components/NoneData";
import API from "../../../../../api/index.js";
import localStorage from "../../../../../utils/storage"
import {responseDataFilter} from "../../../../../utils/tool";
import './index.scss';

moment.locale('zh-cn');

class AddModel extends React.Component {
    constructor(props) {
        super(props);
        const that = this;
        that.state = {
            modelData: {},
            maxHeight: '100%',
            lineColorList: ['#169bd5', '#939516', '#9816d5', '#4b7900'],
            bgColorList: ['#dbeff6', '#f3f4e1', '#fff1ff', '#e6f7eb'],
            labelGroupList: [],
            choiceLabelList: [],
            labelChoiceVisible: false,
            storageChoiceLabel: [],
        };
    }

    componentWillMount = () => {
        const {onRef} = this.props
        if (Object.prototype.toString.call(onRef) === '[object Function]') {
            onRef(this)
        }
        this.getLabelGroupList()
        this.initData()

        window?.addEventListener('resize', this.computedMaxHeight)
    }

    componentWillUnmount = () => {
        window?.removeEventListener('resize', this.computedMaxHeight)
        localStorage.removeItem('modelData')
        localStorage.removeItem('labelList')
    };

    // 初始化数据
    async initData() {
        const {modelId, sortId, type, sortName} = this.props
        let state = {}
        if (modelId) {
            const res = await API.OverseasCaseManager.getModelData({
                data: {
                    modelId: modelId
                }
            })
            let choiceLabelList = res.data.tagClassList || []
            // let choiceLabelList = new Array(labelIds.length)
            // if (labelIds.length > 0) {
            //     const {labelGroupList} = this.state
            //     labelGroupList.forEach(item => {
            //         item.tagClassOperations?.forEach(it => {
            //             if (labelIds.includes(it.id)) {
            //                 const idx = labelIds.indexOf(it.id)
            //                 choiceLabelList[idx] = {...it}
            //             }
            //         })
            //     })
            // }
            // choiceLabelList = choiceLabelList.filter(it => it)
            state = {
                modelData: res.data,
                choiceLabelList,
                storageChoiceLabel: [...choiceLabelList],
            }
        } else {
            state = {
                modelData: {
                    name: '',
                    desc: '',
                    sortName: sortName,
                    id: Date.now(),
                    sortId,
                    dataList: [],
                    tagClassList: [],
                },
            }
        }
        this.setState(state, () => {
            this.computedMaxHeight()
        })
    }

    // 计算一个阶段模块所占高度
    computedMaxHeight = () => {
        const contentDom = document.getElementById('addModelContent')
        if (contentDom) {
            this.setState({
                maxHeight: contentDom.clientHeight - 92
            })
        }
    }

    // 取消添加模板
    cancelAddModel() {
        const {lossFunc} = this.props
        if (Object.prototype.toString.call(lossFunc) === '[object Function]') {
            lossFunc()
        }
    }

    // 确定添加模板
    async confirmAddModel() {
        
        const {saveFunc} = this.props;
        const {choiceLabelList} = this.state;
        const modelData = JSON.parse(JSON.stringify(this.state.modelData))
        const {id, name, sortId, dataList, sortName, desc} = modelData || {};

        if (!id || !sortId) {
            return message.error('关键信息缺失，请检查模板或者分类')
        }
        if (!name) {
            return message.error('模板名称不能为空')
        }

        if ((!dataList) || (dataList.length < 1)) {
            return message.error('模板数据不能为空')
        }

        // 去除未添加成功的最末分支
        let isHaveError = false;
        (dataList || []).forEach(item => {
            if (!item.name) {
                isHaveError = true
                return message.error('阶段名称不能为空')
            } else {
                if (item.children) {
                    item.children.forEach(it => {
                        if (!it.name) {
                            isHaveError = true
                            return message.error('子阶段名称不能为空')
                        } else {
                            const len = (it.children?.length || 0)
                            if (len > 0) {
                                for (let i = 0; i < (it.children.length); i++) {
                                    if (!(it.children[i].data)) {
                                        it.children.splice(i, 1);
                                        i--;
                                    }
                                }
                            }
                        }
                    })
                }
            }
        })

        if (isHaveError) {
            return
        }

        const tagClassList = choiceLabelList.map(item => item.id);


        const res = await API.OverseasCaseManager.saveModel({
            data: {
                id, name,desc, sortId,sortName, dataList, tagClassList: tagClassList
            }
        })
        const data = responseDataFilter(res, "模板保存失败");
        if (data) {
            message.success("模板保存成功")
            if (Object.prototype.toString.call(saveFunc) === '[object Function]') {
                saveFunc()
            }
        }
    }

    // 预览模板
    viewModel () {
        const {history} = this.props
        localStorage.setItem('modelData', this.state.modelData)
        localStorage.setItem('labelList', this.state.choiceLabelList)
        const domA = document.createElement('a')
        domA.href = history.createHref({
            pathname: '/page/view-model',
        })
        domA.target = '_blank'
        domA.click()
        // const w=window.open(, '_blank');
        // w.location.href=location.host+'#/page/view-model'
        // if () {
        //     history.replace('/page/view-model', '_blank')
        // }
    }

    // 模板基本修改
    modelDataChange (val, key) {
        const { modelData } = this.state
        const newData = JSON.parse(JSON.stringify(modelData))
        newData[key] = val
        this.setState({
            modelData: newData
        })
    }

    // 模板阶段名称修改
    modelStageNameChange (val, key, positionList=[]) {
        const { modelData = {} } = this.state
        const newModelData = JSON.parse(JSON.stringify(modelData))
        const dataList = newModelData.dataList || []
        const position = [...positionList]
        const first = position.shift()
        let data = dataList[first]
        position.map(item => {
            data = data?.children[item]
        })
        data[key] = val
        this.setState({
            modelData: newModelData
        })
    }

    // 新增/删除阶段
    addOrDeleteStage (deleteIndex) {
        const { modelData } = this.state
        const newData = JSON.parse(JSON.stringify(modelData))
        const flag = deleteIndex || deleteIndex === 0
        if (flag) {
            newData.dataList.splice(deleteIndex, 1)
        } else {
            if (!(modelData.name.replace(/\s/g, ''))) {
                return message.warning('模板名称不能为空或无效值！')
            }
            if(newData.dataList){
                newData.dataList.push({
                    name: '',
                    id: Date.now().toString(),
                    children: [],
                })
            }else{
                newData.dataList = [{
                    name: '',
                    id: Date.now().toString(),
                    children: [],
                }]
            }
            
        }
        this.setState({
            modelData: newData
        }, () => {
            const dom = document.getElementById('addModelContent')
            if (dom) {
                dom.scrollTop = flag ? 0 : dom.scrollHeight
            }
        })
    }

    // 新增/删除子(阶段/文件)节点
    addOrDeleteChildNode (positionList=[], deleteIndex) {
        const { modelData = {} } = this.state
        const newModelData = JSON.parse(JSON.stringify(modelData))
        const dataList = newModelData.dataList || []
        const position = [...positionList]
        const first = position.shift()
        let data = dataList[first]
        position.map(item => {
            data = data?.children[item]
        })
        const targetId = Date.now().toString()
        const flag = deleteIndex || deleteIndex === 0
        if (flag) {
            const deleteData = data.children[deleteIndex]
            return Modal.confirm({
                title: `确定删除该节点${deleteData.name? (' [ '+deleteData.name+' ] ') : ''}吗？`,
                okText: '确定',
                cancelText: '取消',
                onOk: () => {
                    data.children.splice(deleteIndex, 1)
                    this.setState({
                        modelData: newModelData
                    })
                }
            })
        } else {
            if (!(data.name.replace(/\s/g, ''))) {
                return message.warning('节点名称不能为空或无效值！')
            }
            if (data.children) {
                data.children.push({
                    name: '',
                    id: targetId,
                    children: []
                })
            } else {
                data.children = [
                    {
                        name: '',
                        id: targetId,
                        children: []
                    }
                ]
            }
            this.setState({
                modelData: newModelData
            }, () => {
                const firstLevelId = dataList[positionList[0]].id
                const domA = document.getElementById(targetId)
                const scrollDom = document.getElementById('secondLevel-' + firstLevelId)

                if (scrollDom) {
                    if (domA) {
                        scrollDom.scrollTop = domA.offsetTop
                    }
                    const dom = document.getElementById('addModelContent')
                    const addModelItemDom = document.getElementById('addModelItem-' + firstLevelId)
                    if (dom && addModelItemDom && dom.scrollTop !== addModelItemDom.offsetTop) {
                        dom.scrollTop = addModelItemDom.offsetTop
                    }
                }
            })
        }
    }

    // 文件节点属性修改
    fileNodeAttrChange (positionList=[], val, key) {
        const { modelData = {} } = this.state
        const newModelData = JSON.parse(JSON.stringify(modelData))
        const dataList = newModelData.dataList || []
        const position = [...positionList]
        const first = position.shift()
        let data = dataList[first]
        position.map(item => {
            data = data?.children[item]
        })
        data[key] = val
        this.setState({
            modelData: newModelData
        })
    }

    // 生成文件节点树
    fileDataDom (mapData, positionList=[]) {
        let dom = ''
        const fileData = mapData.data
        const parentPosition = positionList.slice(0, positionList.length - 1)
        const deleteIndex = [...positionList].pop()
        if (fileData) {
            dom = (
                <div className={"fileDataBox"} id={mapData.id}>
                    <div className="mapDataBox">
                        <span className={"mapNameText"}>{mapData.name}</span>
                        <span className={"mapDataSign"} style={{color: mapData.isRequired ? 'red' : ''}}>（{mapData.isRequired ? '必填' : '如有'}）</span>
                        { mapData.isPrivate ? (<Iconfont type={"icon-lock"} />) : ''}
                        <Tooltip placement={'top'} title={'删除'}>
                            <span className={"mapDataDelete"} onClick={() => this.addOrDeleteChildNode(parentPosition, deleteIndex)}><Iconfont type={"icon-delete"} /></span>
                        </Tooltip>

                    </div>
                    <div className={"fileDataListBox"}>
                        <span className={"noneFileText"}>( 空 )</span>
                    </div>
                </div>
            )
        } else {
            dom = (
                <div className={"addSortBox"} id={mapData.id}>
                    <div className={"addSortLeft"}>
                        <Input
                            className={"sortNameInput"}
                            placeholder={"输入分类名称"}
                            value={mapData.name}
                            onChange={(val) => this.fileNodeAttrChange(positionList, val.target.value, 'name')}
                        />
                        <div className={"switchBox"}>
                            <span className={"switchText"}>是否必填</span>
                            <Switch
                                checked={mapData.isRequired}
                                checkedChildren="是" unCheckedChildren="否"
                                onChange={(val) => this.fileNodeAttrChange(positionList, val, 'isRequired')} />
                        </div>

                        <div className={"switchBox"}>
                            <span className={"switchText"}>是否保密</span>
                            <Switch
                                checked={mapData.isPrivate}
                                checkedChildren="是" unCheckedChildren="否"
                                onChange={(val) => this.fileNodeAttrChange(positionList, val, 'isPrivate')}
                            />
                        </div>
                    </div>
                    <div className={"addSortRight"}>
                        <Tooltip placement={'top'} title={'取消'}>
                            <span className={"cancelBtn"} onClick={() => this.addOrDeleteChildNode(parentPosition, deleteIndex)}>
                                <Iconfont type={"icon-quxiao"} />
                            </span>
                        </Tooltip>

                        <Tooltip placement={'top'} title={'确定'}>
                            <span
                                className={"confirmBtn" + (mapData.name ? '' : ' disabled')}
                                onClick={
                                    () => {
                                        if (mapData.name.replace(/\s/g, '')) {
                                            this.fileNodeAttrChange(positionList, [], 'data')
                                        } else {
                                            message.warning('填写节点名称！')
                                        }
                                    }
                                }
                            >
                                <Iconfont type={"icon-baocun"} />
                            </span>
                        </Tooltip>
                    </div>
                </div>
            )
        }
        return dom
    }

    // 生成树
    productTree (data=[], idx) {
        const {lineColorList} = this.state
        return (
            data?.map((it, index) => {
                return (
                    <div className={"secondLevelNode"} key={`${idx}-${index}`}>
                        <div id={it.id} className={"secondLevelItem"} style={{backgroundColor: lineColorList[idx % 4]}}>
                            <Input
                                className={"secondLevelNameInput"}
                                style={{width: '5.5rem'}}
                                placeholder={'输入子阶段名称'}
                                value={it.name}
                                onChange={e => this.modelStageNameChange(e.target.value, 'name', [idx, index])}
                            />
                            {/*<span className={"secondLevelItemName"}>{it.name}</span>*/}
                            <div className={"secondLevelItemOperate"}>
                                <Tooltip placement={"top"} title={"增加"}>
                                    <span className={"addBtn"} onClick={() => this.addOrDeleteChildNode([idx, index])}>
                                        <Iconfont type={"icon-roundadd"} />
                                    </span>
                                </Tooltip>
                                <Tooltip placement={"top"} title={"删除"}>
                                    <span className={"deleteBtn"} onClick={() => this.addOrDeleteChildNode([idx], index)}>
                                        <Iconfont type={"icon-delete"} />
                                    </span>
                                </Tooltip>
                            </div>
                        </div>
                        <div className={"thirdLevelNode"}>
                            {
                                it.children?.map((obj, objIndex) => {
                                    return (
                                        MindMap({
                                            parentDomId: it.id,
                                            domMindMapId: obj.id,
                                            dom: this.fileDataDom(obj, [idx, index, objIndex]),
                                            lineColor: lineColorList[idx % 4]
                                        })
                                    //     <MindMap
                                    //     domMindMapId={obj.id}
                                    //     parentDomId={it.id}
                                    //     dom={}
                                    //     key={`${idx}-${index}-${objIndex}`}
                                    // />
                                )
                                })
                            }
                        </div>
                    </div>
                )
            })
        )
    }

    // 获取标签组（包含标签分类和值）
    async getLabelGroupList () {
        const res = await API.OverseasCaseManager.getAllGroupLabelList()
        const data = responseDataFilter(res, '标签获取失败');
        if (data) {
            this.setState({
                labelGroupList: data
            })
        }
    }

    // 选择标签
    choiceLabel (label, choice) {
        let storageChoiceLabel = this.state.storageChoiceLabel
        if (choice) {
            storageChoiceLabel.push(label)
        } else {
            const id = label.id
            const idx = storageChoiceLabel.findIndex(item => item.id===id)
            storageChoiceLabel.splice(idx, 1)
        }
        this.setState({storageChoiceLabel})
    }

    // 选择分组标签
    choiceGroupLabel (groupLabel, choice) {
        let storageChoiceLabel = this.state.storageChoiceLabel
        const ids = groupLabel.map(item => item.id)
        storageChoiceLabel = storageChoiceLabel.filter(item => !ids.includes(item.id))
        if (choice) {
            storageChoiceLabel.push(...groupLabel)
        }
        this.setState({storageChoiceLabel})
    }

    // 删除选择的标签
    deleteLabel (labelId) {
        const choiceLabelList = [...this.state.choiceLabelList]
        const idx = choiceLabelList.findIndex(item => item.id === labelId)
        choiceLabelList.splice(idx, 1)
        this.setState({
            storageChoiceLabel: JSON.parse(JSON.stringify(choiceLabelList)),
            choiceLabelList: choiceLabelList,
        })
    }

    render() {
        const that = this;
        const {
            modelData, maxHeight, bgColorList,
            labelGroupList, choiceLabelList,
            labelChoiceVisible, storageChoiceLabel,
        } = that.state;
        const dataList = modelData.dataList || []
        const navList = dataList.filter(item => item.name.replace(/\s/g, ''))
        const choiceLabelIdList = storageChoiceLabel.map(item => item.id)
        const haveLabelGroup = labelGroupList && labelGroupList.length > 0
        return (
            <div className="addModelFrame">
                <div className="topContent">
                    <div className="topContentLeft">
                        <div className="inputItemBox">
                            <span className="inputItemLabel isRequiredTip">模板名称：</span>
                            <Input
                                style={{width: 200}}
                                placeholder={'输入模板名称'}
                                value={modelData.name}
                                onChange={(e) => {
                                    this.modelDataChange(e.target.value, 'name')
                                }}
                            />
                        </div>

                        <div className="inputItemBox">
                            <span className="inputItemLabel">模板简介：</span>
                            <Input
                                style={{width: 600}}
                                placeholder={'输入模板简介编写要求(50字以内)'}
                                showCount={true}
                                value={modelData.desc}
                                maxLength={50}
                                onChange={(e) => {
                                    this.modelDataChange(e.target.value, 'desc')
                                }}
                            />
                        </div>
                        <div className="inputItemBox">
                            <span className="inputItemLabel">默认标签：</span>
                            <div className="choiceLabelListBox">
                                {
                                    choiceLabelList.map((item, idx) => {
                                        return (
                                            <div
                                                className="labelValueItem"
                                                key={idx}
                                            >
                                                <span>{item.className}</span>
                                                <Popconfirm
                                                    placement="topRight"
                                                    title={"确定删除标签？"}
                                                    zIndex={6}
                                                    onConfirm={(e) => {
                                                        e?.stopPropagation()
                                                        that.deleteLabel( item.id)
                                                    }}
                                                    okText="确定"
                                                    cancelText="取消"
                                                >
                                                    <span
                                                        draggable={false}
                                                    ><Iconfont type={"icon-quxiao"} /></span>
                                                </Popconfirm>
                                            </div>
                                        )
                                    })
                                }
                                <div
                                    className="labelValueItem labelValueAddBtn"
                                    onClick={() => that.setState({labelChoiceVisible: true})}
                                >
                                    <Iconfont type={"icon-add-bold"} />
                                    <span>引用标签</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="topContentRight">
                        <Tooltip placement={"top"} title={"放弃"}>
                            <span className="lossBtn" onClick={() => this.cancelAddModel()}><Iconfont type={"icon-quxiao"} /></span>
                        </Tooltip>
                        <Tooltip placement={"top"} title={"预览"}>
                            <span className="viewBtn" onClick={() => this.viewModel()}><Iconfont type={"icon-yanjing-zhengyan"} /></span>
                        </Tooltip>
                        <Tooltip placement={"top"} title={"保存"}>
                            <span className="saveBtn" onClick={() => this.confirmAddModel()}><Iconfont type={"icon-icon-baocun"} /></span>
                        </Tooltip>
                    </div>
                </div>
                <div id='addModelContent' className="addModelContent">
                    {
                        dataList.map((item, idx) => {
                            return (
                                <div
                                    className="addModelItem"
                                    key={'mindMap-'+idx}
                                    id={'addModelItem-'+item.id}
                                    style={{background: bgColorList[idx % 4]}}
                                >
                                    <div className="stageNameBox">
                                        <div className="stageNameLeft">
                                            <span className="stageNameLabel isRequiredTip">阶段名称：</span>
                                            <Input
                                                style={{width: 250, height: '1rem'}}
                                                placeholder={'输入阶段名称'}
                                                value={item.name}
                                                onChange={(e) => this.modelStageNameChange(e.target.value, 'name', [idx])}
                                            />
                                        </div>
                                        <div className={"stageOperateBox"}>
                                            <Tooltip placement={"top"} title={"增加子阶段"}>
                                                <span className={"addBtn"} onClick={() => this.addOrDeleteChildNode([idx])}>
                                                    <Iconfont type={"icon-roundadd"} />
                                                </span>
                                            </Tooltip>
                                            <Tooltip placement={"top"} title={"删除本阶段"}>
                                                <span className={"deleteBtn"} onClick={
                                                    () => {
                                                        Modal.confirm({
                                                            title: `确定删除该阶段${item.name? ('['+item.name+']') : ''}吗？`,
                                                            okText: '确定',
                                                            cancelText: '取消',
                                                            onOk: () => {
                                                                this.addOrDeleteStage(idx)
                                                            }
                                                        })
                                                    }
                                                }>
                                                    <Iconfont type={"icon-delete"} />
                                                </span>
                                            </Tooltip>
                                        </div>
                                    </div>
                                    <div className={"secondLevelBox"} id={'secondLevel-'+item.id} style={{height: maxHeight}}>
                                        {
                                            that.productTree(item.children, idx)
                                        }
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
                {/* 新增阶段按钮 */}
                <div className="addStageBox" onClick={() => this.addOrDeleteStage()}>
                    <Iconfont type={"icon-add-bold"} />
                    <span>新增阶段</span>
                </div>

                {/* 标签选择弹窗 */}
                <Modal
                    width={750}
                    open={labelChoiceVisible}
                    maskClosable={false}
                    centered={true}
                    title={(<div className="choiceLabelTitleBox"><Iconfont type={"icon-24gl-tags2"} />选择标签</div>)}
                    okText={'确定'}
                    cancelText={'取消'}
                    onCancel={() => this.setState({labelChoiceVisible: false})}
                    onOk={() => this.setState({
                        choiceLabelList: JSON.parse(JSON.stringify(storageChoiceLabel)),
                        labelChoiceVisible: false,
                    })}
                    className={'labelChoiceModal'}
                    footer={haveLabelGroup ? undefined : null}
                >
                    <div className="labelChoiceContent">
                        {
                            haveLabelGroup ? labelGroupList.map((item, idx) => {
                                const {tagClassOperations: labelList, groupName: text,} = item
                                let choiceList = labelList.filter(labelItem => choiceLabelIdList.includes(labelItem.id))
                                const choiceListLen = choiceList.length
                                const labelListLen = labelList.length
                                const isAll = choiceListLen >= labelListLen
                                return (
                                    <div className="groupListItem" key={idx}>
                                        <div className="groupItemTitle" onClick={() => this.choiceGroupLabel(labelList, !isAll)}>
                                            <i className={`choiceBtn ${choiceListLen > 0 ? choiceListLen < labelListLen ? 'someChoice' : 'allChoice' : ''}`} />
                                            <span className="titleText">{text}</span>
                                        </div>
                                        <div className="labelListBox">
                                            {
                                                labelList.map((it, index) => {
                                                    const {className: labelName, tagValues, id} = it
                                                    let valueList = tagValues.split(',')
                                                    const isChoice = choiceLabelIdList.includes(id)
                                                    return (
                                                        <div className="labelListItem" key={`${idx}-${index}`}>
                                                            <div className="labelNameBox" onClick={() => this.choiceLabel(it, !isChoice)}>
                                                                <i className={`choiceBtn ${isChoice ? 'allChoice' : ''}`} />
                                                                <span className="labelNameText">{labelName}</span>
                                                            </div>
                                                            <div className="labelValueListBox">
                                                                {
                                                                    valueList.map((child, childIndex) => {
                                                                        return (<span className="labelValueText" key={`${idx}-${index}-${childIndex}`}>{child}</span>)
                                                                    })
                                                                }
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                )
                            }) : (NoneData('请先前往标签管理页面添加标签'))
                        }
                    </div>
                </Modal>

                {/* 导航盒 */}
                {
                    navList.length > 1 ? (<ModelNav scrollDomId={'addModelContent'} positionPrefix={'addModelItem-'} navList={navList || []} />) : ''
                }
            </div>
        );
    }
}

AddModel.propTypes = {};

export default AddModel;
