import React, { useState, useEffect } from "react";
import {
  Radio,
  Modal,
  Form,
  Input,
  message,
  Tooltip,
} from "antd";
import { DownloadOutlined,} from "@ant-design/icons"; 
import { useSelector } from "react-redux";
import { useHistory} from 'react-router-dom';

import Api from "../../../../api";
import {KnowledgeApply, downloadFileBlob, getSearchParams, jumpToPage} from '../../../../utils/tool'
import SingleLogo from "../../../../assets/img/singleLogo.jpg";
import Image from "../../../../components/Image/Image";
import "./index.scss";

const KnowledgeTitle = ({backHomePage}) => {
const [addForm] = Form.useForm();
const history = useHistory();

  const knowledge = useSelector(state => state.editKnowledge.knowledgeDetail);
  const downloadModalStatus = useSelector(state => state.editKnowledge.downloadModalStatus);
    const fileTotalPages = useSelector(state => state.editKnowledge.fileTotalPages);
    const fileCurrentPage = useSelector(state => state.editKnowledge.fileCurrentPage);
  
  const [knowledgeDetail, setKnowledgeDetail] = useState({});
  const {knowledgeFileType} = getSearchParams() || {};

  const [downLoading, setDownLoading] = useState(false);
  const [rangeTypeValue, setRangeTypeValue] = useState(1);
  
  // 下载范围选择框
  const [openDownloadRange, setOpenDownloadRange] = useState(false);
  const [specifiedRangeModal, setSpecifiedRangeModal] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);

  const [downloadFileEnable, setDownloadFileEnable] = useState('');

  const [currentPage, setCurrentPage] = useState(1);
  const [permission, setPermission] = useState(false);

  useEffect(() => {
    // 通过知识库知识列表点击跳转的会带有permission=true 表示已经通过了阅读审核可以查看
    if(getSearchParams().permission){
        setPermission(true);
    }
}, [])

  useEffect(() => {
    setKnowledgeDetail(knowledge)
  }, [knowledge]);
  
  useEffect(() => {
    if(downloadModalStatus){
      setSpecifiedRangeModal(true)
    }
  }, [downloadModalStatus]);

  

  useEffect(() => {
    if(permission && !isMobile()){
      getSetting();
    }
  }, [permission])

  const isMobile = () => {
    var userAgentInfo = navigator.userAgent;
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(userAgentInfo);
  }

  const getSetting = async() => {
    try{
      let res = await Api.Settings.getModuleFunction({data:{}});
      if(res && res.code === 0 && res.data){
        let _data = res.data;
        _data.map(item => {
          if (item.moduleType == 1) {
            setDownloadFileEnable(item.isEnable)
          }
        })
      }
    } catch {}
  }

  // 下载
  const onBeforeDownLoad = async () => {
    if (downLoading || !knowledgeDetail.id) {
        return false;
    }
    setDownLoading(true);

    // 点击按钮的时候阻止触发beforeunload
    // onBeforeUnloadFired = true;
    // 0 班子成员，1 表示不是班子成员，下载文件时走审批流程
    let ifTeamMembers = 1;

    message.loading({
        content: '查询中...',
        key: "messageloading"
    });

    if(getSearchParams().type === 'policy'){
        // 政策文件直接可下载
        downloadFiles();
        return false;
    }

    try {
        let res = await Api.Knowledge.ifTeamMembers({});
        //  0 是班子成员
        if (res && res.code === 0 && res.data === 0) {
            // 是班子成员
            ifTeamMembers = 0;
            downloadFiles();
        } else {
            ifTeamMembers = 1
        }
    } catch {
        setDownLoading(false);
        message.destroy("messageloading");
    }

    if (ifTeamMembers === 1) {
        // 审批流程
        try {
            let res = await KnowledgeApply(knowledgeDetail.id, 1)
            if (res.bool) {
                downloadFiles();
            }else{
                setDownLoading(false);
            }
        } catch {
            setDownLoading(false);
            message.destroy("messageloading");
        }
    }
  }

  
  const downloadFiles = async () => {


    setDownLoading(false);
    let fileType = [
        'docx',
        'docm',
        'doc',
        'xlsx',
        'xls',
        'xlsm',
        'dwg'
    ]
    if (fileType.includes(knowledgeDetail.fileType)) {
        // word excel 不能按分页下载 只能下载全部
        rangeOk()
    } else {
        // 申请已通过，弹出下载选择弹窗
        setSpecifiedRangeModal(true);
    }

    // message.loading({
    //     content:'下载中..',
    //     key:"messageloading"
    // });
    // try{
    //     let res = await Api.Knowledge.onDownloadKnowledge({
    //         data:{
    //             knowledgeId:knowledgeDetail.id
    //         }
    //     });
    //     if(res){
    //         setDownLoading(false);
    //         downloadFile(res.data);
    //         onBeforeUnloadFired = true;
    //         message.destroy("messageloading");
    //     }
    // }catch{
    //     setDownLoading(false);
    //     message.destroy("messageloading");
    // }
}

const rangeOk = async () => {
  if (rangeTypeValue == "") {
      message.info("请选择要下载的文件范围");
      return false;
  } else if (rangeTypeValue === 4) {
      // 自定义
      try {
          const row = await addForm.validateFields();
          downByKnowledgeId(row.knowledgeExcerptType)
      } catch (errInfo) {
          console.log('验证失败:', errInfo);
      }
  } else if (rangeTypeValue === 2) {
      // 当前页
      downByKnowledgeId(fileCurrentPage);
  } else if (rangeTypeValue === 1) {
      // 全部
      downByKnowledgeId();
  }

  console.log("rangeTypeValue:", rangeTypeValue, "fileCurrentPage", fileCurrentPage)
}

const downByKnowledgeId = async (splitRule) => {
  setConfirmLoading(true);

  const res = await Api.Knowledge.downByKnowledgeId({
      data: {
          knowledgeId: knowledgeDetail.id,
          splitRule,
          type: getSearchParams().type === 'policy' ? 1 : 0
      }
  })

  setConfirmLoading(false);
  if (res) {
      rangeCancel();
      // 政策爬虫文件没有文件类型，默认给pdf
      downloadFileBlob(res, `${knowledgeDetail.knowledgeTitle}.${knowledgeDetail.fileType || getSearchParams().knowledgeFileType}`);
      if(getSearchParams().type === 'policy'){
          history.replace(window.location.pathname+window.location.search);
      }else{
          jumpToPage({...knowledgeDetail, type: ""}, "", history)
      }
  }
}

const rangeCancel = () => {
  setSpecifiedRangeModal(false);
  addForm.resetFields();
  setRangeTypeValue(1);
}

const onRangeChange = (e) => {
  setRangeTypeValue(e.target.value)
}
  
const knowledgeDetailFileType = knowledgeFileType || knowledgeDetail.fileType;

  return (
    <div>
      <Image
        imgSrc={SingleLogo}
        iClassName="layoutTopLogoSingle"
        onClick={backHomePage}
      />
      <div className="fileTitle">
        {knowledgeDetail.knowledgeTitle}  
        
        {(knowledgeDetailFileType !== 'online' && 
          String(downloadFileEnable) === "0" &&
          knowledgeDetail.downPerm != 1
          ) ? (<Tooltip 
            title="下载文件"
            placement="left"
        >
            <DownloadOutlined onClick={onBeforeDownLoad}/>
        </Tooltip>) : ""}
      </div> 


      <Modal
          title="下载指定范围"
          open={specifiedRangeModal}
          onOk={rangeOk}
          onCancel={rangeCancel}
          maskClosable={false}
          confirmLoading={confirmLoading}
          destroyOnClose={true}
          wrapClassName="specifiedRangeModal"
      >
          <p>指定页码范围</p>
          <Radio.Group
              onChange={onRangeChange}
              value={rangeTypeValue}
          >
              <Radio value={1}>全部</Radio>
              <Radio value={2}>当前页</Radio>
              <Radio value={4}>自定义</Radio>
          </Radio.Group>

          {rangeTypeValue === 4 && (<Form
              form={addForm}
              layout="vertical"
              autoComplete="off"
              style={{marginTop: 20}}
          >
              <Form.Item
                  key={'knowledgeExcerptType'}
                  label={'请输入页码范围'}
                  name={'knowledgeExcerptType'}
                  required
                  rules={[
                      ({getFieldValue}) => ({
                          validator(_, value) {

                              const FormatValidation = /^[0-9,.-]+$/;
                              const isNumber = /^[0-9]+$/;
                              let type = true;

                              if (FormatValidation.test(value) && value) {
                                  let pageList = value.split(',');
                                  console.log(pageList)
                                  // - 前后必须时数字
                                  // , 前后必须时数字
                                  pageList.map(item => {
                                      let page = item.trim();

                                      // 验证分割后是否是数字，是数字的直接保留
                                      if (isNumber.test(page)) {

                                          // 验证数字是否超过文件最大页码
                                          if (Number(page) > fileTotalPages) {
                                              type = false;
                                          }

                                      } else if (page.includes("-")) {
                                          // 不是数字的时候判断是否包含-分隔符
                                          let _page = page.split("-");
                                          // 按分隔符分割后，两位参数都必须为数字
                                          if (isNumber.test(_page[0]) && isNumber.test(_page[1])) {
                                              // 验证数字是否超过文件最大页码
                                              if ((Number(_page[0]) > fileTotalPages) || (Number(_page[1]) > fileTotalPages)) {
                                                  type = false;
                                              }
                                          } else {
                                              console.log("请按格式输入!")
                                              type = false;
                                          }
                                      } else {
                                          console.log("请按格式输入!")
                                          type = false;
                                      }
                                  })

                                  if (!type) {
                                      return Promise.reject(new Error("请按格式输入!"));
                                  } else {
                                      return Promise.resolve();
                                  }

                              } else {
                                  return Promise.reject(new Error("请按格式输入!"));
                              }
                          },
                      }),
                  ]}
              >
                  <Input placeholder="输入页码范围（例如：1,3,5-12）"/>
              </Form.Item>
              <sapn className="prompt">请按格式输入要下载的页码：1,2,3,4,5-9,13,15-16</sapn>
              <br/>
              <sapn className="prompt">当前文件最大可下载页码：{fileTotalPages}</sapn>
          </Form>)}
      </Modal>
    </div>
  );
};

export default KnowledgeTitle;
