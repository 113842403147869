import React from 'react';
import {Modal, Input, Checkbox, message, Tooltip} from 'antd';
import Iconfont from "../../../../components/Iconfont/Iconfont";
import API from '../../../../api/index'

import moment from 'moment';
import SortTree from "../../../../components/SortTree";
import NoneData from "../../../../components/NoneData";
import {responseDataFilter} from "../../../../utils/tool";
import BackIcon from "../../../../components/BackIcon/index";
import './index.scss';

moment.locale('zh-cn');

const Block = React.Fragment;
const OperateObject = {
    move: 'move',
    copy: 'copy',
    edit: 'edit',
    delete: 'delete',
}
class SortManage extends React.Component {
    inputRef = React.createRef()
    sortTreeRef = null;

    constructor(props) {
        super(props);
        const that = this;
        that.state = {
            treeDataList: [],
            expandIds: {},
            keyList: [],
            activeSortId: undefined,
            treeNodeName: '',
            openCreate: false,
            openCopyAndMove: false,
            operateType: '',
            selectedIds: [],
            disabledSelectKey: [],
        };
    }

    componentWillMount() {
        this.getDataList()
    }

    componentWillUnmount() {

    };

    async getDataList () {
        const res = await API.OverseasCaseManager.getSortList({loading: true})
        this.setState({
            treeDataList: res?.data || []
        })
    }

    async apiFunc (func, params, successMessage, errorMessage, successFunc) {
        try {
            const res = await func(params);
            const data = responseDataFilter(res, errorMessage);
            if (data) {
                message.success(successMessage)
                this.getDataList()
                if (successFunc) {
                    successFunc()
                }
            }
        } catch (e) {
            console.log(e);
        }
    }

    // 返回上一级
    returnPage () {
        const {changeType} = this.props
        if (Object.prototype.toString.call(changeType) === '[object Function]') {
            changeType('')
        }
    }

    // 展开树
    expandTree(id, isHaveChild) {
        if (!isHaveChild) {
            return
        }
        const expandIds = this.state.expandIds
        let ids = {...expandIds}
        if (ids[id]) {
            delete ids[id]
        } else {
            ids[id] = 1
        }
        this.setState({
            expandIds: ids
        })
    }

    // 生成树状表格
    createTreeData (treeData, parentId, keyStr, level=1) {
        const key = String(keyStr)
        if (treeData) {
            const {expandIds} = this.state
            const {children, data, id} = treeData

            const isHaveChild = children && children.length > 0
            const noDelete = ((data ? data.length : 0) + (children ? children.length : 0)) > 0

            return (
                <div id={'tree-'+id} className={"treeRoot"} key={key}>
                    <div className={'treeSortBox'}>
                        <div className='treeSortLeft'>
                            {
                                isHaveChild ? (
                                    <span className="expandIcon" onClick={() => this.expandTree(id, isHaveChild)} >
                                        <Iconfont type={expandIds[id] ? 'icon-xiangmu' : 'icon-wenjian'}/>
                                    </span>) :
                                    (
                                        <span className="expandIcon" style={{cursor: 'default'}}>
                                            <Iconfont type={'icon-jian'}/>
                                        </span>
                                    )
                            }
                            <span className={"sortName"}>{treeData.name}</span>
                        </div>
                        <div className={"treeSortRight"}>
                            <div className={"treeOperate"}>
                                <Tooltip placement={'top'} title={'修改'} >
                                    <span className={"operateBtn"} onClick={() => this.confirmTreeNode(id, treeData.name)}>
                                        <Iconfont type={"icon-bianji2"} />
                                    </span>
                                </Tooltip>
                                {
                                    level > 0 ? (
                                        <Block>
                                            <Tooltip placement={'top'} title={'移动'} >
                                                <span className={"operateBtn"} onClick={() => this.confirmOperateType(OperateObject.move, {...treeData, parentId})}>
                                                    <Iconfont type={"icon-zouhang"} />
                                                </span>
                                            </Tooltip>
                                            {/*<Tooltip placement={'top'} title={'复制'} >*/}
                                            {/*    <span className={"operateBtn"} onClick={() => this.confirmOperateType(OperateObject.copy, {...treeData, parentId})}>*/}
                                            {/*        <Iconfont type={"icon-copy"} />*/}
                                            {/*    </span>*/}
                                            {/*</Tooltip>*/}
                                            <Tooltip placement={'top'} title={'删除'} >
                                                <span className={"operateBtn" + (noDelete ? ' disabled' : '')}
                                                      onClick={() => this.confirmOperateType('delete', {...treeData, parentId}, noDelete)}>
                                                    <Iconfont type={'icon-delete'} />
                                                </span>
                                            </Tooltip>
                                        </Block>
                                    ) : ''
                                }
                            </div>
                            <div className={"treeCreate"}>
                                <Tooltip placement={'top'} title={'新建'} >
                                    <span className={"operateBtn"} onClick={() => this.confirmTreeNode(id)}>
                                        <Iconfont type={'icon-roundadd'} />
                                    </span>
                                </Tooltip>
                            </div>
                        </div>
                    </div>
                    <div className={"treeContent"} style={!expandIds[id] ? { height: 0, overflow: 'hidden'} : {}}>
                        {
                            isHaveChild ?
                                children.map((item, idx) => {
                                    return this.createTreeData(item, id, `${key}-${idx}`)
                                })
                                : ''
                        }
                    </div>
                </div>
            )
        }
    }

    // 确定需要操作的分类节点
    confirmTreeNode (id, val) {
        this.setState({
            activeSortId: id,
            openCreate: true,
            treeNodeName: val ? val : '',
            operateType: val ? 'edit' : ''
        }, () => {
            let current = this.inputRef.current
            const timer = setTimeout(()=> {
                clearTimeout(timer)
                current.focus()
            }, 100)
        })
    }

    // 取消创建分类节点
    cancelCreateSort () {
        this.setState({
            keyList: [],
            openCreate: false,
            treeNodeName: '',
            openCopyAndMove: false,
            operateType: '',
            selectedIds: [],
            disabledSelectKey: [],
            activeSortId: undefined,
        }, () => {
            this.sortTreeRef?.initData()
        })
    }

    // 确定创建分类节点
    async createTreeNode () {
        const {expandIds, treeNodeName, activeSortId, operateType} = this.state
        if (!treeNodeName) {
            return message.warning("请填写分类名称！")
        }
        let newExpandIds = {...expandIds}
        const isEdit = operateType === 'edit'
        const func = isEdit ? API.OverseasCaseManager.editSort : API.OverseasCaseManager.addSort
        const params = {
            id: activeSortId,
            pid: activeSortId || 0,
            name: treeNodeName,
        }
        if (isEdit) {
            delete params.pid
        } else {
            newExpandIds[activeSortId] = 1
            delete params.id
        }
        await this.apiFunc(
            func,
            {data: params},
            isEdit ? '修改成功' : '新建成功',
            isEdit ? '修改失败' : '新建失败',
            () => {
                this.cancelCreateSort()
                this.setState({
                    expandIds: newExpandIds,
                })
            }
        )
    }

    // 确定对分类节点进行的操作类型
    confirmOperateType (type, treeNodeData, disabled) {
        if (disabled) {
            return
        }
        const {key, name, id} = treeNodeData
        if (type === 'delete') {
            Modal.confirm({
                title: <span style={{display: 'flex', alignItems: 'center'}}>
                    <Iconfont iStyle={{color: '#ec9807', fontSize: '1rem', marginRight: '0.5rem'}} type={'icon-yyjinggao1'} />
                    确认删除该分类吗？
                </span>,
                icon: '',
                content: <div style={{display: 'flex'}}>
                    <span style={{flexShrink: '0'}}>分类名称：</span>
                    <span style={{wordBreak: 'break-word', textAlign: 'justify'}}>{name}</span>
                </div>,
                centered: true,
                okText: '确认',
                cancelText: '取消',
                onOk: () => {
                    this.apiFunc(API.OverseasCaseManager.deleteSort, {data: {modelGroupId: id}}, '分类删除成功', '分类删除失败')
                }
            })
        } else {
            this.setState({
                keyList: key ? key.split('-') : [],
                openCopyAndMove: true,
                operateType: type,
                disabledSelectKey: type === OperateObject.move ? [treeNodeData.id, treeNodeData.parentId] : [],
                treeNodeName: name,
                activeSortId: treeNodeData.id,
            })
        }
    }

    // 选择分类节点
    selectTreeIds (ids =[]) {
        this.setState({
            selectedIds: ids
        })
    }

    // 操作分类节点
    async confirmOperateSort () {
        const {operateType, selectedIds, activeSortId} = this.state
        if (activeSortId && selectedIds.length > 0) {
            // 访问接口
            switch (operateType) {
                case OperateObject.move:
                    this.apiFunc(
                        API.OverseasCaseManager.moveSort,
                        {
                            data: {
                                id: activeSortId,
                                pid: selectedIds[0]
                            }
                        },
                        '移动成功',
                        '移动失败',
                        () => {
                            this.setState({
                                activeSortId: undefined,
                                selectedIds: [],
                            })
                        }
                    )
                    break;
                case OperateObject.copy:
                    break;
                case OperateObject.edit:
                    break;
                case OperateObject.delete:
                    break;
            }
            this.cancelCreateSort()
        } else {
            message.warning('请选择分类节点！')
        }
    }

    render() {
        const that = this;
        const {
            treeDataList,
            openCreate,
            openCopyAndMove,
            treeNodeName,
            operateType,
            disabledSelectKey,
            activeSortId,
        } = that.state;
        const operate = {
            move: '移动',
            copy: '复制'
        }
        return (
            <div className="sortManageFrame">
                {/* 返回 & 新增分类按钮 */}
                <div className={"topBox"}>
                    {/* 返回 */}
                    <div className={"returnBox"}>
                        {/* <Tooltip placement="right" title={'返回'} zIndex={10}>
                            <span style={{lineHeight: 1}} onClick={() => this.returnPage()}><Iconfont type={'icon-fanhui'} /></span>
                        </Tooltip> */}
                        <BackIcon position="left"/>
                    </div>
                    

                    <div className={"titleBox"}>
                        <Iconfont type={'icon-fenjifenlei'} />
                        <span>分类管理</span>
                    </div>

                    {/* 新增分类按钮 */}
                    <div className={"addSortBtn"} onClick={() => this.confirmTreeNode()}>
                        <Iconfont type={'icon-add-bold'} />
                        <span className={"addSortText"}>新建</span>
                    </div>
                </div>
                {/* 内容 */}
                <div className="contentBox sortTreeBox">
                    {
                        (treeDataList && treeDataList.length > 0)
                            ?   treeDataList.map((item, idx) => {
                                    return this.createTreeData(item, item.id, idx, 0)
                                })
                            : (NoneData('暂无数据，请新建分类！', 'icon-shuju'))
                    }
                </div>
                {/* 添加、编辑分类 */}
                <Modal
                    open={openCreate}
                    centered={true}
                    title={operateType === 'edit' ? '编辑分类' : (activeSortId ? "创建子分类" : "创建分类")}
                    okText={'确认'}
                    cancelText={'取消'}
                    onCancel={() => this.cancelCreateSort()}
                    onOk={() => this.createTreeNode()}
                >
                    <div>
                        <Input
                            placeholder={"请输入分类名称"}
                            value={treeNodeName}
                            ref={that.inputRef}
                            autoFocus="autofocus"
                            onChange={(val) => this.setState({treeNodeName: val.target.value})}
                        />
                    </div>
                </Modal>
                {/* 移动 & 复制分类 */}
                <Modal
                    open={openCopyAndMove}
                    centered={true}
                    title={operate[operateType] + "分类"}
                    okText={'确认'}
                    cancelText={'取消'}
                    onCancel={() => this.cancelCreateSort()}
                    onOk={() => this.confirmOperateSort()}
                    className={'sortModal'}
                >
                    <div className={"tipTextBox"}>
                        <span className={"tipLabel"}>操作分类：</span>
                        <span className={"tipContent"}>{treeNodeName}</span>
                    </div>
                    <SortTree
                        onRef={(ref) => this.sortTreeRef = ref}
                        treeDataList={treeDataList}
                        disabledSelectIds={disabledSelectKey}
                        multiple={!(operateType === OperateObject.move)}
                        onChange={(selectedIds) => this.selectTreeIds(selectedIds)}
                    />
                </Modal>
            </div>
        );
    }
}

SortManage.propTypes = {};

export default SortManage;
