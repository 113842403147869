import React, { useState, useEffect, useRef } from "react";
import {
  Row,
  Col,
  DatePicker,
  Button,
  Space,
  Radio,
  message,
  Typography,
  Modal,
  Descriptions,
  Divider,
  Avatar,
  List,
  Input,
  Form,
  TimePicker,
  Select,
  Steps,
} from "antd";
import { useLocation, useHistory } from "react-router-dom";
import { pagingParam } from "../../../utils/defaultValue";
import Iconfont from "../../../components/Iconfont/Iconfont";
import BackIcon from "../../../components/BackIcon/index";
import SelfTable from "../../../components/SelfTable";
import {jumpToPage, ModalConfirm} from "../../../utils/tool";
import Api from "../../../api";
import moment from "moment";
import "./index.scss";
const { TextArea } = Input;
const { RangePicker } = DatePicker;
const Log = () => {
  const [form] = Form.useForm();
  const history = useHistory();
  const location = useLocation().state;
  const [tableHight, setTableHight] = useState(0);
  // 分页
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 0,
    total: 0,
  });

  // 查询数据
  const [searchData, setSearchData] = useState({
    ...pagingParam,
    startTime: "",
    endTime: "",
  });

  const [tableLoading, setTableLoading] = useState(false);
  const [listData, setListData] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);
  const [checkMessage, setCheckMessage] = useState({});
  const [okLoading, setOkLoading] = useState(false);
  const [rejectLoading, setRejectLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [checkOption, setCheckOption] = useState(null);
  // 可授权对象列表
  const [authorizationUserList, setAuthorizationUserList] = useState([]);
  // 当前展示授权信息编辑盒子还是确认信息盒子
  const [currentShowEditBox, setCurrentShowEditBox] = useState(false);
  const [currentAuthorizationUserInfo, setCurrentAuthorizationUserInfo] =
    useState({});
  const [defaultDate, setDefaultDate] = useState();
  const textareaRef = useRef(null);
  const [searchLoading, setSearchLoading] = useState(false);
  // 审批
  const columns = [
    {
      title: "申请日期",
      dataIndex: "createTime",
      // width: "120px",
      editable: false,
    },
    {
      title: "申请人",
      dataIndex: "applyUserName",
      // width: "120px",
      editable: false,
    },
    {
      title: "工号",
      dataIndex: "workId",
      // width: "200px",
    },
    {
      title: "用户角色",
      dataIndex: "roleName",
      // width: "150px",
      editable: false,
    },
    {
      title: "申请类型",
      dataIndex: "applyForType",
      // width: "150px",
      editable: false,
      render: (text) => {
        // 1下载申请，2发布知识申请，3取消发布知识申请，4阅读权限申请
        return text === 1
          ? "下载申请"
          : text === 2
          ? "发布知识申请"
          : text === 3
          ? "取消发布知识申请"
          : text === 4
          ? "阅读权限申请"
          : "";
      },
    },
    {
      title: "知识名称",
      dataIndex: "knowledgeName",
      // width: "150px",
      render: (text, record) => {
        return (
          <Typography.Link
            onClick={() =>
              queryStatus(
                record.knowledgeId,
                record.fileType,
                record.applyForType
              )
            }
          >
            {text}
          </Typography.Link>
        );
      },
    },
    {
      title: "知识类型",
      dataIndex: "knowledgeTypeName",
      // width: "150px",
      editable: false,
    },
    {
      title: "审批状态",
      dataIndex: "checkType",
      // width: "100px",
      editable: false,
      render: (text) => {
        return text === 2
          ? "待审批"
          : text === 1
          ? "同意"
          : text === 0
          ? "拒绝"
          : "";
      },
    },
    {
      title: "审批人",
      dataIndex: "checkUserName",
      // width: "120px",
      editable: false,
    },
    {
      title: "审批时间",
      dataIndex: "updateTime",
      editable: false,
      render: (text, record) => {
        if(record.checkType != 2){
          return text
        }
      },
    },
    {
      title: "审批意见",
      dataIndex: "checkOption",
      width: "",
      editable: false,
      // render: (_, record) => {
      //   return (
      //     <Radio.Group
      //       onChange={(e) => updateState(e.target.value, record.id)}
      //       value={record.checkType}
      //     >
      //       <Radio value={1}>同意</Radio>
      //       <Radio value={0}>拒绝</Radio>
      //     </Radio.Group>
      //   );
      // },
    },
  ];

  useEffect(() => {
    let divHeight = document.querySelector(".layoutContent");
    setTableHight(divHeight.offsetHeight - 55 - 118 - 80 - 32);
    getAuthorizationUserList();
  }, []);
  useEffect(() => {
    if (location) {
      getCheckMessage();
    }
  }, [location]);
  useEffect(() => {
    getList();
  }, [searchData]);
  useEffect(() => {
    if (isEdit) {
      textareaRef.current.focus();
    }
  }, [isEdit]);
  const getList = async () => {
    setTableLoading(true);
    const res = await Api.Knowledge.getCheckList({ data: searchData });
    setTableLoading(false);
    if (res && res.code === 0) {
      let _data = res.data;
      setListData(_data.records);
      setPagination({
        pageIndex: _data.current,
        pageSize: _data.size,
        total: _data.total,
      });
    } else {
      setListData([]);
    }
  };


  const onChanges = (pagination, filters) => {
    let newSearchData = { ...searchData };
    newSearchData.pageIndex = pagination.current;
    newSearchData.pageSize = pagination.pageSize;
    setSearchData(newSearchData);
  };

  // 审批弹框标题组件
  const titleContent = (
    <div className="modalTitleContent">
      <Iconfont
        type={"icon-chuizi"}
        iStyle={{ marginRight: "0.5rem", fontSize: "1.3rem", color: "#49A9EE" }}
      />
      <span>申请审核流程</span>
    </div>
  );
  // 当前审批人批签
  const opinionMessage = (
    <div className="opinionMessage">
      {isEdit ? (
        <TextArea
          ref={textareaRef}
          placeholder="请输入审批意见"
          showCount
          maxLength={100}
          style={{ width: "100%", height: "80%" }}
          value={checkOption}
          onChange={(e) => setCheckOption(e.target.value)}
        />
      ) : (
        <div
          onClick={() => {
            setIsEdit(true);
          }}
        >
          <Iconfont
            type={"icon-bianji"}
            iStyle={{ color: "#AAAAAA", fontSize: "1rem" }}
          ></Iconfont>
          请批签
        </div>
      )}
    </div>
  );
  // 上级审批人批签
  const superiorOpinionMessage = (item) => {
    return (
      <div className="opinionMessageS">
        <div className="checkOptionS">
          {item.checkOption}
        </div>
        <div className="optionInfoS">
          <span>{item.checkUserName}</span>
          <span>批准于{item.updateTime}</span>
        </div>
      </div>
    );
  }

  const getCheckMessage = async () => {


    try {
      let res = await Api.Knowledge.getWorkflowDetailByCheckId({
        data: { checkId: location.checkId },
      });
      if (res && res.code === 0) {
        setModalOpen(true);


        let approvalProcessUsers = [];

        res.data.workflowUsers.map(item=>{
            let names = [], steps = {};

            item.map(items=>{
                names.push(items.checkUserName)
            })

            steps = {
              ...item[0],
              names,
            }


            approvalProcessUsers.push(steps)
        })

        res.data.approvalProcessUsers = [...approvalProcessUsers];


        setCheckMessage(res.data);


      }
      if (res.code == -1) {
        history.replace("/page/manage/knowledgeReview");
      }
    } catch (error) {
      history.replace("/page/manage/knowledgeReview");
    }
  };
  const handleOk = async () => {
    let data = {
      checkId: location.checkId,
      checkOption:checkOption?checkOption:"同意",
      type: 1
    };
    // if (checkOption) {
      try {
        setOkLoading(true);
        let res = await Api.Message.updateApplyType({ data });
        if (res.data) {
          message.success("操作成功");
          setOkLoading(false);
          setModalOpen(false);
          getList();
          history.replace("/page/manage/knowledgeReview");
          setIsEdit(false);
          setCheckOption("");
          // 更新消息总数以及待办消息列表
          document.dispatchEvent(new CustomEvent("notify"));
          document.dispatchEvent(new CustomEvent("checkToDoMessage"));
        }else{
          setOkLoading(false);
        }
      } catch (error) {
        setOkLoading(false);
        setModalOpen(false);
      }
      // setModalOpen(false);
      // return;
    // } else {
    //   message.info("请输入审批意见后,再进行审批哦");
    // }
  };
  const handleReject = async () => {
    let data = { checkId: location.checkId, checkOption, type: 0 };
    if (checkOption) {
      try {
        setRejectLoading(true);
        let res = await Api.Message.updateApplyType({ data });
        if (res.data) {
          message.success("操作成功");
          setRejectLoading(false);
          setModalOpen(false);
          getList();
          history.replace("/page/manage/knowledgeReview");
          setIsEdit(false);
          setCheckOption("");
          // 更新消息总数以及待办消息列表
          document.dispatchEvent(new CustomEvent("notify"));
          document.dispatchEvent(new CustomEvent("checkToDoMessage"));
          // window.location.reload()
        }
      } catch (error) {
        setModalOpen(false);
      }
      setModalOpen(false);
      return;
    } else {
      message.info("请输入审批意见后,再进行审批哦");
    }
  };
  const handleCancel = () => {
    setModalOpen(false);
  };

  const queryStatus = async(knowledgeId, fileType, applyForType) => {
    let res = await Api.Message.checkMessageStatus({
      data: {
        id:knowledgeId
      },
    });
    if(res.code === 0){
      let _code = res.data;
      // -1已被删除 0已拒绝，1已通过，2待审批，3草稿文章
      if(_code === -1){
        message.info("当前知识已被删除");
      }else{
        toViewCheckKnowledge(knowledgeId, fileType, _code)
      }

    }
  }

  const toViewCheckKnowledge = async (knowledgeId, fileType, applyForType) => {
    let encodedObject = encodeURIComponent(knowledgeId);
    let url;
    if (applyForType == 2 || applyForType == 3 || applyForType == 0) {
      if (fileType == "online") {
        url = `/page/onLineWrite?knowledgeId=${encodedObject}&type=view`;
      } else {
        url = `/page/newKnowledge?knowledgeId=${encodedObject}&type=view`;
      }
    } else {
      jumpToPage({knowledgeId, fileType})
    }
    window.open(url);
  };

  // 临时授权
  const showEditView = () => {

    currentAuthorizationUserInfo.time = [
      moment(currentAuthorizationUserInfo.authorizationStartTime),
      moment(currentAuthorizationUserInfo.authorizationEndTime)
    ]

    form.setFieldsValue(currentAuthorizationUserInfo);
    setCurrentShowEditBox(true);
  };

  const descriptionsTitle = (
    <div className="descriptionsTitle">
      <h4>授权状态</h4>
      <span onClick={showEditView}>
        <Iconfont
          type={"icon-edit1"}
          iStyle={{ color: "#169BD5", marginRight: "0.2rem" }}
        />
        调整
      </span>
    </div>
  );

  const getAuthorizationUserList = async () => {
    try {
      let res = await Api.Message.getAuthorizationUserMessage({});
      if (res.data && res.code === 0) {
        setAuthorizationUserList(res.data);
      }
    } catch (error) {}
  };

  const getCheckAuthorizationUserInfo = async () => {
    try {
      let res = await Api.Message.getCheckAuthorizationUser({});
      setSearchLoading(false);
      if (res.data) {
        if (res.data.isAuthorized === 0) {
          setCurrentShowEditBox(true);
          setCurrentAuthorizationUserInfo({});
          form.resetFields();
        } else {
          let _data = res.data;
          setCurrentAuthorizationUserInfo(_data);
          setCurrentShowEditBox(false);
        }
        setIsModalOpen(true);
        message.destroy("messageloading");
      } else {
        setIsModalOpen(true);
        setCurrentShowEditBox(true);
      }
    } catch (error) {setSearchLoading(false);}
  };


  const showModal = () => {

    if(!searchLoading){
      setSearchLoading(true);
      message.loading({
        content:'查询中...',
        key:"messageloading"
      });

      getCheckAuthorizationUserInfo();
    }

  };
  const onFinish = async () => {
    try {
      let param = {};
      const row = await form.validateFields();

      param.authorizationStartTime = moment(row.time[0]).format(
        "YYYY-MM-DD HH:mm:00"
      );
      param.authorizationEndTime = moment(row.time[1]).format(
        "YYYY-MM-DD HH:mm:00"
      );

      let currentItem = authorizationUserList.find(
        (item) => item.userId === row.authorizedUserId
      );
      param.authorizedUserName = currentItem?.userName;
      param.authorizedUserId = currentItem?.userId;
      param.isAuthorized = 1;

      let diffHours = moment(row.time[1]).diff(row.time[0], "hours");
      if (diffHours < 4) {
        ModalConfirm({
          title: "当前选择授权生效时间小于四小时，您是否确认当前操作？",
          onOk: async (resolve, reject) => {
            updateCheckAuthorization(param);
            resolve();
          },
        });
      } else {
        updateCheckAuthorization(param);
      }

    } catch (error) {
      setModalLoading(false);
    }
  };

  const revokeAuthorization = () => {
    ModalConfirm({
      title: "您确定立即收回当前授权吗？",
      onOk: async (resolve, reject) => {
        updateCheckAuthorization({
          ...currentAuthorizationUserInfo,
          isAuthorized:0
        });
        resolve();
      },
    });
  }

  const updateCheckAuthorization = async (param) => {
    try {
      setModalLoading(true);
      let res = await Api.Message.updateCheckAuthorizationUser({
        data: { ...param},
      });
      if (res.data) {
        message.success("操作成功")
        setIsModalOpen(false);
        setModalLoading(false);
      }
    } catch (error) {
      setModalLoading(false);
    }
  };

  const handleResetting = () => {

    // 有临时授权的时候点击取消才返回上一页，否则直接关闭弹窗
    if(currentAuthorizationUserInfo && currentAuthorizationUserInfo.authorizedUserName){
      setCurrentShowEditBox(false);
    }else{
      setIsModalOpen(false);
    }

  }

  const disabledDate = (current) => {
    return current && current < moment().subtract(1, "days").endOf("day");
  };

  const onDateChange = (dateString) => {
    let newSearchData = { ...searchData };
    newSearchData.startTime = dateString[0];
    newSearchData.endTime = dateString[1];
    newSearchData.pageIndex = 1;
    newSearchData.pageSize = 10;
    setSearchData(newSearchData);
  };
  const resetPickDate = () => {
    setDefaultDate(new Date());
    setSearchData({
      pageIndex: 1,
      pageSize: 10,
      startTime: "",
      endTime: "",
    });
  };
  return (
    <div className="knowledgeReviewFrame">
      <div className="title">
        最近的申请
        <BackIcon />
      </div>
      <div className="operation">
        <Space size={"large"}>
          <RangePicker
            onChange={onDateChange}
            allowClear={true}
            key={defaultDate}
          />
          <Button type="primary" onClick={showModal}>
            临时授权
          </Button>
          <Button onClick={resetPickDate}>重置</Button>
        </Space>
      </div>
      {/* <div className="operation">
        选择时段：
        <RangePicker onChange={dateChange} />
        <div className="operationRight">
          <Space>
            <Button type="primary" danger onClick={() => updateState(1)}>
              全部同意
            </Button>
            <Button type="primary" onClick={() => updateState(0)}>
              全部拒绝
            </Button>
          </Space>
        </div>
      </div> */}
      <div className="table">
        <SelfTable
          rowKey="id"
          columns={columns}
          bordered
          dataSource={listData}
          onChange={onChanges}
          pagination={{
            total: pagination.total,
            pageIndex: pagination.pageIndex,
            pageSize: pagination.pageSize,
          }}
          // scroll={{
          //   scrollToFirstRowOnChange: true,
          //   y: tableHight,
          // }}
          loading={tableLoading}
        />
      </div>
      <div className="reviewModal">
        <Modal
          open={modalOpen}
          title={titleContent}
          onOk={handleOk}
          onCancel={handleCancel}
          maskClosable={false}
          destroyOnClose={true}
          width={520}
          wrapClassName={"processStatusModal"}
          footer={[
            <Button
              danger
              key="back"
              loading={rejectLoading}
              onClick={handleReject}
              disabled={okLoading}
            >
              驳回
            </Button>,
            <Button
              key="submit"
              type="primary"
              loading={okLoading}
              onClick={handleOk}
              disabled={rejectLoading}
            >
              通过
            </Button>,
          ]}
        >


          <div className="detailsTitle">基本信息</div>

          <div className="detailsItem">
              <div className="title">申请时间</div>
              <div className="content">{checkMessage.createTime}</div>
          </div>

          <div className="detailsItem">
              <div className="title">申请人</div>
              <div className="content">{checkMessage.userName}</div>
          </div>

          <div className="detailsItem">
              <div className="title">申请人工号</div>
              <div className="content">{checkMessage.jobNum}</div>
          </div>

          <div className="detailsItem">
              <div className="title">申请人所在部门</div>
              <div className="content">{checkMessage.userDepartmentName}</div>
          </div>

          <div className="detailsTitle">申请详情</div>

          <div className="detailsItem">
              <div className="title">申请类型</div>
              <div className="content">{
                  checkMessage.applyForType === 1 ? "下载" :
                  checkMessage.applyForType === 2 ? "发布知识" :
                  checkMessage.applyForType === 3 ? "取消发布知识" :
                  checkMessage.applyForType === 4 ? "阅读权限" : ""
              }</div>
          </div>
          <div className="detailsItem">
              <div className="title">知识名称</div>
              <div className="content">
                  {checkMessage.knowledgeName}
                  <Button type="primary" size={'small'} onClick={()=>{
                    queryStatus(
                      checkMessage.knowledgeId,
                      checkMessage.fileType,
                      checkMessage.applyForType
                    )
                  }}>查看</Button>
              </div>
          </div>
          <div className="detailsItem">
              <div className="title">知识类型</div>
              <div className="content">{checkMessage.knowledgeTypeName}</div>
          </div>
          <div className="detailsItem">
              <div className="title">分享范围</div>
              <div className="content">{checkMessage.shareKnowledgeDepartmentNames&&checkMessage.shareKnowledgeDepartmentNames.map(item=>{
                  return <span className="shareDepartmentNames">{item}</span>
              })}</div>
          </div>

          <div className="detailsTitle">流转意见</div>

          <List itemLayout="horizontal">
            {checkMessage.approvalProcessUsers&&checkMessage.approvalProcessUsers.map(item=>{
                return(<List.Item>
                  <List.Item.Meta
                    avatar={
                      <div className="Avatar">
                        <Avatar
                          style={{
                            background: "#49A9EE",
                            width: "3rem",
                            height: "3rem",
                            lineHeight: "3rem",
                          }}
                        >
                          {item.names.join("/")}
                        </Avatar>
                        {item.checkType === 1 && <div className="arrowIcon">
                          <Iconfont
                            type={"icon-liuchengjiantou"}
                            iStyle={{ color: "#4B7900", fontSize: "1.3rem" }}
                          ></Iconfont>
                        </div>}
                      </div>
                    }
                    title={
                      <div className="userDepartmentInfo">
                        <span>
                          {item.departmentName}
                        </span>
                        <span>{item.businessRoleName}</span>
                      </div>
                    }
                    description={item.checkType === 1?superiorOpinionMessage(item):opinionMessage}
                  />
                </List.Item>)
              })
            }
          </List>
        </Modal>


        {/* <Modal
          id="reviewModal"
          open={modalOpen}
          title={titleContent}
          onOk={handleOk}
          onCancel={handleCancel}
          maskClosable={false}
          footer={[
            <Button
              danger
              key="back"
              loading={rejectLoading}
              onClick={handleReject}
              disabled={okLoading}
            >
              驳回
            </Button>,
            <Button
              key="submit"
              type="primary"
              loading={okLoading}
              onClick={handleOk}
              disabled={rejectLoading}
            >
              通过
            </Button>,
          ]}
        >
          <Descriptions title="基本信息" column={1} size="small">
            <Descriptions.Item label="申请时间">
              {checkMessage?.applyForDate}
            </Descriptions.Item>
            <Descriptions.Item label="申请人">
              {checkMessage?.applyUserName}
            </Descriptions.Item>
            <Descriptions.Item label="申请人工号">
              {checkMessage?.jobNum}
            </Descriptions.Item>
            <Descriptions.Item label="申请人所在部门">
              {checkMessage?.departmentName}
            </Descriptions.Item>
          </Descriptions>
          <Descriptions title="申请详情" column={1} size="small">
            <Descriptions.Item label="申请类型">
              {checkMessage.applyForType === 1
                ? "文件下载"
                : checkMessage.applyForType === 2
                ? "文件发布"
                : checkMessage.applyForType === 3
                ? "取消发布"
                : "知识阅读"}
            </Descriptions.Item>
            <Descriptions.Item
              label="知识名称"
              contentStyle={{ width: "6rem" }}
            >
              <div className="knowledgeNameWrap">
                <span className="knowledgeName">
                  {checkMessage.knowledgeName}
                </span>
                <button
                  className="viewKnowledge"
                  onClick={() =>
                    toViewCheckKnowledge(
                      checkMessage.knowledgeId,
                      checkMessage.fileType,
                      checkMessage.applyForType
                    )
                  }
                >
                  查看
                </button>
              </div>
            </Descriptions.Item>
            <Descriptions.Item label="知识类型">
              {checkMessage.knowledgeTypeName}
            </Descriptions.Item>
            <Descriptions.Item label="分享范围">
              <div className="shareTags">
                {checkMessage?.shareDepartmentName &&
                  checkMessage?.shareDepartmentName.map((tag) => {
                    return <span>{tag}</span>;
                  })}
              </div>
            </Descriptions.Item>
          </Descriptions>
          <Divider style={{ background: "#999" }} />
          <h3 style={{ fontWeight: "600" }}>流转意见</h3>
          {checkMessage.superiorApplyProcess ? (
            <List itemLayout="horizontal">
              <List.Item>
                <List.Item.Meta
                  avatar={
                    <div className="Avatar">
                      <Avatar
                        style={{
                          background: "#49A9EE",
                          width: "3rem",
                          height: "3rem",
                          lineHeight: "3rem",
                        }}
                      >
                        {checkMessage?.superiorApplyProcess?.userName}
                      </Avatar>
                      <div className="arrowIcon">
                        <Iconfont
                          type={"icon-liuchengjiantou"}
                          iStyle={{ color: "#4B7900", fontSize: "1.3rem" }}
                        ></Iconfont>
                      </div>
                    </div>
                  }
                  title={
                    <div className="userDepartmentInfo">
                      <span>
                        {checkMessage?.superiorApplyProcess?.departmentName}
                      </span>
                      <span>
                        {checkMessage?.superiorApplyProcess?.roleName}
                      </span>
                    </div>
                  }
                  description={superiorOpinionMessage}
                />
              </List.Item>
            </List>
          ) : null}
          <List itemLayout="horizontal">
            <List.Item>
              <List.Item.Meta
                avatar={
                  <Avatar
                    style={{
                      background: "#49A9EE",
                      width: "3rem",
                      height: "3rem",
                      lineHeight: "3rem",
                    }}
                  >
                    {checkMessage?.currentApplyProcess?.userName}
                  </Avatar>
                }
                title={
                  <div className="userDepartmentInfo">
                    <span>
                      {checkMessage?.currentApplyProcess?.departmentName}
                    </span>
                    <span>{checkMessage?.currentApplyProcess?.roleName}</span>
                  </div>
                }
                description={opinionMessage}
              />
            </List.Item>
          </List>
        </Modal> */}
      </div>



      <div className="reviewModal">
        <Modal
          title="审批临时授权"
          open={isModalOpen}
          onCancel={() => {
            setIsModalOpen(false);
            setCurrentShowEditBox(true);
            form.resetFields(),
            setCurrentAuthorizationUserInfo({});
          }}
          maskClosable={false}
          footer={currentShowEditBox?[
            <Button onClick={handleResetting}>
              取消
            </Button>,
            <Button key="submit" type="primary" loading={modalLoading} onClick={onFinish}>
              确定
            </Button>
          ]:[
            <Button danger loading={modalLoading} onClick={revokeAuthorization}>
              立即收回授权
            </Button>
          ]}
        >
          {currentShowEditBox ? (
            <div className="authorizationEdit">
              <Form
                colon={false}
                form={form}
                name="basic"
                labelCol={{
                  span: 6,
                }}
                wrapperCol={{
                  span: 16,
                }}
                initialValues={{
                  remember: true,
                }}
                autoComplete="off"
              >
                <Form.Item
                  label="指定授权对象"
                  name="authorizedUserId"
                  labelCol={{
                    span: 24,
                  }}
                  wrapperCol={{
                    span: 14,
                  }}
                  rules={[
                    {
                      required: true,
                      message: "请选择授权对象!",
                    },
                  ]}
                >
                  <Select>
                    {authorizationUserList &&
                      authorizationUserList.map((item) => {
                        return (
                          <Select.Option value={item.userId} key={item.id}>
                            {item.userName}
                            &emsp; &emsp;
                            {item.jobNum}
                          </Select.Option>
                        );
                      })}
                  </Select>
                </Form.Item>
                <Form.Item
                  name="time"
                  label="授权生效时间"
                  labelCol={{
                    span: 24,
                  }}
                  wrapperCol={{
                    span: 18,
                  }}
                  rules={[
                    {
                      required: true,
                      message: "请选择授权生效时间!",
                    },
                  ]}
                >
                  <RangePicker
                    disabledDate={disabledDate}
                    showTime={{
                      format: "HH:mm",
                    }}
                    format="YYYY-MM-DD HH:mm"
                    disabledTime={(now, partial) => {
                      if (now) {
                        const disabledTime = moment();
                        const disabledDay = moment(disabledTime).date();
                        const nowDay = moment(now).date();
                        if (disabledDay === nowDay) {
                          return {
                            disabledHours: () => {
                              let hours = [];
                              let time = moment();
                              let hour = moment(time).hour();
                              for (let i = 0; i < hour; i++) {
                                hours.push(i);
                              }
                              return hours;
                            },
                          };
                        }
                      }
                      return {};
                    }}
                  />
                </Form.Item>
                <div> * 到期时自动收回权限,被授权人不再具备审批权限</div>
              </Form>
            </div>
          ) : (
            <div className="authorizationOk">
              <Descriptions title={descriptionsTitle} column={1} size="small">
                <Descriptions.Item label="已授权对象">
                  {currentAuthorizationUserInfo.authorizedUserName}(
                  {currentAuthorizationUserInfo.jobNum})
                </Descriptions.Item>
                <Descriptions.Item label="授权生效时间">
                  {currentAuthorizationUserInfo.authorizationStartTime}
                </Descriptions.Item>
                <Descriptions.Item label="授权失效时间">
                  {currentAuthorizationUserInfo.authorizationEndTime}
                </Descriptions.Item>
              </Descriptions>
            </div>
          )}
        </Modal>
      </div>
    </div>
  );
};
export default Log;
