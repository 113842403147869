import { lazy } from "react";
//如需按需加载需要 升级为webpack2  使用import()
import Login from "./pages/login/login.js";
import Root from "./pages/root/root.js";
import NotFound from "./pages/404/404.js";
import Examine from "./pages/examine/index.js";
import ExamineList from "./pages/examine/list.js";
import ErrorPage from "./pages/errorPage";
import HomePage from "./pages/homePage";

import AiAssistant from "./pages/aiAssistant/index.js";
import ReadAssistant from "./pages/aiAssistant/readAssistant/index.js";
import SearchAssistant from "./pages/aiAssistant/searchAssistant/index.js";

import PolicySign from "./pages/policySign";
import PolicySignDetail from "./pages/policySign/policySignDetail";
import Dict from "./pages/manage/dict";
import DictData from "./pages/manage/dict/dictData";

import Manage from "./pages/manage";
import User from "./pages/manage/user/index";
import Menu from "./pages/manage/menu/index";
import Log from "./pages/manage/log/index";
import PolicyOperation from "./pages/manage/policyOperation/index";
import PolicyOperationManage from "./pages/manage/policyOperationManage/index";
import KnowledgeStatistics from "./pages/manage/statistics/index";
import Department from "./pages/manage/department/index";
import KnowledgeType from "./pages/manage/knowledgeType/index";
import KnowledgeReview from "./pages/manage/knowledgeReview/index";
import GlobalSettings from "./pages/manage/globalSettings/index";
import PointsRule from "./pages/manage/pointsRule";
import AIModel from "./pages/manage/AIModel";


import ProjectList from "./pages/manage/projectList";
import KnowledgeList from "./pages/manage/knowledgeList";
import HotWords from "./pages/manage/hotWords";
import Tag from "./pages/manage/tag";
import HotTopicKnowledge from "./pages/manage/hotTopicKnowledge";

import ESList from "./pages/manage/ESList";
import BannerManage from "./pages/manage/bannerManage";
import PublicKnowledge from "./pages/manage/publicKnowledge";
import GeneralKnowledge from "./pages/manage/publicKnowledge/generalKnowledge";
import GeneralPolicy from "./pages/manage/publicKnowledge/generalPolicy";
import CloudSpace from "./pages/manage/cloudSpace";
import OverseasCaseLibrary from "./pages/manage/overseasCaseLibrary";

import OverseasCaseConfig from "./pages/manage/overseasCaseConfig";
import ModelManage from "./pages/manage/overseasCaseConfig/modelManage";
import SignManage from "./pages/manage/overseasCaseConfig/signManage";
import SortManage from "./pages/manage/overseasCaseConfig/sortManage";
import ViewModel from './pages/manage/overseasCaseConfig/modelManage/viewModel'


import KnowledgeCentre from "./pages/knowledgeCentre/index";
import KnowledgeContent from "./pages/knowledgeCentre/knowledgeContent/index";
import KnowledgeDetails from "./pages/knowledgeCentre/knowledgeDetails/index";
import KnowledgeSearch from "./pages/knowledgeCentre/knowledgeSearch/index";


import Notifications from "./pages/notifications/index";


import KnowledgeSystemOperate from "./pages/personalCenter/myNote/noteManage/knowledgeSystemManage/knowledgeSystemOperate";
import KnowledgeSystemDetail from "./pages/personalCenter/myNote/noteSearch/knowledgeSystem/knowledgeSystemDetail";
import MyHome from "./pages/personalCenter/myHome/index";
import MyRead from "./pages/personalCenter/myRead";
import MyStar from "./pages/personalCenter/myStar/index";
import NoteSearch from "./pages/personalCenter/myNote/noteSearch/index";
import NoteManage from "./pages/personalCenter/myNote/noteManage/index";
import MySpace from "./pages/personalCenter/mySpace/index";
import Published from "./pages/personalCenter/myUpload/published/published";
import NotPublished from "./pages/personalCenter/myUpload/notPublished/notPublished";
import NewKnowledge from "./pages/personalCenter/addKonwledge";
import OnLineWrite from "./pages/personalCenter/onLineWrite";
import EdictKnowledge from "./pages/personalCenter/editKnowledge/index.js";
import MyGrowth from "./pages/personalCenter/myGrowth";
import PointsInquiry from "./pages/personalCenter/myEquity/pointsInquiry";
import PointsMall from "./pages/personalCenter/myEquity/pointsMall/index";

// import PointsMall from "./pages/personalCenter/myEquity/pointsMall/index";

export const personalChildren = [
  {
    path: "/personalCenter/myHome",
    name: "我的主页",
    component: MyHome,
    // component: lazy(() => import("./pages/personalCenter/myHome/index")),
    key: "1",
    icon: "icon-fangzi",
    exact: true,
    render: null,
    children: null,
  },
  // {
  //   path: "/personalCenter/myGrowth",
  //   name: "我的成长",
  //   key: "6",
  //   icon: "icon-leafsxian",
  //   component: MyGrowth,
  //   exact: true,
  //   render: null,
  //   children: null,
  // },
  {
    path: "/personalCenter/myRead",
    name: "我的阅读",
    key: "2",
    icon: "icon-shu1",
    component: MyRead,
    // component: lazy(() => import("./pages/personalCenter/myRead/myRead")),
    exact: true,
    render: null,
    children: null,
  },
  {
    path: "/personalCenter/myStar",
    name: "我的收藏",
    key: "3",
    icon: "icon-shoucang3",
    component: MyStar,
    // component: lazy(() => import("./pages/personalCenter/myStar/index")),
    exact: true,
    render: null,
    children: null,
  },
  {
    path: "/personalCenter/myNote/noteManage",
    name: "我的笔记",
    key: "4",
    icon: "icon-bijiben",
    component: NoteManage,
    exact: true,
    render: null,
    children: null,
  },
  // {
  //   path: "/personalCenter/myNote",
  //   name: "我的笔记",
  //   key: "4",
  //   icon: "icon-bijiben",
  //   redirect: "/personalCenter/myNote/noteSearch", // 重定向
  //   exact: true,
  //   render: null,
  //   children: [
  //     // {
  //     //   path: "/personalCenter/myNote/noteSearch",
  //     //   name: "笔记查询",
  //     //   key: "a",
  //     //   // component: lazy(() =>import("./pages/personalCenter/myNote/noteSearch/index")),
  //     //   component: NoteSearch,
  //     //   exact: true,
  //     //   render: null,
  //     //   children: null,
  //     // },
  //     {
  //       path: "/personalCenter/myNote/noteManage",
  //       name: "笔记管理",
  //       key: "b",
  //       // component: lazy(() =>
  //       //   import("./pages/personalCenter/myNote/noteManage/index")
  //       // ),
  //       component: NoteManage,
  //       exact: true,
  //       render: null,
  //       children: null,
  //     },
  //   ],
  // },

  {
    path: "/personalCenter/myUpload",
    name: "我的分享",
    key: "5",
    icon: "icon-fenxiang3",
    redirect: "/personalCenter/myUpload/published",
    exact: true,
    render: null,
    children: [
      {
        path: "/personalCenter/myUpload/published",
        name: "分享成功",
        key: "c",
        // component: lazy(() =>import("./pages/personalCenter/myUpload/published/published")),
        component: Published,
        exact: true,
        render: null,
        children: null,
      },
      {
        path: "/personalCenter/myUpload/notPublished",
        name: "分享中",
        key: "d",
        // component: lazy(() =>
        //   import("./pages/personalCenter/myUpload/notPublished/notPublished")
        // ),
        component: NotPublished,
        exact: true,
        render: null,
        children: null,
      },
    ],
  },
  {
    path: "/personalCenter/mySpace",
    name: "我的空间",
    key: "5",
    icon: "icon-shangchuanyunkongjian",
    component: MySpace,
    exact: true,
    render: null,
    // children: [
    //   {
    //     path: "/personalCenter/myUpload/published",
    //     name: "分享成功",
    //     key: "c",
    //     // component: lazy(() =>import("./pages/personalCenter/myUpload/published/published")),
    //     component: Published,
    //     exact: true,
    //     render: null,
    //     children: null,
    //   },
    //   {
    //     path: "/personalCenter/myUpload/notPublished",
    //     name: "分享中",
    //     key: "d",
    //     // component: lazy(() =>
    //     //   import("./pages/personalCenter/myUpload/notPublished/notPublished")
    //     // ),
    //     component: NotPublished,
    //     exact: true,
    //     render: null,
    //     children: null,
    //   },
    // ],
  },
  {
    path: "/personalCenter/myEquity",
    name: "我的权益",
    key: "7",
    icon: "icon-huiyuanzuanshi-xianxingtubiao-01",
    redirect: "/personalCenter/myEquity/pointsInquiry",
    exact: true,
    render: null,
    children: [
      {
        path: "/personalCenter/myEquity/pointsInquiry",
        name: "积分查询",
        key: "e",
        component: PointsInquiry,
        exact: true,
        render: null,
        children: null,
      },
      // {
      //   path: "/personalCenter/myEquity/pointsMall",
      //   name: "积分商城",
      //   key: "f",
      //   component: PointsMall,
      //   exact: true,
      //   render: null,
      //   children: null,
      // },
    ],
  },
];

// route页面 务必前面加 /page/
const routesConfig = {
  root: {
    Root,
    baseUrl: "/page",
    routes: [
      {
        path: "/404",
        name: "404",
        component: NotFound,
        exact: true,
        render: null,
        children: null,
      },
      {
        path: "/examine",
        name: "examine",
        component: Examine,
        exact: true,
        render: null,
        children: null,
      },
      {
        path: "/examineList",
        name: "examineList",
        component: ExamineList,
        exact: true,
        render: null,
        children: null,
      },
      {
        path: "/homePage",
        name: "HomePage",
        component: HomePage,
        exact: true,
        render: null,
        children: null,
      },
      {
        path: "/aiAssistant",
        name: "AiAssistant",
        component: AiAssistant,
        exact: true,
        render: null,
        children: null,
      },
      {
        path: "/aiAssistant/readAssistant",
        name: "ReadAssistant",
        component: ReadAssistant,
        exact: true,
        render: null,
        children: null,
      },
      {
        path: "/aiAssistant/searchAssistant",
        name: "SearchAssistant",
        component: SearchAssistant,
        exact: true,
        render: null,
        children: null,
      },

      {
        path: "/manage",
        name: "Manage",
        component: Manage,
        exact: true,
        render: null,
        children: null,
      },
      {
        path: "/manage/userList",
        name: "User",
        component: User,
        exact: true,
        render: null,
        children: null,
      },
      {
        path: "/manage/menuList",
        name: "Menu",
        component: Menu,
        exact: true,
        render: null,
        children: null,
      },
      {
        path: "/manage/log",
        name: "Log",
        component: Log,
        exact: true,
        render: null,
        children: null,
      },
      {
        path: "/manage/policyOperation",
        name: "PolicyOperation",
        component: PolicyOperation,
        exact: true,
        render: null,
        children: null,
      },
      {
        path: "/manage/policyOperationManage",
        name: "PolicyOperationManage",
        component: PolicyOperationManage,
        exact: true,
        render: null,
        children: null,
      },
      {
        path: "/manage/statistics",
        name: "KnowledgeStatistics",
        component: KnowledgeStatistics,
        exact: true,
        render: null,
        children: null,
      },
      {
        path: "/manage/department",
        name: "Department",
        component: Department,
        exact: true,
        render: null,
        children: null,
      },
      {
        path: "/manage/knowledgeType",
        name: "KnowledgeType",
        component: KnowledgeType,
        exact: true,
        render: null,
        children: null,
      },
      {
        path: "/manage/knowledgeReview",
        name: "KnowledgeReview",
        component: KnowledgeReview,
        exact: true,
        render: null,
        children: null,
      },
      {
        path: "/manage/globalSettings",
        name: "globalSettings",
        component: GlobalSettings,
        exact: true,
        render: null,
        children: null,
      },
      {
        path: "/manage/pointsRule",
        name: "pointsRule",
        component: PointsRule,
        exact: true,
        render: null,
        children: null,
      },
      {
        path: "/manage/AIModel",
        name: "AIModel",
        component: AIModel,
        exact: true,
        render: null,
        children: null,
      },
      {
        path: "/manage/projectList",
        name: "projectList",
        component: ProjectList,
        exact: true,
        render: null,
        children: null,
      },
      {
        path: "/manage/knowledgeList",
        name: "knowledgeList",
        component: KnowledgeList ,
        exact: true,
        render: null,
        children: null,
      },
      {
        path: "/manage/hotWords",
        name: "hotWords",
        component: HotWords ,
        exact: true,
        render: null,
        children: null,
      },
      {
        path: "/manage/tag",
        name: "tag",
        component: Tag ,
        exact: true,
        render: null,
        children: null,
      },
      {
        path: "/manage/hotTopicKnowledge",
        name: "hotTopicKnowledge",
        component: HotTopicKnowledge ,
        exact: true,
        render: null,
        children: null,
      },
      {
        path: "/manage/ESList",
        name: "ESList ",
        component: ESList,
        exact: true,
        render: null,
        children: null,
      },
      {
        path: "/manage/banner",
        name: "bannerManage",
        component: BannerManage,
        exact: true,
        render: null,
        children: null,
      },
      {
        path: "/manage/cloudSpace",
        name: "cloudSpace",
        component: CloudSpace,
        exact: true,
        render: null,
        children: null,
      },
      {
        path: "/manage/overseasCaseLibrary",
        name: "overseasCaseLibrary",
        component: OverseasCaseLibrary,
        exact: true,
        render: null,
        children: null,
      },
      {
        path: "/manage/overseasCaseConfig",
        name: "overseasCaseConfig",
        component: OverseasCaseConfig,
        exact: true,
        render: null,
        children: null,
      },
      {
        path: "/manage/overseasCaseConfig/model",
        name: "model",
        component: ModelManage,
        exact: true,
        render: null,
        children: null,
      },
      {
        path: "/view-model",
        name: "viewModel",
        component: ViewModel,
        exact: true,
        render: null,
        children: null,
      },
      {
        path: "/manage/overseasCaseConfig/sign",
        name: "sign",
        component: SignManage,
        exact: true,
        render: null,
        children: null,
      },
      {
        path: "/manage/overseasCaseConfig/sort",
        name: "sort",
        component: SortManage,
        exact: true,
        render: null,
        children: null,
      },

      {
        path: "/manage/publicKnowledge",
        name: "PublicKnowledge ",
        component: PublicKnowledge,
        exact: true,
        render: null,
        children: null,
      },
      {
        path: "/manage/publicKnowledge/generalKnowledge",
        name: "GeneralKnowledge ",
        component: GeneralKnowledge,
        exact: true,
        render: null,
        children: null,
      },
      {
        path: "/manage/publicKnowledge/generalPolicy",
        name: "GeneralPolicy ",
        component: GeneralPolicy,
        exact: true,
        render: null,
        children: null,
      },
      {
        path: "/knowledgeCentre",
        name: "KnowledgeCentre",
        component: KnowledgeCentre,
        exact: true,
        render: null,
        children: null,
      },
      {
        path: "/knowledgeContent",
        name: "KnowledgeContent",
        component: KnowledgeContent,
        exact: true,
        render: null,
        children: null,
      },
      {
        path: "/notifications",
        name: "Notifications",
        component: Notifications,
        exact: true,
        render: null,
        children: null,
      },
      {
        path: "/knowledgeDetails",
        name: "KnowledgeDetails",
        component: KnowledgeDetails,
        exact: true,
        render: null,
        children: null,
      },
      {
        path: "/knowledgeSearch",
        name: "KnowledgeSearch",
        component: KnowledgeSearch,
        exact: true,
        render: null,
        children: null,
      },
      {
        path: "/newKnowledge",
        name: "newKnowledge",
        component: NewKnowledge,
        exact: true,
        render: null,
        children: null,
      },
      {
        path: "/onLineWrite",
        name: "onLineWrite",
        component: OnLineWrite,
        exact: true,
        render: null,
        children: null,
      },
      {
        path: "/editKnowledge",
        name: "editKnowledge",
        component: EdictKnowledge,
        exact: true,
        render: null,
        children: null,
      },
      {
        path: "/knowledgeSystemOperate",
        name: "knowledgeSystemOperate",
        component: KnowledgeSystemOperate,
        exact: true,
        render: null,
        children: null,
      },
      {
        path: "/knowledgeSystemDetail",
        name: "knowledgeSystemDetail",
        component: KnowledgeSystemDetail,
        exact: true,
        render: null,
        children: null,
      },
      {
        path: "/policySign",
        name: "policySign",
        component: PolicySign,
        exact: true,
        render: null,
        children: null,
      },
      {
        path: "/policySignDetail",
        name: "policySignDetail",
        component: PolicySignDetail,
        exact: true,
        render: null,
        children: null,
      },
      {
        path: "/manage/dict",
        name: "Dict ",
        component: Dict,
        exact: true,
        render: null,
        children: null,
      },
      {
        path: "/manage/dictData",
        name: "DictData ",
        component: DictData,
        exact: true,
        render: null,
        children: null,
      },
    ],
    personalRoutes: [
      {
        path: "/personalCenter",
        name: "personalCenter",
        component: MyHome,
        // redirect: "/personalCenter/myHome",
        exact: true,
        render: null,
        // children: personalChildren,
        children: [...personalChildren],
      },
    ],
  },
  Login,
  NotFound,
  ErrorPage,
  Examine,
  ExamineList
};
export default routesConfig;
