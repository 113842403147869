import React from 'react';
import {Checkbox, message,} from 'antd';
import PropTypes from 'prop-types'
import Iconfont from "../Iconfont/Iconfont";
import './index.scss';
import moment from 'moment';

moment.locale('zh-cn');

class SortTree extends React.Component {

    constructor(props) {
        super(props);
        const that = this;
        that.state = {
            treeDataList: [],
            expandIds: {},
            selectedIds: [],
        };
    }

    componentWillMount () {
        const {onRef} = this.props
        if (Object.prototype.toString.call(onRef) === "[object Function]") {
            onRef(this)
        }
    }

    componentDidMount() {
        this.initData()
    }

    componentWillUnmount() {};

    initData = (data={}) => {
        const { treeDataList, expandIds={}, selectedIds=[]} = this.props
        this.setState({
            treeDataList: treeDataList,
            expandIds: {...expandIds},
            selectedIds,
            ...data,
        })
    }

    expandTree(id, isHaveChild) {
        if (!isHaveChild) {
            return
        }
        const {expandIds} = this.state
        let ids = {...expandIds}
        if (ids[id]) {
            delete ids[id]
        } else {
            ids[id] = 1
        }
        this.setState({
            expandIds: ids
        })
    }

    createTreeData (treeData, parentId, keyStr) {
        const key = String(keyStr)
        if (treeData) {
            const {
                expandIds,
                selectedIds,
            } = this.state
            const {disabledSelectIds = []} = this.props
            const {children, id} = treeData
            const isHaveChild = children && children.length > 0
            const disabled = disabledSelectIds.includes(id)
            return (
                <div id={'tree-'+id} className={"treeRoot"} key={key}>
                    <div className={'treeSortBox'}>
                        <div
                            className={'treeSortLeft' + (expandIds[id] ? ' expand' : '')}
                            onClick={() => this.expandTree(id, (isHaveChild && (!disabled)))} >
                            {
                                (isHaveChild && (!disabled)) ? (<span className={"expandIcon"}><Iconfont type={'icon-you'}/></span>) : ''
                            }
                            <span className={"sortName"}>{treeData.name}</span>
                            <div onClick={(e) => {e?.stopPropagation()}} style={{lineHeight: "0.75rem",}}>
                                <Checkbox
                                    checked={selectedIds.includes(id)}
                                    disabled={disabled}
                                    onChange={(val) => {
                                        this.selectTreeId(val.target.checked, id)
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                    <div className={"treeContent"} style={!expandIds[id] ? { height: 0, overflow: 'hidden'} : {}}>
                        {/*{dataDom}*/}
                        {
                            isHaveChild ?
                                children.map((item, idx) => {
                                    return this.createTreeData(item, id, `${key}-${idx}`)
                                })
                                : ''
                        }
                    </div>
                </div>
            )
        }
    }

    // 选择分类节点
    selectTreeId (checked, key) {
        const {multiple = true} = this.props
        let {selectedIds} = this.state
        let ids = [...selectedIds]
        if (!multiple) {
            if (checked) {
                ids = [key]
            } else {
                ids = []
            }
        } else {
            if (checked) {
                if (!ids.includes(key)) {
                    ids.push(key)
                }
            } else {
                const idx = ids.findIndex((item) => item === key)
                ids.splice(idx, 1)
            }
        }

        this.setState({
            selectedIds: ids
        }, () => {
            this.selectedIdsChange()
        })
    }

    selectedIdsChange () {
        const {onChange} = this.props
        if (Object.prototype.toString.call(onChange) === '[object Function]') {
            onChange(this.state.selectedIds)
        }
    }

    render() {
        const that = this;
        const {
            treeDataList,
        } = that.state;
        return (
            <div className={"sortTreeFrame"}>
                {
                    treeDataList.map((item, idx) => {
                        return this.createTreeData(item, item.id, idx)
                    })
                }
            </div>
        );
    }
}

SortTree.propTypes = {
    treeDataList: PropTypes.array,
    selectedIds: PropTypes.array,
    expandIds: PropTypes.object,
    multiple: PropTypes.bool,
    disabledSelectIds: PropTypes.array,
    onChange: PropTypes.func,
};

export default SortTree;
