import myAxios from '../utils/request.js';

export default {
    /* ================= 分类管理 ================= API */
    // 获取分类数据列表
    async getSortList (params={}) {
        let res = await myAxios.getJson('/model/modelgroup/list', params);
        return res.data;
    },

    // 新建分类
    async addSort (params={}) {
        let res = await myAxios.postJson('/model/modelgroup/save', params);
        return res.data;
    },

    // 修改分类名称
    async editSort (params={}) {
        let res = await myAxios.postJson('/model/modelgroup/update', params);
        return res.data;
    },

    // 删除分类
    async deleteSort (params={}) {
        let res = await myAxios.getJson('/model/modelgroup/del', params);
        return res.data;
    },

    // 移动分类
    async moveSort (params={}) {
        let res = await myAxios.postJson('/model/modelgroup/update', params);
        return res.data;
    },


    /* ================= 模板管理 ================= API */
    // 获取模板数据列表
    async getModelList (params={}) {
        let res = await myAxios.getJson('/model/modelgroup/modellist', params);
        return res.data;
    },

    // 获取模板数据（包含节点数据）
    async getModelData (params={}) {
        let res = await myAxios.getJson('/model/getModel', params);
        return res.data;
    },

    // 获取模板数据（不包含节点数据）
    async getModelInfo(params={}) {
        let res = await myAxios.getJson('/model/modelInfo', params);
        return res.data;
    },

    // 保存模板数据（包含节点数据）
    async saveModel (params={}) {
        let res = await myAxios.postJson('/model/save', params);
        return res.data;
    },

    // 保存模板基本信息（不包含节点数据）
    async saveModelInfo (params={}) {
        let res = await myAxios.postJson('/model/saveModelInfo', params);
        return res.data;
    },

    // 删除模板
    async deleteModel (params={}) {
        let res = await myAxios.getJson('/model/del', params);
        return res.data;
    },

    // 移动模板
    async moveModel (params={}) {
        let res = await myAxios.getJson('/model/move', params);
        return res.data;
    },

    // 复制模板
    async copyModel (params={}) {
        let res = await myAxios.getJson('/model/copy', params);
        return res.data;
    },
    // 启用/禁用 模板
    async switchModel (params={}) {
        let res = await myAxios.getJson('/model/modelEnabled', params);
        return res.data;
    },

    // 获取模板树（只包含有模板的分类树）
    async getModelTree (params={}) {
        let res = await myAxios.getJson('/model/modelgroup/puremodellist', params);
        return res.data;
    },


    /* ================= 日志管理 ================= API */
    // 获取分类数据列表
    async getLogList (params={}) {
        let res = await myAxios.postJson('/logs/list', params);
        return res.data;
    },

    // 获取操作类型和操作对象数据列表
    async getLogConditionList (params={}) {
        let res = await myAxios.getJson('/logs/option', params);
        return res.data;
    },

    /* ================= 审批管理 ================= API */
    // 获取审批数据列表
    async getApprovalList (params={}) {
        let res = await myAxios.postJson('/app/list', params);
        return res.data;
    },

    // 审批状态修改
    async changeApprovalStatus (params={}) {
        let res = await myAxios.postJson('/app/action', params);
        return res.data;
    },

    // 移动端获取审批数据列表
    async getAppApprovalList (params={}) {
        let res = await myAxios.getJson('/app/appListQt', params);
        return res.data;
    },

    // 移动端审批状态修改
    async changeAppApprovalStatus (params={}) {
        let res = await myAxios.postJson('/app/actionQt', params);
        return res.data;
    },


    /* ================= 标签管理 ================= API */
    // 获取标签分组数据列表
    async getLabelGroupList (params={}) {
        let res = await myAxios.getJson('/tag/tagGroup/list', params);
        return res.data;
    },

    // 获取某个分组下标签分类列表
    async getLabelListByGroupId (params={}) {
        let res = await myAxios.getJson('/tag/tagClass/list', params);
        return res.data;
    },

    // 获取所有标签分类列表（包含下拉值, 不包含分组）
    async getAllLabelList (params={}) {
        let res = await myAxios.getJson('/tag/tag/allOperations', params);
        return res.data;
    },

    // 获取所有分组下所有标签分类列表（包含下拉值, 包含分组）
    async getAllGroupLabelList (params={}) {
        let res = await myAxios.getJson('/tag/tagClass/operations', params);
        return res.data;
    },

    // 获取标签下拉列表值ByLabelId
    async getSelectOptionsByLabelId (params={}) {
        let res = await myAxios.getJson('/tag/tag/operationsByClassIds', params);
        return res.data;
    },

    // 新增、编辑标签分组
    async addOrEditLabelGroup (params={}) {
        let res = await myAxios.postJson('/tag/tagGroup/save', params);
        return res.data;
    },

    // 新增、编辑标签分类
    async addOrEditLabel (params={}) {
        let res = await myAxios.postJson('/tag/tagClass/save', params);
        return res.data;
    },

    // 新增标签分类下拉值
    async addLabelValue (params={}) {
        let res = await myAxios.postJson('/tag/tag/save', params);
        return res.data;
    },

    // 删除标签分组
    async deleteLabelGroup (params={}) {
        let res = await myAxios.getJson('/tag/tagGroup/del', params);
        return res.data;
    },

    // 删除标签分类
    async deleteLabel (params={}) {
        let res = await myAxios.getJson('/tag/tagClass/del', params);
        return res.data;
    },

    // 删除标签分类下拉值
    async deleteLabelValue (params={}) {
        let res = await myAxios.getJson('/tag/tag/del', params);
        return res.data;
    },

    // 标签分类下拉值排序
    async labelValueSort (params={}) {
        let res = await myAxios.postJson('/tag/tag/sort', params);
        return res.data;
    },
};
