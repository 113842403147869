import React, {useState, useRef, useEffect} from "react";
import {useHistory} from "react-router-dom";
import {Spin, DatePicker, Tree, Empty} from "antd";
import moment from "moment";

import storage from "../../utils/storage";
import Iconfont from "../../components/Iconfont/Iconfont";
import PolicyList from "../../components/PolicyList";
import NoneData from "../../components/NoneData";
import UploadPolicyBtn from "../../components/PolicyComponents/UploadBtn";
import UploadDrawer from "../../components/PolicyComponents/UploadDrawer";
import {responseDataFilter} from "../../utils/tool";
import Api from "../../api";

import "./index.scss";

const PolicySign = (props) => {
    const history = useHistory();

    // 政策查询条件分类
    const tabList = [
        {title: "今日新增", key: "nowAdd", icon: "icon-xinzeng"},
        {title: "历史查询", key: "historySearch", icon: "icon-search"},
    ];

    const [nowAddSortList, setNowAddSortList] = useState([
        {title: "未标注", key: 0, count: 0,},
        {title: "已完成", key: 1, count: 0},
    ]);

    const [loadingType, setLoadingType] = useState(false);

    const [activeTab, setActiveTab] = useState("nowAdd");
    const [activeSort, setActiveSort] = useState(0);

    const [taxList, setTaxList] = useState([]); // 税种数据集合
    const [dataList, setDataList] = useState([]);
    const [policyType, setPolicyType] = useState({policyRegionId: "1"});
    // 查询数据
    const [searchData, setSearchData] = useState({
        pageIndex: 1, // 第几页
        pageSize: 10, //  每页多少条数据
        sort: 3, // 排序方式默认时间降序，0相关度排序，1阅读量排序 2 时间升序 3时间降序
        lableTime: null,
        // codes: [], // 地区编码
        // countryDeptIds: [], // 国务院或者部委id集合
        // endTime: null, // 结束时间
        // startTime: null, // 开始时间
        labelType: 0, // 标注类型（0未标注 1已标注）
        // policyRegionId: "", // 国家或地方 1国家 2地方
        // status: "", // 状态 1正常 2冻结
        // type: "", // 来源类型（0国务院 1部委）
        // dictTypes: [], // 政策分类 税种/行业/文件类型
        dictCodes: [], // 政策分类下的选项 codes 集合
    });

    // 分页
    const [pagination, setPagination] = useState({
        "pageIndex": 0,
        "pageSize": 0,
        "total": 0,
    });

    // 抽屉开关
    const [open, setOpen] = useState(false);
    // 上传的文件id
    const [uploadFileId, setUploadFileId] = useState(null);
    // 判断新增类型是上传还是站内搜索
    const [policyFromType, setPolicyFromType] = useState(null);
    // 上传的文件名
    const [uploadFileName, setUploadFileName] = useState(null);
    const [uploadFileExtension, setUploadFileExtension] = useState(null);
    const [toEditFormData, setToEditFormData] = useState(null);

    const [conditionList, setConditionList] = useState([]);
    const [selectedConditionIdList, setSelectedConditionIdList] = useState([]);
    const [conditionListExpandedKeys, setConditionListExpandedKeys] = useState([]);
    const [historyTime, setHistoryTime] = useState(null);

    // useEffect( () => {
    //     getPolicySortCountFunc();
    // }, [searchData.num])

    useEffect(() => {
        getPolicySortData();
    }, [])

    useEffect(() => {
        getPolicySortCountFunc();
        getPolicyList();
    }, [JSON.stringify(searchData)]);

    useEffect(() => {
        if (uploadFileId) {
            setUploadFileId(null);
            history.push(`/page/policySignDetail?fileId=${uploadFileId}`);
        }
    }, [uploadFileId])

    // useEffect
    // lableTime: null,
    const getPolicySortData = async() => { 
            const resDepartment = await Api.Dict.selectDeptAndDict({data:{
                knowledgeRel:0,
                dictType: "policy_tax_types",
            }});
            if(resDepartment&&resDepartment.code === 0){
                let newData = resDepartment.data.map(item=>{
                    return {
                        dictLabel: item.name,
                        dictCode: `${item.id}-departmentId`,
                        isLeaf: item.sysDictDatas ? false : true,
                        selectable: false,
                        disableCheckbox: item.sysDictDatas ? false: true,
                        children: item.sysDictDatas
                    }
                })
                setConditionList(newData);
            }
        }

    const updateTreeData = (list, key, children) =>
            list.map((node) => {
              if (node.dictCode === key) {
                return {
                  ...node,
                  children,
                };
              }
              if (node.children) {
                return {
                  ...node,
                  children: updateTreeData(node.children, key, children),
                };
              }
              return node;
        });
    
        const onLoadData = ({ key, children }) =>
            new Promise(async(resolve) => {

            if (children) {
                resolve();
                return;
            }
            let newData = await getPolicyYearByDictCodeF(key);
            setConditionList((origin) =>{
                let data = updateTreeData(origin, key, newData);
                console.log(data,"data")
                 return data;
            });
    
            resolve();
        });
    
        const getPolicyYearByDictCodeF = (key) => {
            
            return new Promise(async (resolve, reject) => {
                
                let res = await Api.Dict.getPolicyYearByDictCode({
                    data:{
                        dictCode:key,
                        searchMyself: true,  //查讯自己的
                        
                    }
                })
        
                const {data, code} = res;
                if(code === 0 && data){
                    let newData = data.map(item=>{
                        return {
                            dictLabel: item,
                            dictCode: `${key}-${item}`,
                            isLeaf:true
                        }
                    })
                    resolve(newData);
                }else{
                    resolve([]);
                }
            });
        }

        const onTreeCheck = (checkedKeys, info) => {
            console.log('onCheck', checkedKeys, info);
            setSelectedConditionIdList(checkedKeys);
            setConditionListExpandedKeys(checkedKeys);
            let policyTimeDictParams = [];

            if(checkedKeys && checkedKeys.length > 0){
                let dataObj = {};
                checkedKeys.map((item)=>{
                    let newItem = item.split("-")
                    let key = newItem[0], value = newItem[1];
                    if(value != "departmentId"){
                        if(value){
                            dataObj[key] = dataObj[key] ? [...dataObj[key],value] : [value];
                        }else{
                            dataObj[key] = dataObj[key] ? dataObj[key] :[]
                        }
                    }
                })
                for(let key in dataObj){
                    policyTimeDictParams.push({
                        dictCode:key,
                        times:dataObj[key]
                    })
                }
            }

            console.log(policyTimeDictParams,"policyTimeDictParams");
            setActiveSort('');
            setSearchData({
                ...searchData,
                policyTimeDictParams: policyTimeDictParams,
                "pageIndex": 1,
                "pageSize": 10,
                'serachMyHistory': true,
                'lableTime': historyTime ? searchData.lableTime : null
            })
        };

    // 获取政策主题列表
    const getPolicyList = async () => {
        setLoadingType(true);
        let _searchData = {...searchData};
        if (_searchData.regionId === 999) {
            delete _searchData.regionId
        }
        
        if (activeTab === tabList[0].key) {
            // delete _searchData.lableTime;
            delete _searchData.dictCodes;
        } else {
            delete _searchData.labelType;
        }

        let listData = [];
        let pagination = {
            "pageIndex": 1,
            "pageSize": 10,
            "total": 0,
        };

        if(activeSort === 0){
            _searchData.searchReptileOnly = true;
        }

        try {
            let res = await Api.Knowledge.getPolicyPageList({
                data:{
                    ..._searchData,
                    serachMySelf:1
                }
            });
            setLoadingType(false);
            const resData = responseDataFilter(res);

            if(resData){
                const {records, current, size, total} = resData;
                listData = records;
                pagination = {
                    "pageIndex": current,
                    "pageSize": size,
                    "total": total,
                };
            }
            setDataList(listData);
            setPagination({
                "pageIndex": res.data.current,
                "pageSize": res.data.size,
                "total":res.data.total,
            })
        } catch (errInfo) {
            setDataList(listData);
            setPagination(pagination);
            setLoadingType(false);
            console.log('验证失败:', errInfo);
        }
    }

    // 获取政策分类数量
    const getPolicySortCountFunc = async () => {
        const res = await Api.Knowledge.getPolicySortCount({
            data: {},
            loading: false,
        });

        const resData = responseDataFilter(res);
        if (resData && resData !== 1) {
            let {
                addNum, nonLableNum, overNum,
            } = resData;
            let sortList = [...nowAddSortList];
            sortList[0].count = nonLableNum;
            sortList[1].count = overNum;
            setNowAddSortList(sortList)
        }

    };

    // 更新查询参数
    const changeSearchValue = (data) => {
        let _searchData = {
            ...searchData,
            ...data
        }
        setSearchData(_searchData);
    }

    // 政策类型改变
    const policyTypeChange = (param) => {
        sessionStorage.policyType = JSON.stringify({
            ...param
        })
        setPolicyType({
            ...param
        });

        delete param.name;
        changeSearchValue({
            "pageIndex": 1,
            ...param
        });
    };

    // 分页变化
    const paginationChange = (pageIndex, pageSize) => {
        let _searchData = {...searchData}
        _searchData.pageIndex = pageIndex;
        _searchData.pageSize = pageSize;
        setSearchData(_searchData);
    }

    // 政策类型下 - 时间和标题筛选
    const operationChange = (value) => {
        changeSearchValue({
            ...value
        });
    };

    const changeTabFunc = (tabKey, isActive) => {
        if (!isActive) {
            setActiveTab(tabKey);
        }
    };

    const changeSortFunc = (sortKey, isActive, state) => {
        if (!isActive) {
            setActiveSort(sortKey);
            setSelectedConditionIdList([]);
            setConditionListExpandedKeys([]);
            setHistoryTime(null);
            setSearchData({
                ...state.searchData,
                labelType: sortKey,
                lableTime: sortKey === 1 ? moment().format('YYYY-MM-DD') : '',
                "pageIndex": 1,
                "pageSize": 10,
                'serachMyHistory': false,
                'policyTimeDictParams': []
            })
        }
    };

    const selectedTaxFunc = (taxId, isSelected, state) => {
        const {searchData,} = state;
        const list = [...(searchData.dictCodes || [])];
        if (isSelected) {
            const idx = list.findIndex(item => item === taxId);
            list.splice(idx, 1);
        } else {
            list.push(taxId)
        }
        setSearchData({
            ...searchData,
            dictCodes: list,
            "pageIndex": 1,
            "pageSize": 10,
        })
    };

    const dataChangeFunc = (date, dateStr, state) => {
        const {searchData} = state;
        setActiveSort('');
        setHistoryTime(dateStr);
        setSearchData({
            ...searchData,
            'lableTime': dateStr,
            'serachMyHistory': true,
        })
    };

    const state = {
        searchData,
        taxList,
    };
    const isNowAdd = activeTab === tabList[0].key;
    const selectedTaxIds = searchData.dictCodes;
    const selectedSignList = taxList.filter(item => selectedTaxIds.includes(item.id)).map(item => item.text);

    return (
        <div className="policySignFrame">
            <div className="topHeadBox">
                <i></i>
                <div className="pageTitleBox">
                    <Iconfont type="icon-zhengce"/>
                    <span className="pageTitle">政策标注</span>
                </div>
                <i></i>
            </div>

            <div className="contentBox">
                {/* leftBox */}
                <div className="leftConditionBox">
                    {/* 今日新增 */}
                    <div className="tabListBox">
                        {
                            tabList.map(item => {
                                const {title, icon, key} = item;
                                const isActive = activeTab === key;
                                return (
                                    <div
                                        className={`tabItem ${isActive ? "activeStatus" : ""}`}
                                        key={key}
                                        onClick={() => changeTabFunc(key, isActive)}
                                    >
                                        <Iconfont type={icon}/>
                                        <span className="tabName">{title}</span>
                                    </div>
                                )
                            })
                        }
                    </div>
                    {/* 历史查询 */}
                    <div className="tabContentBox">
                        {
                            isNowAdd ? (
                                <div className="nowAddContentBox">
                                    {
                                        nowAddSortList.map(item => {
                                            const {title, icon, key, count} = item;
                                            const isActive = activeSort === key;
                                            return (
                                                <div
                                                    className={`sortItem ${isActive ? "activeStatus" : ""}`}
                                                    key={key}
                                                    onClick={() => changeSortFunc(key, isActive, state)}
                                                >
                                                    <span className="sortName">{title}</span>
                                                    <span className="count">{count}</span>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            ) : (
                                <div className="historySearchBox">
                                    <div className="dateSearchBox">
                                        <span className="labelTitle">标注日期</span>
                                        <div className="searchContentBox">
                                            <DatePicker
                                                style={{width: "100%"}}
                                                size={"small"}
                                                placement={"topRight"}
                                                allowClear={true}
                                                onChange={(date, dateStr) => dataChangeFunc(date, dateStr, state)}
                                            />
                                        </div>
                                    </div>
                                    <div className="taxSearchBox">
                                        <span className="labelTitle">分类</span>
                                        <div className="searchContentBox">
                                        {
                                            conditionList.length > 0 ? (<Tree
                                                checkable
                                                defaultExpandedKeys={conditionListExpandedKeys}
                                                defaultCheckedKeys={selectedConditionIdList}
                                                onCheck={onTreeCheck}
                                                treeData={conditionList}
                                                loadData={onLoadData}
                                                fieldNames={{
                                                    title: 'dictLabel', key: 'dictCode',
                                                }}
                                            />) : (
                                                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                                            )
                                        }
                                            {/* {
                                                taxList.length > 0 ? (
                                                    taxList.map((item, idx) => {
                                                        const {id, text} = item;
                                                        const isSelected = selectedTaxIds.includes(id);
                                                        return (
                                                            <div
                                                                className={`conditionItem ${isSelected ? "selectedStatus" : ""}`}
                                                                key={idx}
                                                                onClick={() => selectedTaxFunc(id, isSelected, state)}
                                                            >
                                                                <i className="choiceBox"><Iconfont type="icon-duihao"/></i>
                                                                <span className="conditionName overflowEllipsis"
                                                                      title={text}>{text}</span>
                                                            </div>
                                                        )
                                                    })
                                                ) : (
                                                    <NoneData text={"暂无可选条件"}/>
                                                )
                                            } */}
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                    </div>
                </div>

                {/* rightBox */}
                <div className="rightContentBox">
                    <Spin tip="数据加载中" spinning={loadingType}>
                        <div className="policyBox">
                            {
                                dataList.length >= 0 ? (
                                    <React.Fragment>
                                        <div className="uploadBtnBox">
                                            <UploadPolicyBtn
                                                setOpen={setOpen}
                                                setUploadFileId={setUploadFileId}
                                                setPolicyFromType={setPolicyFromType}
                                                setUploadFileName={setUploadFileName}
                                                setUploadFileExtension={setUploadFileExtension}
                                            />
                                        </div>
                                        <PolicyList
                                            listDate={dataList}
                                            policyTypeChange={policyTypeChange}
                                            policyType={policyType}
                                            pagination={pagination}
                                            paginationChange={paginationChange}
                                            operationChange={operationChange}
                                            searchData={searchData}
                                            selectedSignList={selectedSignList}
                                            isPolicySign={true}
                                        />
                                    </React.Fragment>
                                ) : NoneData({})
                            }
                        </div>
                    </Spin>
                </div>
            </div>
        </div>
    )
}

export default PolicySign;
