import React , {useEffect, useState} from 'react';
import { Modal, Button, Form, Input, message, Tooltip} from 'antd'
import { ContainerOutlined, SearchOutlined, FileAddOutlined, UploadOutlined, FieldTimeOutlined } from '@ant-design/icons';
import { useDispatch } from "react-redux";
// import HistoryTopic from '../../../components/AIModal/components/HistoryTopic/index'
// import BackIcon from '../../../components/BackIcon';
// import Api from '../../../api';
import PersonalKnowledge from '../../../../components/PersonalKnowledge';
import PublicKnowledge from '../../../../components/PublicKnowledge';
import UploadFile from "../../../personalCenter/components/UploadFile/index";
import SelfDesignModal from "../../../../components/SelfDesignModal"; 
import {fileLimitType} from "../../../../utils/defaultValue"
import {zuStandStore} from "../../../../zustand";
import { setAIModalState } from "../../../../features/AIIntelligent/AIReducer";

import './index.scss';
import Title from 'antd/lib/skeleton/Title';



const AddFiles = ({calbackIds}) => {
    const dispatch = useDispatch();
    const uploadState = zuStandStore((state) => state);
    const changeUploadStateFunc = uploadState.changeUploadStateFunc;

    const [personalknowledgeOpen, setPersonalknowledgeOpen] = useState(false);
    const [publicknowledgeOpen, setPublicknowledgeOpen] = useState(false);
    const [searchValue, setSearchValue] = useState('');

    const [fileModalIsShow, setFileModalIsShow] = useState(false);
    const [fileLists, setFileLists] = useState([]);

    const [themeItemKey, setThemeItemKey] = useState('public');
    const items = [
        {title:"从公共知识库添加",key:"public"},
        {title:"从个人知识库添加",key:"personal"},
        {title:"上传本地文档",key:"upload"}
    ]
    
    const themeChange = (key) => {
        if(key === 'personal'){
            setPersonalknowledgeOpen(true)
        }else if(key === 'public') {
            setPublicknowledgeOpen(true)
        }else if(key === 'upload'){
            // changeUploadStateFunc({
            //     typesOfUploadedFiles:"parsingFiles",
            // });
            // setFileModalIsShow(true);
            dispatch(setAIModalState({
                uploadModalOpen:true,
                uploadModalType:'createCv',
            }))
        }
    }

    const addKnowledgeF = (ids) =>{
        calbackIds(ids)
    }

    const selectFilesChangeFunc = (files, e) => {
        setFileModalIsShow(true);
        e.target.value = "";
        setTimeout(()=>{
            setFileLists(files);
        },500)
    }

    return (<div className='addFilesFrame'>
        <div className='centerText'>
            <div className='fileIcon'>
                <ContainerOutlined />
            </div>
            <div>
                <p>文档阅读助手</p>
                <p className='describe'>添加各类文档，分析文档中的关键内容信息</p>
            </div>
        </div>

        {/* <Button type="primary" onClick={() => themeChange("public")}>从公共知识库添加</Button> */}
            {/* <Button type="primary" onClick={() => themeChange("personal")}>从个人知识库添加</Button> */}

        <div className='tabsBox'>
            {items.map(item=>{
                return <div onClick={()=>{setThemeItemKey(item.key)}} className={`themeItem ${themeItemKey === item.key ? "themeItemSelete" : ""}`} key={item.key}>
                    {item.title}
                    <Tooltip title="查看历史上传记录">
                        <span className='themeItemIcon' onClick={(e)=>{
                            e.stopPropagation();
                            e.preventDefault();
                            window.open("/personalCenter/mySpace");
                        }}>
                            {themeItemKey === 'upload' && item.key === 'upload' && <FieldTimeOutlined />}
                        </span>
                    </Tooltip>
                </div>
            })}
        </div>
        <div className='footerBtn'>
            {themeItemKey === 'public' ? <Input 
                suffix={<SearchOutlined onClick={() => themeChange("public")}/>} 
                placeholder='输入内容进行搜索' 
                value={searchValue} 
                onChange={(e)=>{setSearchValue(e.target.value)}}
                onPressEnter={() => themeChange("public")}
            />: themeItemKey === "personal" ?
            <div className='addFileBox' onClick={() => themeChange("personal")}>
                <div className='addFileIcon'><FileAddOutlined /></div>
                <p>点击添加文件</p>
            </div>: <div className='uploadFileBox' onClick={(e) => {
                e?.preventDefault();
                themeChange("upload")
            }}>
                <div className='addFileIcon'><UploadOutlined /></div>
                <p>点击上传或将文件拖拽到这里</p>
                <input
                    onDrop={(e, a) => {
                        e?.preventDefault();
                        const files = e.dataTransfer.files;
                        selectFilesChangeFunc(files, e)
                    }}
                    id="fileInput3"
                    type="file"
                    multiple={true}
                    accept={fileLimitType.join(",")}
                    onChange={(e) => selectFilesChangeFunc(e.target.files, e)}
                />
            </div>}
        
        </div>

        {themeItemKey === 'public' ? 
        <div className='tips'>* 公共知识库指已经在知识库中公开发布的内容</div>:
        themeItemKey === 'personal' ?
        <div className='tips'>
            <p>*个人知识库包括:</p>
            <p> 1、已上传的本地知识；</p>
            <p>2、个人收藏夹中的知识；</p>
            <p>3、个人上传并成功公开发布的知识；</p>
            <p>4、作为作者创作，且已被公开发布的知识。</p>
        </div>:<div className='tips'>
            <p>*上传文档要求:</p> 
            <p>文档格式：支持 PDF/ Word / Excel / PPT / ZIP ；</p>
            {/* <p>2、文档大小：文件最大支持100M，图片最大支持20M；</p> */}
            {/* <p>3、文档页数：PDF/Word最多支持1000页；</p> */}
        </div>}

        {personalknowledgeOpen&&<PersonalKnowledge 
            open={personalknowledgeOpen} 
            cancelModal={()=>setPersonalknowledgeOpen(false)}
            addKnowledgeF={addKnowledgeF}
        />}

        {publicknowledgeOpen&&<PublicKnowledge
            defaultValue={searchValue}
            open={publicknowledgeOpen} 
            cancelModal={()=>setPublicknowledgeOpen(false)}
            addKnowledgeF={addKnowledgeF}
        />}


        {/* 文件上传 */}
        <SelfDesignModal
            open={fileModalIsShow}
            centered={true}
            closeIcon={false}
            closable={false}
            maskClosable={false}
            wrapClassName={"uploadFileModalBox"}
            destroyOnClose={true}
            content={(
                <UploadFile
                    isChoiceFile={true}
                    isSelected={false}
                    onCancel={() => {
                        setFileModalIsShow(false);
                    }}
                    uploadType={"parsingFiles"}
                    defaultFileLists={fileLists}
                />
            )}
        />
    </div>)
}

export default AddFiles