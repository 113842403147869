import React, { useState, useEffect} from 'react';
import {useHistory} from "react-router-dom";
import {Select, Input, Button, Tooltip} from 'antd';
import { useDispatch } from "react-redux";

import storage from "../../../../../utils/storage";
import {serachType} from '../../../../../utils/defaultValue'
import Iconfont from "../../../../../components/Iconfont/Iconfont";
import { setAIModalState } from "../../../../../features/AIIntelligent/AIReducer";
import './index.scss';

const Search = ({}) => {
    const [title] = useState();
    let history = useHistory();
    const dispatch = useDispatch();

    const [selecthValue, setSelecthValue] = useState('1');

    const [searchValue, setSearchValue] = useState('');

    const onSearchValueChange = (e) => {
        setSearchValue(e.target.value)
    }

    const onSelect = (value) => {
        setSelecthValue(value);
    }

    const onSearch = () => {
        let searchValueTrim = searchValue.trim()
        storage.setItem('searchData', JSON.stringify({
            text:searchValueTrim,
            searchType:selecthValue
        }));
        history.push('/page/knowledgeSearch');
    }

    const onAISearch = () => {
        let searchValueTrim = searchValue.trim();

        dispatch(setAIModalState({
            modalOpen:true,
            questionLocation: 2,
            relQuestions: 2,
            knowledgeEsParam: {},
            selectKnowledgeParam:{},
            knowledgePublishedParam:{},
            questionModule:"summary",
            knowledgeIds:[],
            sendAPI: Date.now().toString(),
            queryText: searchValueTrim || "推荐问题"
        }))
    }

    return (
        <div className="searchFrame">
            <div className="searchBar">
                <Select 
                    value={selecthValue || '全文'}
                    size={"large"} 
                    options={[...serachType]}  
                    onChange={onSelect}
                    bordered={false}
                    style={{width:100}}>
                </Select>
                <Input
                    value={searchValue}
                    placeholder="输入关键字"
                    size={"large"}
                    bordered={false}
                    onChange={onSearchValueChange}
                    onPressEnter={onSearch}
                />
                <Button 
                onClick={onSearch} 
                type="primary" 
                size={"large"}>搜索一下</Button>
            </div>
            {/* <Tooltip 
                placement="top" 
                title={"AI帮我找"}
                getPopupContainer={() => document.getElementById(`homePageSearch`)}
            > */}
                <div title={"AI帮我找"} id="homePageSearch" className='homePageSearch' onClick={onAISearch}>
                    <Iconfont type="icon-waixingren"/>
                </div>
            {/* </Tooltip> */}
        </div>
    )
}

export default Search;
