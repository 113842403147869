import React, { useEffect, useState, useRef } from "react";
import { Typography, Tooltip, Space, Button, Input, Popconfirm } from "antd";
import { useHistory } from "react-router-dom";

import SelfTable from "../../../../components/SelfTable/index"
import BackIcon from "../../../../components/BackIcon/index";
import Iconfont from "../../../../components/Iconfont/Iconfont";
const { Title } = Typography;
const { Search } = Input;

const srtles = {
    top: '9px',
    color: '#1890ff',
    fontWeight: '600',
    cursor: 'pointer',
    fontSize: '25px',
    top: 0
}

const FileList = ({
    knowledgeList,
    selectedRowKey,
    setSelectedRowKey,
    onOperation,
    opreationDisable
}) => {

    const history = useHistory();

    const columns = [{
        title: '报告名称',
        dataIndex: 'fileName',
        render: (text) => <a>{text}</a>,
      },
      {
        title: '操作',
        dataIndex: 'operation',
        width:'120px',
        render: (_, record, index) => {
            return <Space>
            <Typography.Link onClick={()=>onOperation('reviseOneByOne',record, index)}>
                修改
            </Typography.Link>
            <Popconfirm title="确定发布这条知识吗？" onConfirm={()=>onOperation('releaseOne',record)}>
                <Typography.Link >
                    发布
                </Typography.Link>
            </Popconfirm>
            
        </Space>
        },
    },];

    const onSearch = (value) => {
        console.log(value);

        // knowledgeList.map(item=>{

        // })
    };

    const goBack = () => {
        // const url = sessionStorage.getItem("shareBeforePath");
        // if (url) {
            location.replace("/personalCenter/mySpace");
        // }else{
        //     history.go(-1);
        // }
    }

    return <div className="fileListFrame">
        <div className="topTitle">
            <div className="searchBox">
                <Title level={5}>批量操作文件列表</Title>
                <Search
                    placeholder="输入报告名称搜素"
                    onSearch={onSearch}
                    style={{
                        width: 200,
                    }}
                />
            </div>
            <Space>
                <Button disabled={opreationDisable} onClick={()=>onOperation('batchModify')}>批量修改</Button>
                <Button disabled={opreationDisable} onClick={()=>onOperation('reviseOneByOne')}>逐篇修改</Button>
                <Button disabled={opreationDisable} onClick={()=>onOperation('batchRelease')}type="primary">批量发布</Button>
                <Tooltip 
                    placement={"top"} 
                    title={"返回"}
                >
                    <Iconfont 
                        type={"icon-fanhui"} 
                        onClick={goBack}
                        iStyle={srtles}
                    />
                </Tooltip>
            </Space>
        </div>
        <SelfTable
            rowKey="knowledgeId"
            rowRadioSelection={{
                type: 'checkbox',
                selectedRowKey:selectedRowKey,
                setSelectedRowKey:setSelectedRowKey,
            }}
            dataSource={knowledgeList}
            columns={columns}
        />
    </div>;
}
export default FileList;