import React, { useState, useEffect } from 'react';
import { Form, Input, InputNumber, Select, Modal, message, Tooltip } from 'antd';
import Api from "../../../../api";
import Iconfont from "../../../../components/Iconfont/Iconfont";
import ResizeTable from "../../../../components/ResizeTable";
import {getSizeText, responseDataFilter} from "../../../../utils/tool";
import './index.scss';

const UserSpaceList = () => {
    const [form] = Form.useForm();
    const columns = [
        {
            title: "序号",
            key: "idx",
            dataIndex: "idx",
            align: "center",
            width: 60,
            minWidth: 60,
            disableResize: true,
            render: (val) => {
                return val || "-";
            },
        },
        {
            title: "用户工号",
            key: "jobNum",
            dataIndex: "jobNum",
            align: "center",
            minWidth: 100,
            render: (val) => {
                return val || "-";
            },
        },
        {
            title: "用户名称",
            key: "userName",
            dataIndex: "userName",
            align: "center",
            minWidth: 100,
            render: (val) => {
                return val || "-";
            },
        },
        {
            title: "业务角色",
            key: "businessRoleName",
            dataIndex: "businessRoleName",
            align: "center",
            minWidth: 100,
            render: (val) => {
                return val || "-";
            },
        },
        {
            title: "文件数量",
            key: "initNum",
            dataIndex: "initNum",
            align: "center",
            minWidth: 100,
            render: (val, record) => {
                const {
                    initNum=0,otherNum=0,useNum=0,totalNum=0,
                } = record;
                const total = initNum + otherNum;
                const isDanger = (total - useNum) / (total) <= 0.2;
                let list = [
                    {label: "初始数量", value: initNum},
                    {label: "额外数量", value: otherNum},
                    {label: "使用数量", value: useNum},
                    {label: "剩余数量", value: total - useNum, className: isDanger ? "danger" : "safe"},
                ];
                return (
                    <div className="userSpaceInfoBox">
                        {
                            list.map((item, idx) => {
                                return (
                                    <div className={`infoItem ${item.className}`} key={idx}>
                                        <span className="labelText">{item.label}：</span>
                                        <span className="infoText">{item.value} 个</span>
                                    </div>
                                )
                            })
                        }
                    </div>
                );
            },
        },
        {
            title: "空间容量",
            key: "intiCapacity",
            dataIndex: "intiCapacity",
            align: "center",
            minWidth: 100,
            render: (val, record) => {
                const {
                    initCapacity=0,otherCapacity=0,useCapacity=0,totalCapacity=0,
                } = record;
                const total = initCapacity + otherCapacity;
                const isDanger = (total - useCapacity) / (total) <= 0.2;
                let list = [
                    {label: "初始容量", value: initCapacity},
                    {label: "额外容量", value: otherCapacity},
                    {label: "使用容量", value: useCapacity},
                    {label: "剩余容量", value: total - useCapacity, className: isDanger ? "danger" : "safe"},
                ];
                return (
                    <div className="userSpaceInfoBox">
                        {
                            list.map((item, idx) => {
                                return (
                                    <div className={`infoItem ${item.className}`} key={idx}>
                                        <span className="labelText">{item.label}：</span>
                                        <span className="infoText">{getSizeText(item.value, "Mb")}</span>
                                    </div>
                                )
                            })
                        }
                    </div>
                );
            },
        },
        {
            title: "操作",
            key: "operate",
            dataIndex: "operate",
            align: "center",
            disableResize: true,
            width: 100,
            render: (val, record) => {
                return (
                    <div className="operateBox">
                        <Tooltip title={"调整"} placement={"top"}>
                            <span
                                className="operateBtn editBtn"
                                onClick={() => {
                                    setEditData(record);
                                    form.setFieldsValue({
                                        ...record,
                                    });
                                    setIsModalOpen(true);
                                }}
                            ><Iconfont type="icon-bianji2"/></span>
                        </Tooltip>
                    </div>
                )
            },
        },
    ];

    const [userName, setUserName] = useState(""); // 搜索值 - 用户名称
    const [jobNum, setJobNum] = useState(""); // 搜索值 - 工号
    const [listData, setListData] = useState([]); // 列表数据
    const [total, setTotal] = useState(0); // 列表数据总条数
    const [pageSize, setPageSize] = useState(15);
    const [currentPage, setCurrentPage] = useState(1);
    const [isModalOpen, setIsModalOpen] = useState(false); // 编辑弹窗是否显示
    const [confirmLoading, setConfirmLoading] = useState(false); // 是否保存中
    const [editData, setEditData] = useState({}); // 编辑数据

    // 获取数据列表
    const getDataList = async () => {
        const res = await Api.CloudSpace.getUserSpaceList({
            data: {
                pageIndex: currentPage,
                pageSize: pageSize,
                userName: userName,
                jobNum: jobNum,
            },
            loading: true,
            loadingText: "数据加载中1..."
        });

        const resData = responseDataFilter(res);
        if (resData) {
            let dataList = (resData.records || []).map((item, idx) => {
                return {
                    ...item,
                    idx: (currentPage - 1) * pageSize + (idx + 1),
                }
            })
            setListData(dataList);
            setTotal(resData.total);
        }
    }

    // 编辑数据弹窗确认回调
    const handleOk = async () => {
        try {
            const row = await form.validateFields();
            setConfirmLoading(true);
            const res = await Api.CloudSpace.updateUserSpace({
                data: {
                    otherCapacity: row.otherCapacity,
                    otherNum: row.otherNum,
                    userId: editData.userId,
                },
            });

            const resData = responseDataFilter(res);
            if (resData) {
                message.success("修改成功!");
                handleCancel();
                getDataList();
            }
        } catch (e) {
            console.log(e);
        }
    }

    // 编辑数据弹窗取消回调
    const handleCancel = () => {
        setConfirmLoading(false);
        setIsModalOpen(false);
        setEditData({});
        form.setFieldsValue({});
    }

    useEffect(() => {
        getDataList();
    }, [pageSize, currentPage]);

    return (
        <div className="userSpaceListFrame">
            <div className="searchBox">
                <div className="searchItemBox">
                    <span>工号：</span>
                    <Input
                        value={jobNum}
                        placeholder={`输入工号`}
                        style={{width: 200}}
                        onChange={(e) => setJobNum(e.target.value)}
                        onPressEnter={() => {
                            if(currentPage === 1){
                                getDataList();
                            }else{
                                setCurrentPage(1);
                            }
                        }}
                    />
                </div>
                <div className="searchItemBox">
                    <span>用户名称：</span>
                    <Input
                        value={userName}
                        placeholder={`输入用户名称`}
                        style={{width: 200}}
                        onChange={(e) => setUserName(e.target.value)}
                        onPressEnter={() => {
                            if(currentPage === 1){
                                getDataList();
                            }else{
                                setCurrentPage(1);
                            }
                        }}
                    />
                </div>
                <span
                    className="searchBtn"
                    onClick={() => {
                        if(currentPage === 1){
                            getDataList();
                        }else{
                            setCurrentPage(1);
                        }
                    }}
                ><Iconfont type={"icon-search"} /> 搜索</span>
            </div>
            <div className="tableContentBox" id={"tableContentBox"}>
                <ResizeTable
                    columns={columns}
                    dataSource={listData}
                    tableBoxId="tableContentBox"
                    computedWidth={false}
                    pagination={{
                        size: 'small',
                        hideOnSinglePage: true,
                        pageSizeOptions: ['15', '30', '60', '100'],
                        pageSize: pageSize,
                        current: currentPage,
                        total,
                        showTotal: (num) => `总计 ${num} 条`,
                        onChange: (pageNum, pageSize) => {
                            setCurrentPage(pageNum);
                            setPageSize(pageSize);
                        },
                    }}
                />
            </div>

            <Modal
                title={"修改用户空间容量" }
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
                centered={true}
                maskClosable={false}
                destroyOnClose={true}
                closable={!confirmLoading}
                confirmLoading={confirmLoading}
                wrapClassName={"editUserSpaceModalBox"}
                width={500}
            >
                <Form
                    form={form}
                    component={false}
                    labelCol={{ span: 6 }}
                    wrapperCol={{ span: 18 }}
                    preserve={false}
                >
                    <Form.Item
                        key={'userName'}
                        label={'用户名称'}
                        name={'userName'}
                    >
                        <span>{editData.userName}</span>
                    </Form.Item>
                    <Form.Item
                        key={'initNum'}
                        label={'初始文件数量'}
                        name={'initNum'}
                    >
                        <span>{editData.initNum} 个</span>
                    </Form.Item>
                    <Form.Item
                        key={'initCapacity'}
                        label={'初始空间容量'}
                        name={'initCapacity'}
                    >
                        <span>{getSizeText(editData.initCapacity, "Mb")}</span>
                    </Form.Item>
                    <Form.Item
                        key={'otherNum'}
                        label={'额外文件数量'}
                        name={'otherNum'}
                        rules={
                            [
                                {
                                    required:true,
                                    message: `输入额外文件数量!`
                                }
                            ]
                        }
                    >
                        <InputNumber min={0} addonAfter={'个'} />
                    </Form.Item>
                    <Form.Item
                        key={'otherCapacity'}
                        label={'额外空间容量'}
                        name={'otherCapacity'}
                        rules={
                            [
                                {
                                    required:true,
                                    message: `输入额外空间容量!`
                                }
                            ]
                        }
                    >
                        <InputNumber min={0} addonAfter={'Mb'} />
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    );
}
export default UserSpaceList;
