import myAxios from '../utils/request.js';
import callback from "../utils/callback.js";

export default {
    // 根据时间段查询所有用户ID及名称
    async getUserByTime (params={}, cb) {
        let res = await myAxios.postJson('/logManage/getUserByTime', params);
        return res.data;
    },

    // 获取用户列表
    async getUserList (params={}, cb) {
        let res = await myAxios.postJson('/sysUsers/select', params);
        return res.data;
    },

    // 获取所有状态为正常的用户id及名称
    async getAllUsersList (params={}, cb) {
        let res = await myAxios.getJson('/sysUsers/getUserIdAndName', params);
        return res.data;
    },
    // 获取所有状态为正常的用户id、名称、部门、职务
    async getUserListAll (params={}, cb) {
        let res = await myAxios.getJson('/sysUsers/getUserDepartmentJobById', params);
        return res.data;
    },
    // 根据条件分页查询用户及业务角色
    async getUserBusinessList (params={}, cb) {
        let res = await myAxios.postJson('/sysUsers/selectUserBusinessRole', params);
        return res.data;
    },

    // 新增用户
    async addUser (params={}, cb) {
        let res = await myAxios.postJson('/sysUsers/insert', params);
        return res.data;
    },

    // 修改用户
    async updateUser (params={}, cb) {
        let res = await myAxios.postJson('/sysUsers/update', params);
        return res.data;
    },

    // 修改用户头像 
    async editAvatar (params={}, cb) {
        let res = await myAxios.getJson('/sysUsers/editAvatar', params);
        return res.data;
    },

    // --------------------------------------


    // 日志列表 
    async getLogPage (params={}, cb) {
        let res = await myAxios.postJson('/logManage/selectPage', params);
        return res.data;
    },

    // 重置密码
    async resetUserPassword (params={}, cb) {
        let res = await myAxios.getJson('/sysUsers/resetById', params);
        return res.data;
    },

     // 导出日志
    async exportLog (params={}, cb) {
        let res = await myAxios.downloadJson('/logManage/exportLog', params);
        return res.data;
    },
    
};