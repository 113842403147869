import React from 'react'
import PropTypes from 'prop-types';
import './index.scss'
import Iconfont from "../Iconfont/Iconfont";

class ModelNav extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    componentDidMount () {}

    // 定位
    toPosition (positionId) {
        const {
            scrollDomId,
            positionPrefix='',
        } = this.props;
        const scrollDom = document.getElementById(scrollDomId)
        let positionDom = document.getElementById(positionPrefix + positionId)
        if (scrollDom && positionDom) {
            scrollDom.scrollTop = positionDom.offsetTop
        }
    }

    render () {
        const {
            navList = []
        } = this.props;

        return (
            <div className="modelNavFrame" style={{display: navList.length > 1 ? '' : 'none'}}>
                <div>
                    {
                        navList.map((item ,idx) => {
                            return (<span key={idx} onClick={() => this.toPosition(item.id)}><Iconfont type={"icon-book"} />{item.name}</span>)
                        })
                    }
                </div>
            </div>
        )
    }
}

ModelNav.propTypes = {
    scrollDomId: PropTypes.string.isRequired,
    navList: PropTypes.array.isRequired,
    positionPrefix: PropTypes.string,
}

export default ModelNav