import React, { useState, useEffect, useRef } from "react";
import {
  Button,
  DatePicker,
  Drawer,
  Form,
  Input,
  Space,
  Radio,
  message,
} from "antd";
import moment from "moment";

import Iconfont from "../../Iconfont/Iconfont";
import { ModalConfirm } from "../../../utils/tool";
import Api from "../../../api";
import "./index.scss";

const { TextArea } = Input;
let jsSdk = null;
const UploadDrawer = ({
  open,
  setOpen,
  uploadFileId,
  selectedTheme,
  policyFromType,
  uploadFileName,
  uploadFileExtension,
  // 站内搜索获取到的数据
  formData,
  getPolicyList,
  setPolicyFromType,
  setToEditFormData,
  onCloseSearchModal,
  treeName,
  // 手动上传财务政策文件接口函数
  uploadPolicyFunc,
}) => {

  const containerRef = useRef();
  const [form] = Form.useForm();
  const [policyLevelList, setPolicyLevelList] = useState([]);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [jsSDK, setJsSDK] = useState(null);
  const [editFormData, setEditFormData] = useState(formData);
  const [policyDigest, setPolicyDigest] = useState(null);
  const [isCopyDigest, setIsCopyDigest] = useState(false);

  const [isDisabled, setIsDisabled] = useState(false);

  let WPSAPP = null;

  useEffect(() => {
    if (open) {
      if(jsSdk){
        // 这个销毁没有生效
        // jsSDK.destroy();
        document.querySelector(".custom-mount").innerHTML = "";
      }
      sessionStorage.addPolicyData = JSON.stringify({
        open:open,
        uploadFileId:uploadFileId,
        selectedTheme:selectedTheme,
        policyFromType:policyFromType,
        uploadFileName:uploadFileName,
        uploadFileExtension:uploadFileExtension,
        formData:formData,
        treeName:treeName
      })
    }
  }, [open, policyFromType]);

  useEffect(() => {
    if (formData) {
      setEditFormData(formData);
    }
  }, [formData, open]);

  useEffect(() => {
    if (open) {
      getPolicyLevelList();
      if (policyFromType === "upload") {
        form.setFieldsValue({ policyTitle: uploadFileName });
      } else {
        if (editFormData) {
          // 爬虫数据除了摘录不能做任何修改
          if(editFormData.policySourceType === 0){
            setIsDisabled(true)
          }

          let _editFormData = { ...editFormData };
          _editFormData.publicationTime = moment(
            editFormData.publicationTime,
            "YYYY-MM-DD"
          );
          form.setFieldsValue(_editFormData);
          if (editFormData.relationFileId) {
            getWPSFileUrl(editFormData.relationFileId);
          }
        }
      }
    } else {
      form.resetFields();
      setEditFormData(null);
      setToEditFormData(null);
      setPolicyFromType(null);
      if (jsSDK && jsSDK.destroy) {
        jsSDK.destroy();
        setJsSDK(null);
      }
    }
  }, [open, editFormData, policyFromType]);

  useEffect(() => {
    if (open && policyFromType === "upload") {
      if (uploadFileId && containerRef) {
        getWPSFileUrl(uploadFileId);
      }
    }
  }, [uploadFileId, containerRef, open, policyFromType]);

  useEffect(() => {
    if (open && editFormData?.titleHref && editFormData?.policyMainBody) {
      var o = document.getElementById("editFormData");
      if(o){
        let ed = document.all ? o.contentWindow.document : o.contentDocument;
        ed.open();
        ed.write(editFormData.policyMainBody);
        ed.close();
      }
    }
  }, [editFormData, open]);


  const getPolicyLevelList = async () => {
    try {
      let res = await Api.Knowledge.getPolicyRegionList();
      if (res.code == 0 && res.data) {
        setPolicyLevelList(res.data);
      }
    } catch (error) {}
  };
  const disabledDate = (current) => {
    // 获取当前日期
    const currentDate = moment();
    // 将 current 和 currentDate 转换为只包含日期的 moment 对象，忽略时间部分
    const currentDay = moment(current).startOf("day");
    const currentDayAfter = currentDate.startOf("day");
    // 禁用当前日期之后的日期
    return currentDay.isAfter(currentDayAfter);
  };

  const onFinish = async (values) => {
    setSubmitLoading(true);
    values.policyThemeId = selectedTheme.id;
    values.departmentId = selectedTheme.departmentId;
    values.policyRegionId = parseInt(values.policyRegionId);
    values.publicationTime = moment().format("YYYY-MM-DD");
    // 全文有效还是失效，0全文有效
    values.isEffective = 0;
    // 用来判断是不是新增
    if (policyFromType === "upload") {
      values.relationFileId = uploadFileId;
      try {
        let func = Api.Knowledge.addNewPolicy;
        if (uploadPolicyFunc) {
          func = uploadPolicyFunc;
        }
        let res = await func({
          data: values,
        });
        if (res.data && res.code == 0) {
          sessionStorage.removeItem("addPolicyData");
          setSubmitLoading(false);
          setOpen(false);
          setIsDisabled(false);
          onCloseSearchModal();
          getPolicyList();
          message.success(res.msg);
        }
      } catch (error) {}
    }
    // 不是新增调保存接口 policyFromType == 2-站内搜索
    else {
      values.policySourceType = editFormData.policySourceType;
      values.id = editFormData.elasticId || editFormData.policyId;
      if (editFormData.titleHref) {
        values.titleHref = editFormData.titleHref;
      }
      if (editFormData.relationFileId) {
        values.relationFileId = editFormData.relationFileId;
      }
      if (editFormData.fileType) {
        values.fileType = editFormData.fileType;
      }
      if (policyFromType == 2) {
        values.policySourceType = 2;
      }
      try {
        let res = await Api.Knowledge.editPolicy({
          data: values,
        });
        if (res.data && res.code == 0) {
          sessionStorage.removeItem("addPolicyData");
          setSubmitLoading(false);
          setOpen(false);
          setIsDisabled(false);
          onCloseSearchModal();
          getPolicyList();
          message.success(res.msg);
        }
      } catch (error) {}
    }
    setSubmitLoading(false);
  };
  const onClose = () => {
    ModalConfirm({
      title: "您编辑的数据还没有保存,确认离开吗？",
      onOk: async (resolve, reject) => {
        sessionStorage.removeItem("addPolicyData");
        setOpen(false);
        setIsDisabled(false);
        resolve();
      },
    });
  };
  const handeleOk = () => {
    form.submit();
  };
  // 获取WPS内容
  const getWPSFileUrl = async (fileId) => {

    try {
      let res = await Api.Knowledge.getWPSFilePreview({
        fileId,
      });
      if (res && res.code === 200 && res.data) {
        const container = containerRef.current;
        const data = JSON.stringify({
          tagName: container.tagName,
          className: container.className,
          // 在这里可以提取其他需要的HTML属性
        });

        jsSdk = OpenSDK.config({
          url: res.data.link,
          mount: document.querySelector(".custom-mount"),
        })

        setJsSDK(jsSdk);

        if (jsSdk.iframe) {
          jsSdk.iframe.width = "100%";
          jsSdk.iframe.height = "100%";
        }

        // 等待加载完毕
        await jsSdk.ready();

        // 监听选区变化事件
        WPSAPP = jsSdk.Application;


        let _fileType = "";
        if(policyFromType === "upload"){
          _fileType = uploadFileExtension
        }else{
          if(formData && formData.fileType){
            _fileType = formData.fileType
          }else if(editFormData && editFormData.fileType){
            _fileType = editFormData.fileType
          }
        }


        if(_fileType === 'doc' || _fileType === 'docx' || _fileType === 'docm'){
          wordMethod(jsSdk,WPSAPP)
        }else if(_fileType === 'pdf'){
          pdfMethod(jsSdk)
        }
        // else if(_fileType === 'ppt' || _fileType === 'pptx'){
        //     pptMethod(WPSSDK,WPSAPP)
        // }else if(_fileType === 'xls' || _fileType === 'xlsx'){
        //     excleMethod(WPSSDK,WPSAPP)
        // }

      }
    } catch (error) {}
  };


  const wordMethod = async(jsSdk, WPSAPP) => {
    // Word
    // 监听选区变化事件
    jsSdk.ApiEvent.AddApiEventListener("WindowSelectionChange", async(data) => {
        if(data.begin != data.end){
            // 获取选中区域
            const range = await WPSAPP.ActiveDocument.Range(data.begin, data.end);
            // 获取文本
            const text = await range.Text;

            setPolicyDigest(text);
            setIsCopyDigest(true);
        }else{
          setIsCopyDigest(false);
        }
    });
  }

  const pdfMethod = (jsSdk) => {
    // 监听选区变化事件
    jsSdk.ApiEvent.AddApiEventListener("TextSelectChange", async(data) => {
        if((data.x != data.y) && data.hasSelection){
            //获取选区文本
            const selectionText = await WPSAPP.ActivePDF.GetTextSelection();
            setPolicyDigest(selectionText);
            setIsCopyDigest(true);
        }else{
          setIsCopyDigest(false);
        }
    });
  }


  const copyDigest = () => {
    form.setFieldsValue({ policyDigest: policyDigest });
    setIsCopyDigest(false);
    setPolicyDigest(null);
  };
  return (
    <div className="uploadDrawerFrame">
      <Drawer
        title={policyFromType === "upload"?"新增政策":"修改政策"}
        width={"100%"}
        onClose={onClose}
        open={open}
        bodyStyle={{
          padding: "0",
          position: "relative",
          display: "flex",
          background: "#f0f0f0",
        }}
        extra={
          <Space>
            <Button onClick={onClose}>取消</Button>
            <Button onClick={handeleOk} type="primary" loading={submitLoading}>
              完成
            </Button>
          </Space>
        }
        className="uploadDrawerBox"
      >
        <div
          className="baseInfo"
          style={{
            height: "100%",
            width: "17rem",
            marginRight: "0.5rem",
            background: "#fff",
            padding: "1.5rem 1.5rem 0 1.5rem",
            overflowY: "scroll",
          }}
        >
          <div
            className="infoTitle"
            style={{
              marginBottom: "1rem",
              paddingLeft: "1rem",
              fontSize: "0.9rem",
            }}
          >
            基本信息
          </div>

          <Form
            layout="horizontal"
            form={form}
            labelCol={{ span: 8 }}
            onFinish={onFinish}
          >
            <Form.Item
              name="policyTitle"
              label="政策名称"
              rules={[
                {
                  required: true,
                  message: "请输入政策名称",
                },
              ]}
            >
              <Input placeholder={isDisabled?"":"请输入政策名称"} disabled={isDisabled} allowClear />
            </Form.Item>

            {
              treeName ? (
                  <div className="showItem">
                    <span className="title">政策主题：</span>
                    <span className="content">{treeName}</span>
                  </div>
              ) : undefined
            }

            <Form.Item
              name="policyInstitution"
              label="发文机构"
            >
              <Input placeholder={isDisabled?"":"请输入发文机构"} disabled={isDisabled} allowClear />
            </Form.Item>
            <Form.Item
              name="publicationTime"
              label="发文时间"
              rules={[
                {
                  required: true,
                  message: "请选择发文时间",
                },
              ]}
            >
              <DatePicker disabledDate={disabledDate}  disabled={isDisabled}/>
            </Form.Item>
            <Form.Item
              name="docNum"
              label="发文字号"
            >
              <Input placeholder={isDisabled?"":"请输入发文字号"} disabled={isDisabled} allowClear />
            </Form.Item>
            <Form.Item
              label="政策级别"
              name="policyRegionId"
              rules={[
                {
                  required: true,
                  message: "请选择政策级别",
                },
              ]}
            >
              <Radio.Group
                disabled={isDisabled}
              >
                {policyLevelList &&
                  policyLevelList.map((item) => {
                    return (
                      <Radio key={item.id} value={item.id}>
                        {item.name}
                      </Radio>
                    );
                  })}
              </Radio.Group>
            </Form.Item>
            <Form.Item
              className="policyDigest"
              name="policyDigest"
              label="主题摘要"
              rules={[
                {
                  required: true,
                  message: "请输入主题摘要",
                },
              ]}
            >
              <TextArea rows={4} placeholder="请输入正文内容摘要" allowClear />
            </Form.Item>
          </Form>
        </div>
        <div
          ref={containerRef}
          className="custom-mount"
          style={{
            flex: "1",
            background: "#fff",
            padding: "1.5rem 1.5rem 0 1.5rem",
            overflow: "hidden",
          }}
        >
          {open && editFormData?.titleHref && editFormData?.policyMainBody ? (
            <iframe
              id="editFormData"
              style={{ width: "100%", height: "98%" }}
            ></iframe>
          ) : null}
        </div>
        {isCopyDigest ? (
          <div
            className="oprModal"
            style={{
              position: "absolute",
              left: "55%",
              top: "40%",
              background: "#fff",
              padding: "1rem",
              borderRadius: '8px',
              boxShadow: '0 0 10px #000000'
            }}
          >
            <Button
              style={{ display: "flex", alignItems: "center" }}
              type="primary"
              ghost
              onClick={copyDigest}
            >
              <Iconfont
                iStyle={{ marginRight: "0.5rem" }}
                type={"icon-zhailu"}
              />
              摘录
            </Button>
          </div>
        ) : null}
      </Drawer>
    </div>
  );
};
export default UploadDrawer;
