import React, { useEffect, useState, useRef } from "react";
import { Button, Input, message, Empty,Spin, Space } from "antd";
import Api from "../../../api";
import "./index.scss";
const PolicyIndicator = ({ selectedTheme, tabsActiveKey }) => {
  let jsSdk = null
  const containerRef = useRef(null);
  const inputRef = useRef(null);
  const [isEdit, setIsEdit] = useState();
  const [btnLoading, setBtnLoading] = useState(false);
  const [contentLoading, setContentLoading] = useState(false);
  const [policyIndicatorTitleId, setPolicyIndicatorTitleId] = useState(null);
  const [policyIndicatorTitle, setPolicyIndicatorTitle] = useState(null);
  const [policyOperationFrameDom, setPolicyOperationFrameDom] = useState(
    document.querySelector(".policyOperationFrame").offsetHeight
  );
  const [jsSDK, setJsSDK] = useState(null);
  window.addEventListener("resize", function () {
    // 在窗口缩小或放大时执行的代码
    const policyOperationFrameDom = document.querySelector(
      ".policyOperationFrame"
    ).offsetHeight;
    setPolicyOperationFrameDom(policyOperationFrameDom);
    // console.log(policyOperationFrameDom, "policyOperationFrameDom");
  });
  // useEffect(()=>{
  //   console.log(jsSDK,"-----")
  //   if(jsSDK){
  //     jsSDK.destroy();

  //     document.getElementsByClassName("tableContent")[0].innerHTML = ""
  //   }
  // },[])
  useEffect(() => {
    if (tabsActiveKey === "2" && selectedTheme) {
      getByPolicyThemeId();
      getWPSUrl();
      setIsEdit(false)
    }
  }, [tabsActiveKey, selectedTheme]);
  useEffect(() => {
    if (isEdit) {
      inputRef.current.focus();
    }
  }, [isEdit]);
  useEffect(() => {
    // let rightBoxDom = document.querySelector(".rightContentBox");
    // let rootHeight = policyOperationFrameDom - rightBoxDom.offsetTop;
    // containerRef.current.style.height = `${rootHeight}px`;
    // if (jsSDK?.iframe) {
    //   jsSDK.iframe.style.height = `${rootHeight}px`;
    //   jsSDK.iframe.style.width = `${containerRef.current.offsetWidth}px`;
    // }
  }, [policyOperationFrameDom]);
  // 获取当前政策指标标题
  const getByPolicyThemeId = async () => {
    try {
      let res = await Api.Knowledge.getByPolicyThemeId({
        data: { policyThemeId: selectedTheme.id },
      });
      if (res.data && res.code == 0) {
        setPolicyIndicatorTitle(res.data.name);
        setPolicyIndicatorTitleId(res.data.id);
      } else {
        setPolicyIndicatorTitle(null);
        setPolicyIndicatorTitleId(null);
      }
    } catch (error) {
      console.log(error, "错误信息");
    }
  };
  // 获取wps编辑地址
  const getWPSUrl = async () => {
    setContentLoading(true);
    if(jsSDK){
      // 这个销毁没有生效
      // jsSDK.destroy();
      document.getElementsByClassName("tableContent")[0].innerHTML = ""
    }
    try {
      let res = await Api.Knowledge.getPolicyIndicatorUrl({
        data: { policyThemeId: selectedTheme.id },
      });
      // console.log(res,"获取url结果");
      if (res.code == 0 && res.data) {
        // let url = res.data.link;
        const container = containerRef.current;
        const data = JSON.stringify({
          tagName: container.tagName,
          className: container.className,
          // 在这里可以提取其他需要的HTML属性
        });
        jsSdk = OpenSDK.config({
          url: res.data.data.link,
          mount: document.querySelector(".tableContent"),
        });

        // 等待加载完毕
        await jsSdk.ready();

        if (jsSdk.iframe) {
          jsSdk.iframe.style.width = "100%";
          // jsSdk.iframe.style.height = "100%";
        }
        setJsSDK(jsSdk);
        // console.log(jsSdk.iframe.offsetTop, "iframe节点");
      } else {
        if (jsSDK) {
          setJsSDK(null);
          jsSDK.destroy();
        }
        // containerRef.current
        // message.info("暂未绑定数据，请先添加政策指标标题");
      }
    } catch (error) {
      console.log(error, "错误信息");
    }
    setContentLoading(false);
  };
  const cancel = () => {
    setIsEdit(false);
  };
  const handeleOk = async () => {
    setBtnLoading(true);
    let value = {
      name: policyIndicatorTitle,
      policyThemeId: selectedTheme.id,
    };
    if (policyIndicatorTitleId) {
      value.id = policyIndicatorTitleId;
    }
    try {
      let res = await Api.Knowledge.savePolicyIndicator({ data: value });
      if (res.code === 0 && res.data) {
        getByPolicyThemeId();
        getWPSUrl();
        message.success(res.msg);
      }
    } catch (error) {}
    setBtnLoading(false);
    setIsEdit(false);
  };
  const editName = (e) => {
    setPolicyIndicatorTitle(e.target.value);
  };
  return (
    <div className="policyIndicatorFrame">
      <div className="policyIndicatorTitle">
        <div className="policyIndicatorName">
          {!isEdit && policyIndicatorTitle ? (
            <div className="name">{policyIndicatorTitle}</div>
          ) : (
            <Input
              ref={inputRef}
              placeholder="点击编辑，添加政策指标标题"
              disabled={!isEdit}
              onChange={editName}
              value={policyIndicatorTitle}
            />
          )}
        </div>
        <div className="operate">
          {isEdit ? (
            <Space>
              <Button onClick={cancel}>取消</Button>
              <Button type="primary" onClick={handeleOk} loading={btnLoading}>
                保存
              </Button>
            </Space>
          ) : (
            <Button
              type="primary"
              onClick={() => {
                setIsEdit(true);
              }}
            >
              编辑
            </Button>
          )}
        </div>
      </div>
      <div className="tableContent" ref={containerRef}>
        {/* <Empty
          description="暂未绑定数据，请先添加政策指标标题"
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          style={{ display: !jsSDK && !contentLoading ? "block" : "none" }}
        /> */}
        {/* <Spin size="large" spinning={contentLoading} /> */}
      </div>
    </div>
  );
};

export default PolicyIndicator;
