import React, { useState, useEffect } from 'react';
import { Tree, Button, Empty, Tooltip } from 'antd';
import { CaretDownOutlined, FileTextOutlined, FolderOpenOutlined, FolderOutlined,} from '@ant-design/icons';
import Iconfont from "../../../../components/Iconfont/Iconfont";
// import storage from "../../../utils/storage";
import Api from "../../../../api";
import "./index.scss";

let defalutPolicyThemeList = [];
const PolicyTree = ({
    // treeDate,
    changePolicyTree,
    selectedNode,
    type,
    searchData,
    selectedConditionIdList,
    conditionListExpandedKeys = []
}) => {

    const [expandedKeys, setExpandedKeys] = useState([]);

    const conditionSortList = [
        {title: "精选", key: "policy_tax_types", icon: "icon-hangye"},
        {title: "分类", key: "policy_all", icon: "icon-quanbu"},
    ];

    const [choiceConditionSortHide, setChoiceConditionSortHide] = useState(false);
    const [choiceConditionSort, setChoiceConditionSort] = useState('policy_all');
    const [conditionList, setConditionList] = useState([]);
    const [loadingType, setLoadingType] = useState(false);
    const [selected, setSelected] = useState([]);
    const [treeDate, setTreeDate] = useState([]);

    useEffect(() => {
        const node = firstPolicy(treeDate);
        changePolicyTree(node);
    }, [treeDate])


    useEffect(() => {
        // setTreeDate([]);
        // setConditionList([]);
    }, [choiceConditionSort])

    useEffect(()=>{
        const {knowledgeTypeId, departmentId} = searchData || {};
        const isPolicy = String(knowledgeTypeId) === "4";
        // 查询左侧树结构
        if(isPolicy){
            setChoiceConditionSort('policy_all');
            // 政策主题
            if(departmentId){
                // if(choiceConditionSort === "policy_all"){
                    getPolicyeThemeTree(departmentId);
                // }else{
                    getPolicySortData(departmentId);
                // }
                setChoiceConditionSortHide(false);
            }else{
                // 查讯政策公共知识
                getPolicyeThemeTree(-1);
                setChoiceConditionSortHide(true);
            }
        }
    },[searchData.knowledgeTypeId,searchData.departmentId]);

    // 政策主题左侧树形结构
    const getPolicyeThemeTree = async(departmentId) =>{

        setLoadingType(true);
        const res = await Api.Knowledge.getPolicyeThemeList({
            data:{
                departmentId
            }
        });
        setLoadingType(false);
        if(res && res.code === 0 && res.data){
            let _data = res.data;
            // 没有记录选中的政策的时候默认选中第一个
            // 先递归出所有可以选择的政策
            if(!sessionStorage.policyThemeId){
                getFirstPolicyeTheme(_data)
            }

            setTreeDate(_data);
            // 设置选中第一个
            if(defalutPolicyThemeList.length > 0 && !sessionStorage.policyThemeId){
                changePolicyTree(defalutPolicyThemeList[0])
            }

            if(expandedKeys.length > 0){
                setExpandedKeys(expandedKeys);
            }
        }else{
            setTreeDate('');
        }
    };

    // 获取所有的主题
    const getFirstPolicyeTheme = (data) => {
        data.map(item=>{
            if(item.policyThemeType === 1){
                defalutPolicyThemeList.push(item)
                return false;
            }else if(item.children){

                expandedKeys.push(item.id);//获取所有的分类ID

                getFirstPolicyeTheme(item.children)
            }
        })
    }

    // 获取政策分类的过滤条件数据
    const getPolicySortData = async (departmentId) => {
        const res = await Api.Dict.getManyDictDataList({
            data: {
                dictTypes: ["policy_tax_types",],
                departmentId
            },
        });
        const {code, data} = res;
        if (code === 0 && data) {
            let conditionListArray = [];
            data.forEach(item => {
                const {dictType, dictName, sysDictDatas,} = item;
                conditionListArray = sysDictDatas.map(it => {
                    const {dictLabel, dictCode, dictValue} = it;
                    return {
                        title: dictLabel,
                        key: dictCode,
                        isLeaf:false
                    }
                })
            })

            setConditionList(conditionListArray)

            // let newData = await getPolicyYearByDictCodeF(conditionListArray[0].key);
            // setConditionListExpandedKeys([conditionListArray[0].key]);
            // let ids = [];
            //  newData.map((item) => {
            //     ids.push(item.key)
            // })
            // ids.push(conditionListArray[0].key)
            // setSelected(ids);

            // changePolicyTree(ids,"classification", conditionListArray);
            // setConditionList(() =>
            //     updateTreeData(conditionListArray, conditionListArray[0].key, newData),
            // );
        }else{
            setConditionList([])
        }
    };

    // 高亮显示第一个主题节点
    function firstPolicy (list) {
        for (const item of list) {
            if (String(item.policyThemeType) === "1") {
                return item;
            } else if (item.children?.length > 0) {
                return firstPolicy(item.children);
            }
        }
        return undefined;
    }

    const titleRender = (nodeData) => {
        const domId = `nodeId-${nodeData.id}`;
        return (
            <span id={domId} style={{whiteSpace: "pre-wrap", wordBreak: "break-all",}}>{nodeData.policyName}</span>
        );
    }

    const onSelect = (selectedKeys, e) => {
        const node = e.node;
        if (type === "view") {
            if (e.selected) {
                changePolicyTree(node)
            }
        } else {
            changePolicyTree(e.selected ? node : null)
        }
    }


    const onExpand = (expandedKeys, e) => {
        console.log(expandedKeys, e,"expandedKeys, e")
        let id = e.node.id;
        if(e.expanded){
            let _expandedKeys = [...expandedKeys,id]
            setExpandedKeys(_expandedKeys);
        }else{
             let _expandedKeys = expandedKeys.filter((item) => {
                return item != id
            });
            console.log(_expandedKeys,"_expandedKeys")
            setExpandedKeys(_expandedKeys)
        }
    }


    const onTreeSelect = (selectedKeys, info) => {
        console.log('selected', selectedKeys, info);
        setSelected(selectedKeys)
    };
    const onTreeCheck = (checkedKeys, info) => {
        console.log('onCheck', checkedKeys, info);
        setSelected(checkedKeys);
        changePolicyTree(checkedKeys,"classification", conditionList);
    };

    const updateTreeData = (list, key, children) =>
        list.map((node) => {
          if (node.key === key) {
            return {
              ...node,
              children,
            };
          }
          if (node.children) {
            return {
              ...node,
              children: updateTreeData(node.children, key, children),
            };
          }
          return node;
    });

    const onLoadData = ({ key, children }) =>
        new Promise(async(resolve) => {
        if (children) {
            resolve();
            return;
        }
        let newData = await getPolicyYearByDictCodeF(key);
        
        setConditionList((origin) =>
            updateTreeData(origin, key, newData),
        );

        resolve();
    });

    const getPolicyYearByDictCodeF = (key) => {

        return new Promise(async (resolve, reject) => {

            let res = await Api.Dict.getPolicyYearByDictCode({
                data:{dictCode:key}
            })
    
            const {data, code} = res;
            if(code === 0 && data){
                let newData = data.map(item=>{
                    return {
                        title: item,
                        key: `${key}-${item}`,
                        isLeaf:true
                    }
                })
                resolve(newData);
            }else{
                resolve([]);
            }
        });
    }

    const selectedKeys = selectedNode?[selectedNode.id]:[];
    
    return(
        <div className="policyTreeFrame">
            <div className="conditionSortListBox">
                {
                    !choiceConditionSortHide && conditionSortList.map(item => {
                        const {title, key, icon} = item;
                        const isChoice = choiceConditionSort === key;
                        return (
                            <div
                                className={`conditionSortItem ${isChoice ? "choiceStatus" : ""}`}
                                key={key}
                                onClick={() => {
                                    setChoiceConditionSort(key);
                                }}
                            >
                                <Iconfont type={icon} />
                                <span className="conditionSortName">{title}</span>
                            </div>
                        )
                    })
                }
            </div>

            {choiceConditionSort === "policy_tax_types" ? (<div className="conditionListBox" id="conditionListBox">
                {
                    conditionList.length > 0 ? (<Tree
                        checkable
                        defaultExpandedKeys={conditionListExpandedKeys}
                        defaultCheckedKeys={selectedConditionIdList}
                        onCheck={onTreeCheck}
                        treeData={conditionList}
                        loadData={onLoadData}
                      />) : (
                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                    )
                }
            </div>): (choiceConditionSort === "policy_all" && treeDate && treeDate.length > 0) ?
                (<Tree
                    showIcon={true}
                    icon={(item) => {
                        const isTheme = String(item.policyThemeType) === "1";
                        return isTheme ? <FileTextOutlined /> : (item.expanded ? <FolderOpenOutlined /> : <FolderOutlined />);
                    }}
                    switcherIcon={<CaretDownOutlined />}
                    treeData={treeDate}
                    onSelect={onSelect}
                    titleRender={titleRender}
                    selectedKeys={selectedKeys}
                    expandedKeys={[...expandedKeys]}
                    // defaultExpandAll={true}
                    // autoExpandParent={true}
                    onExpand={onExpand}
                    blockNode
                    // draggable={draggable}
                    // onDrop={onDrop}
                    fieldNames={{
                        title:"policyName",
                        key: "id",
                        children:"children",
                        isLeaf:"policyThemeType",
                    }}
                />):
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            }

        </div>
    )
}

export default PolicyTree;
