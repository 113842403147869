import React from 'react';
import {message, DatePicker, Switch, Modal, Input, Select, Tooltip, Cascader} from 'antd';
import Iconfont from "../../../../components/Iconfont/Iconfont";
import Api from "../../../../api/index.js";
// import AddProjectFile from "./addProjectFile";
import moment from 'moment';
import noneData from "../../../../components/NoneData";
import {responseDataFilter} from "../../../../utils/tool";

import './index.scss';

moment.locale('zh-cn');

const {TextArea} = Input;
const PUBLISH = 'publish'
const NO_PUBLISH = 'noPublish'

class Documentation extends React.Component {
    addProjectRef = null
    constructor(props) {
        super(props);
        const that = this;
        that.state = {
            conditionList: [
                {
                    text: '项目地点',
                    type: 'countryName',
                },
                {
                    text: '签约年份',
                    type: 'projectDate',
                },
                {
                    text: '项目阶段',
                    type: 'projectStage',
                },
                // {
                //     text: '业务',
                //     type: 'businessType',
                // },
                // {
                //     text: '建设类型',
                //     type: 'buildingType',
                // },
                // {
                //     text: '开拓/执行',
                //     type: 'openUpType',
                // },
            ],
            activeProjectId: null,
            expandIds: {},
            publishSortType: 'countryName',
            noPublishSortType: 'countryName',
            addProjectData: {},
            stateList: [
                {
                    label: '机会研究/概念设计/规划',
                    value: '机会研究/概念设计/规划',
                },
                {
                    label: '预可研',
                    value: '预可研',
                },
                {
                    label: '可研',
                    value: '可研',
                },
                {
                    label: '其他',
                    value: '其他',
                },
            ],
            taskList: [
                {
                    label: '投资决策',
                    value: '投资决策',
                },
                {
                    label: '系统优化',
                    value: '系统优化',
                },
                {
                    label: '产业咨询',
                    value: '产业咨询',
                },
                {
                    label: '绿色低碳',
                    value: '绿色低碳',
                },
                {
                    label: '市政交通环保',
                    value: '市政交通环保',
                },
                {
                    label: '其他',
                    value: '其他',
                },
            ],
            buildTypeList: [
                {
                    label: '绿地新建',
                    value: '绿地新建',
                },
                {
                    label: '改扩建项目',
                    value: '改扩建项目',
                },
                {
                    label: '其他',
                    value: '其他',
                },
            ],
            exploitList: [
                {
                    label: '仅开拓',
                    value: '仅开拓',
                },
                {
                    label: '执行(含开拓)',
                    value: '执行(含开拓)',
                },
                {
                    label: '其他',
                    value: '其他',
                },
            ],
            projectNameList: [],
            modelList: [],
            belongType: null, // publish/noPublish 标识选中项目属于已发布还是未发布
            enableDelete: false, // 标识选中项目能否删除
            isExpand: true,
            labelGroupList: [],
            choiceLabelList: [],
            labelChoiceVisible: false,
            storageChoiceLabel: [],
        };
    }

    componentWillMount() {
        // this.getLabelGroupList();
        // this.initData();
    }

    componentWillUnmount() {}

    componentDidUpdate(prevProps) {
        const {modalOpen, isEdit} = this.props;
        if (modalOpen !== prevProps.modalOpen && modalOpen) {
            console.log('componentDidUpdate',modalOpen, isEdit);
            this.getLabelGroupList();
            this.initData();
            this.openModal(isEdit);
        }
      }

    // 初始化数据
    async initData () {
        const enableProjectList = await this.getEnableProject();
        const modelList = await this.getModelTreeList();
        this.setState({
            modelList,
            projectNameList: enableProjectList,
        })
    }


    // 获取未发布的项目
    async getUnPublishProject () {
        const res = await Api.Documentation.getUnPublishProjectList();
        return res?.data || []
    }

    // 获取可选择的项目
    async getEnableProject () {
        const res = await Api.Documentation.getEnableProjectList()
        return res?.data || []
    }

    // 获取可选择的模板树
    async getModelTreeList () {
        const res = await Api.OverseasCaseManager.getModelTree()
        return res?.data || []
    }


    // 打开弹窗
    async openModal (project) {
        let newState = {
            choiceLabelList: [],
            storageChoiceLabel: [],
        }
        if (project) {
            let _data = JSON.parse(project.paramJson);
            const tagClassIds = project.tagClassIds;
            const res = await Api.OverseasCaseManager.getSelectOptionsByLabelId({data: {classIds: tagClassIds.join()}, loading: true})
            let choiceLabelList = responseDataFilter(res) || [];
            
            newState.addProjectData = {
                id: _data.projectId,
                name: _data.projectName,
                projectCode: _data.projectCode,
                desc: _data.describe,
                modelId: _data.modelId,
                tagValue: project.tagClassIdMap
            }
            
            newState.choiceLabelList = [...choiceLabelList]
            newState.storageChoiceLabel = [...choiceLabelList];
        } else {
            newState.projectNameList = await this.getEnableProject();
            newState.modelList = await this.getModelTreeList();
            newState.addProjectData = this.initAddProjectData();
        }
        this.setState(newState, () => {
            this.toBottom()
        })
    }

    // 初始化新建项目数据
    initAddProjectData () {
        return {
            name: '',
            id: '',
            projectDesc: '',
            projectCode: '',
            modelId: '',
            modelList: [],
            // isPrivate: true,
            tagValue: {},
        }
    }

    // 确定新建/修改项目
    async createProject () {
        const {addProjectData, noPublishSortType, activeProjectId,  publishSortType} = this.state;
        const {isEdit} = this.props;

        if(!addProjectData.modelId){
            addProjectData.modelId = [...(addProjectData.modelList || [])].pop();
        }
        
        if (!addProjectData.id) {
            return message.warning('项目名称不能为空！')
        }
        if (!addProjectData.modelId) {
            return message.warning('选择模板不能为空！')
        }
        // if (!addProjectData.year) {
        //     return message.warning('签约年份不能为空！')
        // }
        let tagList = [];
        
        for (let tagValueKey in (addProjectData.tagValue || {})) {
            let value = addProjectData.tagValue[tagValueKey];
            if(typeof(value) === 'object'){
                tagList.push(...value);
            }else{
                tagList.push(value);
            }
        }
        const params = {
            data: {
                projectId: addProjectData.id,
                projectName: addProjectData.name,
                projectCode: addProjectData.projectCode,
                describe: addProjectData.desc,
                // privated: addProjectData.isPrivate,
                // projectDate: addProjectData.year,
                // countryName: addProjectData.country,
                // projectStage: addProjectData.stage,
                // buildingType: addProjectData.buildType,
                // businessType: addProjectData.task,
                // openUpType: addProjectData.exploit,
                modelId: addProjectData.modelId,
                tagIdList: tagList,
            }
        }
        let func = Api.Documentation.addProject
        let beforeStr = '新建'
        if (isEdit) {
            params.data.id = isEdit.id;
            func = Api.Documentation.updateOct
            beforeStr = '修改'
        }
        // const projectId = params.data.projectId;
        
        const res = await func(params);
        const data = responseDataFilter(res, `项目${beforeStr}失败`);
        if (data) {
            message.success(`项目${beforeStr}成功`)
            // const newList = await this.getUnPublishProject();
            // let sortData = this.dataSort(noPublishSortType, newList, NO_PUBLISH);
            // let idKey = null;
            // let projectItemId = null;
            // for (let item of sortData) {
            //     const {data, id} = item;
            //     for (let datum of data) {
            //         if (datum.projectId === projectId) {
            //             idKey = id;
            //             projectItemId = datum.id;
            //             break;
            //         }
            //     }
            //     if (idKey) {
            //         break;
            //     }
            // }
            this.props.handleCancel(true);
            
            // if (idKey) {
            //     newState.activeProjectId = projectItemId
            //     newState.expandIds = {
            //         ...this.state.expandIds,
            //         [idKey]: 1,
            //     }
            // }

            // if (isEdit) {
            //     newState.belongType = NO_PUBLISH;
            // }
            // this.setState(newState, () => {
            //     this.addProjectRef?.initData(true)
            // })
        }
    }

    // 取消新建项目
    cancelCreateProject () {
        this.props.handleCancel();
        this.setState({
            choiceLabelList: [],
            storageChoiceLabel: [],
        })
    }

    // 添加项目项目基本信息变化
    addProjectValueChange (val, key) {
        let addProjectData = {...this.state.addProjectData}
        if (key) {
            // if (key === 'isPrivate' && !val) {
            //     return Modal.confirm({
            //         title: '提示',
            //         className: 'privateTip',
            //         content: (
            //             <div>新增项目选择了不保密，项目内容将被公开 请确认是否不保密，如确认请按“继续”进入下一步</div>
            //         ),
            //         okText: '继续',
            //         cancelText: '上一步',
            //         centered: true,
            //         onOk: () => {
            //             addProjectData[key] = val
            //             this.setState({
            //                 addProjectData: addProjectData
            //             })
            //         }
            //     })
            // }
            addProjectData[key] = val
            if (key === 'modelList') {

            }
        } else {
            addProjectData = {
                ...addProjectData,
                ...val,
            }
        }
        this.setState({
            addProjectData
        })
    }

    // 数据分类
    dataSort (sortType, dataList, key) {
        const data = JSON.parse(JSON.stringify(dataList))
        const keyList = Array.from(new Set(data.map(item => item[sortType])))
        keyList.sort((a, b) => a - b)
        return keyList.map((item, idx) => {
            let filterList = data.filter((it) => it[sortType] === item)
            return {
                sortName: item,
                id: `${key}-${item}-${idx}`,
                data: filterList,
            }
        })
    }


    // 模板选择过滤
    modelFilter = (inputValue, path) => {
        return path.some(
            option => (option.label).toLowerCase().indexOf(inputValue.toLowerCase()) > -1,
        );
    }

    // 选择模板
    modelChange (val, selectOption) {
        console.log(val, selectOption);
    }

    // 删除项目
    async deleteProject () {
        const {activeProjectId, belongType, enableDelete, noPublishSortType} = this.state
        if (belongType === NO_PUBLISH && enableDelete) {
            // 调用接口删除
            Modal.confirm({
                title: `'确认删除所选项目吗？`,
                okText: '确认',
                cancelText: '取消',
                onOk: async (close) => {
                    try {
                        const res = await Api.Documentation.deleteProject({data: {id: activeProjectId}})
                        const data = await this.getUnPublishProject()
                        const resData = responseDataFilter(res, "项目删除失败");
                        if (resData) {
                            message.success("项目删除成功")
                            this.setState({
                                activeProjectId: null,
                                belongType: null,
                                enableDelete: false,
                            })
                        }
                    } catch (e) {
                        message.error("项目删除失败")
                        close()
                    }
                }
            })
        }
    }

    // 跳转到注意事项页面
    toTipPage () {
        if (window.toNoteThing) {
            window.toNoteThing()
        }
    }

    // 选择标签
    choiceLabel (label, choice) {
        let storageChoiceLabel = this.state.storageChoiceLabel
        if (choice) {
            storageChoiceLabel.push(label)
        } else {
            const id = label.id
            const idx = storageChoiceLabel.findIndex(item => item.id===id)
            storageChoiceLabel.splice(idx, 1)
        }
        this.setState({storageChoiceLabel})
    }

    // 选择分组标签
    choiceGroupLabel (groupLabel, choice) {
        let storageChoiceLabel = this.state.storageChoiceLabel
        const ids = groupLabel.map(item => item.id)
        storageChoiceLabel = storageChoiceLabel.filter(item => !ids.includes(item.id))
        if (choice) {
            storageChoiceLabel.push(...groupLabel)
        }
        this.setState({storageChoiceLabel})
    }

    // 确定选择标签
    async confirmChoiceLabel () {
        const {storageChoiceLabel, addProjectData, } = this.state
        const tagValue = {...addProjectData.tagValue};
        const choiceLabelObject = {};
        let classIds = storageChoiceLabel.map(it => it.id).join()
        const res = await Api.OverseasCaseManager.getSelectOptionsByLabelId({data: {classIds}, loading: true})
        const data = responseDataFilter(res, "下拉列表获取失败");
        if (data) {
            let choiceLabelList = data.map((item) => {
                const {id} = item
                let valueList = item.tags || []
                choiceLabelObject[id] = [valueList[0]?.id]
                return item
            })
            for (const key in tagValue) {
                if (choiceLabelObject[key] === undefined) {
                    delete tagValue[key]
                }
            }
            this.setState({
                choiceLabelList: choiceLabelList,
                labelChoiceVisible: false,
                addProjectData: {...addProjectData, tagValue: {...choiceLabelObject, ...tagValue}}
            }, () => {
                this.toBottom()
            })
        }
    }

    // 获取标签组（包含标签分类和值）
    async getLabelGroupList () {
        const res = await Api.OverseasCaseManager.getAllGroupLabelList();
        const data = responseDataFilter(res, "标签获取失败");
        if (data) {
            this.setState({
                labelGroupList: data
            })
        }
    }

    // 获取选中的标签的下拉列表
    async getSelectOptionsByLabelIdFunc (labelIds) {
        const res = await Api.OverseasCaseManager.getSelectOptionsByLabelId({data: {classIds: labelIds.join()}, loading: true});
        const data = responseDataFilter(res, "下拉列表获取失败");
        if (data) {
            let tagValue = {}
            data.forEach(item => {
                tagValue[item.id] = item.tags[0]?.id || undefined
            })
            for (let tagValueKey in tagValue) {
                if (tagValue[tagValueKey] === undefined) {
                    delete tagValue[tagValueKey]
                }
            }
            this.setState({
                storageChoiceLabel: [...data],
                choiceLabelList: [...data],
                addProjectData: {...this.state.addProjectData, tagValue: tagValue}
            }, () => {
                this.toBottom()
            })
        }
    }

    toBottom () {
        let dom = document.getElementById('addProjectModelContent')
        if (dom) {
            dom.scrollTop = 10000
        }
    }

    render() {
        const that = this;
        const {
            publishProjectList, conditionList,
            publishSortType, noPublishSortType, activeProjectId,
            stateList, taskList, projectNameList,
            buildTypeList, exploitList, modelList, addProjectData, enableDelete,
             expandIds, isExpand,  labelGroupList, choiceLabelList,
            labelChoiceVisible, storageChoiceLabel,
        } = that.state;
        
        const{modalOpen, isEdit} = this.props;

        const choiceLabelIdList = storageChoiceLabel.map(item => item.id)
        const haveLabelGroup = labelGroupList && labelGroupList.length > 0

        return (
            <div className="documentationFrame">
                {/* 新建项目modal */}
                <Modal
                    open={modalOpen}
                    centered={true}
                    maskClosable={false}
                    wrapClassName={"addProjectModel"}
                    width={650}
                    style={{minWidth: '30rem'}}
                    title={(<span className={"addProjectModelTitle"}><Iconfont type={"icon-fabu"} />{isEdit ? '编辑' : '新建'}项目</span>)}
                    okText={'确定'}
                    cancelText={'取消'}
                    onCancel={() => this.cancelCreateProject()}
                    onOk={() => this.createProject()}
                >
                    <div className={"addProjectModelContent"} id="addProjectModelContent">
                        <div className={"addProjectItem"}>
                            <span className={"itemLabelText isRequiredTip"}>项目名称</span>
                            <div className="itemContent">
                                {
                                    isEdit ? (<span className="projectIdText">{addProjectData.name}</span>)
                                        : (
                                            <Select
                                                showSearch
                                                filterOption={(inputValue, option) => {
                                                    return option.projectName.indexOf(inputValue) > -1
                                                }}
                                                disabled={isEdit}
                                                value={addProjectData.id}
                                                placeholder="选择项目"
                                                style={{width:'100%'}}
                                                size={'large'}
                                                optionFilterProp={'label'}
                                                defaultActiveFirstOption={false}
                                                fieldNames={{
                                                    label: 'projectName',
                                                    value: 'projectId',
                                                }}
                                                onSelect={(val, item) => {
                                                    this.addProjectValueChange({
                                                        name: item.projectName,
                                                        id: item.projectId,
                                                        projectCode: item.projectCode
                                                    })
                                                }}
                                                notFoundContent={null}
                                                options={projectNameList}
                                            />
                                        )
                                }

                            </div>
                        </div>
                        <div className={"addProjectItem"}>
                            <span className={"itemLabelText isRequiredTip"}>项目编号</span>
                            <div className={"itemContent"}>
                                <span className={"projectIdText"}>{addProjectData.projectCode}</span>
                            </div>
                        </div>
                        <div className={"addProjectItem noCenter"}>
                            <span className={"itemLabelText"}>项目简介</span>
                            <div className={"itemContent"}>
                                <TextArea
                                    allowClear
                                    bordered={false}
                                    onResize={false}
                                    maxLength={200}
                                    showCount
                                    value={addProjectData.desc}
                                    onChange={(val) => this.addProjectValueChange(val.target.value, 'desc')}
                                />
                                <span className={"tipText"}>撰写要求：年份+业主名称+项目特点（建设地点，规模，建厂类型，工艺类型等）+研究阶段，不超过200字</span>
                            </div>
                        </div>
                        {
                            isEdit ? '' : (
                                <div className={"addProjectItem"}>
                                    <span className={"itemLabelText isRequiredTip"}>选择模板</span>
                                    <div className={"itemContent"}>
                                        <Cascader
                                            options={modelList}
                                            style={{width:'100%'}}
                                            size={'large'}
                                            placeholder="选择模板"
                                            disabled={isEdit}
                                            value={addProjectData.modelList}
                                            fieldNames={{
                                                label: 'label',
                                                value: 'id',
                                                children: 'children'
                                            }}
                                            showSearch={{ filter: this.modelFilter }}
                                            onChange={(val, selectOption) => {
                                                const valList = [...(val || [])]
                                                const option = [...selectOption]
                                                this.addProjectValueChange(valList, "modelList")
                                                this.getSelectOptionsByLabelIdFunc(option.pop()?.tagClassIds || [])
                                            }}
                                        />
                                    </div>
                                </div>
                            )
                        }

                        {/* <div className={"addProjectItem"}>
                            <span className={"itemLabelText"}>是否保密</span>
                            <div className={"itemContent noBackground"}>
                                <Switch
                                    style={{width: '2.5rem'}}
                                    checked={addProjectData.isPrivate}
                                    checkedChildren="是" unCheckedChildren="否"
                                    onChange={(val) => this.addProjectValueChange( val, 'isPrivate')}
                                />
                            </div>
                        </div> */}
                        <div className={"addProjectItem selectLabelBox"}>
                            {
                                choiceLabelList.map((it, index) => {
                                    const {className, tags, id} = it
                                    const tagValue = addProjectData.tagValue || {};
                                    let options = tags.map(item => ({value:item.id, label: item.title}))
                                    return (
                                        <div
                                            className="itemChildItem"
                                            key={index}
                                        >
                                            <span className={"itemLabelText"}>{className}</span>
                                            <Select
                                                mode={"multiple"}
                                                showSearch
                                                value={tagValue[id]}
                                                placeholder={'选择'+className}
                                                style={{width:'100%'}}
                                                size={'large'}
                                                optionFilterProp={'label'}
                                                defaultActiveFirstOption={false}
                                                onChange={(val, item) => {
                                                    this.addProjectValueChange({
                                                        tagValue: {...tagValue, [id]: val}
                                                    })
                                                }}
                                                notFoundContent={null}
                                                options={options}
                                            />
                                        </div>
                                    )
                                })
                            }
                        </div>
                        <div className={"addProjectItem"}>
                            <span
                                className="addLabelBtn"
                                onClick={() => this.setState({labelChoiceVisible: true})}><Iconfont type={"icon-add-bold"} /> 引用标签</span>
                        </div>
                    </div>
                </Modal>

                {/* 标签选择弹窗 */}
                <Modal
                    width={750}
                    open={labelChoiceVisible}
                    maskClosable={false}
                    centered={true}
                    title={(<div className="choiceLabelTitleBox"><Iconfont type={"icon-24gl-tags2"} />选择标签</div>)}
                    okText={'确定'}
                    cancelText={'取消'}
                    onCancel={() => this.setState({labelChoiceVisible: false})}
                    onOk={() => {
                        this.confirmChoiceLabel()
                    }}
                    className={'labelChoiceModal'}
                    footer={haveLabelGroup ? undefined : null}
                >
                    <div className="labelChoiceContent">
                        {
                            haveLabelGroup ? labelGroupList.map((item, idx) => {
                                const {tagClassOperations: labelList, groupName: text,} = item
                                let choiceList = labelList.filter(labelItem => choiceLabelIdList.includes(labelItem.id))
                                const choiceListLen = choiceList.length
                                const labelListLen = labelList.length
                                const isAll = choiceListLen >= labelListLen
                                return (
                                    <div className="groupListItem" key={idx}>
                                        <div className="groupItemTitle" onClick={() => this.choiceGroupLabel(labelList, !isAll)}>
                                            <i className={`choiceBtn ${choiceListLen > 0 ? choiceListLen < labelListLen ? 'someChoice' : 'allChoice' : ''}`} />
                                            <span className="titleText">{text}</span>
                                        </div>
                                        <div className="labelListBox">
                                            {
                                                labelList.map((it, index) => {
                                                    const {className: labelName, tagValues, id} = it
                                                    let valueList = tagValues.split(',')
                                                    const isChoice = choiceLabelIdList.includes(id)
                                                    return (
                                                        <div className="labelListItem" key={`${idx}-${index}`}>
                                                            <div className="labelNameBox" onClick={() => this.choiceLabel(it, !isChoice)}>
                                                                <i className={`choiceBtn ${isChoice ? 'allChoice' : ''}`} />
                                                                <span className="labelNameText">{labelName}</span>
                                                            </div>
                                                            <div className="labelValueListBox">
                                                                {
                                                                    valueList.map((child, childIndex) => {
                                                                        return (<span className="labelValueText" key={`${idx}-${index}-${childIndex}`}>{child}</span>)
                                                                    })
                                                                }
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                )
                            }) : (noneData('请先前往标签管理页面添加标签'))
                        }
                    </div>
                </Modal>
            </div>
        );
    }
}

Documentation.propTypes = {};

export default Documentation;
