/*
    * 职务
*/
import myAxios from '../utils/request.js';

export default {
    // 获取列表
    async getDictList (params={}, cb) {
        let res = await myAxios.getJson('/dictType/list', params);
        return res.data;
    },
    // 修改
    async updateDictType (params={}, cb) {
        let res = await myAxios.putJson('/dictType', params);
        return res.data;
    },
    // 新增
    async addDictType (params={}, cb) {
        let res = await myAxios.postJson('/dictType', params);
        return res.data;
    },
    // 删除
    async deleteDictType (params={}, cb) {
        let res = await myAxios.deleteJson(`/dictType/${params.data}`, {});
        return res.data;
    },
    // 刷新缓存
    async deleteRefreshCache (params={}, cb) {
        let res = await myAxios.deleteJson(`/dictType/refreshCache`, {});
        return res.data;
    },
    // 查询字典类型详细
    async getDictTypeDetails (params={}, cb) {
        let res = await myAxios.getJson(`/dictType/${params.data}`, {});
        return res.data;
    },
    // 获取字典选择框列表
    async getOptionselect (params={}, cb) {
        let res = await myAxios.getJson(`/dictType/optionselect`, {});
        return res.data;
    },

    // 获取单个字典类型的字典值列表
    async getDictDataList (params={}, cb) {
        let res = await myAxios.getJson('/dictData/list', params);
        return res.data;
    },

    // 获取多个字典类型的字典值列表
    async getManyDictDataList (params={}, cb) {
        let res = await myAxios.postJson('/dictData/selectDictByDictTypes', params);
        return res.data;
    },
    // 修改
    async updateDictData (params={}, cb) {
        let res = await myAxios.putJson('/dictData', params);
        return res.data;
    },
    // 新增
    async addDictData (params={}, cb) {
        let res = await myAxios.postJson('/dictData', params);
        return res.data;
    },
    // 删除
    async deleteDictData (params={}, cb) {
        let res = await myAxios.deleteJson(`/dictData/${params.data}`, {});
        return res.data;
    },

    // 根据dictCode 获取政策年份信息 为空获取全部
    async getPolicyYearByDictCode (params={}, cb) {
        let res = await myAxios.getJson(`/policyCw/getPolicyYearByDictCode`,params);
        return res.data;
    },

    // 查询所有部门以及字典分类
    async selectDeptAndDict (params={}, cb) {
        let res = await myAxios.postJson(`/policyCw/selectDeptAndDict`,params);
        return res.data;
    },
}
