import React, {useState, useEffect, useRef} from "react";
import {useHistory} from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {zuStandStore} from "../../zustand";
import {Layout, Checkbox, Spin, Space, Button, Modal, Form, message} from "antd";
import { CloseOutlined, SyncOutlined } from '@ant-design/icons';

import LayoutTop from "./layoutTop";
import Iconfont from "../../components/Iconfont/Iconfont";
import AIModal from "../../components/AIModal";
import SplitUpload from "../../components/SplitUpload/index-websocket";
import AddKnowledgeFormModal from "../personalCenter/components/addKnowledgeForm/AddKnowledgeFormModal";
import UploadFile from "../personalCenter/components/UploadFile";
import storage from "../../utils/storage";
import { setAIModalState } from "../../features/AIIntelligent/AIReducer";
import KnowledgeDetails from "../../pages/knowledgeCentre/knowledgeDetails/index";
import Api from "../../api";
import UploadFileModal from "../../components/UploadFileModal";

import "./layout.scss";

const {Header, Content, Footer} = Layout;

const LayoutBox = ({children, match}) => {
    const dispatch = useDispatch();
    let isHomePage = window.location.pathname.includes("homePage")
    let isKnowledgeCentre = window.location.pathname.includes("knowledgeCentre");
    let history = useHistory();
    const uploadStatus = {
        processing: "icon-pc-processing",
        fail: "icon-fail",
        success: "icon-success",
    };
    const [isShowHomeNav, setIsShowHomeNav] = useState(true);
    const uploadState = zuStandStore((state) => state);
    const changeUploadStateFunc = uploadState.changeUploadStateFunc;
    const getUploadStateFunc = uploadState.getUploadStateFunc;
    const changeIsShowAiChat = uploadState.changeIsShowAiChat;
    const typesOfUploadedFiles = uploadState.typesOfUploadedFiles;

    const [isBig, setIsBig] = useState(false);
    const isShowChat = uploadState.isShowAiChat;

    const stateRef = useRef();
    const [isMove, setIsMove] = useState(false);
    const [mousePosition, setMousePosition] = useState({x: 0, y: 0});
    const [chatStyle, setChatStyle] = useState({bottom: 40, right: 20}); // 单位 rem

    const [homePageZoom, setHomePageZoom] = useState(1);
    const [themeKey, setThemeKey] = useState(storage.getItem("theme"));

    const [disabledFile,setDisabledFile] = useState([]);
    const [editFileVlue,setEditFileVlue] = useState([]);
    const [addKnowledgeFormOpen,setAddKnowledgeFormOpen] = useState(false);
    const [form] = Form.useForm();
    const [confirmType, setConfirmType] = useState('');
    const [checkedValue, setCheckedValue] = useState([]);
    const [checkAll, setCheckAll] = useState(false);
    const [indeterminate, setIndeterminate] = useState(false);

    // AI聊天框
    const showAIModal = useSelector(state => state.AIModal.modalOpen);
    // 多文档AI问答
    const multiOpen = useSelector(state => state.AIModal.multiOpen);
    const multiPage = useSelector(state => state.AIModal.multiPage);
    const uploadModalOpen = useSelector(state => state.AIModal.uploadModalOpen);

    useEffect(() => {
        const dom = document.getElementById("homePageFrame");

        if (isHomePage && themeKey!="oldVersion") {
            dom?.addEventListener("scroll", handleScroll);
            console.log(themeKey,"开始监听")
            listeningResize();

            const divElement = document.querySelector('#root');
            let zoom = ((divElement.clientWidth-40)/1760).toFixed(3);
            setHomePageZoom(zoom);

        } else {
            setIsShowHomeNav(true);
            setHomePageZoom(1);
        }
        return () => {
            dom?.removeEventListener("scroll", handleScroll);
            window.removeEventListener("resize", myResizeHandler);
        };
    }, [window.location.pathname,themeKey]);

    // 订阅刷新主题
    useEffect(() => {
        document.addEventListener("theme", handleEvent);
        return () => {
            document.removeEventListener("theme", handleEvent);
        };
    }, []);

    const handleEvent = () => {
        setThemeKey(storage.getItem("theme"))
    }

    useEffect(() => {
        if (isShowChat) {
            const dom = document.getElementById("inputContent")
            if (dom) {
                dom.focus();
            }
        }
    }, [isShowChat])

    useEffect(() => {
        console.log(uploadState,"uploadStateuploadStateuploadState")
        if (uploadState.uploadFileList.length > 0 && uploadState.uploadOpen) {
            uploadFileCallBack(uploadState);
        }

    }, [uploadState.uploadFileList.length]);

    useEffect(() => {
        stateRef.current = {
            isMove,
            chatStyle,
            mousePosition,
        }
    }, [isMove, chatStyle, mousePosition]);

    useEffect(() => {
        window.addEventListener("beforeunload", answeringFunc);
        window.addEventListener("mousemove", movingFunc);
        window.addEventListener("mouseup", endMoveFunc);

        return () => {
            window.removeEventListener("beforeunload", answeringFunc);
            window.removeEventListener("mousemove", movingFunc);
            window.removeEventListener("mouseup", endMoveFunc);
        }
    }, [])


    const listeningResize = () => {
        window.addEventListener('resize', myResizeHandler);
    }

    const myResizeHandler = () =>{
        const divElement = document.querySelector('#root');
        let zoom = ((divElement.clientWidth-40)/1760).toFixed(3);
        setHomePageZoom(zoom)
    }

    // 回答中
    const answeringFunc = (e) => {
        const {isAnswering, linkStatus} = getUploadStateFunc();
        if (isAnswering || linkStatus === "success") {
            e?.preventDefault();
            e.returnValue = "";
        }
    }

    // 开始拖动
    const startMoveFunc = (e, isBig) => {
        if (e && !isBig) {
            setMousePosition({
                x: e.screenX,
                y: e.screenY
            });
            setIsMove(true);
        }
    }

    // 拖动中
    const movingFunc = (e) => {
        const {isMove, mousePosition, chatStyle} = stateRef?.current || {};

        if (e && isMove) {
            const {screenX, screenY} = e;
            const {x, y} = mousePosition;

            const dom = document.getElementById("intelligentAssistantChatBox");
            const documentBody = document.documentElement || document.body;

            if (dom && documentBody) {
                const {right, bottom} = chatStyle;
                const {clientHeight, clientWidth} = dom;
                const {clientHeight: bodyHeight, clientWidth: bodyWidth} = documentBody;

                const rightX = (right + (x - screenX)) || 0;
                const bottomY = (bottom + (y - screenY)) || 0;
                let style = {
                    right: rightX < 20 ? 20 : rightX,
                    bottom: bottomY < 40 ? 40 : bottomY,
                };

                if ((clientHeight + style.bottom + 40) > bodyHeight) {
                    style.bottom = bodyHeight - clientHeight - 40;
                }
                if ((clientWidth + style.right + 20) > bodyWidth) {
                    style.right = bodyWidth - clientWidth - 20;
                }
                setChatStyle(style);
                setMousePosition({
                    x: screenX,
                    y: screenY,
                });
            }
        }
    }

    // 拖动结束
    const endMoveFunc = (e) => {
        const {isMove,} = stateRef?.current || {};
        if (isMove) {
            setIsMove(false);
        }
    }

    // 滚动回调
    const handleScroll = (ev) => {
        const dom = ev.target;
        if (dom.scrollTop > 60) {
            setIsShowHomeNav(false);
        } else if (dom.scrollTop < 60) {
            setIsShowHomeNav(true);
        }
    }

    // 上传文件执行函数
    const uploadFileCallBack = async (uploadParams) => {
        const {uploadFileList} = uploadParams;
        for (let uploadFile of uploadFileList) {
            const {fileList} = uploadFile;
            await SplitUpload(fileList, (params, socket) => {
                const {status, process, fileId, otherData, linkStatus, msg} = params || {};
                const {fileProcessObject, fileUploadStatus, fileOtherData, linkStatus: sourceLinkStatus, fileBelongSpaceObject} = getUploadStateFunc();
               
                if(params.status === "fail"){
                    params.msg
                }

                if (sourceLinkStatus === "closed") {
                    return
                }

                let state = {};
                if (status) {
                    if (fileUploadStatus[fileId] !== "success") {
                        state.fileUploadStatus = {
                            ...fileUploadStatus,
                            [fileId]: status,
                        };
                    }

                    if (status === "success") {
                        state.fileOtherData = {
                            ...fileOtherData,
                            [fileId]: otherData,
                        };
                    }
                }

                if (process) {
                    let value = fileProcessObject[fileId];
                    if (value <= process) {
                        state.fileProcessObject = {
                            ...fileProcessObject,
                            [fileId]: process,
                        };
                    }
                }
                let flag = true;
                if (state.fileUploadStatus) {
                    for (let key in state.fileUploadStatus) {
                        let item = state.fileUploadStatus[key];
                        if (item === "processing") {
                            flag = false;
                            break;
                        }
                    }
                } else {
                    flag = false;
                }

                if (linkStatus) {
                    state.linkStatus = linkStatus;
                    // if (linkStatus !== "success") {// fail / stop
                    if (linkStatus === "fail") {
                        state.modalStatus = "big";
                        state.uploadOpen = true;
                    }
                }
                if (flag) {
                    socket?.close();
                    state.modalStatus = "big";
                    state.uploadOpen = true;
                    state.linkStatus = "closed";
                    state.msg = msg;
                    // 上传的知识空间分类
                    let spaceKnowledgeObject = {};
                    for (let key in fileBelongSpaceObject) {
                        let spaceId = fileBelongSpaceObject[key];
                        let fileData = state.fileOtherData[key];
                        if (spaceId && fileData) {
                            const knowledgeId = fileData.knowledgeId || fileData.knowledgeManagementId;
                            if (spaceKnowledgeObject[spaceId]) {
                                spaceKnowledgeObject[spaceId].push(knowledgeId);
                            } else {
                                spaceKnowledgeObject[spaceId] = [knowledgeId];
                            }
                        }
                    }
                    for (let spaceId in spaceKnowledgeObject) {
                        let knowledgeIds = spaceKnowledgeObject[spaceId];
                        Api.PersonalCenter.batchMoveKnowledgeFiles({
                            data: {
                                "knowledgeIds": knowledgeIds,
                                "spaceTypeId": spaceId,
                            }
                        });
                    }

                    state.fileBelongSpaceObject = {}; // 清空已经移动分类后的知识id
                    if (Object.keys(spaceKnowledgeObject).length > 0) {
                        state.isFlush = !uploadState.isFlush;
                    }
                }

                // if (state.modalStatus !== "big") {
                //     if (status === "fail") {
                //         message.error("上传失败！");
                //     } else if (status === "success") {
                //         message.success("上传成功！");
                //     }
                // }
                changeUploadStateFunc(state);
            });
        }
    }

    // 精度转换
    function numberToFixed(num, digits) {
        return Number(Number(num).toFixed(digits));
    }

    // 计算进度
    function computedProcess(uploadFileList, fileUploadStatus, fileProcessObject) {
        let totalSize = 0;
        let uploadSize = 0;
        let fileTotalList = [];
        uploadFileList.forEach(item => {
            item.fileList.forEach(it => {
                const {size: fileSize, fileId} = it;
                fileTotalList.push(it);
                totalSize += fileSize;
                if (fileUploadStatus[fileId] === "fail") {
                    uploadSize += fileSize;
                } else {
                    uploadSize += fileSize * (fileProcessObject[fileId]);
                }
            })
        });
        return {
            processNum: uploadSize / (totalSize || 1),
            fileTotalList,
        };
    }

    // 关闭或者缩小 上传文件弹窗
    function closeUploadFileModal(uploading) {
        let state = {
            modalStatus: "small",
        };
        if (!uploading) {
            state = {
                uploadFileList: [],
                fileProcessObject: {},
                fileUploadStatus: {},
                fileOtherData: {},
                uploadOpen: false,
                modalStatus: "big",
                isAnswering: false,
                linkStatus: null,
            }
        }
        changeUploadStateFunc(state)
    }

    // 文件上传完成之后的操作
    function uploadFileAfter(fileData, otherData) {
        // 只上传了一个文件的时候直接跳转编辑页面
        // 多个文件跳转草稿箱
        if (fileData) {
            let fileName = fileData.name;
            const lastDotIndex = fileName.lastIndexOf(".");

            let fileType = fileName.slice(lastDotIndex + 1).toLowerCase();
            fileName = fileName.slice(0, lastDotIndex);

            storage.removeItem("fileId");
            storage.removeItem("knowledgeDetailInfo");
            let encodedObject = encodeURIComponent(otherData.knowledgeId || otherData.knowledgeManagementId);
            let fileIdEncode = encodeURIComponent(otherData.fId || otherData.fileId);
            let url = `/page/newKnowledge?knowledgeId=${encodedObject}&fileId=${fileIdEncode}&fileType=${fileType}&fileName=${fileName}`;
            window.open(url);
        } else {
            history.push("/personalCenter/myUpload/notPublished");
        }
    }

    function onCheckboxChange(checkedValue){
        setCheckedValue(checkedValue)
        let newData = [], optional = [];

        knowledgeFileList.map(item=>{
            const status = fileUploadStatus[item.fileId];
            if(status === 'success' && !disabledFile.includes(item.fileId)){
                optional.push(item)
            }
            if(checkedValue.includes(item.fileId)){
                let fileName = item.name;
                const lastDotIndex = fileName.lastIndexOf(".");
                let fileType = fileName.slice(lastDotIndex + 1).toLowerCase();
                fileName = fileName.slice(0, lastDotIndex);
                newData.push({
                    fileName:fileName,
                    fileId:fileOtherData[item.fileId].fId || fileOtherData[item.fileId].fileId,
                    fileType,
                    id:item.fileId,
                    knowledgeId:fileOtherData[item.fileId].knowledgeId || fileOtherData[item.fileId].knowledgeManagementId
                })

            }
        })
        console.log(newData.length,optional.length,"---")
        setIndeterminate(!!newData.length && newData.length < optional.length);
        setCheckAll(newData.length === optional.length);
        setEditFileVlue(newData)
    }

    function bulkEdit(fileList){
        // setAddKnowledgeFormOpen(true);
        // dispatch(setData(fileList))
        // dispatch(setKnowledgeState({
        //     knowledgeList:fileList,
        //     editType:"share", //share 分享 补充文章信息。updateFile 更新
        // }))
        // history.push(`/page/editKnowledge`);
        
        sessionStorage.setItem("editFileVlue", JSON.stringify({
            knowledgeList:fileList,
            editType:"share", //share 分享 补充文章信息。updateFile 更新
        }))
        // localStorage.setItem("editFileVlue",JSON.stringify(value))
        window.open(`/page/editKnowledge`);
        closeUploadFileModal(false)
    }


    function handleKnowledgeFormOk(type){
        setConfirmType(type)
        form.submit(type);
    }

    function handleKnowledgeFormCancel(type){
        if(type === 'success'){
            // 已经编辑成功后的数据不能再次选择
            let ids = [...disabledFile]
            editFileVlue.map(item=>{
                ids.push(item.id)
            })
            setCheckedValue([]);
            setDisabledFile(ids);
            setEditFileVlue([])
        }
        form.resetFields();
        setAddKnowledgeFormOpen(false)
    }

    const onCheckAllChange = (e) => {
        const plainOptions = [], newData = [];
        if(e.target.checked){
            knowledgeFileList.map(item=>{
                const fileId = item.fileId
                const status = fileUploadStatus[fileId];
                if(status === 'success' && !disabledFile.includes(fileId)){
                    plainOptions.push(item.fileId);
                    let fileName = item.name;
                    const lastDotIndex = fileName.lastIndexOf(".");
                    let fileType = fileName.slice(lastDotIndex + 1).toLowerCase();
                    fileName = fileName.slice(0, lastDotIndex);
                    newData.push({
                        fileName:fileName,
                        fileId:fileOtherData[item.fileId].fId || fileOtherData[item.fileId].fileId,
                        fileType,
                        id:item.fileId,
                        knowledgeId:fileOtherData[item.fileId].knowledgeId || fileOtherData[item.fileId].knowledgeManagementId
                    })
                }
            })
        }
        // console.log(newData,plainOptions)
        setCheckedValue(plainOptions);
        setEditFileVlue(newData);
        setCheckAll(e.target.checked);
        setIndeterminate(false);
    };

    // render uploadFileList
    function renderUploadFileList(list, sortName, isHaveEdit = true) {
        if (list.length < 1) {
            return
        }
        return (
            <div className="uploadFileListBox" style={{display: list.length > 0 ? "" : "none"}}>
                <div style={{height:'27px'}}>
                    <span className="sortName">{sortName}</span>
                    {isHaveEdit&&<Checkbox
                        indeterminate={indeterminate}
                        onChange={onCheckAllChange}
                        checked={checkAll}
                    >
                        全选
                    </Checkbox>}

                </div>
                <div className="uploadFileListContentBox">
                    <Checkbox.Group onChange={onCheckboxChange} value={checkedValue}>
                    {
                        list.map(item => {
                            const {fileId, name} = item;
                            const status = fileUploadStatus[fileId] || "processing";
                            const process = fileProcessObject[fileId] || 0;
                            const otherData = fileOtherData[fileId] || 0;
                            const rate = numberToFixed(process * 100, 2) + "%";
                            return (
                                <Checkbox
                                    value={fileId}
                                    disabled={
                                        status != "success" ||
                                        !isHaveEdit ||
                                        disabledFile.includes(fileId)
                                    }
                                ><div
                                    className={"uploadFileItem " + status}
                                    style={status === "success" && isHaveEdit ?{padding: '0.2rem 4rem 0.3rem 0.2rem'}:{}}
                                >
                                    <div className="fileInfo">
                                        <span className="fileName overflowEllipsis" title={name}>{name}</span>
                                        <div className="operateBox">

                                            {
                                                status === "processing" ?
                                                    (<span className="processNumText">{rate}</span>)
                                                    :
                                                    <Iconfont type={uploadStatus[status]}/>
                                            }
                                        </div>
                                        {
                                            (status === "success" && isHaveEdit && !disabledFile.includes(fileId)) ?
                                                (
                                                    <Button
                                                        size="small"
                                                        className="editBtn"
                                                        type="primary"
                                                        onClick={() => uploadFileAfter(item, otherData)}
                                                        style={{height: '28px'}}
                                                    ><Iconfont type="icon-bianji2"/>编辑</Button>
                                                )
                                                :
                                                undefined
                                        }
                                    </div>
                                    <div className="processBox">
                                        <span className="processLine" style={{width: rate,}}/>
                                    </div>
                                </div></Checkbox>
                            )
                        })
                    }
                    </Checkbox.Group>
                </div>

            </div>
        )
    }

    // 上传文件提示语
    function  renderTipText (linkStatus, fileList, msg) {
        // console.log(linkStatus,msg,"linkStatus")
        let content = <Spin size="large" tip={"连接建立中..."}></Spin>;

        switch (linkStatus) {
            case "success":
                content = (<Spin size="large" tip={"文件上传中..."}></Spin>);
                break;
            case "fail":
                content = (<span className="tipBox stopStatus"><Iconfont type="icon-fail"/>连接失败，请检查网络是否中断！</span>);
                break;
            case "stop":
                content = (
                    <div className="tipBox stopStatus">
                        <span className="tipText"><Iconfont type="icon-fail"/>连接中断!</span>
                        <span
                            className="linkBtn"
                            onClick={() => {
                                changeUploadStateFunc({linkStatus: null})
                                uploadFileCallBack({uploadFileList: fileList})
                            }}
                        >重新上传</span>
                    </div>
                );
                break;
            case "closed":
                content = (msg ? <span className="tipBox stopStatus"><Iconfont type="icon-fail"/>{msg}</span> : <span className="tipBox"><Iconfont type="icon-success"/>上传完成</span>);
                break;
        }

        return content;
    }

    const deleteFileFunc = (file) => {
        let list = [...uploadFileList];
        let flag = false;
        console.log(list,file,"listlistlistlist")
        for (let listElement of list) {
            let idx = listElement.fileList.findIndex(item => item.fileId === file.fileId);
            if (idx > -1) {
                flag = true;
                listElement.fileList.splice(idx, 1);
                break;
            }
        }
        if (flag) {
            const newState = {
                uploadFileList: list,
            };
            changeUploadStateFunc(newState);
        } else {
            message.warning("文件不存在");
        }
    }

    const {
        uploadFileList,
        fileProcessObject,
        fileUploadStatus,
        fileOtherData,
        uploadOpen,
        modalStatus,
        linkStatus,
        msg
    } = uploadState;
    const uploading = linkStatus === "success";

    const {processNum, fileTotalList} = computedProcess(uploadFileList, fileUploadStatus, fileProcessObject);

    const commonFileList = [];
    const knowledgeFileList = [];
    let successNum = 0;

    fileTotalList.forEach(item => {
        const fileItem = item;
        const fileId = item.fileId;
        const status = fileUploadStatus[fileId];
        fileItem.status = status;
        fileItem.process = fileProcessObject[fileId];
        if (fileItem.uploadType === "knowledge") {
            knowledgeFileList.push(fileItem);
            if (status === "success") {
                successNum += 1;
            }
        } else {
            commonFileList.push(fileItem);
        }
    })

    const onFinish = (values) => {

    }

    const isHaveManyKnowledge = knowledgeFileList.length > 1;
    const isHaveKnowledge = knowledgeFileList.length > 0;

    let okText = knowledgeFileList.length > 1 ? "批量分享" : "分享";
    
    return (
        <Layout className="rootLayout" id="rootLayout" style={{zoom:homePageZoom}}>
            <Header
                className={
                    isKnowledgeCentre && isShowHomeNav
                        ? "layoutTopFrame layoutHome"
                        : "layoutTopFrame layoutOther"
                }
            >
                <LayoutTop match={match} isShowHomeNav={isShowHomeNav} showAiModalFunc={() => changeIsShowAiChat(!isShowChat)} />
            </Header>
            <Content
                className={isKnowledgeCentre ? "" : "layoutContent"}
                style={
                    isHomePage?{background: '#F2F9FC'}:
                    !isKnowledgeCentre && !isHomePage ? {padding:'0.5rem'} :{}
                    // !isKnowledgeCentre && !isHomePage ? {padding:'1rem'} :{}
                    // 不在首页和知识中心的时候增加padding
                }
            >
                {children}
            </Content>
            <div id="visibilityBox"></div>
            <div id="visibilityBoxOriginal"></div>

            {showAIModal &&<div className="AIHelperBox" id="AIHelperBox">
                <CloseOutlined onClick={()=>{
                    dispatch(setAIModalState({
                        modalOpen: false
                    }))
                }}/>
                <AIModal/>
            </div> }

            {/* <div className="toolOperateBox">
                <div
                    className="intelligentAssistantIcon"
                    style={{display: isShowChat ? "none" : "", visibility: 'visible',}}
                    onClick={(e) => {
                        changeIsShowAiChat(true);
                    }}
                >
                    <span className="iconBtn"><Iconfont type="icon-jiqirenpeixun" /></span>
                    <span className="text">AI小赛助手</span>
                </div>
                {
                    isShowChat !== null ? (
                        <div
                            id={"intelligentAssistantChatBox"}
                            className={`intelligentAssistantChatBox ${isBig ? "bigStatus" : ""}`}
                            style={{
                                visibility: 'visible',
                                ...(isBig ? {} : {bottom: chatStyle.bottom / 20 + "rem", right: chatStyle.right / 20 + "rem",}),
                                display: isShowChat ? "" : "none",
                            }}
                        >
                            <i className="moveBar" onMouseDown={(e) => startMoveFunc(e, isBig)} />
                            <ChatModal
                                isShow={isShowChat}
                                isBig={isBig}
                                changeViewSizeFunc={() => setIsBig(!isBig)}
                                closeModalFunc={() => changeIsShowAiChat(false)}
                            />
                        </div>
                    ) : undefined
                }
            </div> */}

            {/* 文件上传进度详情弹窗 */}
            <div
                className={`uploadFileModalBox ${modalStatus === "big" ? "" : "smallStatus"}`}
                style={{display: uploadOpen ? "" : "none"}}
            >
                {/* 大面板 */}
                <div className="uploadBox">
                    {/*<div className="loadingBox">*/}
                    {/*    {*/}
                    {/*        renderTipText(linkStatus, uploadFileList,msg)*/}
                    {/*    }*/}
                    {/*</div>*/}
                    <UploadFile
                        isChoiceFile={false}
                        isSelected={linkStatus === "closed"}
                        fileDataList={[...commonFileList, ...knowledgeFileList]}
                        okText={okText}
                        cancelText={(isHaveKnowledge && linkStatus === "closed") ? "查看文件" : (uploading ? "缩小弹窗" : "关 闭")}
                        okBtnDisabled={!isHaveKnowledge && linkStatus === "closed"}
                        onOk={(uploadState) => {
                            const selectedFileList = uploadState.selectedKeys;
                            let newData = [];
                            uploadState.fileList?.forEach(item => {
                                let {fileId, name: fileName} = item;
                                const lastDotIndex = fileName.lastIndexOf(".");
                                let fileType = fileName.slice(lastDotIndex + 1).toLowerCase();
                                fileName = fileName.slice(0, lastDotIndex);
                                if (selectedFileList.includes(item.fileId)) {
                                    newData.push({
                                        fileName,
                                        fileId: fileOtherData[fileId].fId || fileOtherData[fileId].fileId,
                                        fileType,
                                        id: fileId,
                                        knowledgeId:fileOtherData[fileId].knowledgeId || fileOtherData[fileId].knowledgeManagementId
                                    })
                                }
                            })

                            bulkEdit(newData)
                            console.log("")
                        }}
                        onCancel={(uploadState) => {
                            if (uploadState && knowledgeFileList.length > 1 && linkStatus === "closed") {
                                const selectedFileList = uploadState.selectedKeys;
                                if (selectedFileList.length === 1) {
                                    let fileId = selectedFileList[0];
                                    let file = uploadState.fileList.find(item => item.fileId === fileId);
                                    uploadFileAfter(file, fileOtherData[fileId]);
                                } else {
                                    uploadFileAfter();
                                }
                                closeUploadFileModal(uploading);
                            } else{
                                closeUploadFileModal(uploading);
                                history.replace("/personalCenter/mySpace");
                            }
                        }}
                        deleteFileFunc={deleteFileFunc}
                        name={"layout"}
                        uploadType={typesOfUploadedFiles}
                    />
                </div>

                {/* 缩小进度圈 */}
                <div
                    className="circleProcessBox"
                    data-num={`${numberToFixed(processNum * 100, 2)}%`}
                    onClick={() => {
                        changeUploadStateFunc({modalStatus: "big",})
                    }}
                >
                    <svg className="circleSvg">
                        <defs>
                            <radialGradient
                                id="gradient"
                                cx="50%"
                                cy="50%"
                                r="60%"
                                fx="50%"
                                fy="50%"
                            >
                                <stop offset="0%" stopColor="#c1dfc4"/>
                                <stop offset="100%" stopColor="#3cba92"/>
                            </radialGradient>
                        </defs>
                        <circle
                            r={35}
                            cx={40}
                            cy={40}
                            stroke={"#f1f1f1"}
                            strokeWidth={10}
                            fill={"none"}
                        />
                        <circle
                            r={35}
                            cx={40}
                            cy={40}
                            stroke={"url(#gradient)"}
                            strokeWidth={processNum > 0 ? 10 : 0}
                            strokeDasharray={`${Math.PI * 2 * 35 * (processNum)}, ${Math.PI * 2 * 35}`}
                            strokeLinecap={"round"}
                            fill={"none"}
                        />
                    </svg>
                </div>
            </div>

            {/* 文件解析进度详情弹窗 */}
            {uploadModalOpen ? <UploadFileModal/> : null}


            <Footer className="rootFrameBottom">
                <div className="bottomContent">© 2024 知识库 中冶赛迪重庆环境咨询有限公司版权所有</div>
            </Footer>
            {addKnowledgeFormOpen&&<Modal title="基本信息"
                open={addKnowledgeFormOpen}
                onCancel={handleKnowledgeFormCancel}
                destroyOnClose={true}
                maskClosable={false}
                footer={[
                    <Button onClick={()=>handleKnowledgeFormCancel()}>取消</Button>,
                    <Button onClick={()=>handleKnowledgeFormOk(0)} type="primary">发布</Button>,
                    <Button onClick={()=>handleKnowledgeFormOk(2)} type="primary">保存</Button>
                ]}
            >
                <AddKnowledgeFormModal
                    form={form}
                    type={confirmType}
                    editFileVlue={editFileVlue}
                    cancel={handleKnowledgeFormCancel}
                />
            </Modal>}

            
            {/* 多文档详情 */}
            {multiOpen&&<div className="AIMultiDocDetailsBox">
                <KnowledgeDetails pageType={"multiAi"} aiPage = {multiPage}/>
            </div>}
            
        </Layout>
    );
};

export default LayoutBox;
